import React, { useState } from "react";
import Toast from "../../../../common/toast";
import Spinner from "../../../../common/spinner";
import apiCall from "../../../../../apicall";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ConfirmationWithdrawJobSeekerPopUp = ({ jobId }) => {
  const navigate= useNavigate();
  const { TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [isWithdrawJobSeeker, setIsWithdrawJobSeeker] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setIsWithdrawJobSeeker(false);
  };

  const handleWithdrawJobSeeker = async () => {
    try {
      setIsLoading(true);
      const withdrawJobSeekerResponse = await apiCall(
        `jobs/${jobId}/withdraw`,
        "DELETE",
        null,
        TOKEN
      );
      console.log("withdrawJobSeekerResponse----", withdrawJobSeekerResponse);
      if (withdrawJobSeekerResponse?.status === 200) {
        setIsLoading(false);
        setIsWithdrawJobSeeker(true);
        setErrSuccessMsg({
          message: "Job withdrawn successfully!",
          status: true,
        });
        // setTimeout(()=>{
        //   document.body.style.overflow = "unset";
        //     document.body.style.paddingRight = "0";
        //     document.getElementById("withdraw_job_seeker_confirmation_popup").classList.remove("show");
        //     document.body.classList.remove("modal-open");
        //     document.querySelector(".modal-backdrop").remove();
        //     document
        //       .getElementById("withdraw_job_seeker_confirmation_popup")
        //       .setAttribute("aria-hidden", "true");
        //     document
        //       .getElementById("withdraw_job_seeker_confirmation_popup")
        //       .removeAttribute("aria-modal");
        //     document.getElementById("withdraw_job_seeker_confirmation_popup").style.display = "none";
        //     navigate("/job-list");
        // },1000)
      } else if (withdrawJobSeekerResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(withdrawJobSeekerResponse?.response?.data?.message)
      }
    } catch (err) {
      console.log("setSessionExpired--", err);
      displayErrMsg("Something went wrong!");
    }
  };

  const navigateToSearchJob = async () => {
    setIsWithdrawJobSeeker(false)
    navigate("/search-job");
  }

  return (
    <div
      className="modal fade twm-sign-up"
      id="withdraw_job_seeker_confirmation_popup"
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="sign_up_popupLabel2">
              {isWithdrawJobSeeker
                ? "Job withdraw successfully"
                : "Are you sure you want to withdraw job ?"}
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={navigateToSearchJob}
            />
          </div>
          <div className="modal-footer">
            {isWithdrawJobSeeker ? (
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={navigateToSearchJob}
              >
                Ok
              </button>
            ) : (
              <>
                {/* <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button> */}
                <button
                  type="button"
                  id="deleteConfirmationModal"
                  className="btn btn-success"
                  // data-bs-dismiss="modal"
                  onClick={handleWithdrawJobSeeker}
                >
                  {isLoading ? <Spinner /> : "Withdraw"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </div>
  );
};

export default ConfirmationWithdrawJobSeekerPopUp;
