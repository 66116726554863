import React, { useRef, useState, useEffect } from "react";
import Toast from "../../../../common/toast";
import Spinner from "../../../../common/spinner";
import apiCall from "../../../../../apicall";
import { useSelector } from "react-redux";
import moment from "moment";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";

const DeleteProfileConfirmation = () => {
  const deletionDate = moment().add(7, "days").format("MM/DD/YYYY");
  const { TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserDeteleted, setIsUserDeteleted] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const modalRef = useRef(null);
  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setIsUserDeteleted(false);
  };

  const handleUserDelete = async () => {
    try {
      setIsLoading(true);
      const deleteUserResponse = await apiCall(
        `deleteAccount`,
        "PUT",
        null,
        TOKEN
      );
      console.log("deleteUserResponse----", deleteUserResponse);
      if (deleteUserResponse?.status === 200) {
        setIsLoading(false);
        setIsUserDeteleted(true);
        setErrSuccessMsg({
          message: "Account deletion request submitted.",
          status: true,
        });
        setTimeout(() => {
          if (modalRef.current) {
              modalRef.current.click();
          }
      }, 2000);
      } else if (deleteUserResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(deleteUserResponse?.response?.data?.message)
      }
    } catch (err) {
      console.log("setSessionExpired--", err);
      displayErrMsg("Something went wrong!");
    }
  };

  useEffect(() =>{
    logEvent_Analytics('profile_deletion_web');
  }, [])

  return (
    <div
      className="modal fade twm-sign-up"
      id="delete_profile_popup"
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            {/* {isUserDeteleted ? (
              <h2 className="modal-title" id="sign_up_popupLabel2">
                "Account Delete requested Successfully!"
              </h2>
            ) : (
              <> */}
                <h2 className="modal-title" id="sign_up_popupLabel2">
                Are you sure you want to delete your account?
                </h2>
                <p>
                  If you confirm account deletion, your account will be
                  deleted on <b>{deletionDate}</b>
                </p>
                <br />
                <p>
                  If you don't want your account to be deleted, please log in to the app
                  before the date mentioned above and your request will automatically be withdrawn.
                </p>
                <p>Thank you for using the app.</p>
              {/* </>
            )} */}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={modalRef}
              onClick={() => setIsUserDeteleted(false)}
            />
          </div>
          <div className="modal-footer">
            {/* {isUserDeteleted ? (
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setIsUserDeteleted(false)}
              >
                Ok
              </button>
            ) : (
              <> */}
                <button
                  type="button"
                  id="deleteConfirmationModal"
                  className="btn btn-primary"
                  onClick={handleUserDelete}
                >
                  {isLoading ? <Spinner /> : "Delete"}
                </button>
              {/* </>
            )} */}
          </div>
        </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </div>
  );
};

export default DeleteProfileConfirmation;
