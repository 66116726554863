import React from 'react';

const Avatar = ({ firstName, lastName, isAdmin }) => {
  // Function to get initials
  const getInitials = (firstName, lastName) => {
    const initials = (firstName?firstName.charAt(0).toUpperCase():'') + (lastName ? lastName.charAt(0).toUpperCase() : '');
    return initials;
  };

  // Function to generate background color based on initials
  const getColor = (initials) => {
    const colors = ['#2196F3', '#4CAF50', '#FFC107', '#E91E63', '#9C27B0']; // Array of colors
    const charCodeSum = initials.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return colors[charCodeSum % colors.length]; // Select color based on sum of char codes
  };

  const initials = getInitials(firstName, lastName);
  const color = getColor(initials);

  return (
    <div
      style={{
        // display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '45px',
        height: '45px',
        borderRadius: '50%',
        backgroundColor: color,
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '1rem',
        // marginLeft:'-4px',
        // marginTop:'-4px',
        // paddingLeft:'9px',
        // paddingTop:'9px',
        marginRight:'10px',
        display: 'flex'
      }}
    >
      {isAdmin ? <i class="fa fa-users" aria-hidden="true"></i> : initials}
    </div>
  );
};

export default Avatar;