import React, { useState, useEffect, useRef } from "react";
import PrivacyPolicayModal from "../../pannels/candidate/sections/profile/privacyPolicy";
import TermsConditionModal from "../../pannels/candidate/sections/profile/termsCondition";
import "react-datepicker/dist/react-datepicker.css";
import Toast from "../toast";
import Spinner from "../spinner";
// import { GOOGLE_API } from "../../../config";
import apiCall from "../../../apicall";
import GooglePlacesAutocomplete from "react-google-autocomplete";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationRegistrationSeekerPopUp from "./confirmationRegistrationSeeker";
import { EmailVerification, deleteFirebaseUser, insertFirebaseUser } from "./firebase-email-verification";
import { navigateVisitedPage } from "../../../store/reducers/menu";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

const JobSeekerRegister = ({ role_type }) => {
  const { CONFIGKEY } = useSelector((state) => state.menu)
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPositionList, setIsLoadingPositionList] = useState(false);
  const [positionDropdownList, setPositionDropdownList] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedFields, setSelectedFields] = useState([]);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsCondition, setShowTermsCondition] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedProfessionList, setSelectedProfessionList] = useState([]);
  const [selectedProfession, setSelectedProfession] = useState("");
  const dropdownRef = useRef(null);
  const [formValues, setFormValues] = useState({
    name: "",
    position: "",
    // profession: {},
    // userName: "",
    firstName: "",
    lastName: "",
    // phoneNumber: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    suitNumber: "",
  });
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const [privacyPolicyId, setPrivacyPolicyId] = useState("");
  const [termsAndConditionId, setTermsAndConditionId] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, ""); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNumber; // Return original value if not 10 digits
  };

  // const handleInputChange = (e, fieldName) => {
  //   const { name, value } = e.target;
  //   if (fieldName === "phoneNumber") {
  //     // Format phone number when 10 digits are completed
  //     const formattedPhoneNumber = formatPhoneNumber(value);
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       [fieldName || name]: formattedPhoneNumber,
  //     }));
  //   } else {
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       [fieldName || name]: value,
  //     }));
  //   }
  //   setFormErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [fieldName || name]: "",
  //   }));
  // };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  // const handleInputChange = (e, fieldName) => {

  //   const { name, value } = e.target;
  //   console.log("name, value ",name, value )
  //   let formattedValue = value;

  //   if (fieldName === "firstName" || fieldName === "lastName") {
  //     formattedValue = capitalizeFirstLetter(value);
  //   } 
  //   // else if (fieldName === "userName") {
  //   //   formattedValue = value.toLowerCase();
  //   // } 
  //   else if (fieldName === "phoneNumber") {
  //     formattedValue = formatPhoneNumber(value);
  //   }

  //   setFormValues((prevValues) => ({
  //     ...prevValues,
  //     [fieldName || name]: formattedValue,
  //   }));

  //   setFormErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [fieldName || name]: "",
  //   }));
  // }; 
  // const handleInputChange = (e, fieldName, position) => {
  //   const { name, value } = e.target;
  //   console.log("name, value ", name, value);
  //   let formattedValue = value;

  //   if (name === "firstName" || name === "lastName") {
  //     formattedValue = capitalizeFirstLetter(value);
  //   } else if (name === "phoneNumber") {
  //     formattedValue = formatPhoneNumber(value);
  //   }

  //   if (position) {
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       [fieldName]: {
  //         ...prevValues[fieldName],
  //         [name]: formattedValue,
  //       },
  //     }));
  //     setFormErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [fieldName]: {
  //         ...prevErrors[fieldName],
  //         [name]: "",
  //       },
  //     }));
  //   } else {
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       [name]: formattedValue,
  //     }));
  //     setFormErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [name]: "",
  //     }));
  //   }
  // };
  const handleInputChange = (e, fieldName, position) => {
    let name, value;
  
    if (typeof e === "string") {
      // If e is a string, it means this is a ReactQuill change event
      name = fieldName;
      value = e;
    } else {
      // Otherwise, it's a regular input change event
      ({ name, value } = e.target);
    }
  
    console.log("name, value ", name, value);
    let formattedValue = value;
  
    if (name === "firstName" || name === "lastName") {
      formattedValue = capitalizeFirstLetter(value);
    } else if (name === "phoneNumber") {
      formattedValue = formatPhoneNumber(value);
    }
  
    if (position) {
      setFormValues((prevValues) => ({
        ...prevValues,
        [fieldName]: {
          ...prevValues[fieldName],
          [name]: formattedValue,
        },
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: {
          ...prevErrors[fieldName],
          [name]: "",
        },
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: formattedValue,
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };
  


  const handleDateChange = (date, fieldName) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: date,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };
  const today = new Date();

  // Calculate the latest birth date for 18-year-olds
  const min18Date = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );

  const handlePlaceSelect = (place) => {
    // Update form values with selected place details
    if (place?.geometry) {
      const addressComponents = place.address_components;
      const formattedAddress = place.formatted_address;
      const locationName =
        addressComponents.find(
          (component) =>
            component.types.includes("point_of_interest") ||
            component.types.includes("sublocality") ||
            component.types.includes("neighborhood")
        )?.long_name || formattedAddress.split(",")[0];
      setFormValues((prevValues) => ({
        ...prevValues,
        address: formattedAddress,
        locationName,
        city:
          addressComponents.find((component) =>
            component.types.includes("locality")
          )?.long_name || "",
        state:
          addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.short_name || "",
        country:
          addressComponents.find((component) =>
            component.types.includes("country")
          )?.long_name || "",
        zipCode:
          addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.short_name || "",
      }));
      // setAddressValue(formattedAddress); // Update the address value
    }
  };
  console.log("Selected Types:", selectedType);

  const validateForm = () => {
    const errors = {};
  
    // Validate fields common to both roles
    if (!formValues.firstName || formValues.firstName.trim() === "") {
      errors.firstName = "First Name is required.";
    }
  
    if (!formValues.lastName || formValues.lastName.trim() === "") {
      errors.lastName = "Last Name is required.";
    }
  
    // if (!formValues.phoneNumber || formValues.phoneNumber.length !== 12) {
    //   errors.phoneNumber = "Phone number must be in the format 123-456-7890.";
    // }
  
    if (!formValues.email || formValues.email.trim() === "") {
      errors.email = "Email is required.";
    }
  
    if (!formValues.address || formValues.address.trim() === "") {
      errors.address = "Address is required.";
    }
  
    if (role_type === "ROLE_USER") {
      if (!formValues.dob) {
        errors.dob = "Date of Birth is required.";
      } else {
        const dob = new Date(formValues.dob);
        const min18Date = new Date(
          new Date().getFullYear() - 18,
          new Date().getMonth(),
          new Date().getDate()
        );
        if (dob > min18Date) {
          errors.dob = "You must be at least 18 years old.";
        }
      }
      // if (!formValues.last4SSN || formValues.last4SSN.trim() === "") {
      //   errors.last4SSN = "Last 4 SSN is required.";
      // } else if (!/^\d{4}$/.test(formValues.last4SSN)) {
      //   errors.last4SSN = "Last 4 SSN must be exactly 4 digits long and contain only numbers.";
      // }
      if (!formValues.coverLetter || formValues.coverLetter.trim() === "") {
        errors.coverLetter = "Resume / Cover Letter is required.";
      }
    }
  
    // Add validation for profession
    if (selectedType.length === 0) {
      errors.profession = "At least one profession must be selected.";
    }
  
    // Check if Privacy Policy and Terms are agreed to
    if (!formValues.privacyPolicyId) {
      errors.privacyPolicyId = "Privacy Policy must be agreed to.";
    }
  
    if (!formValues.termsAndConditionId) {
      errors.termsAndConditionId = "Terms & Conditions must be agreed to.";
    }
  
    // Validate additional fields
    Object.entries(selectedFields).forEach(([typeName, fields]) => {
      fields.forEach((field) => {
        if (
          field.required &&
          (!formValues[typeName] ||
            !formValues[typeName][field.mapField]?.trim())
        ) {
          errors[typeName] = errors[typeName] || {};
          errors[typeName][field.mapField] = `${field.title} is required.`;
        }
      });
    });
  
    // Log errors for debugging
    console.log("Validation Errors:", errors);
  
    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors, false if there are errors
  };
  

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
  };

  // const handlePositionchange = (e) => {
  //   if (e.target.value !== "") {
  //     setSelectedType(e.target.value);
  //     const selectedPosition = positionDropdownList.find(
  //       (pos) => pos.typeName === e.target.value
  //     );
  //     const fileds = JSON.parse(selectedPosition.relatedFields);
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       profession: selectedPosition?.typeName,
  //     }));

  //     setSelectedFields(selectedPosition ? fileds.fields : []);
  //     setFormErrors((prevErrors) => ({
  //       ...prevErrors,
  //       profession: "",
  //     }));
  //   }
  // };

  const displaySuccessMsg = () => {
    setIsLoading(false);
    setErrSuccessMsg({
      message: "User registered successfully!",
      status: true,
    });
    setShowConfirmation(true);
    // alert("afterConfirm")
    setSelectedType("");
    setSelectedFields([]);
    setFormValues({
      name: "",
      position: "",
      // userName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      last4SSN: "",
      dob: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      profession: "",
      coverLetter: "",
      privacyPolicyId: "",
      termsAndConditionId: "",
      suitNumber: "",
    });
  };

  const handleAgreePrivacy = (modalRef) => {
    // console.log("handle privacy-----")
    setFormValues((prevValues) => ({
      ...prevValues,
      privacyPolicyId: privacyPolicyId,
    }));
    // const modal = modalRef.current;
    // if (modal) {
    //   const modalInstance = Modal.getInstance(modal);
    //   modalInstance.hide();
    // }
    // const privacyModal = document.getElementById("privacy_policy");
    // const modal = window.bootstrap.Modal.getInstance(privacyModal);
    // modal.hide();
    //setShowPrivacyPolicy(false);
  };
  const handleConfirmationPopup = () => {
    setShowConfirmation(false);
  };

  const handleAgreeTerms = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      termsAndConditionId: termsAndConditionId,
    }));
    const privacyModal = document.getElementById("terms_and_condition_id");
    const modal = window.bootstrap.Modal.getInstance(privacyModal);
    modal.hide();
    // setShowTermsCondition(false);
  };

  const handleRegisterSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setIsLoading(true);
      const firebaseUID = await EmailVerification(formValues.email);
      console.log('firebaseUID:poster::::::::::::::', firebaseUID)
      if (firebaseUID?.uid) {
        const finalProfessionData = [];

        selectedType.forEach((profession) => {
          const professionData = formValues[profession] || {};
          const data = {
            profession,
            ...professionData,
          };
          finalProfessionData.push(data);
          console.log("data::::::::::::::", data);
        });

        const formattedData = {
          ...formValues,

          dob: moment(formValues.dob).format("YYYY-MM-DD"),

          tosDate: "2019-04-10T01:24:34.060Z",

          tosIpAddress: "10.10.10.1",
          // dob: moment(formValues.dob).format("YYYY-MM-DD"),
          // tosDate: "2019-04-10T01:24:34.060Z",
          // tosIpAddress: "10.10.10.1",
          address: formValues?.address?.split(",")[0],
          phoneType: "Mobile",
          country: "US",
          zipCode: formValues?.zipCode != "" ? formValues?.zipCode : '77077',
          firebaseId: firebaseUID?.uid,
          professions: finalProfessionData
        };
        try {
          const registerResponse = await apiCall("registration/users", "POST", formattedData);
          console.log("registerResponse", registerResponse)
          if (registerResponse?.status === 201) {
            displaySuccessMsg();
            setShowConfirmation(true);
            EmailVerification(formValues.email)
            const ConfirmationBackDropSeeker = document.getElementById("modalBackdropSeekerRegister");
            const ConfirmationPopupModalSeeker = document.getElementById(
              "confirmationRegistrationSeekerPopup"
            );
            formattedData["role_type"] = "ROLE_USER"
            insertFirebaseUser(formattedData, firebaseUID?.uid)
              .then(() => {
                console.log('User inserted successfully');
              })
              .catch((error) => {
                console.error('Error inserting user:', error);
              });
            if (ConfirmationPopupModalSeeker) {
              ConfirmationBackDropSeeker.style.zIndex = "1";
              ConfirmationBackDropSeeker.classList.add("show");
              ConfirmationPopupModalSeeker.classList.add("show");
              ConfirmationPopupModalSeeker.style.display = "block";
              ConfirmationBackDropSeeker.style.display = "block";
            }
          } else if (registerResponse?.response?.status === 400) {
            displayErrMsg(registerResponse?.response?.data?.errorMessage);
            setIsLoading(false);
            deleteFirebaseUser()
          } else {
            // displayErrMsg("Something went wrong!");
            displayErrMsg(registerResponse?.response?.data?.message)
            deleteFirebaseUser()
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
          displayErrMsg("Something went wrong!");
          deleteFirebaseUser()
          // console.error("Error:", error);
        }
      }
      else {
        setIsLoading(false);
        displayErrMsg(firebaseUID?.message);
        // console.log("Form validation failed.");
      }
    } else {
      setIsLoading(false);
      displayErrMsg("Check the form validation!");
      // console.log("Form validation failed.");
    }
  };

  const onPrivacyPolicyChange = (e) => {


    if (e.target.checked) {
      const privacyPolicyLink = document.querySelector('a[href="#privacy_policy"]');
      // console.log("inside privacy change-----", privacyPolicyLink, e.target.checked)

      privacyPolicyLink?.click();
      // setShowPrivacyPolicy(true);
      setFormValues((prevValues) => ({
        ...prevValues,
        privacyPolicyId: privacyPolicyId,
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        privacyPolicyId: "",
      }));
    } else {
      setShowPrivacyPolicy(false);
      setFormValues((prevValues) => ({
        ...prevValues,
        privacyPolicyId: "",
      }));
    }
  };

  const onTermsConditionChange = (e) => {
    if (e.target.checked) {
      const termsConditionLink = document.querySelector('a[href="#terms_and_condition_id"]');
      if (termsConditionLink) {
        termsConditionLink.click();
      }
      //setShowTermsCondition(true);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        termsAndConditionId: "",
      }));
      setFormValues((prevValues) => ({
        ...prevValues,
        termsAndConditionId: termsAndConditionId,
      }));
    } else {
      setShowPrivacyPolicy(false);
      setFormValues((prevValues) => ({
        ...prevValues,
        termsAndConditionId: "",
      }));
    }
  };

  const removePrivacy = () => {
    const privacyModal = document.getElementById("privacy_policy");
    const modal = window.bootstrap.Modal.getInstance(privacyModal);
    modal.hide();
    setFormValues((prevValues) => ({
      ...prevValues,
      privacyPolicyId: '',
    }));
    // setShowTermsCondition(false);
  };

  const removeTermsCondition = () => {
    // setShowTermsCondition(false);
    const privacyModal = document.getElementById("terms_and_condition_id");
    const modal = window.bootstrap.Modal.getInstance(privacyModal);
    modal.hide();
    setFormValues((prevValues) => ({
      ...prevValues,
      termsAndConditionId: '',
    }));
  };

  const getPositionDropdownList = async () => {
    try {
      setIsLoadingPositionList(true);
      const positionResponse = await apiCall("config/allJobTypes", "GET", null);
      console.log("JobSeekerRegister", positionResponse)
      if (
        positionResponse?.status === 200 &&
        positionResponse?.data?.length > 0
      ) {
        setIsLoadingPositionList(false);
        setPositionDropdownList(positionResponse?.data);
      } else if (
        positionResponse?.status === 200 &&
        positionResponse?.data?.length === 0
      ) {
        setIsLoadingPositionList(false);
      }
    } catch (err) {
      setIsLoadingPositionList(false);
      displayErrMsg("Somthing went wrong!");
    }
  };

  useEffect(() => {
    getPositionDropdownList();
    dispatch(navigateVisitedPage(null));
  }, []);
  const dropdownIconBoxStyle = {
    position: "relative",
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const dropdownIconStyle = {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const dropdownPaddingRight = {
    paddingRight: "30px", // Adjust the padding to make room for the icon
  };
  const getCheckboxAttributesDisabled = (position) => {
    const isChecked = selectedType.includes(position.typeName);
    const isOnlySelected = selectedType.length === 1 && isChecked;

    return isOnlySelected ? { disabled: true } : "";
  };
  const getCheckboxAttributes = (checked) => {
    // setSelectedProfession(profession);
    console.log("profession-----------", checked, selectedType);
    return !checked
      ? {
        "data-bs-toggle": "modal",
        href: "#remove_profession_confirmation",
      }
      : {};
  };
  const handlePositionChange = (e, profession) => {
    const { value, checked } = e.target;
    setSelectedProfession(profession);
    setSelectedType((prevSelectedTypes) => {
      const updatedSelectedTypes = checked
        ? [...prevSelectedTypes, value]
        : prevSelectedTypes.filter((type) => type !== value);
      const selectedPositions = updatedSelectedTypes
        .map((type) =>
          positionDropdownList.find((pos) => pos.typeName === type)
        )
        .filter(Boolean); // Ensure only valid positions are processed

      const newFields = {};
      selectedPositions.forEach((pos) => {
        newFields[pos.typeName] = JSON.parse(pos.relatedFields).fields;
      });
      setSelectedFields(newFields);

      const newFormValues = { ...formValues };
      updatedSelectedTypes.forEach((typeName) => {
        if (!newFormValues[typeName]) {
          newFormValues[typeName] = {};
          newFields[typeName]?.forEach((field) => {
            newFormValues[typeName][field.mapField] = "";
          });
        }
      });
      setFormValues(newFormValues);
      if (updatedSelectedTypes.length > 0) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          profession: "", // Clear the profession error
        }));
      }

      console.log("updatedSelectedTypes---", updatedSelectedTypes);

      return updatedSelectedTypes;
    });
  };
  const handlePrivacyPolicyClick = () => {
    if (!formValues.privacyPolicyId) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        privacyPolicyId: "",
      }));
    }
  };
  const handleTermsAndConditionsClick = () => {
    if (!formValues.termsAndConditionId) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        termsAndConditionId: "",
      }));
    }
  };
  return (
    <>
      <PrivacyPolicayModal
        setPrivacyPolicyId={setPrivacyPolicyId}
        handleAgreePrivacy={handleAgreePrivacy}
        removePrivacy={removePrivacy}
      />

      {/* ) : showConfirmation ? ( */}
      {/* {showConfirmation && */}
      <ConfirmationRegistrationSeekerPopUp />
      {/* } */}
      {/* ) : showTermsCondition ? ( */}
      <TermsConditionModal
        setTermsAndConditionId={setTermsAndConditionId}
        handleAgreeTerms={handleAgreeTerms}
        removeTermsCondition={removeTermsCondition}
      />
      {/* ) : ( */}
      <form id="jobseeker_form">
        <div className="panel panel-default">
          <div className="panel-body wt-panel-body p-a20 m-b30 ">
            <div className="row">
              {/* <div className="col-xl-4 col-lg-4 col-md-12">
                <div className="form-group">
                  <label>User Name</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="userName"
                      type="text"
                      placeholder="User Name"
                      maxLength={20}
                      value={formValues.userName}
                      onChange={(e) => handleInputChange(e, "userName")}
                    />
                    <i className="fs-input-icon fa fa-user " />
                  </div>
                  {formErrors.userName && (
                    <span className="error-message">{formErrors.userName}</span>
                  )}
                </div>
              </div> */}
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>First Name</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="firstName"
                      maxLength={20}
                      type="text"
                      placeholder="First Name"
                      value={formValues.firstName}
                      onChange={(e) => handleInputChange(e, "firstName")}
                    />
                    <i className="fs-input-icon fa fa-user " />
                  </div>
                  {formErrors.firstName && (
                    <span className="error-message">
                      {formErrors.firstName}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Last Name</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="lastName"
                      maxLength={20}
                      type="text"
                      placeholder="Last Name"
                      value={formValues.lastName}
                      onChange={(e) => handleInputChange(e, "lastName")}
                    />
                    <i className="fs-input-icon fa fa-user " />
                  </div>
                  {formErrors.lastName && (
                    <span className="error-message">{formErrors.lastName}</span>
                  )}
                </div>
              </div>
              {/* <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Phone</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="phoneNumber"
                      type="text"
                      placeholder="Phone Number"
                      value={formValues.phoneNumber}
                      maxLength={12}
                      onChange={(e) => handleInputChange(e, "phoneNumber")}
                    />
                    <i className="fs-input-icon fa fa-phone-alt" />
                  </div>
                  {formErrors.phoneNumber && (
                    <span className="error-message">
                      {formErrors.phoneNumber}
                    </span>
                  )}
                </div>
              </div> */}
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Email Address</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="email"
                      type="email"
                      placeholder="Email"
                      value={formValues.email}
                      onChange={(e) => handleInputChange(e, "email")}
                    />
                    <i className="fs-input-icon fas fa-at" />
                  </div>
                  {formErrors.email && (
                    <span className="error-message">{formErrors.email}</span>
                  )}
                </div>
              </div>
              {/* {role_type === "ROLE_USER" && ( */}
              {/* <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>SSN Last 4</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="last4SSN"
                      type="text"
                      placeholder="Last 4 SSN"
                      value={formValues.last4SSN}
                      maxLength={4}
                      onChange={(e) => handleInputChange(e, "last4SSN")}
                    />
                    <i className="fs-input-icon fa fa-id-card" />
                  </div>
                  {formErrors.last4SSN && (
                    <span className="error-message">
                      {formErrors.last4SSN}
                    </span>
                  )}
                </div>
              </div> */}
              {/* )} */}
              {/* {role_type === "ROLE_USER" && ( */}
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>DOB</label>
                  <div className="ls-inputicon-box">
                    {/* <input
                        className="form-control"
                        name="dob"
                        type="date"
                        placeholder="MM/DD/YYYY"
                        value={formValues.dob}
                        onChange={(e) => handleInputChange(e, "dob")}
                      /> */}
                    <ReactDatePicker
                      className="form-control"
                      name="dob"
                      id="dob"
                      selected={formValues.dob}
                      onChange={(date) => handleDateChange(date, "dob")}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="Date Of Birth"
                      maxDate={min18Date}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    <i className="fs-input-icon fa fa-calendar-alt" />
                  </div>
                  {formErrors.dob && (
                    <span className="error-message">{formErrors.dob}</span>
                  )}
                </div>
              </div>
              {/* )} */}
              <div className="col-xl-12 col-lg-12 col-md-12">
                {/* <div className="form-group city-outer-bx has-feedback"> */}
                <div className="form-group ">
                  <label>Address</label>
                  <div className="ls-inputicon-box">
                    <GooglePlacesAutocomplete
                      //ref={googlePlacesRef}
                      name='address'
                      value={formValues.address}
                      onChange={(e) => handleInputChange(e, "address")}
                      onPlaceSelected={handlePlaceSelect}
                      className="form-control"
                      placeholder="Select for a location"
                      apiKey={process.env.REACT_APP_GOOGLE_API}
                      // apiKey={GOOGLE_API}
                      types={["geocode"]}
                      options={{
                        types: ["address"],
                        bounds: {
                          north: parseFloat(process.env.REACT_APP_LOC_NORTH_BOUND),  
                          south: parseFloat(process.env.REACT_APP_LOC_SOUTH_BOUND),  
                          east:  parseFloat(process.env.REACT_APP_LOC_EAST_BOUND), 
                          west:  parseFloat(process.env.REACT_APP_LOC_WEST_BOUND),  
                        },
                        componentRestrictions: { country: "us" },
                      }}
                    />
                    <i className="fs-input-icon fas fa-map-marker-alt" />
                  </div>
                  {formErrors.address && (
                    <span className="error-message">{formErrors.address}</span>
                  )}
                </div>
              </div>
              {/* {formValues.address && (
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="form-group ">
                    <label>Location Name</label>
                    <div className="ls-inputicon-box">
                      <input
                        className="form-control"
                        name="locationName"
                        type="text"
                        placeholder="Location Name"
                        value={formValues.locationName}
                        onChange={(e) => handleInputChange(e, "locationName")}
                      />
                    </div>
                  </div>
                </div>
              )} */}
              {/* {formValues.address && (
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="form-group ">
                    <label>Suite/Unit/Building</label>
                    <div className="ls-inputicon-box">
                      <input
                        className="form-control"
                        name="suitNumber"
                        type="text"
                        placeholder="Suite/Unit/Building"
                        value={formValues.suitNumber}
                        onChange={(e) => handleInputChange(e, "suitNumber")}
                      />
                    </div>
                  </div>
                </div>
              )} */}
              {/* {positionDropdownList?.length > 0 &&
                ( */}
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="form-group ">
                  <label>Professions</label>
                  {/* <div style={dropdownIconBoxStyle}>
                        <select
                          className="form-control"
                          name="profession"
                          id="profession"
                          style={dropdownPaddingRight}
                          value={formValues.profession || ""}
                          onChange={handlePositionchange}
                        >
                          <option value="">Select Job Type</option>
                          {positionDropdownList?.map((position) => (
                            <option
                              key={position.typeName}
                              value={position.typeName}
                            >
                              {position.typeName}
                            </option>
                          ))}
                        </select>
                        <i
                          className="fs-input-icon fa fa-chevron-down"
                          style={dropdownIconStyle}
                        />
                      </div> */}
                  <div className="custom-dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle form-control"
                      onClick={toggleDropdown}
                      style={{ color: "black", textAlign: "left", height: "auto", textWrap: "wrap" }}
                    >
                      {/* {Object.keys(selectedFields).join(', ')} */}
                      {selectedType.length > 0 ? selectedType.join(', ') : 'Select Job Type'}
                    </button>
                    <div
                      ref={dropdownRef}
                      className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}
                    >
                      {positionDropdownList?.map((position) => (
                        <div key={position.typeName} className="dropdown-item">
                          <input
                            type="checkbox"
                            style={{ marginRight: 10 }}
                            value={position.typeName}
                            checked={selectedType.includes(position.typeName)}
                            onChange={(e) =>
                              handlePositionChange(e, position.typeName)
                            }
                            {...getCheckboxAttributes(
                              !selectedProfessionList.includes(position.typeName)
                            )}
                            {...getCheckboxAttributesDisabled(position)}
                          />
                          <label>{position.typeName}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                  {formErrors.profession && (
                        <span className="error-message">
                          {formErrors.profession}
                        </span>
                      )}

                </div>
                {Object.entries(selectedFields).map(([typeName, fields]) => (
                  fields.length > 0 && (
                  <div key={typeName}>
                    <h4>{typeName}</h4>
                    {fields.map((field) => (
                      <div key={field.mapField} className="form-group">
                        {/* {console.log("ield.mapField",field.mapField,formValues,formValues[typeName],formValues[typeName]?.[field.mapField])} */}
                        <label>{field.title}</label>
                        <input
                          type="text"
                          name={field.mapField}
                          className="form-control"
                          value={formValues[typeName]?.[field.mapField] || ""}
                          onChange={(e) => handleInputChange(e, typeName, "position")}
                        />
                        {formErrors[typeName]?.[field.mapField] && (
                          <span className="error-message">
                            {formErrors[typeName][field.mapField]}
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                  )
                ))}
              </div>
              {/* )} */}

              {/* {selectedFields?.length > 0 &&
                selectedFields.map((field) => (
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div key={field.mapField} className="form-group">
                      <label>{field.title}</label>
                      <div className="ls-inputicon-box">
                        <input
                          type="text"
                          className="form-control"
                          name={field.mapField}
                          value={formValues[field.mapField] || ""}
                          onChange={(e) => handleInputChange(e, field.mapField)}
                          required={field.required}
                        />
                        {formErrors[field.mapField] && (
                          <span className="error-message">
                            {formErrors[field.mapField]}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))} */}

              {/* {role_type === "ROLE_USER" && ( */}
              {/* <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="form-group city-outer-bx has-feedback">
                  <label>Cover Letter</label>
                  <div className="ls-inputicon-box">
                    <textarea
                      className="form-control"
                      name="coverLetter"
                      placeholder="Cover Letter"
                      value={formValues.coverLetter}
                      onChange={(e) => handleInputChange(e, "coverLetter")}
                      rows={5} // You can adjust the number of rows as needed
                    />
                    <i className="fs-input-icon fas fa-book" />
                    {formErrors.coverLetter && (
                      <span className="error-message">
                        {formErrors.coverLetter}
                      </span>
                    )}
                  </div>
                </div>
              </div> */}
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="form-group city-outer-bx has-feedback">
                  <label>Resume / Cover Letter</label>
                  <div className="ls-inputicon-box">
                    <ReactQuill
                      theme="snow"
                      value={formValues.coverLetter}
                      onChange={(e) => handleInputChange(e, "coverLetter")}
                      placeholder="Resume / Cover Letter"
                      modules={{
                        toolbar: [
                          [{ 'font': [] }],
                          [{size: []}],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{'list': 'ordered'}, {'list': 'bullet'}],
                          ['link', 'image'],
                          ['clean']
                        ]
                      }}
                      formats={[
                        'header', 'font', 'size',
                        'bold', 'italic', 'underline', 'strike', 'blockquote',
                        'list', 'bullet',
                        'link', 'image', 'video'
                      ]}
                    />
                    {formErrors.coverLetter && (
                      <span className="error-message">
                        {formErrors.coverLetter}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {/* )} */}
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group city-outer-bx has-feedback">
                  <div className="ls-inputicon-box">
                    <input
                      type="checkbox"
                      name="privacyPolicyId"
                      checked={formValues.privacyPolicyId}
                      onChange={(e) => onPrivacyPolicyChange(e)}
                    />
                    <a
                      // className=""
                      data-bs-toggle="modal"
                      href="#privacy_policy"
                      role="button"
                      style={{ marginLeft: 10 }}
                      onClick={handlePrivacyPolicyClick}
                    >
                      Privacy Policy
                    </a>
                    {formErrors.privacyPolicyId && (
                      <div className="error-message">
                        {formErrors.privacyPolicyId}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group city-outer-bx has-feedback">
                  <div className="ls-inputicon-box">
                    <input
                      type="checkbox"
                      name="termsAndConditionId"
                      checked={formValues.termsAndConditionId}
                      onChange={(e) => onTermsConditionChange(e)}
                    />
                    <a
                      className=""
                      //className="twm-nav-post-a-job"
                      data-bs-toggle="modal"
                      href="#terms_and_condition_id"
                      role="button"
                      style={{ marginLeft: 10 }}
                      onClick={handleTermsAndConditionsClick}
                    >
                      Terms & Conditions
                    </a>
                    {formErrors.termsAndConditionId && (
                      <div className="error-message">
                        {formErrors.termsAndConditionId}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="text-center">
                  <button
                    type="submit"
                    className="site-button"
                    onClick={handleRegisterSubmit}
                  >
                    {isLoading ? <Spinner /> : "Register"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/* )} */}
      {errSuccessMesg?.message !== "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
};

export default JobSeekerRegister;
