
import JobPosterRegister from "../register/jobposterregister";
import JobSeekerRegister from "../register/jobseekerregister";

function SignUpPopup() {
  

  return (
    <div className="twm-sign-up-screen">
      <div className="sign-up-container">
        <form>
          <div className="header">
            <h2 className="title">Sign Up</h2>
            <p>Sign Up and get access to all the features of {process.env.REACT_APP_COMPANY_NAME}</p>
          </div>
          <div className="body">
            <div className="twm-tabs-style-2">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                {/* Signup Candidate */}
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    data-bs-toggle="tab"
                    data-bs-target="#sign-candidate"
                    type="button"
                  >
                    <i className="fas fa-user-tie" /> Candidate
                  </button>
                </li>
                {/* Signup Employer */}
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#sign-Employer"
                    type="button"
                  >
                    <i className="fas fa-building" /> Employer
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                {/* Signup Candidate Content */}
                <div className="tab-pane fade show active" id="sign-candidate">
                  <JobSeekerRegister role_type="ROLE_USER"/>
                </div>
                {/* Signup Employer Content */}
                <div className="tab-pane fade" id="sign-Employer">
                  <JobPosterRegister role_type="ROLE_INSTITUTION_ADMIN"/>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="footer">
            <span className="footer-title">Login or Sign up with</span>
            <ul className="twm-modal-social">
              <li>
                <a href="https://www.facebook.com/" className="facebook-clr">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="https://www.twitter.com/" className="twitter-clr">
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="https://in.linkedin.com/" className="linkedin-clr">
                  <i className="fab fa-linkedin-in" />
                </a>
              </li>
              <li>
                <a href="https://www.google.com/" className="google-clr">
                  <i className="fab fa-google" />
                </a>
              </li>
            </ul>
          </div> */}
        </form>
      </div>
    </div>
  );
}

export default SignUpPopup;
