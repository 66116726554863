function TableLoader() {
    return (
      <center>
        <div className="wrapper">
          <div className="cssload-loader" />
        </div>
      </center>
    );
  }
  
  export default TableLoader;
  