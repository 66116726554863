import { NavLink, useNavigate } from "react-router-dom"
import { publicUser } from "../../../../../globals/route-names"
import SectionPagination from "../common/section-pagination"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import GetProfileImage from "../../../../common/getProfileImage"
import TableLoader from "../../../../common/tableLoader"
import { employerDetails } from "../../../../../store/reducers/menu"
import { Helmet } from "react-helmet-async"
import { formatCompanyName } from "../../../../../globals/fomat-comapny-name"

function SectionEmpsGrid({ isLoading, empListData, currentPage, setCurrentPage, totalPages }) {
  const { role_type } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const StarRating = ({ rating }) => {
  //     const stars = [];
  //     for (let i = 0; i < 5; i++) {
  //         if (i < rating) {
  //             stars.push(<i key={i} className="feather feather-star" style={{ color: '#128c5e' }} />);
  //         } else {
  //             stars.push(<i key={i} className="feather feather-star" style={{ color: 'lightgrey' }} />);
  //         }
  //     }
  //     return stars;
  // };

  const StarRating = ({ rating }) => {
    const stars = [];
    // console.log("rating----------", rating);
    // Iterate over 5 stars
    for (let i = 0; i < 5; i++) {
      if (rating >= i + 1) {
        // Full star if rating is greater than or equal to current index + 1
        stars.push(
          <i
            key={i}
            className="fas fa-star"
            style={{ color: '#128c5e' }}
          />
        );
      } else if (rating >= i + 0.5) {
        // Half star if rating is greater than or equal to current index + 0.5
        stars.push(
          <i
            key={i + 0.5}
            className="fas fa-star-half-alt"
            style={{ color: '#128c5e' }}
          />
        );
      } else {
        // Empty star otherwise
        stars.push(
          <i
            key={i}
            className="far fa-star"
            style={{ color: 'lightgrey' }}
          />
        );
      }
    }

    return <div>{stars}</div>;
  };


  const navigateToEmpDetails = (item) => {
    console.log("item----------", item);
    dispatch(employerDetails(item))
    navigate('/emp-detail', { state: item })
  }
  const formatAddress = (address, city, state, zipCode) => {
    const parts = [address, city, state, zipCode].filter(Boolean);
    return parts.join(", ");
  };
  return (
    <>
    <div>
        <Helmet>
          <title>{`Employer Grid | Companies Hiring | Employer Listings | Company Profiles | ${formatCompanyName()}`}</title>
          <meta name="description" content="Welcome to the Employer Grid! This section provides a comprehensive directory of companies actively hiring and seeking talent. Browse through a diverse range of employers to find potential job opportunities that align with your career goals." />
          <meta name="keywords" content="employers, hiring companies, company directory, Career Opportunities, Employer Listings, Job Openings" />
        </Helmet>
      </div>
      <div>
        <div className="twm-employer-list-wrap">
          <div className="row">
            {!isLoading ? (
              empListData?.length > 0 ? (
                empListData?.map((item) => (
                  <div class="col-lg-6 col-md-6">
                    <div className="twm-employer-grid-style1 mb-5">
                      <div className="twm-media">
                        <GetProfileImage id={item?.id} type={'jobList'} />
                      </div>
                      <div className="twm-mid-content">
                        {/* <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                                <h4>{item?.name}</h4>
                                            </NavLink> */}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => navigateToEmpDetails(item)}
                          className="twm-job-title">
                          <h4>{item?.name}</h4>
                        </span>
                        <p className="twm-job-address">{formatAddress(
                          item?.address,
                          item?.city,
                          item?.state,
                          item?.zipCode
                        )}</p>
                        <span className="twm-jobs-browse site-text-primary job-details" onClick={() => navigateToEmpDetails(item)}>
                          More Info
                        </span>
                      </div>
                      <div className="twm-right-content">
                        {/* <div className="twm-jobs-vacancies"><span>25</span>Vacancies</div> */}
                        <StarRating rating={item?.averageNumStars} />{" "}
                        <p style={{ fontSize: 12 }}>
                          {(item?.numberOfReview || 0) + " Reviews"}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                "No Record Found"
              )
            ) : (
              <TableLoader />
            )}
          </div>
        </div>
        <SectionPagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
    </>
  )
}

export default SectionEmpsGrid