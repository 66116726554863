function SectionApplicantVerification({ applicantDetailInfo }) {
    return (
        <>
            <h4 className="section-head-small mb-4">Verification Details</h4>
            <div className="twm-s-info">
                <ul>
                    <li>
                        <div className="twm-s-info-inner">
                            {applicantDetailInfo?.validBankAccount ? (
                                <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                            ) : (
                                <i className="fas fa-times-circle" style={{ color: 'red' }}></i>
                            )}
                            <span className="twm-title">Bank Verified</span>
                            <div className="twm-s-info-discription">
                                {applicantDetailInfo?.validBankAccount ? "Yes" : "No"}
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="twm-s-info-inner">
                            {applicantDetailInfo?.isVerified ? (
                                <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                            ) : (
                                <i className="fas fa-times-circle" style={{ color: 'red' }}></i>
                            )}
                            <span className="twm-title">Is Verified</span>
                            <div className="twm-s-info-discription">{applicantDetailInfo?.isVerified ? "Yes" : "No"}</div>
                        </div>
                    </li>
                    <li>
                        <div className="twm-s-info-inner">
                            {applicantDetailInfo?.canReceivePayout ? (
                                <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                            ) : (
                                <i className="fas fa-times-circle" style={{ color: 'red' }}></i>
                            )}
                            <span className="twm-title">Received Payout</span>
                            <div className="twm-s-info-discription">
                                {applicantDetailInfo?.canReceivePayout ? "Yes" : "No"}
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="twm-s-info-inner">
                            <i className="fas fa-thumbs-up" />
                            <span className="twm-title">Review</span>
                            <div className="twm-s-info-discription">
                                {applicantDetailInfo?.numberOfReview ? applicantDetailInfo?.numberOfReview : 0}
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="twm-s-info-inner">
                            {/* <i className="fas fa-check-circle" /> */}
                            {applicantDetailInfo?.applicationStatus === 'rejected' ? (
                                <i className="fas fa-times-circle" style={{ color: 'red' }}></i>
                            ) : applicantDetailInfo?.applicationStatus === 'Approved' ? (
                                <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                            ) : applicantDetailInfo?.applicationStatus === 'WaitingForDocuments' ? (
                                <i className="fas fa-clock"></i>
                            ) : applicantDetailInfo?.applicationStatus === 'WaitingForApproval' ? (
                                <i className="fas fa-clock"></i>
                            ): null}
                            <span className="twm-title">Application Status</span>
                            <div className="twm-s-info-discription">{applicantDetailInfo?.applicationStatus ? applicantDetailInfo?.applicationStatus : '-'}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default SectionApplicantVerification;
