import React, { useEffect, useState, useRef } from "react";
import apiCall from "../../../../../apicall";
// import { useSelector } from "react-redux";
import Toast from "../../../../common/toast";
// import SessionExpired from "../../../../common/sessionExpired";
import TableLoader from "../../../../common/tableLoader";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";

// const PrivacyPolicayInstitutionModal = ({ setPrivacyPolicyId, handleAgreePrivacy}) => {
const PrivacyPolicayInstitutionModal = ({
  setPrivacyPolicyId,
  handleAgreePrivacy,
  removePrivacy
}) => {

  const modalRef = useRef(null);
  //   const { role_type, TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [privacyText, setPrivacyText] = useState();
  const [show, setShow] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  //   const [sessionExpired, setSessionExpired] = useState(false);

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
  };

  const getPrivacyPolicyText = async () => {
    try {
      setIsLoading(true);
      let privacyResponse = await apiCall(
        "privacyPolicy/Institutions",
        "GET",
        null
      );
      // if (role_type === "ROLE_USER") {
        // privacyResponse = await apiCall(
        //   "privacyPolicy/Applicants",
        //   "GET",
        //   null
        // );
      // } 
      // else if (role_type === "ROLE_INSTITUTION_ADMIN") {
      //   privacyResponse = await apiCall(
      //     "privacyPolicy/Institutions",
      //     "GET",
      //     null
      //   );
      // }
      console.log("privacyResponse----", privacyResponse);
      if (privacyResponse?.status === 200) {
        setIsLoading(false);
        setPrivacyPolicyId(privacyResponse?.data?.id);
        const text = privacyResponse?.data?.document.replace(/\r?\n/g, "<br>");
        setPrivacyText(text);
      } else if (privacyResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(privacyResponse?.response?.data?.message)
      }
    } catch (err) {
      console.log("setSessionExpired--", err);
      displayErrMsg("Something went wrong!");
    }
  };
  useEffect(() => {
    getPrivacyPolicyText();
    logEvent_Analytics('privacy_policy_web');
  }, []);
  return (
    
    <div
      className="modal fade twm-sign-up"
      id="privacy_policy_institution"
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      tabIndex={-1}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      ref={modalRef}

    >
      <div className="modal-dialog modal-dialog-centered">
        {isLoading ? (
          <div className="modal-content">
            <TableLoader />
          </div>
        ) : (
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title" id="session_expired_popupLabel2">
                Privacy Policy
              </h2>
              <button
                type="button"
                className="btn-close"
                onClick={removePrivacy}
              />
            </div>
            <div className="modal-body" style={{"maxHeight": "500px", "overflowY": "scroll"}}>
              <p
                style={{ textAlign: "left" }}
                dangerouslySetInnerHTML={{ __html: privacyText }}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-bs-dismiss="modal"
                // onClick={handleAgreePrivacy}
                onClick={() => handleAgreePrivacy(modalRef)}

              >
                Agree
              </button>
            </div>
          </div>
        )}

        {/* {sessionExpired && <SessionExpired />} */}
        {errSuccessMesg?.message != "" ? (
          <Toast message={errSuccessMesg} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PrivacyPolicayInstitutionModal;
