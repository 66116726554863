import React, { useState } from "react";
import Toast from "../../../../../common/toast";
import Spinner from "../../../../../common/spinner";
import apiCall from "../../../../../../apicall";
import { useSelector } from "react-redux";
import axios from "axios";
import BASE_URL from "../../../../../../config";

const RejectDocConfirmationPopup = ({
  rejectedDocId,
  handleDocumentRejectSuccess,
  tabType,
  documentType,
  applicantDetailsId,
}) => {
  const { TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [noteReject, setNoteReject] = useState();
  const [isDocRejected, setisDocRejected] = useState(false);
  const [formData, setFormData] = useState();
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setisDocRejected(false);
  };
  const handleChange = (e) => {
    console.log("setFormData", formData);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const setter_add_notes = async (notes) => {
    const payload = {
      userEntity: { id: applicantDetailsId },
      note: notes,
      noteType: 2,
    };
    try {
      const notesResponse = await apiCall(
        "openstaff/notes",
        "POST",
        payload,
        TOKEN
      );
      if (notesResponse?.status === 204) {
        setIsLoading(false);
        setisDocRejected(true);
        setErrSuccessMsg({
          message: `${
            tabType === "document"
              ? "Document"
              : tabType === "certificate"
              ? "Certifcate"
              : ""
          } rejected successfully!`,
          status: true,
        });
        handleDocumentRejectSuccess();
      } else if (notesResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(notesResponse?.response?.data?.message)
      }
    } catch (error) {
      console.log("setSessionExpired--", error);
      displayErrMsg("Something went wrong!");
    }
  };

  const handleDocRejected = async (e) => {
    e.preventDefault();
    try {
      let url;
      setIsLoading(true);
      const formData = new FormData();

      if (tabType === "document" && !documentType) {
        url = `${BASE_URL}openstaff/documents/${rejectedDocId}/rejects`;
      } else if (tabType === "certificate") {
        url = `${BASE_URL}openstaff/certificates/${rejectedDocId}/rejects`;
      } else if (documentType) {
        //to reject the background doc
        url = `${BASE_URL}openstaff/background/${rejectedDocId}/rejects`;
      }

      const config = {
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${TOKEN}`,
        },
      };

      const rejectedDocResponse = await axios.put(url, formData, config);
      if (rejectedDocResponse?.status === 200) {
        setter_add_notes(formData.reason);
      } else if (rejectedDocResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(rejectedDocResponse?.response?.data?.message)
      }
    } catch (err) {
      console.log("setSessionExpired--", err);
      displayErrMsg("Something went wrong!");
    }
  };

  return (
    <div
      className="modal fade twm-sign-up"
      id={`${
        tabType === "document"
          ? "reject_doc_confirmation"
          : tabType === "certificate"
          ? "reject_certificate_confirmation"
          : ""
      }`}
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <form onSubmit={handleDocRejected}>
            <div className="modal-header">
              <h2 className="modal-title" id="sign_up_popupLabel2">
                {isDocRejected
                  ? `${
                      tabType === "document"
                        ? "Document"
                        : tabType === "certificate"
                        ? "Certifcate"
                        : ""
                    } Rejected Successfully`
                  : `Are you sure you want to reject ${
                      tabType === "document"
                        ? "document"
                        : tabType === "certificate"
                        ? "certificate"
                        : ""
                    }?`}
              </h2>

              <div className="col-md-12">
                <div className="form-group">
                  {/* <label>Description</label> */}
                  {!isDocRejected && (
                    <textarea
                      className="form-control"
                      rows={8}
                      name="reason"
                      value={noteReject}
                      placeholder="Add Reason"
                      onChange={handleChange}
                      required
                    />
                  )}
                </div>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setisDocRejected(false)}
              />
            </div>
            <div className="modal-footer">
              {isDocRejected ? (
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => setisDocRejected(false)}
                >
                  Ok
                </button>
              ) : (
                <>
                  {/* <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button> */}
                  <button
                    type="submit"
                    id="deleteConfirmationModal"
                    className="btn btn-primary"
                    // data-bs-dismiss="modal"
                    // onClick={handleDocRejected}
                  >
                    {isLoading ? <Spinner /> : "Reject"}
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </div>
  );
};

export default RejectDocConfirmationPopup;
