import { useState, useEffect, useRef } from "react"; // Add useEffect for handling side effects
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // DatePicker styles
import Toast from "../../../../common/toast";
import moment from "moment";
import { useSelector } from "react-redux";
import Spinner from "../../../../common/spinner";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";

function ClockinClockoutPopup({
  jobDetailsInfo,
  lastClockinClockoutItem,
  isClockInLoading,
  isClockOutLoading,
  handleClockInClockOut,
  errSuccessMesg,
  clockinClockoutLoader,
  selectedClockinClockout,
  handleUpdateClockinClockout
}) {
  const { role_type } = useSelector((state) => state.menu);
  const modalRef = useRef(null);
  console.log("clockinClockoutLoader", clockinClockoutLoader);

  const [formValues, setFormValues] = useState({
    timeIn: "",
    timeOut: "",
  });
  const [selectedDate, setSelectedDate] = useState(new Date()); 

  const [errors, setErrors] = useState({
    timeInError: "",
    timeOutError: "",
  });

  // Use useEffect to update formValues based on lastClockinClockoutItem
  useEffect(() => {
    console.log("lastClockinClockoutItem===",lastClockinClockoutItem)
    if (lastClockinClockoutItem?.checkinTime) {
      // Both checkinTime and checkoutTime exist, set timeIn and timeOut to empty
      setFormValues((prevFormValues) => ({
        timeIn: lastClockinClockoutItem?.checkinTime
          ? moment.utc(lastClockinClockoutItem.checkinTime).toDate() // Convert moment to Date object
          : new Date(),
        timeOut: new Date(),
      }));
    } else {
      // If checkinTime exists, set timeIn, else set current time
      setFormValues({
        timeIn: "",
        timeOut: "",
      });
    }
  }, [lastClockinClockoutItem]); // Dependency on lastClockinClockoutItem

  useEffect(() =>{
    logEvent_Analytics('clockin_clockout_screen_web');
  }, [])

  const handleTimeChange = (date, field) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [field]: date,
    }));

    // Perform validation only if both timeIn and timeOut have values
    if (field === "timeIn" && formValues.timeOut) {
      if (date >= formValues.timeOut) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          timeInError: "Check-in time must be less than check-out time.",
          timeOutError: "",
        }));
      } else {
        setErrors({
          timeInError: "",
          timeOutError: "",
        });
      }
    } else if (field === "timeOut" && formValues.timeIn) {
      if (date <= formValues.timeIn) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          timeOutError: "Check-out time must be greater than check-in time.",
          timeInError: "",
        }));
      } else {
        setErrors({
          timeInError: "",
          timeOutError: "",
        });
      }
    } else {
      setErrors({
        timeInError: "",
        timeOutError: "",
      });
    }
  };


      // Helper function to merge date and time
      const mergeDateAndTime = (date, time) => {
        const mergedDateTime = new Date(date);
        mergedDateTime.setHours(time.getHours());
        mergedDateTime.setMinutes(time.getMinutes());
        console.log("mergedDateTime=====",mergedDateTime)
        return mergedDateTime;
      };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formValues=====",formValues,!lastClockinClockoutItem?.checkinTime,!formValues.timeIn,!formValues.timeOut)
    // Validation: If both fields are empty, show error for "Clock-in required"
    if (!formValues.timeIn && !formValues.timeOut) {
      setErrors({
        timeInError: "Clock-in is required.",
        timeOutError: "",
      });
      return; // Stop form submission
    }

    // Validation: If clock-out is filled but check-in does not exist (no previous clock-in record), show error
    if (
      !lastClockinClockoutItem?.checkinTime &&
      formValues.timeOut &&
      !formValues.timeIn
    ) {
      setErrors({
        timeInError: "Clock-in is required before clock-out.",
        timeOutError: "",
      });
      return; // Stop form submission
    }

    // Skip validation if timeOut is empty
    if (formValues.timeOut && formValues.timeIn >= formValues.timeOut) {
      setErrors({
        timeInError: "Check-in time must be less than check-out time.",
        timeOutError: "Check-out time must be greater than check-in time.",
      });
      return; // Prevent form submission
    }

    // Call the function only for timeIn if it exists
    if (formValues.timeIn && !formValues.timeOut) {
      const mergedClockIn = mergeDateAndTime(selectedDate, formValues.timeIn);
      await handleClockInClockOut("clockin", mergedClockIn);
    }


    console.log("inside the timein timoe out",formValues.timeOut,!formValues.timeIn,formValues,selectedClockinClockout)

    // Call the function only for timeOut if it exists
    if (formValues.timeOut && !formValues.timeIn) {
        console.log("inside the timein timoe out",formValues.timeOut,!formValues.timeIn)
      const mergedClockOut = mergeDateAndTime(selectedDate, formValues.timeOut);
      await handleClockInClockOut("clockout", mergedClockOut);
    //   await handleUpdateClockinClockout(formValues, null, selectedClockinClockout);
    }

    console.log(
      "condition 5=====",
    //   !lastClockinClockoutItem?.checkinTime,
      lastClockinClockoutItem,
      formValues.timeIn,
      formValues.timeOut,
      lastClockinClockoutItem?.id
    );

    // If both timeIn and timeOut exist, call the function for both
    if (
      lastClockinClockoutItem?.checkinTime &&
      formValues.timeIn &&
      formValues.timeOut
    ) {
        console.log("inside elseee")
      //   await handleClockInClockOut("clockin", formValues.timeIn);
      const mergedClockOut = mergeDateAndTime(selectedDate, formValues.timeOut);
      const clockinClockoutRes = await handleClockInClockOut("clockout", mergedClockOut);
      console.log("clockinClockoutRes==",clockinClockoutRes)
      if(clockinClockoutRes){
        await handleUpdateClockinClockout({timeIn : formValues?.timeIn}, null, {checkinData : {id : lastClockinClockoutItem?.id }});
      }
    } else if (
      (!lastClockinClockoutItem?.checkinTime &&
      lastClockinClockoutItem?.checkoutTime &&
      formValues.timeIn &&
      formValues.timeOut) || 
      (
      !lastClockinClockoutItem?.checkinTime &&
      !lastClockinClockoutItem?.checkoutTime &&
      formValues.timeIn &&
      formValues.timeOut
      )
    ) {
      console.log("condition 66=====");
      const mergedClockIn = mergeDateAndTime(selectedDate, formValues.timeIn);
      const mergedClockOut = mergeDateAndTime(selectedDate, formValues.timeOut);
      const clockinClockoutRes = await handleClockInClockOut("clockin", mergedClockIn);
      if(clockinClockoutRes){
        await handleClockInClockOut("clockout", mergedClockOut);
      }
    }

    setTimeout(() => {
      if (modalRef.current) {
        modalRef.current.click();
      }
    }, 1200);
  };

  return (
    <>
      <div
        className="modal fade twm-sign-up"
        id="clockin_clockout_popup"
        aria-hidden="true"
        aria-labelledby="sign_up_popupLabel"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={modalRef}
                />
              </div>
              <div className="modal-body">
                <div className="row justify-content-center">
                  <div className="col-12 mb-3">
                    <label className="form-label">Date</label>
                <div className="ls-inputicon-box clock-in-date-picker">
                    <ReactDatePicker
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      dateFormat="yyyy-MM-dd" // Format to only show date
                      className="form-control"
                    />
                    </div>
                    {/* <input
                      type="text"
                      className="form-control"
                      value={moment().format("YYYY-MM-DD")} // Non-editable Date
                      readOnly
                    /> */}
                  </div>
                  <div className="col-6">
                    <label className="form-label">Check In</label>
                    <div className="ls-inputicon-box">
                      <ReactDatePicker
                        className="form-control"
                        selected={formValues?.timeIn}
                        onChange={(date) => handleTimeChange(date, "timeIn")}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={1} // Change interval to 1 minute
                        timeCaption="Time"
                        dateFormat="hh:mm aa" // Correct format for AM/PM in date-fns
                        placeholderText="Select time"
                        // disabled={!!lastClockinClockoutItem?.checkinTime} // Disable if checkinTime exists
                      />
                      <i className="fs-input-icon fa fa-clock" />
                    </div>
                    {errors.timeInError && (
                      <p className="text-danger">{errors.timeInError}</p>
                    )}
                  </div>
                  <div className="col-6">
                    <label className="form-label">Check Out</label>
                    <div className="ls-inputicon-box">
                      <ReactDatePicker
                        className="form-control"
                        selected={formValues?.timeOut}
                        onChange={(date) => handleTimeChange(date, "timeOut")}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={1} // Change interval to 1 minute
                        timeCaption="Time"
                        dateFormat="hh:mm aa" // Correct format for AM/PM in date-fns
                        placeholderText="Select time"
                      />
                      <i className="fs-input-icon fa fa-clock" />
                    </div>
                    {errors.timeOutError && (
                      <p className="text-danger">{errors.timeOutError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="modal-footer mb-5"
                style={{
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="col-xl-6 col-lg-6 col-md-12 text-center">
                  <button type="submit" className="site-button">
                    {isClockInLoading || isClockOutLoading ? (
                      <Spinner />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {errSuccessMesg?.message !== "" && <Toast message={errSuccessMesg} />}
    </>
  );
}

export default ClockinClockoutPopup;
