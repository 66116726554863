// authUtils.js
export function setTokenWithExpiry(token, days) {
  const expires = new Date();
  expires.setDate(expires.getDate() + days);
  // expires.setTime(expires.getTime() + minutes * 60 * 1000);
  document.cookie = `token=${token};expires=${expires.toUTCString()};path=/`;
}

export function getCookie(cookieName) {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}

export function getTokenExpiration() {
  const cookieValue = getCookie("token");
  if (!cookieValue) {
    return null;
  }

  const tokenParts = cookieValue.split(".");
  if (tokenParts.length < 3) {
    return null;
  }

  const payload = JSON.parse(atob(tokenParts[1]));
  if (!payload.exp) {
    return null;
  }

  return payload.exp * 1000; // Convert expiration time from seconds to milliseconds
}

export function isTokenExpired() {
  const expirationTime = getTokenExpiration();
  if (!expirationTime) {
    return true; // Token is considered expired if expiration time is not available
  }
  const currentTime = Date.now();
  return expirationTime < currentTime;
}
