import React from "react";
import Spinner from "../../../../common/spinner";
import apiCall from "../../../../../apicall";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";
import Toast from "../../../../common/toast";
import TableLoader from "../../../../common/tableLoader";
import SectionPagination from "../../../public-user/sections/common/section-pagination";
import SectionRecordsFilter from "../../../public-user/sections/common/section-records-filter";
import GetProfileImage from "../../../../common/getProfileImage";

const ReviewListPopup = () => {
    const { role_type, TOKEN } = useSelector((state) => state.menu);
    const [reviewDetailsInfo, setReviewDetailsInfo] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [reviewListLoader, setReviewListLoader] = useState(false);
    const [paginationData, setPaginationData] = useState({
        totalElements: 0,
        totalPages: 0,
    });
    const [errSuccessMesg, setErrSuccessMsg] = useState({
        message: "",
        status: "error",
    });

    const displayErrMsg = (errMsg) => {
        setErrSuccessMsg({
            message: errMsg,
            status: false,
        });
        setPaginationData({
            totalElements: 0,
            totalPages: 0,
        });
        setReviewListLoader(false);
    };
    const [tableFilter, setTableFilter] = useState({
        sortType: "createdDate",
        sortDirection: "desc",
        status: "",
        searchKey: "",
        // jobType: "",
        // applicant: "",
        // customer: "",
        // categoryType: "All",
    });
    const handleRecordsPerPageChange = (event) => {
        setCurrentPage(1);
        setRowPerPage(event.target.value);
    };
    const _filterConfig = {
        prefix: "Showing",
        type: "Records",
        total: paginationData?.totalElements,
        showRange: false,
        showingUpto: "",
    };
    const StarRating = ({ rating }) => {
        const stars = [];
        console.log("rating----------", rating);
        // Iterate over 5 stars
        for (let i = 0; i < 5; i++) {
            if (rating >= i + 1) {
                // Full star if rating is greater than or equal to current index + 1
                stars.push(
                    <i key={i} className="fas fa-star" style={{ color: "#128c5e" }} />
                );
            } else if (rating >= i + 0.5) {
                // Half star if rating is greater than or equal to current index + 0.5
                stars.push(
                    <i
                        key={i + 0.5}
                        className="fas fa-star-half-alt"
                        style={{ color: "#128c5e" }}
                    />
                );
            } else {
                // Empty star otherwise
                stars.push(
                    <i key={i} className="fas fa-star" style={{ color: "lightgrey" }} />
                );
            }
        }

        return <span>{stars}</span>;
    };

    const getReviewListData = async () => {
        {
            try {
                setReviewListLoader(true);
                const api = `${!role_type.includes("ROLE_OPENSTAFF_ADMIN")
                    ? `reviewList?&offset=0&page=${currentPage - 1}&size=${rowPerPage}`
                    : ""

                    }`;
                const reviewListResponse = await apiCall(api, "GET", null, TOKEN);
                console.log("reviewListResponse---", reviewListResponse);
                console.log("reviewListResponseData---", reviewListResponse?.data?.content);

                if (reviewListResponse?.data?.content?.length > 0) {
                    setReviewDetailsInfo(reviewListResponse?.data?.content);
                    setPaginationData({
                        totalElements: reviewListResponse.data.totalElements,
                        totalPages: reviewListResponse.data.totalPages,
                    });
                    setReviewListLoader(false);
                }
                else {
                    displayErrMsg(reviewListResponse?.response?.data?.message)
                    setPaginationData({
                        totalElements: 0,
                        totalPages: 0,
                    });
                    setReviewListLoader(false);
                }
            } catch (err) {
                displayErrMsg("Something went wrong!");
            }
        }
    };
    useEffect(() => {

        getReviewListData();

    }, [currentPage, rowPerPage]);

    return (
        <div
            className="modal fade twm-sign-up"
            id="review_list_popup"
            aria-hidden="true"
            aria-labelledby="sign_up_popupLabel2"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header" style={{ margin: 0 }}>
                        {/* <h2 className="modal-title" id="sign_up_popupLabel2">
              All Applicants List
            </h2> */}
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />

                    </div>
                    <div className="modal-body">
                        <SectionRecordsFilter
                            _config={_filterConfig}
                            rowPerPage={rowPerPage}
                            handleRecordsPerPageChange={handleRecordsPerPageChange}
                            setTableFilter={setTableFilter}
                            tableFilter={tableFilter}
                            setCurrentPage={setCurrentPage}
                        />
                        <div className="twm-candidates-list-wrap">
                            {/* {console.log("section record filter",locationList)} */}
                            <ul>
                                {!reviewListLoader ? (
                                    reviewDetailsInfo?.length > 0 ? (
                                        reviewDetailsInfo?.map((item) => (
                                            <li>
                                                <div className="twm-candidates-list-style1 mb-5">
                                                    <div className="twm-media">
                                                        <div className="twm-media-pic">
                                                            {role_type.includes("ROLE_USER") && (
                                                            <GetProfileImage
                                                                institutionId={item?.writerId}
                                                                type={"applicant"}
                                                                logoType={"reviewSeeker"}
                                                            />
                                                            )}
                                                            {role_type.includes("ROLE_INSTITUTION_ADMIN") && (
                                                            <GetProfileImage
                                                                applicantId={item?.writerId}
                                                                type={"applicant"}
                                                                logoType={"reviewPoster"}
                                                            />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="twm-mid-content">
                                                        <div style={{ width: "50%", display: "inline-block" }}>
                                                            <span
                                                                className="twm-job-title"
                                                            >
                                                                <h4>{`${item?.writerFirstName}`}</h4>
                                                            </span>
                                                            <div>
                                                                <p className="twm-candidate-address">
                                                                    {moment
                                                                        .utc(item?.modifiedDate, "YYYY-MM-DD HH:mm:ss")
                                                                        .local()
                                                                        .format("MM/DD/YYYY")}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p className="twm-candidate-address">
                                                                    {item?.jobTitle}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p className="twm-candidate-address">
                                                                    {item?.review}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        {/* <div style={{width: "50%", display:"inline-block", textAlign:"right"}}>
                      <a
                        className="site-button"
                        data-bs-toggle="modal"
                        href={"#sign_up_popup3"}
                        role="button"
                        onClick={() => handlePostJobClick(item.id)}
                      >
                        Post job
                      </a>
                      </div> */}
                                                    </div>
                                                    <div className="twm-right-content">
                                                        <StarRating
                                                            rating={item?.numStars}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    ) : (
                                        "No Record Found"
                                    )
                                ) : (
                                    <TableLoader />
                                )}
                            </ul>
                            <SectionPagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={paginationData?.totalPages}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
        </div>
    );
};

export default ReviewListPopup;
