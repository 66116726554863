import { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // DatePicker styles
import Toast from "../../../../common/toast";
import moment from "moment";
import axios from "axios"; // Import axios for API requests
import apiCall from "../../../../../apicall";
import { useSelector } from "react-redux";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";

function EditClockInClockOutPopup({ selectedClockinClockout, handleUpdateClockinClockout, getJobDetailsData }) {
  // const { TOKEN } = useSelector((state) => state.menu);
  // const [errSuccessMesg, setErrSuccessMsg] = useState({
  //   message: "",
  //   status: "error",
  // });
  const [formValues, setFormValues] = useState({
    timeIn: null,
    timeOut: null,
    dateIn: null,
    dateOut: null,
  });
  const [initialValues, setInitialValues] = useState({
    timeIn: null,
    timeOut: null,
  });
  const [errors, setErrors] = useState({
    timeInError: "",
    timeOutError: "",
  });

  useEffect(() => {
    if (selectedClockinClockout?.checkinData) {
      const initialTimeIn = selectedClockinClockout?.checkinData?.checkinTime
        ? moment
            .utc(selectedClockinClockout.checkinData.checkinTime)
            .local()
            .toDate()
        : null;
      const initialTimeOut = selectedClockinClockout?.checkoutData?.checkoutTime
        ? moment
            .utc(selectedClockinClockout.checkoutData.checkoutTime)
            .local()
            .toDate()
        : null;

      setFormValues({
        timeIn: initialTimeIn,
        timeOut: initialTimeOut,
        dateIn: initialTimeIn,
        dateOut: initialTimeOut,
      });

      setInitialValues({
        timeIn: initialTimeIn,
        timeOut: initialTimeOut,
      });
    }

    logEvent_Analytics('update_clockin_clockout_screen_web');
  }, [selectedClockinClockout]);

  const handleTimeChange = (date, field) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: date,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${field}Error`]: "", // Clear error on field change
    }));
  };

  // const displayErrMsg = (errMsg) => {
  //   setErrSuccessMsg({
  //     message: errMsg,
  //     status: false,
  //   });
  // };

  // const displaySuccessMsg = (successMsg) => {
  //   setErrSuccessMsg({
  //     message: successMsg,
  //     status: true,
  //   });
  // };

  const validateTimes = () => {
    let isValid = true;
    let tempErrors = { timeInError: "", timeOutError: "" };

    if (formValues.timeIn && formValues.timeOut) {
      // Ensure timeIn is before timeOut
      if (formValues.timeIn >= formValues.timeOut) {
        tempErrors.timeInError = "Check In time must be earlier than Check Out time";
        tempErrors.timeOutError = "Check Out time must be later than Check In time";
        isValid = false;
      }
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleUpdate = async () => {
    // const requests = []; // Collect all the requests to send
    console.log("handle update")
    if (validateTimes()) {
     await handleUpdateClockinClockout(formValues,initialValues,selectedClockinClockout);
     getJobDetailsData();
    }

    // // Check if timeIn was updated
    // if (formValues.timeIn && !moment(formValues.timeIn).isSame(initialValues.timeIn)) {
    //   const updatedTimeInData = {
    //     arrivalTime: moment.utc(formValues.timeIn)
    //       .format("yyyy-MM-DD HH:mm:ss"),
    //     location: {
    //       latitude: 29.749907,
    //       longitude: -95.358421,
    //     },
    //     notes: "",
    //   };

    //   const timeInRequest = apiCall(
    //     `openstaff/jobs/${selectedClockinClockout?.checkinData?.id}/updateClockIn`,
    //     "PUT",
    //     updatedTimeInData,
    //     TOKEN
    //   );
    //   requests.push(timeInRequest);
    // }

    // // Check if timeOut was updated
    // if (formValues.timeOut && !moment(formValues.timeOut).isSame(initialValues.timeOut)) {
    //   const updatedTimeOutData = {
    //     endTime: moment.utc(formValues.timeOut).format("yyyy-MM-DD HH:mm:ss"),
    //     location: { latitude: 29.749907, longitude: -95.358421 },
    //   };

    //   const timeOutRequest = apiCall(
    //     `openstaff/jobs/${selectedClockinClockout?.checkoutData?.id}/updateClockIn`,
    //     "PUT",
    //     updatedTimeOutData,
    //     TOKEN
    //   );
    //   requests.push(timeOutRequest);
    // }

    // // Ensure at least one field is updated
    // if (requests.length === 0) {
    //   displayErrMsg("Please update either Time In or Time Out.");
    //   return;
    // }

    // try {
    //   // Perform all API requests
    //   const responses = await Promise.all(requests);
    //   console.log("response of time in time out", responses);
    //   // Check responses for success or failure
    //   const allSuccessful = responses.every(
    //     (response) => response.status === 200
    //   );
    //   if (allSuccessful) {
    //     getClockinClockout()
    //     displaySuccessMsg("Clock-in/Clock-out updated successfully.");
    //   } else {
    //     displayErrMsg("Failed to update clock-in/clock-out.");
    //   }
    // } catch (error) {
    //   displayErrMsg("An error occurred while updating the data.");
    // }
  };

  return (
    <>
      <div
        className="modal fade twm-sign-up"
        id="edit_popup"
        aria-hidden="true"
        aria-labelledby="sign_up_popupLabel"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                {/* <h2 className="modal-title" id="sign_up_popupLabel">
                  Edit Clock-in - Clock-out
                </h2> */}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="row">
                  {/* Display date and time fields */}
                  {/* <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>Start Date</label>
                      <ReactDatePicker
                        className="form-control"
                        selected={formValues?.dateIn}
                        onChange={(date) => handleTimeChange(date, "dateIn")}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Select start date"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>End Date</label>
                      <ReactDatePicker
                        className="form-control"
                        selected={formValues?.dateOut}
                        onChange={(date) => handleTimeChange(date, "dateOut")}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Select end date"
                        disabled
                      />
                    </div>
                  </div> */}
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>Check In</label>
                      <ReactDatePicker
                        className="form-control"
                        selected={formValues?.timeIn}
                        onChange={(date) => handleTimeChange(date, "timeIn")}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={1}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        placeholderText="Select start time"
                      />
                       {errors.timeInError && (
                        <div style={{ color: "red", fontSize: "12px" }}>{errors.timeInError}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>Check Out</label>
                      <ReactDatePicker
                        className="form-control"
                        selected={formValues?.timeOut}
                        onChange={(date) => handleTimeChange(date, "timeOut")}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={1}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        placeholderText="Select end time"
                      />
                       {errors.timeOutError && (
                        <div style={{ color: "red", fontSize: "12px" }}>{errors.timeOutError}</div>
                      )}
                    </div>
                  </div>
                </div>
                {/* Update Button */}
                <div className="modal-footer"
                 style={{
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                }}>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* {errSuccessMesg?.message !== "" && <Toast message={errSuccessMesg} />} */}
    </>
  );
}

export default EditClockInClockOutPopup;
