import React, { useState, useEffect } from 'react';

const Toast = ({ message }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        let timer;
        if (isVisible) {
            timer = setTimeout(() => {
                setIsVisible(false);
            }, 5000);
        }

        return () => clearTimeout(timer);
    }, [isVisible,message]);

    useEffect(()=>{
        setIsVisible(true);
    },[message])

    const handleClose = () => {
        setIsVisible(false);
    };

    return (
        isVisible ? (
            <div className={`toaster toaster-message ${message?.status ? 'toaster-success' : 'toaster-error'}`}>
                <span>{message?.message}</span>
                    <span aria-hidden="true" onClick={handleClose} style={{fontSize:'20px',marginLeft:'10px',cursor:'pointer'}}>&times;</span>
            </div>
        ) : null
    );
};

export default Toast;
