import React, { useState, useEffect } from "react";
import AwaitDoc from "../../../../../common/openstaff-icon/iconDocYellow.png";
import ApprovedDoc from "../../../../../common/openstaff-icon/iconDocGreen.png";
import RejectDoc from "../../../../../common/openstaff-icon/iconDocRed.png";
import { NavLink } from "react-router-dom";
import apiCall from "../../../../../../apicall";
import Toast from "../../../../../common/toast";
import Spinner from "../../../../../common/spinner";
import SessionExpired from "../../../../../common/sessionExpired";
import { useSelector } from "react-redux";
import BASE_URL from "../../../../../../config";
import axios from "axios";
import TableLoader from "../../../../../common/tableLoader";
import { logEvent_Analytics } from "../../../../../common/firebase/firebase-analytics-service";
 
const CertificateList = ({ certificateList, getCertificates }) => {
  const { TOKEN } = useSelector((state) => state.menu);
  const [isCertificateListHide, setIsCertificateListHide] = useState({
    certificateList: true,
    addCertificateForm: false,
    deleteCertificateForm: false,
    viewCertificateForm: false,
  });
  const [documentUploadType, setDocumentUploadType] = useState("")
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isCertificateLoading, setIsCertificateLoading] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [deletedCertificateId, setDeletedCertificateId] = useState("");
  const [sessionExpired, setSessionExpired] = useState(false);
  const [isViewCertificateLoading, setIsViewCertificateLoading] =
    useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const [certificateDetails, setCertificateDetails] = useState({});
  const [formData, setFormData] = useState({
    description: "",
    hours: "",
    file: null,
  });
 
  const addCertificate = () => {
    setImagePreviewUrl(null)
    setIsCertificateListHide({
      certificateList: false,
      addCertificateForm: true,
      deleteCertificateForm: false,
      viewCertificateForm: false,
    });
  };
 
  const handleDeleteCertificate = (item) => {
    setDeletedCertificateId(item?.id);
    setIsCertificateListHide({
      certificateList: false,
      addCertificateForm: false,
      deleteCertificateForm: true,
      viewCertificateForm: false,
    });
  };
 
  const handleViewCertificate = (item) => {
    getCertficateViewData(item);
    setIsCertificateListHide({
      certificateList: false,
      addCertificateForm: false,
      deleteCertificateForm: false,
      viewCertificateForm: true,
    });
  };
 
  const resetThePopup = () => {
    setIsCertificateListHide({
      certificateList: true,
      addCertificateForm: false,
      deleteCertificateForm: false,
      viewCertificateForm: false,
    });
  };
 
  const displayDeleteErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsDeleteLoading(false);
  };
 
  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsViewCertificateLoading(false);
  };
 
  const getCertficateViewData = async (item) => {
    console.log("getCertficateViewData", item);
    try {
      setIsViewCertificateLoading(true);
 
      // let data = {
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
 
      // if (TOKEN) {
      //   data.headers["Authorization"] = "Bearer " + TOKEN;
      // }
 
      const url = `certificates/${item?.id}/download`;
 
      // const getCertificateResponse = await fetch(url, data);
      const getCertificateResponse = await apiCall(url, "GET", null, TOKEN, false, true);
      console.log("getCertificateResponse---", getCertificateResponse);
      if (getCertificateResponse?.status === 200) {
        const responseBlob = await getCertificateResponse.data;
        const reader = new FileReader();

        const fileSignature = await responseBlob.slice(0, 4).arrayBuffer();
        const bytes = new Uint8Array(fileSignature);

        // Detect file type using magic bytes
        const detectFileType = (bytes) => {
          if (bytes[0] === 0x25 && bytes[1] === 0x50) return "application/pdf";   // PDF: "%PDF"
          if (bytes[0] === 0x89 && bytes[1] === 0x50) return "image/png";         // PNG: "\x89PNG"
          if (bytes[0] === 0xFF && bytes[1] === 0xD8) return "image/jpeg";        // JPEG: "\xFF\xD8"
          return responseBlob.type;  // Fallback to blob type
        };

        const fileType = detectFileType(bytes);
        // Handle PDF or Image display
        reader.onloadend = () => {
          if (fileType === "application/pdf") {
            setDocumentUploadType("pdf");
            if(reader?.result?.includes("text/xml")){
              setImagePreviewUrl(reader.result?.replace("data:text/xml;base64,", "data:application/pdf;base64,"));
            }else{
              setImagePreviewUrl(reader.result);
            }
          } else if(fileType === "image/png" || fileType === "image/jpeg"){
            setDocumentUploadType("image");
            setImagePreviewUrl(reader.result);
          } else {
            setImagePreviewUrl(reader.result?.replace("data:text/xml;base64,", "data:application/pdf;base64,"));
            setDocumentUploadType("pdf");
          }
        };

        reader.readAsDataURL(responseBlob);  // Convert to base64
        // reader.readAsDataURL(responseBlob);
 
        // if (responseBlob?.type === "application/pdf" || responseBlob?.type === "text/xml" ) {
        //   reader.onloadend = () => {
        // console.log("responseBlob?.type",reader?.result)

        //   setDocumentUploadType("pdf");
        //   if(responseBlob?.type === "application/pdf"){
        //     setImagePreviewUrl(reader.result);
        //   }else{
        //     setImagePreviewUrl(reader.result?.replace("data:text/xml;base64,", "data:application/pdf;base64,"));
        //   }
        //   }
        // } else {
        //   reader.onloadend = () => {
        //   setDocumentUploadType("image");
        //   setImagePreviewUrl(reader.result);
        //   };
        // }

        setCertificateDetails({
          fileName: item?.fileName,
          description: item?.description,
          hours: item?.numberOfHours,
          fileData: reader.result,
        });
        setIsViewCertificateLoading(false);
 
        // reader.onloadend = () => {
        //   const base64data = reader.result;
 
        //   console.log("base64data-----", base64data);
 
        //   setCertificateDetails({
        //     fileName: item?.fileName,
        //     description: item?.description,
        //     hours: item?.numberOfHours,
        //     fileData: base64data,
        //   });
        //   setIsViewCertificateLoading(false);
        // };
      } else if (getCertificateResponse?.response?.status === 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!");
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(getCertificateResponse?.response?.data?.message)
      }
    } catch (err) {
      console.log("dskjfskdlfs", err);
      displayErrMsg("Something went wrong!");
    }
  };
 
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "file" && files && files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (files[0]?.type === "application/pdf") {
          setDocumentUploadType("pdf");
          setImagePreviewUrl(reader.result);
        } else {
          setDocumentUploadType("image");
          setImagePreviewUrl(reader.result);
        }
      };
      reader.readAsDataURL(files[0]);
    }
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };
 
  const handleAddCertificateSubmit = async (e) => {
    e.preventDefault();
    const { description, hours, file } = formData;
 
    if (!description || !hours || !file) {
      setErrSuccessMsg({
        message: "All fields are required!",
        status: "error",
      });
      return;
    }

    if (file?.size > 50000000) {
      setErrSuccessMsg({
        message: "File size must be less than 50 MB.",
        status: false,
      });
      return;
    }

    const allowedFileTypes = ["application/pdf", "image/jpeg", "image/png"];
    if (!allowedFileTypes.includes(file?.type)) {
      setErrSuccessMsg({
        message: "Only PDF or image files are allowed.",
        status: false,
      });
      return;
    }
 
 
    console.log("fileType---", file);
 
    try {
      setIsCertificateLoading(true);
      const postData = new FormData();
      postData.append("fileType", file?.type === "application/pdf" ? "ePdf" : "ePng");
      postData.append("file", file);
      postData.append("numberOfHours", hours);
      postData.append("description", description);
 
      const config = {
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
 
      const uploadResponse = await axios.post(
        `${BASE_URL}certificates`,
        postData,
        config
      );
 
      if (uploadResponse?.status === 200) {
        setIsCertificateLoading(false);
        setErrSuccessMsg({
          message: "Certificate added successfully!",
          status: "success",
        });
        setFormData({
          description: "",
          hours: "",
          file: null,
        });
        setImagePreviewUrl("");
        getCertificates();
        resetThePopup();
      } else if (uploadResponse?.response?.status === 401) {
        setIsCertificateLoading(false);
        setSessionExpired(true);
        setErrSuccessMsg({
          message: "Your session has expired!",
          status: false,
        });
      } else {
        setIsCertificateLoading(false);
        setErrSuccessMsg({
          message: uploadResponse?.response?.data?.message,
          status: false,
        });
      }
    } catch (error) {
      setIsCertificateLoading(false);
      console.log("error===addCertificateApi=:", error);
      setErrSuccessMsg({
        message: "Something went wrong!",
        status: false,
      });
    }
  };
 
  const handleCertificateDelete = async () => {
    try {
      setIsDeleteLoading(true);
      const deleteCertificateResponse = await apiCall(
        `certificates/${deletedCertificateId}`,
        "DELETE",
        null,
        TOKEN
      );
 
      console.log("deleteCertificateResponse", deleteCertificateResponse);
      if (deleteCertificateResponse?.status === 200) {
        setIsDeleteLoading(false);
        setDeletedCertificateId("");
        setErrSuccessMsg({
          message: "Certificate deleted successfully!",
          status: "success",
        });
        getCertificates(); // update the latest certificate list after deletion
        setIsCertificateListHide({
          certificateList: true,
          addCertificateForm: false,
          deleteCertificateForm: false,
          viewCertificateForm: false,
        });
      } else if (deleteCertificateResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayDeleteErrMsg("Your session has been expired!");
      } else {
        // displayDeleteErrMsg("Something went wrong!");
        displayDeleteErrMsg(deleteCertificateResponse?.response?.data?.message)
      }
    } catch (error) {
      console.log("error===deleteCertificatesApi=:", error);
      displayDeleteErrMsg("Something went wrong!");
    }
  };

 
  const DeleteCertificateForm = ({ item }) => {
    return (
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4> Are you sure you want to delete this certificate ?</h4>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={resetThePopup}
            >
              Back
            </button>
            <button
              type="button"
              id="deleteConfirmationModal"
              className="btn btn-primary"
              onClick={() => handleCertificateDelete(item)}
            >
              {isDeleteLoading ? <Spinner /> : "Delete"}
            </button>
          </div>
        </div>
      </div>
    );
  };
 
  const ViewCertificateForm = () => {
    {
      console.log("certificateDetails-----", certificateDetails);
    }
    return (
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4>View Certificate</h4>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <input
                type="text"
                className="form-control"
                id="description"
                name="description"
                value={certificateDetails.description}
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="hours">Number of Hours</label>
              <input
                type="number"
                className="form-control"
                id="hours"
                name="hours"
                value={certificateDetails.hours}
                readOnly
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="file">Uploaded Certificate</label> */}
              {imagePreviewUrl && (
                // <img
                //   src={certificateDetails.fileData}
                //   alt="Certificate"
                //   className="img-thumbnail"
                // />
                (documentUploadType === "pdf") ? (
                  <iframe
                    src={imagePreviewUrl}
                    title="Document"
                    style={{ width: "100%", height: "500px" }}
                  />
                ) : (
                  <img src={imagePreviewUrl} alt="Document" style={{ maxWidth: "100%" }} />
                )

              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={resetThePopup}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    );
  };
 
  const renderAvatar = (item) => {
    if (item.documentStatus === "approved") {
      return (
        <img style={{ height: "70px" }} src={ApprovedDoc} alt="approved" />
      );
    } else if (item.documentStatus === "waitingForReview") {
      return <img style={{ height: "70px" }} src={AwaitDoc} alt="awating" />;
    } else if (item.documentStatus === "rejected") {
      return <img style={{ height: "70px" }} src={RejectDoc} alt="rejected" />;
    } else {
      return (
        <img
          style={{ height: "70px" }}
          src="images/candidates/pic1.jpg"
          alt="#"
        />
      );
    }
  };

  useEffect(()=>{
    logEvent_Analytics('training_certificate_screen_web');
  },[])

  return (
    <div className="twm-right-section-panel candidate-save-job site-bg-gray">
      {isCertificateListHide?.certificateList ? (
        <>
          <div className="product-filter-wrap d-flex justify-content-between align-items-center">
            <span className="woocommerce-result-count-left">
              Certificate List
            </span>
            <span className="woocommerce-result-count-right">
              <button
                className="site-button"
                role="button"
                onClick={addCertificate}
              >
                Add Certificate
              </button>
            </span>
          </div>
          <div className="twm-cv-manager-list-wrap">
            <ul>
              {certificateList?.length > 0
                ? certificateList?.map((item, index) => (
                    <li key={item.id}>
                      <div className="twm-cv-manager-list-style1 certificate-list-cotnainer">
                        <div className="twm-media certificate-media">
                          <div className="twm-media-pic">
                            {renderAvatar(item, index)}
                          </div>
                        </div>
                        <div className="twm-mid-content">
                          <NavLink to="" className="twm-job-title">
                            <h4>{item?.description}</h4>
                          </NavLink>
                          <div className="twm-fot-content">
                            <div className="twm-left-info"></div>
                            <div className="twm-view-button">
                              <NavLink
                                // href="#"
                                title="View"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                onClick={() => handleViewCertificate(item)}
                              >
                                <i className="fa fa-eye" />
                              </NavLink>
                              <NavLink
                                // href="#"
                                title="Delete"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                onClick={() => handleDeleteCertificate(item)}
                              >
                                <i className="fa fa-trash-alt" />
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))
                : "No Record Found"}
            </ul>
          </div>
        </>
      ) : isCertificateListHide?.addCertificateForm ? (
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4>Add Certificate</h4>
            </div>
            <div className="modal-body">
              <form onSubmit={handleAddCertificateSubmit}>
                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="hours">Number of Hours</label>
                  <input
                    type="number"
                    className="form-control"
                    id="hours"
                    name="hours"
                    value={formData.hours}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="file">Upload Certificate</label>
                  <input
                    type="file"
                    className="form-control"
                    id="file"
                    name="file"
                    onChange={handleInputChange}
                    accept="image/*,application/pdf"
                    required
                  />
                </div>
                {imagePreviewUrl && (
                  (documentUploadType === "pdf") ? (
                    <iframe
                      src={imagePreviewUrl}
                      title="Document"
                      style={{ width: "100%", height: "500px" }}
                    />
                  ) : (
                    <img src={imagePreviewUrl} alt="Document" style={{ maxWidth: "100%" }} />
                  )
                ) }
                <button type="submit" className="btn btn-primary">
                  {isCertificateLoading ? <Spinner /> : "Upload"}
                </button>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={resetThePopup}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      ) : isCertificateListHide?.deleteCertificateForm ? (
        <DeleteCertificateForm />
      ) : isCertificateListHide?.viewCertificateForm ? (
        !isViewCertificateLoading ? (
          <ViewCertificateForm />
        ) : (
          <TableLoader />
        )
      ) : (
        ""
      )}
      {sessionExpired && <SessionExpired />}
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </div>
  );
};
 
export default CertificateList;