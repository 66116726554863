import SectionCandidateBankInfo from "../sections/profile/section-can-bank-details";

const CanBankDetails = () => {
    return(
        <>
        <SectionCandidateBankInfo />
        </>
    )
}

export default CanBankDetails;