import React, { useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  // PaymentElement,
} from "@stripe/react-stripe-js";
// import BASE_URL from "../../../../config";
import { useSelector } from "react-redux";
import Spinner from "../../spinner";
import Toast from "../../toast";
import SessionExpired from "../../sessionExpired";
import apiCall from "../../../../apicall";

const CardCheckoutForm = ({
  totalCharges,
  setShowPaymentScreen,
  postJobFunction,
  postJobReqParam,
  handleHideJobType,
  // clientSecret,
  // response
}) => {
  const { TOKEN, userInfo } = useSelector((state) => state.menu);
  const stripe = useStripe();
  const elements = useElements();
  const [sessionExpired, setSessionExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  // console.log("client secret for checkout", clientSecret);

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!stripe || !elements) {
      setIsLoading(false);
      return;
    }

    // const paymentData = {
    //   payment_method: {
    //     billing_details: {
    //       name: "Customer Name",
    //     },
    //   },
    // };

    // let paymentMethod;
    // let error;

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    // const achElement = elements.getElement(PaymentElement);
    // ({ error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: "us_bank_account",
    //   us_bank_account: achElement,
    //   // billing_details: {
    //   //   name: "Customer Name",
    //   // },
    // }));

    if (error) {
      //   setErrorMessage(error.message);
      setIsLoading(false);
      displayErrMsg(error.message);
    } else {
        var postData = new FormData();
        postData.append("apiVersion", "2015-10-12");
        postData.append("amount", parseInt(totalCharges)); //must required to pass currency in cent to backend
        postData.append("currency", "USD");
    }

    try {
      const api = "institutions/stripe/ephemeralKeyAndIntent";
      const response = await apiCall(api, "POST", postData, TOKEN, true, false);

      // const response = await response?.data?.json();
      console.log("responseJson-----", response);
      if (response?.data?.id) {
        // const { error: confirmError } = await stripe.confirmPayment({
        //   elements,
        //   confirmParams: {
        //     // return_url: 'https://your-website.com/payment-complete',
        //     payment_method: paymentMethod.id,
        //   },
        // });

        const { error: confirmError } = await stripe.confirmCardPayment(
          response?.data?.paymentIntentSecret,
          {
            payment_method: paymentMethod.id,
          }
        );

        console.log("errrroror", confirmError);
        if (confirmError) {
          setIsLoading(false);
          displayErrMsg(confirmError?.message || error?.message);
        } else {
          setErrSuccessMsg({
            message: "Payment submitted successfully!",
            status: "Success",
          });
          const postJobResponse = await postJobFunction({
            paymentIntentId: response?.data?.paymentIntentId,
            ...postJobReqParam,
          });
          if (postJobResponse) {
            setShowPaymentScreen(true);
          } else {
            displayErrMsg("Error occurred while posting your job!");
          }
          setTimeout(() => {
            setShowPaymentScreen(false);
          }, 1000);
        }
      } else if (response?.response?.status === 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!");
      } else {
        setIsLoading(false);
        displayErrMsg("Failed to retrieve client secret");
        console.log("confirmError-----");
      }
    } catch (error) {
      displayErrMsg(error.message);
      console.log("confirmError-----", error);
    } finally {
      setIsLoading(false);
    }
    // }
  };

  const handleBackButtonCilck = () => {
    setShowPaymentScreen(false);
    handleHideJobType(true);
  };

  const cardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  // const paymentOptions = {
  //   layout: "tabs",
  //   defaultValues: 
  //   {
  //       billingDetails: {
  //         email: userInfo?.email,
  //         name: `${userInfo?.firstName} ${userInfo?.lastName}`,
  //       },
  //     },
  // };

  return (
    // </form>
    <form onSubmit={handleSubmit}>
      <div className="payment-element-container mb-3">
        <CardElement options={cardElementOptions} className="card-element" />
        {/* {clientSecret && (
          <PaymentElement
            clientSecret={clientSecret}
            options={paymentOptions}
            className="ach-element"
          />
        )} */}
      </div>

      <button
        onClick={handleBackButtonCilck}
        className="site-button"
        style={{ marginRight: 10, marginTop: 20 }}
      >
        Back
      </button>
      <button
        type="submit"
        className="site-button"
        disabled={!stripe || isLoading}
      >
        {isLoading ? <Spinner /> : `Pay $${(totalCharges / 100).toFixed(2)}`}
      </button>
      {errSuccessMesg?.message !== "" ? <Toast message={errSuccessMesg} /> : ""}
      {sessionExpired && <SessionExpired />}
    </form>
  );
};

export default CardCheckoutForm;
