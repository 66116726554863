import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
// import BASE_URL from "../../../../config";
import { useSelector } from "react-redux";
import Spinner from "../../spinner";
import Toast from "../../toast";
import SessionExpired from "../../sessionExpired";

const WithoutStripeMethod = ({
    setShowPaymentScreen,
    handleHideJobType,
    postJobFunction,
    postJobReqParam
}) => {
  const { TOKEN } = useSelector((state) => state.menu);
  const stripe = useStripe();
  const elements = useElements();
  const [sessionExpired, setSessionExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const handleBackButtonCilck = () => {
    setShowPaymentScreen(false);
    handleHideJobType(true);
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
      try {
            setIsLoading(true);
            const postJobResponse = await postJobFunction(postJobReqParam);
            console.log("Payment successful!", postJobResponse);

            if (postJobResponse) {
              setShowPaymentScreen(false);
              setIsLoading(false);
            } else {
              displayErrMsg("Error Occured While posting your job!");
            }
          }
       catch (error) {
        displayErrMsg(error.message);
        setIsLoading(false);
        console.log("confirmError-----", error);
      } finally {
        setIsLoading(false);
      }
    }
  

  return (
    <form 
    // onSubmit={handleSubmit}
    >
      <button
        onClick={handleBackButtonCilck}
        className="site-button"
        style={{ marginRight: 10 }}
      >
        Back
      </button>
      <button
        type="button"
        className="site-button"
        onClick={handleSubmit}
        // disabled={!stripe || isLoading}
      >
        {isLoading ? <Spinner /> : `Submit`}
      </button>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
      {sessionExpired && <SessionExpired />}
    </form>
  );
};

export default WithoutStripeMethod;
