import { createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import { auth, db } from "../firebase/firebase";
import { getAuth, deleteUser } from 'firebase/auth';
import { setDoc, doc } from "firebase/firestore";
export const EmailVerification = async (email) => {
    const generateRandomPassword = () => {
        const length = 8;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~";
        let password = "";
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset[randomIndex];
        }
        return password;
    }
    const randomPassword = generateRandomPassword();
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, randomPassword);
        await sendPasswordResetEmail(auth,email);
        console.log('userCredential:', userCredential);
        return { message: 'Verification email sent! Please check your inbox.', uid: userCredential.user.uid };
    } catch (error) {
        if (error.code === 'auth/email-already-in-use') {
            console.error('Error: Email already in use');
            return { message: 'The email address is already in use. Please use a different email or reset your password.', uid: null };
        } else {
            console.error('Error during user creation or email verification:', error);
            return { message: 'Error during user creation or email verification.', uid: null };
        }
    }
}
export const deleteFirebaseUser = () => {
    const authuser = getAuth();
  const user = authuser.currentUser;
console.log('user:::::::::::::::',user)
  if (user) {
     deleteUser(user)
      .then(() => {
        console.log('User deleted successfully');
      })
      .catch((error) => {
        console.error('Error deleting user:', error);
      });
  } else {
    console.log('No user is signed in.');
  }
  }
  export const insertFirebaseUser = async (request, userId) => {
    if (!request) {
      throw new Error('Request object is required');
    }
  
    const docRef = doc(db, 'users', userId);
  
    await setDoc(docRef, request);
  };