import React, { useState } from "react";
import { useSelector } from "react-redux";
import JobPostForm from "../../../../common/register/JobPostForm";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../../globals/fomat-comapny-name";

function JobPostPosterForm() {
  const [sessionExpired, setSessionExpired] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedItemId, setSelectedItemId] = useState("");
  const { userInfo } = useSelector((state) => state.menu);
  const [showJobTypeTab, setShowJobTypeTab] = useState(true);
  const [activeTab, setActiveTab] = useState("single"); // State for active tab

  const empDetailId = userInfo?.id;

  const handleHideJobType = (status) => {
    setShowJobTypeTab(status);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
    <div>
        <Helmet>
          <title>{`Post a Job Opportunity | Create Your Job Listing | ${formatCompanyName()}`}</title>
          <meta name="description" content="Welcome to our Job Posting Form! Use this simple and intuitive form to submit your job openings and connect with potential candidates." />
          <meta name="keywords" content="job application, employment opportunities, job posting, hire talent, career opportunities" />
        </Helmet>
      </div>
      <div className="twm-tabs-style-1">
        <div className="row">
          <div className="twm-tabs-style-2">
            {showJobTypeTab ? (
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === "single" ? "active" : ""}`}
                    onClick={() => handleTabChange("single")}
                    type="button"
                  >
                    <i className="fas fa-user-tie" />
                    Single
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === "multiple" ? "active" : ""}`}
                    onClick={() => handleTabChange("multiple")}
                    type="button"
                  >
                    <i className="fas fa-building" />
                    Multiple
                  </button>
                </li>
              </ul>
            ) : (
              ""
            )}

            <div className="tab-content" id="myTab2Content">
              {/* <div className={`tab-pane fade ${activeTab === "single" ? "show active" : ""}`} id="sign-single"> */}
                <JobPostForm
                  role_type={activeTab === "single" ? "Single" : "Multiple"}
                  empDetailId={empDetailId}
                  selectedItemId={selectedItemId ? selectedItemId : ""}
                  handleClosePopup={undefined}
                  modalShow={undefined}
                  handleHideJobType={handleHideJobType}
                />
              {/* </div> */}
              {/* <div className={`tab-pane fade ${activeTab === "multiple" ? "show active" : ""}`} id="sign-multiple">
                <JobPostForm
                  role_type={"Multiple"}
                  empDetailId={empDetailId}
                  selectedItemId={selectedItemId ? selectedItemId : ""}
                  handleClosePopup={undefined}
                  modalShow={undefined}
                  handleHideJobType={handleHideJobType}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {sessionExpired && (
        <div className="session-expired-message">Your session has expired.</div>
      )}
    </>
  );
}

export default JobPostPosterForm;
