import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { loadScript } from "../../../../../../globals/constants";
import ReactDatePicker from "react-datepicker";
import apiCall from "../../../../../../apicall";
import { useSelector } from "react-redux";
import moment from "moment";
import Spinner from "../../../../../common/spinner";
import Toast from "../../../../../common/toast";
import SessionExpired from "../../../../../common/sessionExpired";
import { logEvent_Analytics } from "../../../../../common/firebase/firebase-analytics-service";

const BackgroundForm = ({ getBackgroundForms, backgroundScreenFormData }) => {
  const { TOKEN } = useSelector((state) => state.menu);
  const [formData, setFormData] = useState({
    otherNames: backgroundScreenFormData?.alternateNames ? backgroundScreenFormData?.alternateNames : "",
    driverLicenseOrStateId: backgroundScreenFormData?.driverLicenseOrStateId ? backgroundScreenFormData?.driverLicenseOrStateId : "",
    ExpirationDate: new Date(),
    issueState: backgroundScreenFormData?.issueState ? backgroundScreenFormData?.issueState : "",
    county: backgroundScreenFormData?.county ? backgroundScreenFormData?.county : "",
    ssn: backgroundScreenFormData?.ssn ? backgroundScreenFormData?.ssn : "",
    race: backgroundScreenFormData?.race ? backgroundScreenFormData.race : "",
    ethnicity: backgroundScreenFormData?.ethnicity ? backgroundScreenFormData.ethnicity : "",
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [showStateId, setShowStateId] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const buttonTabs = ["Driver License", "State ID"]; // Example tab labels
  const raceVal = [
    {
      key: "American_Indian_Alaskan_Native",
      value: "American Indian or Alaskan Native",
    },
    { key: "Asian", value: "Asian" },
    { key: "Black", value: "Black" },
    {
      key: "Native_Hawaiian_Pacific_Islander",
      value: "Native Hawaiian or Pacific Islander",
    },
    { key: "White", value: "White" },
    { key: "Unable_to_Determine", value: "Unable to determine" },
  ];
  const ethenticityVal = [
    { key: "Hispanic", value: "Hispanic" },
    { key: "Non_Hispanic", value: "Non-Hispanic" },
    { key: "Unable_to_determine", value: "Unable to determine" },
  ];
  const formatSSN = (ssn) => {
    const cleaned = ssn.replace(/\D/g, ""); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return ssn; // Return original value if not 9 digits
  };  

  const handleChange = (field, value) => {
    const formattedValue = field === "ssn" ? formatSSN(value) : value;
    setFormData({
      ...formData,
      [field]: formattedValue,
    });
    setValidationErrors({
      ...validationErrors,
      [field]: formattedValue 
        ? field === "ssn" && !/^\d{3}-\d{2}-\d{4}$/.test(formattedValue) 
          ? "SSN must be in ###-##-#### format" 
          : "" 
        : "This field is required",
    });
  };  

  const updateIndex = (index) => {
    // setFormData({ ...formData, selectedIndex: index });
    setSelectedIndex(index);
    setShowStateId(index === 1); // Example condition to show/hide state ID input
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, ExpirationDate: date });
    setValidationErrors({
      ...validationErrors,
      ExpirationDate: date ? "" : "This field is required",
    });
  };

  const validateForm = () => {
    const errors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        errors[key] = "This field is required";
      } else if (key === "ssn" && !/^\d{3}-\d{2}-\d{4}$/.test(formData[key])) {
        errors[key] = "SSN must be in ###-##-#### format";
      }
    });
    setValidationErrors(errors);
    console.log("errors===========", errors);
    return Object.keys(errors).length === 0;
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
  };

  const handleBackgroundFormFill = async (e) => {
    console.log("inside the handleback");
    e.preventDefault();

    console.log("sdklfjsadjlkjflasldf", !validateForm());

    if (!validateForm()) {
      return;
    }
    try {
      // Replace with your API call
      setIsLoading(true);
      const updatedData = {
        ...formData,
        driversLicense: showStateId ? false : true,
        expirationDate: moment(formData?.ExpirationDate).format("DD-MM-YYYY"),
        alternateNames: [formData?.otherNames],
        county: formData?.county,
        signatureTimeStamp: new Date(),
        stateId: showStateId ? formData.driverLicenseOrStateId : ""
      };

      console.log("handleBackgroundFormFill--", updatedData);
      const backgroundResponse = await apiCall(
        `applicants/background`,
        "POST",
        updatedData,
        TOKEN
      );
      if (backgroundResponse?.status === 200) {
        setIsLoading(false);
        setErrSuccessMsg({
          message: "Background form submitted Successfully!",
          status: "success",
        });
        getBackgroundForms();
        setFormData({
          otherNames: "",
          driverLicenseOrStateId: "",
          ExpirationDate: new Date(),
          issueState: "",
          county: "",
          ssn: "",
          race: "",
          ethnicity: "",
        });
        setSelectedIndex(0);
        setShowStateId(false);
      } else if (backgroundResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!");
      } else {
        setSessionExpired(false);
        // displayErrMsg("Something went wrong!");
        displayErrMsg(backgroundResponse?.response?.data?.message)
      }
    } catch (error) {
      setIsLoading(false);
      displayErrMsg("Something went wrong!");
    }
  };

  useEffect(() => {
    loadScript("js/custom.js");
  });

  useEffect(()=>{
    logEvent_Analytics('background_form_web');
  },[])

  console.log("backgroundScreenFormData", backgroundScreenFormData);

  return (
    <>
      {backgroundScreenFormData?.applicationDocumentStatus === "approved" ? (
        <h3>Background certificate approved successfully!</h3>
      ) : (
        <form className="twm-filter-select" onSubmit={handleBackgroundFormFill}>
          <input
            className="form-control mb-3"
            name="otherNames"
            placeholder="Other Names (if applicable)"
            value={formData.otherNames}
            onChange={(e) => handleChange("otherNames", e.target.value)}
          />
          {validationErrors.otherNames && (
            <div className="text-danger">{validationErrors.otherNames}</div>
          )}
          <div className="btn-group mb-3">
            {buttonTabs.map((tab, index) => (
              <button
                key={index}
                type="button"
                className={`btn ${
                  selectedIndex === index ? "btn-primary" : "btn-secondary"
                }`}
                onClick={() => updateIndex(index)}
              >
                {tab}
              </button>
            ))}
          </div>
          {!showStateId ? (
            <>
              <input
                className="form-control mb-3"
                placeholder="Driver Licence Number"
                type="text"
                name="driverLicenseOrStateId"
                value={formData.driverLicenseOrStateId}
                onChange={(e) => handleChange("driverLicenseOrStateId", e.target.value)}
              />
              {validationErrors.driverLicenseOrStateId && (
                <div className="text-danger">{validationErrors.driverLicenseOrStateId}</div>
              )}
            </>
          ) : (
            <>
              <input
                className="form-control mb-3"
                placeholder="State ID"
                type="text"
                name="driverLicenseOrStateId"
                value={formData.driverLicenseOrStateId}
                onChange={(e) => handleChange("driverLicenseOrStateId", e.target.value)}
              />
              {validationErrors.driverLicenseOrStateId && (
                <div className="text-danger">{validationErrors.driverLicenseOrStateId}</div>
              )}
            </>
          )}
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <label>Expiration Date (MM-DD-YYYY):</label>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="input-group mb-3">
                <ReactDatePicker
                  className="form-control"
                  name="ExpirationDate"
                  id="date"
                  selected={formData.ExpirationDate}
                  onChange={(date) => handleDateChange(date)}
                  dateFormat="MM-dd-yyyy"
                  placeholderText="Date"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  minDate={new Date()}
                />
              </div>
              {validationErrors.ExpirationDate && (
                <div className="text-danger">
                  {validationErrors.ExpirationDate}
                </div>
              )}
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="form-group">
                <input
                  className="form-control mb-3"
                  placeholder="Issue State"
                  name="issueState"
                  value={formData.issueState}
                  onChange={(e) =>
                    handleChange("issueState", e.target.value.toUpperCase())
                  }
                />
                {validationErrors.issueState && (
                  <div className="text-danger">
                    {validationErrors.issueState}
                  </div>
                )}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="form-group">
                <input
                  className="form-control mb-3"
                  placeholder="County of residence"
                  name="county"
                  value={formData.county}
                  onChange={(e) => handleChange("county", e.target.value)}
                />
                {validationErrors.county && (
                  <div className="text-danger">{validationErrors.county}</div>
                )}
              </div>
            </div>
          </div>
          <input
            className="form-control mb-3"
            placeholder="Social Security Number"
            type="text"
            name="ssn"
            maxLength={11}
            value={formData.ssn}
            onChange={(e) => handleChange("ssn", e.target.value)}
          />
          {validationErrors.ssn && (
            <div className="text-danger">{validationErrors.ssn}</div>
          )}
          <div className="form-group mb-3">
            <label className="float-left">Race:</label>
            <select
              className="wt-select-bar-large selectpicker"
              data-live-search="true"
              data-bv-field="size"
              name="race"
              value={formData.race}
              onChange={(e) => handleChange("race", e.target.value)}
            >
              <option disabled value="">
                Select Item
              </option>
              {raceVal.map((option) => (
                <option key={option.key} value={option.key}>
                  {option.value}
                </option>
              ))}
            </select>
            {validationErrors.race && (
              <div className="text-danger">{validationErrors.race}</div>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="float-left">Ethnicity:</label>
            <select
              className="wt-select-bar-large selectpicker"
              data-live-search="true"
              data-bv-field="size"
              name="ethnicity"
              value={formData.ethnicity}
              onChange={(e) => handleChange("ethnicity", e.target.value)}
            >
              <option disabled value="">
                Select Item
              </option>
              {ethenticityVal.map((option) => (
                <option key={option.key} value={option.key}>
                  {option.value}
                </option>
              ))}
            </select>
            {validationErrors.ethnicity && (
              <div className="text-danger">{validationErrors.ethnicity}</div>
            )}
          </div>
          <div className="d-flex justify-content-center">
            <button className="btn btn-primary" type="submit">
              {isLoading ? <Spinner /> : "Send"}
            </button>
          </div>
        </form>
      )}

      {sessionExpired && <SessionExpired />}
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
};

export default BackgroundForm;
