// analyticsService.js
import { analytics } from "./firebase"; 
import { logEvent } from "firebase/analytics";

export const logEvent_Analytics =async (eventName, params = {}) => {
  if (!eventName) {
    console.error("Event name is required for Firebase Analytics.");
    return;
  }

  try {
    await logEvent(analytics, eventName, params);
    console.log(`Event logged: ${eventName}`, params);
  } catch (error) {
    console.error("Failed to log event:", error);
  }
};
