import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import SessionExpired from "../../../../common/sessionExpired";
import Toast from "../../../../common/toast";
import { userDetails } from "../../../../../store/reducers/menu";
import { loadScript } from "../../../../../globals/constants";
import Spinner from "../../../../common/spinner";
import apiCall from "../../../../../apicall";
import { themeColors } from "../../../../../store/reducers/menu";
import { fetchCompanyLogo, fetchThemeColor } from "./themeColor";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../../globals/fomat-comapny-name";

function ContactUs() {
    const dispatch = useDispatch();
    const { userInfo, TOKEN } = useSelector(
        (state) => state.menu
    );
    const [isLoading, setIsLoading] = useState(false);
    const [sessionExpired, setSessionExpired] = useState(false);
    const [formValues, setFormValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: ""
    });
    const [errSuccessMesg, setErrSuccessMsg] = useState({
        message: "",
        status: "error",
    });
    const displayErrMsg = (errMsg) => {
        setErrSuccessMsg({
            message: errMsg,
            status: false,
        });
    };
    const displaySuccessMsg = () => {
        setIsLoading(false);
        setErrSuccessMsg({
            message: "Contact Us form submitted successfully!",
            status: true,
        });
        setFormValues({
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
        });
    };

    const [formErrors, setFormErrors] = useState({});
    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = phoneNumber.replace(/\D/g, ""); // Remove non-numeric characters
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `${match[1]}-${match[2]}-${match[3]}`;
        }
        return phoneNumber; // Return original value if not 10 digits
    };
    // const handleInputChange = (e, fieldName) => {
    //     const { name, value } = e.target;
    //     setFormValues((prevValues) => ({
    //         ...prevValues,
    //         [fieldName || name]: value,
    //     }));

    //     setFormErrors((prevErrors) => ({
    //         ...prevErrors,
    //         [fieldName || name]: "",
    //     }));
    // };
    const handleInputChange = (e, fieldName) => {
        const { name, value } = e.target;
        if (fieldName === "phoneNumber") {
            // Format phone number when 10 digits are completed
            const formattedPhoneNumber = formatPhoneNumber(value);
            setFormValues((prevValues) => ({
                ...prevValues,
                [fieldName || name]: formattedPhoneNumber,
            }));
        } else {


            setFormValues((prevValues) => ({
                ...prevValues,
                [fieldName || name]: value,
            }));
        }
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName || name]: "",
        }));
    };

    const validateForm = () => {
        const errors = {};
        if (!formValues.phoneNumber || formValues.phoneNumber.length !== 12) {
            errors.phoneNumber = "Phone number must be in the format 123-456-7890.";
        }
        ["firstName", "lastName", "email", "phoneNumber"].forEach((key) => {
            if (!formValues[key]) {
                errors[key] = `${key} is required.`;
            }
        });
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const handleContactSubmit = async (event) => {
        event.preventDefault();

        if (validateForm()) {
            setIsLoading(true);

            const formattedData = {
                ...formValues,
            };
            try {
                const registerResponse = await apiCall("config/contactForm", "POST", formattedData);
                console.log("registerResponse", registerResponse)
                if (registerResponse?.status === 200) {
                    displaySuccessMsg();
                } else if (registerResponse?.response?.status === 400) {
                    displayErrMsg(registerResponse?.response?.data?.errorMessage);
                    setIsLoading(false);
                } else {
                    // displayErrMsg("Something went wrong!");
                    displayErrMsg(registerResponse?.response?.data?.message)
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
                displayErrMsg("Something went wrong!");
                // console.error("Error:", error);
            }
        } else {
            setIsLoading(false);
            displayErrMsg("Check the form validation!");
            // console.log("Form validation failed.");
        }
    };

    useEffect(() => {
        loadScript("js/custom.js");
    });
    useEffect(() => {
        fetchThemeColor(dispatch);
        fetchCompanyLogo(dispatch);
    }, []);

    return (
        <>
        <div>
        <Helmet>
          <title>{`Contact Us | Get in Touch | Connect with Us | ${formatCompanyName()}`}</title>
          <meta name="description" content="Get in touch with us! Whether you have questions, feedback, or need assistance, we’re here to help. Fill out the contact form or reach us directly through the provided information. Your inquiries are important to us!" />
          <meta name="keywords" content="contact us, get in touch, customer support, inquiries" />
        </Helmet>
      </div>
            <div className="section-full p-t120  p-b90 site-bg-white">
                <div className="container">
                    <div className="row">
                        <form id="jobseeker_profile" onSubmit={handleContactSubmit}>
                            <div className="panel panel-default">
                                <div className="panel-heading d-flex justify-content-between align-items-center wt-panel-heading p-a20">
                                    <h4 className="panel-tittle mt-5">Contact Us</h4>
                                    {/* {role_type !== "ROLE_OPENSTAFF_ADMIN" && (
                                        <a
                                            className="site-button"
                                            data-bs-toggle="modal"
                                            href="#delete_profile_popup"
                                            role="button"
                                        >
                                            Delete Profile
                                        </a>
                                    )} */}
                                </div>
                                <div className="panel-body wt-panel-body p-a20 m-b30 ">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <label>First Name</label>
                                                <div className="ls-inputicon-box">
                                                    <input
                                                        className="form-control"
                                                        name="firstName"
                                                        type="text"
                                                        placeholder="First Name"
                                                        value={formValues.firstName}
                                                        onChange={(e) => handleInputChange(e, "firstName")}
                                                    />
                                                    <i className="fs-input-icon fa fa-user " />
                                                </div>
                                                {formErrors.firstName && (
                                                    <span className="error-message">
                                                        {formErrors.firstName}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <label>Last Name</label>
                                                <div className="ls-inputicon-box">
                                                    <input
                                                        className="form-control"
                                                        name="lastName"
                                                        type="text"
                                                        placeholder="Last Name"
                                                        value={formValues.lastName}
                                                        onChange={(e) => handleInputChange(e, "lastName")}
                                                    />
                                                    <i className="fs-input-icon fa fa-user " />
                                                </div>
                                                {formErrors.lastName && (
                                                    <span className="error-message">{formErrors.lastName}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <label>Email Address</label>
                                                <div className="ls-inputicon-box">
                                                    <input
                                                        className="form-control"
                                                        name="email"
                                                        type="email"
                                                        placeholder="Email"
                                                        value={formValues.email}
                                                        onChange={(e) => handleInputChange(e, "email")}
                                                    />
                                                    <i className="fs-input-icon fas fa-at" />
                                                </div>
                                                {formErrors.email && (
                                                    <span className="error-message">{formErrors.email}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <label>Phone</label>
                                                <div className="ls-inputicon-box">
                                                    <input
                                                        className="form-control"
                                                        name="phoneNumber"
                                                        type="text"
                                                        placeholder="Phone Number"
                                                        value={formValues.phoneNumber}
                                                        maxLength={12}
                                                        onChange={(e) => handleInputChange(e, "phoneNumber")}

                                                    />
                                                    <i className="fs-input-icon fa fa-phone-alt" />
                                                </div>
                                                {formErrors.phoneNumber && (
                                                    <span className="error-message">
                                                        {formErrors.phoneNumber}
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                            <div className="col-lg-12 col-md-12">
                                                <div className="text-left">
                                                    <button type="submit" className="site-button">
                                                        {isLoading ? <Spinner /> : "Save Changes"}
                                                    </button>

                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {sessionExpired && <SessionExpired />}
            {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
        </>
    );
}

export default ContactUs;
