import React, { useEffect, useState, useRef } from "react";
import apiCall from "../../../../../apicall";
// import { useSelector } from "react-redux";
import Toast from "../../../../common/toast";
// import SessionExpired from "../../../../common/sessionExpired";
import TableLoader from "../../../../common/tableLoader";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";

const TermsConditionModal = ({ setTermsAndConditionId, handleAgreeTerms, removeTermsCondition }) => {
  //   const { role_type, TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [termsText, setTermsText] = useState();
  const modalRef = useRef(null);

  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  //   const [sessionExpired, setSessionExpired] = useState(false);

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
  };

  const getTermsConditionText = async () => {
    try {
      setIsLoading(true);
      let privacyResponse = await apiCall(
        "termsAndConditions/Applicants",
        "GET",
        null,
      );
      // if (role_type === "ROLE_USER") {
      // privacyResponse = await apiCall(
      //   "termsAndConditions/Applicants",
      //   "GET",
      //   null,
      // );
      // } 
      // else if (role_type === "ROLE_INSTITUTION_ADMIN") {
      //   privacyResponse = await apiCall(
      //     "termsAndConditions/Institutions",
      //     "GET",
      //     null,
      //   );
      // }
      console.log("privacyResponse----", privacyResponse);
      if (privacyResponse?.status === 200) {
        setIsLoading(false);
        setTermsAndConditionId(privacyResponse?.data?.id);
        const text = privacyResponse?.data?.document.replace(/\r?\n/g, "<br>");
        setTermsText(text);
      } else if (privacyResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(privacyResponse?.response?.data?.message)
      }
    } catch (err) {
      console.log("setSessionExpired--", err);
      displayErrMsg("Something went wrong!");
    }
  };
  useEffect(() => {
    getTermsConditionText();
    logEvent_Analytics('terms_conditions_web');
  }, []);

  return (
    <div
      className="modal fade twm-sign-up"
      id="terms_and_condition_id"
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        {isLoading ? (
          <div className="modal-content">
            <TableLoader />
          </div>
        ) : (
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title" id="session_expired_popupLabel2">
                Terms And Conditions
              </h2>
              {/* <p
              style={{ textAlign: "left" }}
              dangerouslySetInnerHTML={{ __html: termsText }}
            /> */}
              <button
                type="button"
                className="btn-close"
                onClick={removeTermsCondition}
              />
            </div>
            <div className="modal-body" style={{"maxHeight": "500px", "overflowY": "scroll"}}>
              <p
                style={{ textAlign: "left" }}
                dangerouslySetInnerHTML={{ __html: termsText }}
              />
            </div>

            <div className="modal-footer">
              {/* <button
              type="button"
              className="btn btn-secondary"
              onClick={removeTermsCondition}
            >
              Close
            </button> */}
              <button
                type="button"
                className="btn btn-success"
                onClick={() => handleAgreeTerms(modalRef)}
              >
                Agree
              </button>
            </div>
          </div>
        )}
        {/* {sessionExpired && <SessionExpired />}*/}
        {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
      </div>
    </div>
  );
};

export default TermsConditionModal;
