import GoogleMapCotainer from "../../../../../common/googleMap";

function SectionJobLocation({ jobDetailsInfo }) {

  return (
    <>
      <h4 className="twm-s-title">Location</h4>
      <div className="twm-m-map mb-2" style={{ height: "310px" }}> {/* Ensure height is applied */}
        <GoogleMapCotainer mapDetails={jobDetailsInfo}/>
      </div>
    </>
  );
}

export default SectionJobLocation;
