import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import JobzImage from "../../../../common/jobz-img";
import { publicUser } from "../../../../../globals/route-names";
import Toast from "../../../../common/toast";
import apiCall from "../../../../../apicall";
import Spinner from "../../../../common/spinner";
import { useDispatch } from "react-redux";
import {
  userDetails,
  logout,
  roleType,
} from "../../../../../store/reducers/menu";

function ActivateUser() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState("");
  const [pin, setPin] = useState("");
  const [formValues, setFormValues] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formValues.password) {
      errors.password = "Password is required";
    }
    if (!formValues.confirmPassword) {
      errors.confirmPassword = "Confirm Password is required";
    }
    if (formValues.password !== formValues.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }
    return errors;
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      try {
        const activeUserResponse = await apiCall("activate/finish", "POST", {
          key: key,
          newPassword: formValues?.password,
          pin: pin,
        });
        console.log("activeUserResponse", activeUserResponse);
        if (activeUserResponse?.status === 200) {
          setIsLoading(false);
          setErrSuccessMsg({
            message: "Password created successfully!",
            status: "Success",
          });
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else if (activeUserResponse?.response?.status === 400) {
          displayErrMsg(activeUserResponse?.response?.data?.errors[0]);
        } else {
          displayErrMsg("Something went wrong");
        }
      } catch (err) {
        console.log("acitve user catch error--", err);
        displayErrMsg("Something went wrong!");
      }
    } else {
      setFormErrors(errors);
      setIsLoading(false);
      displayErrMsg("Check form validation!");
    }
  };

  const getActivateUserPin = async (urlKey) => {
    try {
      const activateUserPinResponse = await apiCall(
        `activate/init?key=${urlKey}`,
        "GET",
        null
      );
      console.log("activateUserPinResponse", activateUserPinResponse);
      if (activateUserPinResponse?.status === 200) {
        setPin(activateUserPinResponse?.data);
      } else if (activateUserPinResponse?.response?.status === 404) {
        displayErrMsg(activateUserPinResponse?.response?.data?.errorMessage);
      } else {
        displayErrMsg("Something went wrong");
        displayErrMsg(activateUserPinResponse?.response?.data?.message)
      }
    } catch (err) {
      console.log("acitve user pin catch error--", err);
      displayErrMsg("Something went wrong!");
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    const key = searchParams.get("key");
    console.log("key value", key);
    if (key) {
      setKey(key);
      getActivateUserPin(key);
    }
  }, [location]);

  useEffect(() => {
    dispatch(logout(null));
    dispatch(userDetails(null));
    dispatch(roleType([]));
  }, []);

  return (
    <>
      <div
        className="section-full site-bg-gray twm-u-maintenance-area"
        style={{
          backgroundImage: `url(https://thewebmax.org/react/jobzilla/assets/images/background/bg-1.jpg)`,
        }}
      >
        <div className="twm-u-maintenance-wrap">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="twm-u-maintenance-content">
                <div className="media">
                  <NavLink to={publicUser.HOME1}>
                    <JobzImage
                      src="images/openStaffLogo.png"
                      alt=""
                      style={{ height: "80px" }}
                    />
                  </NavLink>
                </div>
                <h2 className="twm-u-maintenance-title">Activate User</h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="password"
                        name="password"
                        value={formValues.password}
                        onChange={handleInputChange}
                        required
                      />
                      <span
                        className="password-toggle"
                        style={{ paddingRight: "10px" }}
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        <i
                          className={`fa ${
                            showPassword ? "fa-eye-slash" : "fa-eye"
                          }`}
                        ></i>
                      </span>
                    </div>
                    {formErrors.password && (
                      <span className="text-danger">{formErrors.password}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <div style={{ position: "relative" }}>
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-control"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formValues.confirmPassword}
                        onChange={handleInputChange}
                        required
                      />
                      <span
                        style={{ paddingRight: "10px" }}
                        className="password-toggle"
                        onClick={() => setShowConfirmPassword((prev) => !prev)}
                      >
                        <i
                          className={`fa ${
                            showConfirmPassword ? "fa-eye-slash" : "fa-eye"
                          }`}
                        ></i>
                      </span>
                    </div>
                    {formErrors.confirmPassword && (
                      <span className="text-danger">
                        {formErrors.confirmPassword}
                      </span>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="site-button"
                    disabled={pin ? false : true}
                  >
                    {isLoading ? <Spinner /> : "Activate"}
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="twm-u-maintenance-image">
                <JobzImage src="images/under-m.png" alt="#" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default ActivateUser;
