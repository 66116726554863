import React, { useState } from "react";
import Toast from "../../../../../common/toast";
import Spinner from "../../../../../common/spinner";
import { useSelector } from "react-redux";
import axios from "axios";
import BASE_URL from "../../../../../../config";
import moment from "moment";
import ReactDatePicker from "react-datepicker";

const ApprovedDocumentPopUp = ({
  approvedDocId,
  handleDocApprovedSuccess,
  tabType,
  documentType,
}) => {
  const { TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [isDocApproved, setIsDocApproved] = useState(false);
  const [numberOfHours, setNumberOfHours] = useState("");
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const [expireTime, setExpireTime] = useState(new Date() + 1);

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setIsDocApproved(false);
  };

  const handleDocApproved = async () => {
    try {
      let url;
      if (expireTime <= new Date()) {
        displayErrMsg("Expiration time must be greater than the current date.");
        return;
      }
      if (tabType === "certificate" && !numberOfHours) {
        displayErrMsg("Please enter the number of hours.");
        return;
      }
      setIsLoading(true);
      const formData = new FormData();
      formData.append("expiredTime", moment(expireTime).format("MM/DD/YYYY"));

      if (tabType === "document" && !documentType) {
        url = `${BASE_URL}openstaff/documents/${approvedDocId}/approves?expiredTime=${new Date(expireTime)}`;
      } else if (tabType === "certificate") {
        url = `${BASE_URL}openstaff/certificates/${approvedDocId}/approves?expiredTime=${new Date(expireTime)}&numberOfHours=${numberOfHours}`;
      } else if (documentType) {
        //to approve background doc
        url = `${BASE_URL}openstaff/background/${approvedDocId}/approves?expiredTime=${new Date(expireTime)}`;
      }

      const config = {
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${TOKEN}`,
        },
      };

      const approvedDocResponse = await axios.put(url, formData, config);
      if (approvedDocResponse?.status === 200) {
        setIsLoading(false);
        setIsDocApproved(true);
        setErrSuccessMsg({
          message: `${
            tabType === "document"
              ? "Document"
              : tabType === "certificate"
              ? "Certifcate"
              : ""
          } Approved successfully!`,
          status: true,
        });
        handleDocApprovedSuccess();
      } else if (approvedDocResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(approvedDocResponse?.response?.data?.message)
      }
    } catch (err) {
      console.log("setSessionExpired--", err);
      displayErrMsg("Something went wrong!");
    }
  };

  return (
    <div
      className="modal fade twm-sign-up"
      //   id="approved_doc_confirmation"
      id={`${
        tabType === "document"
          ? "approved_doc_confirmation"
          : tabType === "certificate"
          ? "approved_certificate_confirmation"
          : ""
      }`}
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="sign_up_popupLabel2">
              {isDocApproved
                ? "Document Approved Successfully"
                : "Are you sure you want to Approved Document?"}
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setIsDocApproved(false)}
            />
          </div>
          <div className="modal-body">
            {!isDocApproved && (
              <center>
                <div className="form-group mb-3">
                  <div htmlFor="expireTime" className="form-label">
                    Select Expiration Time:
                  </div>
                  <ReactDatePicker
                    selected={expireTime}
                    onChange={(date) => setExpireTime(date)}
                    minDate={new Date()}
                    placeholderText="MM-DD-YYYY"
                    dateFormat="MM-dd-yyyy"
                    className="form-control"
                    id="expireTime"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>

                {tabType === "certificate" && ( // Render number of hours input field if tabType is certificate
                  <div className="form-group mb-3">
                    <label htmlFor="numberOfHours" className="form-label">
                      Number of Hours:
                    </label>
                    <input
                      type="number"
                      id="numberOfHours"
                      className="form-control"
                      value={numberOfHours}
                      onChange={(e) => setNumberOfHours(e.target.value)}
                      required
                    />
                  </div>
                )}
              </center>
            )}
          </div>
          <div className="modal-footer">
            {isDocApproved ? (
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setIsDocApproved(false)}
              >
                Ok
              </button>
            ) : (
              <>
                {/* <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button> */}
                <button
                  type="button"
                  id="deleteConfirmationModal"
                  className="btn btn-primary"
                  // data-bs-dismiss="modal"
                  onClick={handleDocApproved}
                >
                  {isLoading ? <Spinner /> : "Approve"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </div>
  );
};

export default ApprovedDocumentPopUp;
