import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import SessionExpired from "../../../../common/sessionExpired";
import Toast from "../../../../common/toast";
import { loadScript } from "../../../../../globals/constants";
import apiCall from "../../../../../apicall";
import { formatCompanyName } from "../../../../../globals/fomat-comapny-name";
import TableLoader from "../../../../common/tableLoader";
import { themeColors } from "../../../../../store/reducers/menu";
import { fetchCompanyLogo, fetchThemeColor } from "./themeColor";
import { Helmet } from "react-helmet-async";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";

function PrivacyPolicy() {
    const dispatch = useDispatch();
    const { userInfo, TOKEN, role_type } = useSelector(
        (state) => state.menu
    );
    const [isLoading, setIsLoading] = useState(false);
    const [sessionExpired, setSessionExpired] = useState(false);
    const [formValues, setFormValues] = useState({
        // userName: userInfo?.userName ? userInfo.userName : "",
        firstName: userInfo?.firstName ? userInfo.firstName : "",
        lastName: userInfo?.lastName ? userInfo.lastName : "",
        email: userInfo?.email ? userInfo.email : ""
    });
    const [errSuccessMesg, setErrSuccessMsg] = useState({
        message: "",
        status: "error",
    });
    const [privacyText, setPrivacyText] = useState();

    const displayErrMsg = (errMsg) => {
        setErrSuccessMsg({
            message: errMsg,
            status: false,
        });
        setIsLoading(false);
    };

    const getPrivacyPolicyInfo = async () => {
        try {
            setIsLoading(true);
            let privacyPolicyResponse = await apiCall(
                "privacyPolicy/Applicants",
                "GET",
                null
            );
            console.log("privacyPolicyResponse----", privacyPolicyResponse);
            if (privacyPolicyResponse?.status === 200) {
                setIsLoading(false);
                // setPrivacyPolicyId(privacyPolicyResponse?.data?.id);
                const text = privacyPolicyResponse?.data?.document.replace(/\r?\n/g, "<br>");
                setPrivacyText(text);
            } else if (privacyPolicyResponse?.response?.status == 401) {
                displayErrMsg("Your session has been expired!");
            } else {
                // displayErrMsg("Something went wrong!");
                displayErrMsg(privacyPolicyResponse?.response?.data?.message)
            }
        } catch (err) {
            console.log("setSessionExpired--", err);
            displayErrMsg("Something went wrong!");
        }
    };

    useEffect(() => {
        getPrivacyPolicyInfo();
        logEvent_Analytics('privacy_policy_web');
    }, []);
    useEffect(() => {
        fetchThemeColor(dispatch);
        fetchCompanyLogo(dispatch);
    }, []);

    useEffect(() => {
        loadScript("js/custom.js");
    });

    return (
        <>
            <div>
                <Helmet>
                    <title>{`Privacy Policy | User Privacy Rights | ${formatCompanyName()}`}</title>
                    <meta name="description" content="Welcome to our Privacy Policy page. Here, we outline how we collect, use, and protect your personal information. We are committed to safeguarding your privacy and ensuring transparency about how your data is handled." />
                    <meta name="keywords" content="privacy policy" />
                </Helmet>
            </div>
            <div className="section-full p-t120  p-b90 site-bg-white">
                <div className="container">
                    <div className="row">
                        <form id="jobseeker_profile">
                            <div className="panel panel-default">
                                <div className="panel-heading d-flex justify-content-between align-items-center wt-panel-heading p-a20">
                                    <h4 className="panel-tittle mt-5">{formatCompanyName()} Privacy Policy</h4>
                                </div>
                                <div className="panel-body wt-panel-body p-a20 m-b30 ">
                                    {!isLoading ? (<div className="row">
                                        <p
                                            style={{ textAlign: "justify" }}
                                            dangerouslySetInnerHTML={{ __html: privacyText }}
                                        />
                                    </div>
                                    ) : (
                                        <TableLoader />
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {sessionExpired && <SessionExpired />}
            {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
        </>
    );
}

export default PrivacyPolicy;
