import { useLocation, useNavigate } from "react-router-dom";
import SectionPagination from "../common/section-pagination";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import GetProfileImage from "../../../../common/getProfileImage";
import TableLoader from "../../../../common/tableLoader";
import ConfirmationDeleteJobPopUp from "./confirmation-delete-job";
import JobReviewPopUp from "../../../../common/popups/popup-job-review";
import Toast from "../../../../common/toast";
import { jobDetailsData } from "../../../../../store/reducers/menu";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

// Add legend component
const JobStatusLegend = () => {
  const statuses = [
    { status: "Open", color: "#28a745" },
    { status: "Scheduled", color: "#17a2b8" },
    { status: "In Progress", color: "#ffc107" },
    { status: "Accepted", color: "#007bff" },
    { status: "Applied", color: "#6c757d" },
    { status: "Completed", color: "#20c997" },
    { status: "Cancelled", color: "#dc3545" },
    { status: "Closed", color: "#795548" },
  ];

  return (
    <div style={{ display: "flex", gap: "15px", marginBottom: "10px" }}>
      {statuses.map(({ status, color }) => (
        <div key={status} style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              backgroundColor: color,
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              display: "inline-block",
              marginRight: "5px",
            }}
          ></span>
          <span>{status}</span>
        </div>
      ))}
    </div>
  );
};

function SectionJobsCalenderList({
  isLoading,
  jobListData,
  currentPage,
  setCurrentPage,
  totalPages,
  fetchjobList,
  seekerTotalAmount,
  jobDetailsInfo,
  jobDetailId,
  jobRating,
}) {
  moment.locale("en-GB");
  const localizer = momentLocalizer(moment);

  const { role_type, isLoggedIn } = useSelector((state) => state.menu);
  const [jobId, setJobId] = useState();
  const location = useLocation();
  const dispatch = useDispatch();
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const navigate = useNavigate();

  const navigateToJobDetails = (item) => {
    console.log("item====", item);
    navigate(`/job-detail/${item.id}`, { state: item });
  };

  const events = jobListData?.map((job) => {
    return {
      title: job?.jobTitle,
      start: moment.utc(job?.startTime).local().toDate(),
      end: moment.utc(job?.endTime).local().toDate(),
      jobStatus: job?.jobStatus,
      ...job,
    };
  });

  const eventStyleGetter = (event) => {
    let backgroundColor;
    console.log("event=====", event?.jobStatus);

    switch (event?.jobStatus) {
      case "eOpen":
        backgroundColor = "#28a745"; // Green for open status
        break;
      case "scheduled":
        backgroundColor = "#17a2b8"; // Light blue for scheduled
        break;
      case "eInProgress":
        backgroundColor = "#ffc107"; // Yellow for in-progress
        break;
      case "eAccepted":
        backgroundColor = "#007bff"; // Blue for accepted
        break;
      case "eApplied":
        backgroundColor = "#6c757d"; // Gray for applied
        break;
      case "eCompleted":
        backgroundColor = "#20C997"; // Green for completed
        break;
      case "eCancelled":
        backgroundColor = "#dc3545"; // Red for cancelled
        break;
      case "eClosed":
        backgroundColor = "#795548"; //(Muted Gray)
        break;
      default:
        backgroundColor = "#6c757d"; // Gray for unknown status
    }

    return {
      style: {
        backgroundColor,
        color: "white",
        borderRadius: "5px",
        border: "0px",
      },
    };
  };

  return (
    <>
      <JobStatusLegend />
      <div style={{ height: 550 }}>
        {!isLoading ? (
          events?.length > 0 ? (
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              views={Object.values(Views)}
              defaultView={Views.MONTH}
              onSelectEvent={(event) => navigateToJobDetails(event)}
              step={15}
              popup={false}
              eventPropGetter={eventStyleGetter} // Apply the custom styles
            />
          ) : (
            "No Record Found"
          )
        ) : (
          <TableLoader />
        )}
      </div>

      <SectionPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
      <ConfirmationDeleteJobPopUp
        jobId={jobId}
        fetchjobList={fetchjobList}
        setErrSuccessMsg={setErrSuccessMsg}
      />
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default SectionJobsCalenderList;
