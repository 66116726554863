import React, { useEffect, useState } from "react";
import apiCall from "../../../../../apicall";
import { useSelector } from "react-redux";
import Toast from "../../../../common/toast";

const AddToFeatureJob = ({ jobDetailId, featuredJob }) => {
  const { TOKEN } = useSelector((state) => state.menu);

  const [isFeatured, setIsFeatured] = useState(featuredJob);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "success",
  });

  console.log("featuredJob",featuredJob)

  useEffect(()=>{
    setIsFeatured(featuredJob)
  },[featuredJob])

  const handleCheckboxChange = async (event) => {
    const { checked } = event.target;
    setIsFeatured(checked);

    try {
      const response = await apiCall(
        `openstaff/jobs/${jobDetailId}/featuredJob?featuredJob=${checked}`,
        "POST",
        null,
        TOKEN
      );

      console.log("response dfdsf", response);
      if (response?.status === 200) {
        setErrSuccessMsg({
          message: checked 
          ? "Job added to featured job successfully" 
          : "Job removed from featured job successfully",
          status: true,
        });
      } else {
        setErrSuccessMsg({
          message: response?.response?.data?.message,
          status: false,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setErrSuccessMsg({
        message: "Something went wrong!",
        status: false,
      });
    }
  };
  return (
    <>
      <h4 className="twm-s-title">Add to Feature Job</h4>
      <label className="custom-checkbox">
        <input
          type="checkbox"
          checked={isFeatured}
          onChange={handleCheckboxChange}
        />
        <span className="custom-checkbox-label ml-3">Feature this Job</span>
      </label>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
};

export default AddToFeatureJob;
