import { useState } from "react";
import { useSelector } from "react-redux";
import Toast from "../toast";
import Spinner from "../spinner";
import { useNavigate } from "react-router-dom";
import apiCall from "../../../apicall";

function JobHirePopUp({ jobDetailId, applicantId }) {
  const navigate = useNavigate();
  const { TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const handleClosePopup = () => {
    const modal = document.getElementById("hire_popup");
    const modalInstance = window.bootstrap.Modal.getInstance(modal);
    modalInstance.hide();
  };
  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
  };
  const displaySuccessMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: true,
    });
    setIsLoading(false);
  };
  const handleConfirm = async () => {
    setIsLoading(true);

    try {
      const response = await apiCall(
        `institutions/jobs/${jobDetailId}/hires/${applicantId}`,
        "POST",
        null,
        TOKEN
      );
      console.log("job hire---", response);

      if (response?.data == "") {
        displaySuccessMsg("Applicant hired successfully.");
        setIsLoading(false);
        setTimeout(() => {
          navigate("/job-list");
        }, 500)
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(response?.response?.data?.message)
        setIsLoading(false);
      }
    } catch (err) {
      displayErrMsg("Something went wrong!");
      setIsLoading(false);
    }
    handleClosePopup();
  };

  const handleCancel = () => {
    handleClosePopup();
  };

  return (
    <>
      <div
        className="modal fade twm-sign-up"
        id="hire_popup"
        aria-hidden="true"
        aria-labelledby="sign_up_popupLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <h2 className="modal-title" id="sign_up_popupLabel">
                  Hire
                </h2>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <h3 style={{textAlign : "center"}}>Are you sure you want to proceed?</h3>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleConfirm}
                  style={{width:'', display:''}}
                >
                  {isLoading ? <Spinner /> : "Hire"}
                </button>
                {/* <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleCancel}
                >
                  Cancel
                </button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
      {errSuccessMesg?.message !== "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default JobHirePopUp;
