import { useState, useEffect } from "react";
import JobZImage from "./jobz-img";
import BASE_URL from "../../config";
import { useSelector } from "react-redux";
import apiCall from "../../apicall";

const GetProfileImage = ({ id, type, logoType, jobId, institutionId, applicantId }) => {
  const { TOKEN, role_type, isLoggedIn } = useSelector((state) => state.menu);
  const [imageSrc, setImageSrc] = useState(" ");

  useEffect(() => {
    const fetchData = async () => {
      // let data = {
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //     // role:localStorage?.role_type
      //   },
      // };

      // if (TOKEN) {
      //   data.headers["Authorization"] = "Bearer " + TOKEN;
      //   // data.headers["role"]=localStorage?.role_type
      // }
      // institutions/jobs/{jobId}/applicants/{applicantId}/avatar

      const api =
        type === "jobList" && isLoggedIn
          ? "openstaff/institutions/" + id + "/logo"
          : type === "applicant"
            ? logoType === "profile-logo"
              ? "avatar"
              : logoType === "company-logo"
                ? "logo"
                : logoType === "avatar" ?
                  `institutions/jobs/${jobId}/applicants/${id}/avatar`
                : logoType === "reviewSeeker"
                  ? `applicants/${institutionId}/logo`
                : logoType === "reviewPoster"
                  ? `institutions/${applicantId}/applicantAvatar`
                  : ""
            : type === "userAvatar"
              ? `openstaff/users/${id}/avatar`
              : `config/${id}/logo`;

      try {
        // const response = await fetch(api, data);
        const response = await apiCall(api, "GET", null, TOKEN, false, true);
        if (response.status === 200) {
          const blobResponse = await response.data;
          const reader = new FileReader();
          reader.readAsDataURL(blobResponse);

          reader.onloadend = () => {
            const imageResponse = reader.result;
            // console.log("imageResponse--------", imageResponse, imageResponse === 'data:text/xml;base64,')
            if (imageResponse === 'data:text/xml;base64,') {
              setImageSrc("");
            } else {
              setImageSrc(imageResponse);
            }
          };
        } else {
          console.error("Error fetching image:", response.status);
          setImageSrc("");
        }
      } catch (error) {
        console.error("Error fetching image:", error);
        setImageSrc("");
      }
    };

    fetchData();
  }, [id]);

  if (imageSrc == "") {
    return <JobZImage src="images/work-process/avtar.png" alt="Job Image" />;
  } else if (imageSrc !== "data:application/octet-stream;base64,") {
    return (
      <img
        type="file"
        name="myfile"
        id="file-uploader"
        src={imageSrc}
        alt="Job Image"
        style={{ height: "100%" }}
      />
    );
  } else {
    return (
      <JobZImage src="images/work-process/avtar.png" alt="Job Image" />
    );
  }
};

export default GetProfileImage;
