import React, { useState, useEffect, useCallback, useRef } from "react";
import GooglePlacesAutocomplete from "react-google-autocomplete";
// import { GOOGLE_API } from "../../../../../config";
import { useSelector, useDispatch } from "react-redux";
import apiCall from "../../../../../apicall";
import BASE_URL from "../../../../../config";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "../../../../common/spinner";
import SessionExpired from "../../../../common/sessionExpired";
import Toast from "../../../../common/toast";
import { userDetails } from "../../../../../store/reducers/menu";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import debounce from "lodash/debounce";
import SectionJobLocation from "../../../public-user/sections/jobs/detail/section-job-location";
import ReactSlider from "react-slider";
import DeleteProfileConfirmation from "./deleteProfileConfirmation";
import { loadScript } from "../../../../../globals/constants";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { GoogleMapsProvider } from "@ubilabs/google-maps-react-hooks";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";

function SectionCandicateBasicInfo() {
  const dispatch = useDispatch();
  const { userInfo, TOKEN, role_type, CONFIGKEY } = useSelector(
    (state) => state.menu
  );
  const [value, setValue] = useState(100);
  const [sliderValue, setSliderValue] = useState(() => {
    // Initialize slider value from local storage if available
    const savedValue = localStorage.getItem("sliderValue");
    return savedValue !== null ? JSON.parse(savedValue) : 100;
  });
  const [mapShowLoc, setMapShowLoc] = useState([]);
  const coverLetterRef = useRef(null);
  const [originalCoverLetter, setOriginalCoverLetter] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [formValues, setFormValues] = useState({
    // userName: userInfo?.userName ? userInfo.userName : "",
    firstName: userInfo?.firstName ? userInfo.firstName : "",
    lastName: userInfo?.lastName ? userInfo.lastName : "",
    phoneNumber: userInfo?.phoneNumber ? userInfo.phoneNumber : "",
    email: userInfo?.email ? userInfo.email : "",
    // last4SSN: userInfo?.last4SSN ? userInfo.last4SSN : "",
    dob: userInfo?.dob ? userInfo.dob : "",
    address: userInfo?.address ? userInfo.address : "",
    city: userInfo?.city ? userInfo.city : "",
    state: userInfo?.state ? userInfo.state : "",
    // country: userInfo?.country ? userInfo.country : "",
    zipCode: userInfo?.zipCode ? userInfo.zipCode : "",
    coverLetter: userInfo?.coverLetter ? userInfo.coverLetter : "",
  });
  const [error, setError] = useState(null);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  const [formErrors, setFormErrors] = useState({});
  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, ""); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNumber; // Return original value if not 10 digits
  };
  const handleInputChange = (e, fieldName) => {
    const { name, value } = e.target;
    if (fieldName === "phoneNumber") {
    // Format phone number when 10 digits are completed
    const formattedPhoneNumber = formatPhoneNumber(value);
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName || name]: formattedPhoneNumber,
    }));
    } else {

    
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName || name]: value,
    }));
    }
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName || name]: "",
    }));
  };
  const handleDateChange = (date, fieldName) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: date,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };
  const today = new Date();

  // Calculate the latest birth date for 18-year-olds
  const min18Date = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );

  const handleAddressChange = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      address: e.target.value,
    }));
  };

  const handlePlaceSelect = (place) => {
    // Update form values with selected place details
    // console.log("handlePlaceSelect---------", place);
   
    if (place?.geometry) {
      const addressComponents = place.address_components;
      const formattedAddress = place.formatted_address;
      
      setFormValues((prevValues) => ({
        ...prevValues,
        locationName:
          addressComponents.find(
            (component) =>
              component.types.includes("sublocality_level_1") ||
              component.types.includes("locality") ||
              component.types.includes("neighborhood") ||
              component.types.includes("route")
          )?.long_name || place.formatted_address,
        address: formattedAddress,
        city:
          addressComponents.find((component) =>
            component.types.includes("locality")
          )?.long_name || "",
        state:
          addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.short_name || "",
        country:
          addressComponents.find((component) =>
            component.types.includes("country")
          )?.long_name || "",
        zipCode:
          addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.long_name || "00000",
      }));
    }
  };

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   const month = date.getMonth() + 1;
  //   const day = date.getDate();
  //   const year = date.getFullYear();
  //   return `${month}/${day}/${year}`;
  // };

  const validateForm = () => {
    const errors = {};
    // Validate required fields
    // Object.keys(formValues).forEach((key) => {
    //   if (!formValues[key]) {
    //     errors[key] = `${key} is required.`;
    //   }
    // });

    if (role_type.includes("ROLE_USER")) {
      // All fields are required for ROLE_USER
      Object.keys(formValues).forEach((key) => {
        if (key !== "userName" && key !== "phoneNumber" && !formValues[key]) {
          errors[key] = `${key} is required.`;
        }
      });
    } else if (role_type.includes("ROLE_INSTITUTION_ADMIN")) {
      // Only specific fields are required for ROLE_INSTITUTION_ADMIN
      ["userName", "firstName", "lastName", "email", "address"].forEach(
        (key) => {
          if (!formValues[key]) {
            errors[key] = `${key} is required.`;
          }
        }
      );
    }

    // Validate phone number format
    // if (formValues.phoneNumber && formValues.phoneNumber.length !== 12) {
    //   errors.phoneNumber = "Phone number must be in the format 222-222-2222.";
    // }
    // Validate zip code format
    // if (formValues.zipCode && formValues.zipCode.length !== 5) {
    //   errors.zipCode = "Zip code must be 5 digits.";
    // }

    // Validate Date of Birth to ensure user is at least 18 years old
    // if (role_type !== "ROLE_INSTITUTION_ADMIN") {
    //   if (formValues.dob) {
    //     const dob = new Date(formValues.dob);
    //     const today = new Date();
    //     const min18Date = new Date(
    //       today.getFullYear() - 18,
    //       today.getMonth(),
    //       today.getDate()
    //     );

    //     if (dob > min18Date) {
    //       errors.dob = "You must be at least 18 years old.";
    //     }
    //   } else {
    //     errors.dob = "Date of Birth is required.";
    //   }
    // }
    setFormErrors(errors);
    console.log("errors:::::", errors)
    return Object.keys(errors).length === 0;
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
  };

  const displaySuccessMsg = (accountResponse) => {
    setIsLoading(false);
    setSessionExpired(false);
    setErrSuccessMsg({
      message: "User Details updated successfully!",
      status: true,
    });
    console.log("accountResponse------", userInfo, accountResponse);
    dispatch(userDetails({ ...userInfo, ...accountResponse?.data }));
  };

  const updateCoverLetter = async (accountResponse) => {
    const converLetterResponse = await apiCall(
      "applicants/coverLetter",
      "PUT",
      formValues?.coverLetter,
      TOKEN
    );
    // console.log("converLetterResponse---", converLetterResponse);
    if (converLetterResponse?.status === 200) {
      displaySuccessMsg(accountResponse);
    }
  };
  const updateAddress = async (accountResponse) => {
    const data = {
      address: formValues?.address,
      locationName: formValues?.locationName,
      // suitNumber: formValues?.suitNumber,
    };
    let addressResponse = "";
    if (role_type.includes("ROLE_USER")) {
      addressResponse = await apiCall("userAddress", "PUT", data, TOKEN);
    } else if (role_type.includes("ROLE_INSTITUTION_ADMIN")) {
      addressResponse = await apiCall("PosterAddress", "PUT", data, TOKEN);
    }
    // console.log("converLetterResponse---", converLetterResponse);
    if (addressResponse?.status === 200) {
      displaySuccessMsg(accountResponse);
      getLocation();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setIsLoading(true);

      if (!role_type.includes("ROLE_INSTITUTION_ADMIN")) {
        const formattedData = {
          ...formValues,
          dob: moment(formValues.dob).format("YYYY-MM-DD"),
          country: "US",
        };
        console.log("formattedData:::::", formattedData)
        try {
          const registerResponse = await apiCall(
            "account",
            "PUT",
            formattedData,
            TOKEN
          );
          if (registerResponse?.status === 200) {
            const accountResponse = await apiCall(
              "account",
              "GET",
              null,
              TOKEN
            );

            console.log("accountResponse------", accountResponse);
            if (originalCoverLetter !== formValues?.coverLetter) {
              await updateCoverLetter(accountResponse);
              // } else if (userInfo?.address != formValues?.address) {
            } else if (accountResponse?.data?.roleEntities?.length > 0) {
              displaySuccessMsg(accountResponse);
            } else {
              setIsLoading(false);
            }
            await updateAddress(accountResponse);
          } else if (registerResponse?.response?.status == 401) {
            setSessionExpired(true);
            displayErrMsg("Your session has been expired!!");
          } else {
            setSessionExpired(false);
            displayErrMsg("Something went wrong!");
          }
          // console.log("registerResponse--", registerResponse);
        } catch (error) {
          setSessionExpired(false);
          displayErrMsg("Something went wrong!");
          console.error("Error:", error);
        }
      } else {
        updateAddress({ data: formValues });
      }
    } else {
      setSessionExpired(false);
      displayErrMsg("Check the form validation!");
      // console.log("Form validation failed.");
    }
  };

  const getCoverLetter = async () => {
    try {
      const converLetterResponse = await apiCall(
        "applicants/coverLetter",
        "GET",
        null,
        TOKEN
      );
      console.log("converLetterResponse", converLetterResponse);
      if (converLetterResponse?.status === 200) {
        setOriginalCoverLetter(converLetterResponse?.data);
        setFormValues((prevValues) => ({
          ...prevValues,
          coverLetter: converLetterResponse?.data,
        }));
        // setFormValues({
        //   ...formValues,
        //   coverLetter: converLetterResponse?.data,
        // });
      } else if (converLetterResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!!");
      } else {
        setSessionExpired(false);
        displayErrMsg("Something went wrong!");
      }
      // console.log("converLetterResponse----", converLetterResponse);
    } catch (err) {
      setSessionExpired(false);
      displayErrMsg("Something went wrong!");
    }
  };
  // function extractCoordinates(locationString) {
  //   const regex =
  //     /longitude\s*:\s*(-?\d+\.\d+)\s*,\s*latitude\s*:\s*(-?\d+\.\d+)/;

  //   const matches = locationString.match(regex);

  //   if (matches && matches.length >= 3) {
  //     const longitude = parseFloat(matches[1]);
  //     const latitude = parseFloat(matches[2]);
  //     return { location: { latitude, longitude } };
  //   } else {
  //     return null;
  //   }
  // }

  // Extract coordinates

  const getLocation = async () => {
    try {
      let apiEndpoint;
      if (role_type.includes("ROLE_INSTITUTION_ADMIN")) {
        apiEndpoint = "PosterLocation";
      } else {
        apiEndpoint = "userLocation";
      }
  //     const locationResponse = await apiCall(apiEndpoint, "GET", null, TOKEN);

  //     console.log("locationResponse", locationResponse);

  //     if (locationResponse?.status === 200) {
  //       // Extract the data from the response
  //       let responseData = locationResponse.data;

  //       const fixedResponseText = responseData
  // // Wrap keys with double quotes
  // .replace(/([{,]\s*)([a-zA-Z_][a-zA-Z0-9_]*)(\s*:)/g, '$1"$2"$3')
  // // Replace single quotes with double quotes (if needed)
  // .replace(/'/g, '"')
  // // Wrap address value with double quotes (handling various formats)
  // .replace(
  //   /("address"\s*:\s*)([^,{]+, [^,]+, [^,]+, [^,]+)(,|\s*})/g,
  //   (match, p1, p2, p3) => {
  //     // Wrap the address with double quotes
  //     return `${p1}"${p2.trim()}"${p3}`;
  //   }
  // )
  // // Wrap locationName value with double quotes
  // .replace(/("locationName"\s*:\s*)([^,]+)(,|\s*})/g, '$1"$2"$3')
  // // Ensure suitNumber is quoted properly or set to ""
  // .replace(
  //   /("suitNumber"\s*:\s*)([^,{]+)(,|\s*})/g,
  //   (match, p1, p2, p3) => {
  //     // If suitNumber value is missing or empty, replace with ""
  //     if (!p2.trim()) {
  //       p2 = '""';
  //     } else if (!(p2.startsWith('"') && p2.endsWith('"'))) {
  //       p2 = `"${p2}"`;
  //     }
  //     return `${p1}${p2}${p3}`;
  //   }
  // )
  // // Ensure address is double-quoted if not already
  // .replace(
  //   /("address"\s*:\s*)([^"{]+)(,|\s*})/g,
  //   (match, p1, p2, p3) => {
  //     // Wrap the address with double quotes if not already
  //     if (!(p2.startsWith('"') && p2.endsWith('"'))) {
  //       p2 = `"${p2.trim()}"`;
  //     }
  //     return `${p1}${p2}${p3}`;
  //   }
  // );


  //         console.log("fixedResponseText--",fixedResponseText)


  //       // Parse the fixed JSON string to an object
  //       const locationObject = JSON.parse(fixedResponseText);

  let data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + TOKEN,
    },
  };

  fetch(BASE_URL + apiEndpoint, data)
    .then((response) => response.text())
    .then((responseJson) => {
      console.log("rresponsejob  --", responseJson);
      try {
        const fixedResponseText = responseJson
          .replace(/([{,]\s*)([a-zA-Z_][a-zA-Z0-9_]*)(\s*:)/g, '$1"$2"$3')
          .replace(/'/g, '"')
          .replace(
            /("address"\s*:\s*)([^,]+, [^,]+, [^,]+, [^,]+)(,)/g,
            '$1"$2"$3'
          )
          .replace(/("locationName"\s*:\s*)([^,]+)(,|\s*})/g, '$1"$2"$3')
          .replace(/("city"\s*:\s*)([^,]+)(,|\s*})/g, '$1"$2"$3')
          .replace(/("state"\s*:\s*)([^,]+)(,|\s*})/g, '$1"$2"$3')
          .replace(/("zipCode"\s*:\s*)([^,]+)(,|\s*})/g, '$1"$2"$3')
          .replace(/("country"\s*:\s*)([^,]+)(,|\s*})/g, '$1"$2"$3')
          .replace(/("suitNumber"\s*:\s*)([^,]+)(,|\s*})/g, '$1"$2"$3');

        console.log(
          "fixedResponseText::::userlocation::::::::",
          fixedResponseText
        );
        // parsedObject = JSON.parse(fixedResponseText);
        const updatedJsonString = fixedResponseText.replace(
          /(}\s*)(")/g,
          "$2$1"
        );
        const locationObject = JSON.parse(updatedJsonString);

         // Update state or variables
         setUserLocation({ location: locationObject?.location, address: locationObject?.address });
         let locationDetails = {
           locationName:
             locationObject?.locationName === "null"
               ? ""
               : locationObject?.locationName,
           // suitNumber: locationObject?.suitNumber,
         };
 
         if (role_type.includes("ROLE_INSTITUTION_ADMIN")) {
           locationDetails.address = locationObject?.address;
         }
 
         setFormValues((prevValues) => ({
           ...prevValues,
           ...locationDetails,
         }));
         // const coordinates = extractCoordinates(locationObject.location);
         setMapShowLoc({ location: locationObject.location });
      
      } catch (err) {
        console.log("errerrerr", err);
      }
    });

       
      // } else if (locationResponse?.response?.status === 401) {
      //   setSessionExpired(true);
      //   displayErrMsg("Your session has been expired!!");
      // } else {
      //   setSessionExpired(false);
      //   // displayErrMsg("Something went wrong!");
      // }
    } catch (err) {
      console.log("errorrr", err);
      setSessionExpired(false);
      // displayErrMsg("Something went wrong!");
    }
  };

  const handleSliderChange = (value) => {
    localStorage.setItem("sliderValue", JSON.stringify(value));
    setSliderValue(value);
  };

  const checkPaymentMethodStatus =async () =>{
    try {
      // setPaymentMethodCheckLoader(true);
      const accountResponse = await apiCall(
        "account",
        "GET",
        null,
        TOKEN
      );
      console.log("accountResponse-----",accountResponse)
      if (accountResponse?.data?.id && accountResponse?.status === 200) {
        // setPaymentMethodCheckLoader(false);
        dispatch(userDetails(accountResponse?.data));
        // setUserInfo(accountResponse?.data)
      } else {
        // setPaymentMethodCheckLoader(false);
        displayErrMsg(accountResponse?.response?.data?.message);
      }
      // setPaymentMethodCheckLoader(false);
    } catch (err) {
      // setSessionExpired(false);
      displayErrMsg("Something went wrong!");
      // setPaymentMethodCheckLoader(false);
    }
  }

  useEffect(() => {
    if (role_type.includes("ROLE_USER")) {
      getCoverLetter();
    }
    getLocation();

    if(role_type.includes("ROLE_INSTITUTION_ADMIN")){
      checkPaymentMethodStatus()
    }

    logEvent_Analytics('profile_screen_web');
    // const sliderValue = localStorage.getItem(sliderValue)
  }, []);

  useEffect(() => {
    loadScript("js/custom.js");
  });
  const handleError = (error) => {
    console.error("Google Places API error:", error);
    setError("Failed to load autocomplete suggestions. Please try again.");
  };
  useEffect(() => {
    const loadScript = () => {
      if (document.querySelector('script[src="https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API}&libraries=places"]')) {
        setIsScriptLoaded(true);
        return;
      }

      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API}&libraries=places`;
      script.onload = () => setIsScriptLoaded(true);
      document.body.appendChild(script);
    };

    loadScript();
  }, []);

  if (!isScriptLoaded) {
    return <div>Loading...</div>;
  }
  

  return (
    <>
    {!role_type.includes("ROLE_INSTITUTION_ADMIN") &&
    <SectionJobLocation jobDetailsInfo={userLocation} />
    }
      
      {role_type.includes("ROLE_USER") && (
        <>
          <h4 class="twm-s-title mb-2">Job Search Radius</h4>
          <div className="slider-container mb-5">
            <ReactSlider
              ariaLabelledby="slider-label"
              className="horizontal-slider"
              name="distance"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              value={sliderValue}
              onChange={handleSliderChange}
              min={0}
              max={100}
              renderThumb={(props, state) => (
                <div {...props}>{state.valueNow}</div>
              )}
            />
            <div className="slider-input">
              <input
                class="form-control"
                type="text"
                disabled=""
                value={sliderValue}
              />
            </div>
          </div>
        </>
      )}

      <form onSubmit={handleSubmit} id="jobseeker_profile">
        <div className="panel panel-default">
          <div className="panel-heading d-flex justify-content-between align-items-center wt-panel-heading p-a20">
            <h4 className="panel-tittle m-a0">Basic Informations</h4>
            {
              role_type.includes('ROLE_INSTITUTION_ADMIN') && (
                    <h4 className="panel-tittle m-a0">Payment Method: <span style={{fontWeight: 400}}>{userInfo?.paymentMethod ? userInfo?.paymentMethod?.replace(/^"(.*)"$/, '$1') : 'stripe'}</span></h4>
              )
            }
            {(role_type.includes("ROLE_INSTITUTION_ADMIN") || role_type.includes("ROLE_USER")) && (
              <a
                className="site-button"
                data-bs-toggle="modal"
                href="#delete_profile_popup"
                role="button"
              >
                Delete Profile
              </a>
            )}
          </div>
          <div className="panel-body wt-panel-body p-a20 m-b30 ">
            <div className="row">
              {/* <div className="col-xl-4 col-lg-4 col-md-12">
                <div className="form-group">
                  <label>User Name</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="userName"
                      type="text"
                      placeholder="User Name"
                      value={formValues.userName}
                      onChange={(e) => handleInputChange(e, "userName")}
                      disabled
                    />
                    <i className="fs-input-icon fa fa-user " />
                  </div>
                  {formErrors.userName && (
                    <span className="error-message">{formErrors.userName}</span>
                  )}
                </div>
              </div> */}
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>First Name</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      value={formValues.firstName}
                      onChange={(e) => handleInputChange(e, "firstName")}
                      disabled={role_type.includes("ROLE_INSTITUTION_ADMIN")}
                    />
                    <i className="fs-input-icon fa fa-user " />
                  </div>
                  {formErrors.firstName && (
                    <span className="error-message">
                      {formErrors.firstName}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Last Name</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      value={formValues.lastName}
                      onChange={(e) => handleInputChange(e, "lastName")}
                      disabled={role_type.includes("ROLE_INSTITUTION_ADMIN")}
                    />
                    <i className="fs-input-icon fa fa-user " />
                  </div>
                  {formErrors.lastName && (
                    <span className="error-message">{formErrors.lastName}</span>
                  )}
                </div>
              </div>
              {role_type.includes("ROLE_USER") && (
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Phone</label>
                    <div className="ls-inputicon-box">
                      <input
                        className="form-control"
                        name="phoneNumber"
                        type="text"
                        placeholder="Phone Number"
                        value={formValues.phoneNumber}
                        maxLength={12}
                        onChange={(e) => handleInputChange(e, "phoneNumber")}
                        
                      />
                      <i className="fs-input-icon fa fa-phone-alt" />
                    </div>
                    {formErrors.phoneNumber && (
                      <span className="error-message">
                        {formErrors.phoneNumber}
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Email Address</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="email"
                      type="email"
                      placeholder="Email"
                      value={formValues.email}
                      onChange={(e) => handleInputChange(e, "email")}
                      disabled
                    />
                    <i className="fs-input-icon fas fa-at" />
                  </div>
                  {formErrors.email && (
                    <span className="error-message">{formErrors.email}</span>
                  )}
                </div>
              </div>
              {/* {role_type === "ROLE_USER" && (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>SSN Last 4</label>
                      <div className="ls-inputicon-box">
                        <input
                          className="form-control"
                          name="last4SSN"
                          type="text"
                          placeholder="Last 4 SSN"
                          value={formValues.last4SSN}
                          onChange={(e) => handleInputChange(e, "last4SSN")}
                          disabled
                        />
                        <i className="fs-input-icon fa fa-globe-americas" />
                      </div>
                      {formErrors.last4SSN && (
                        <span className="error-message">
                          {formErrors.last4SSN}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>DOB</label>
                      <div className="ls-inputicon-box">
                        <ReactDatePicker
                          className="form-control"
                          name="dob"
                          id="dob"
                          selected={formValues.dob}
                          onChange={(date) => handleDateChange(date, "dob")}
                          dateFormat="MM-dd-yyyy"
                          placeholderText="Date Of Birth"
                          maxDate={min18Date}
                          disabled
                        />
                        <i className="fs-input-icon fa fa-user-graduate" />
                      </div>
                      {formErrors.dob && (
                        <span className="error-message">{formErrors.dob}</span>
                      )}
                    </div>
                  </div>
                </>
              )} */}
              {!role_type.includes("ROLE_OPENSTAFF_ADMIN") && !role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") && !role_type.includes("ROLE_INSTITUTION_ADMIN") && (
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="form-group city-outer-bx has-feedback">
                    <label>Address</label>
                    <div className="ls-inputicon-box">
                    <GoogleMapsProvider>
                      <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_GOOGLE_API}
                        // apiKey={GOOGLE_API}
                        onPlaceSelected={handlePlaceSelect}
                        onChange={(e) => handleAddressChange(e)}
                        className="form-control"
                        onSelect={(place) => console.log(place)}
                        onError={handleError}
                        placeholder="Select for a location"
                        types={["geocode"]}
                        options={{
                          types: ["address"],
                          bounds: {
                            north: parseFloat(process.env.REACT_APP_LOC_NORTH_BOUND),  
                            south: parseFloat(process.env.REACT_APP_LOC_SOUTH_BOUND),  
                            east:  parseFloat(process.env.REACT_APP_LOC_EAST_BOUND), 
                            west:  parseFloat(process.env.REACT_APP_LOC_WEST_BOUND),  
                          },
                          componentRestrictions: { country: "us" },
                        }}
                        defaultValue={formValues.address}
                      />
                      </GoogleMapsProvider>
                      <i className="fs-input-icon fas fa-map-marker-alt" />
                    </div>
                    {formErrors.address && (
                      <span className="error-message">
                        {formErrors.address}
                      </span>
                    )}
                  </div>
                </div>
              )}
              {/* {formValues.address && role_type != "ROLE_OPENSTAFF_ADMIN" && (
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="form-group ">
                    <label>Location Name</label>
                    <div className="ls-inputicon-box">
                      <input
                        className="form-control"
                        name="locationName"
                        type="text"
                        placeholder="Location Name"
                        value={formValues.locationName}
                        onChange={(e) => handleInputChange(e, "locationName")}
                      />
                    </div>
                  </div>
                </div>
              )} */}
              {/* {formValues.address && role_type != "ROLE_OPENSTAFF_ADMIN" && (
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="form-group ">
                    <label>Suite/Unit/Building</label>
                    <div className="ls-inputicon-box">
                      <input
                        className="form-control"
                        name="suitNumber"
                        type="text"
                        placeholder="Suite/Unit/Building"
                        value={formValues.suitNumber}
                        onChange={(e) => handleInputChange(e, "suitNumber")}
                      />
                    </div>
                  </div>
                </div>
              )} */}

              {/* <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group city-outer-bx has-feedback">
                  <label>City</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="city"
                      type="text"
                      placeholder="City"
                      value={formValues.city}
                      onChange={(e) => handleInputChange(e, "city")}
                      disabled
                    />
                    <i className="fs-input-icon fa fa-globe-americas" />
                  </div>
                  {formErrors.city && (
                    <span className="error-message">{formErrors.city}</span>
                  )}
                </div>
              </div> */}
              {/* <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group city-outer-bx has-feedback">
                  <label>State</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="state"
                      type="text"
                      placeholder="State"
                      value={formValues.state}
                      onChange={(e) => handleInputChange(e, "state")}
                      disabled
                    />
                    <i className="fs-input-icon fa fa-globe-americas" />
                  </div>
                  {formErrors.state && (
                    <span className="error-message">{formErrors.state}</span>
                  )}
                </div>
              </div> */}
              {/* <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group city-outer-bx has-feedback">
                  <label>Country</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="country"
                      type="text"
                      placeholder="Country"
                      value="USA"
                      onChange={(e) => handleInputChange(e, "country")}
                      disabled
                    />
                    <i className="fs-input-icon fa fa-globe-americas" />
                  </div>
                  {formErrors.country && (
                    <span className="error-message">{formErrors.country}</span>
                  )}
                </div>
              </div> */}
              {/* <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group city-outer-bx has-feedback">
                  <label>Postcode</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="zipCode"
                      type="text"
                      placeholder="Zipcode"
                      value={formValues.zipCode}
                      onChange={(e) => handleInputChange(e, "zipCode")}
                      disabled
                    />
                    <i className="fs-input-icon fas fa-map-pin" />
                  </div>
                  {formErrors.zipCode && (
                    <span className="error-message">{formErrors.zipCode}</span>
                  )}
                </div>
              </div> */}
              {/* {role_type === "ROLE_USER" && (
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="form-group city-outer-bx has-feedback">
                    <label>Cover Letter</label>
                    <div className="ls-inputicon-box">
                      <input
                        className="form-control"
                        name="coverLetter"
                        type="text"
                        placeholder="Cover Letter"
                        value={formValues.coverLetter}
                        onChange={(e) => handleInputChange(e, "coverLetter")}
                      />
                      <i className="fs-input-icon fas fa-book" />
                      {formErrors.coverLetter && (
                        <span className="error-message">
                          {formErrors.coverLetter}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )} */}
              {/* {role_type === "ROLE_USER" && (
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="form-group city-outer-bx has-feedback">
                    <label>Cover Letter</label>
                    <div className="ls-inputicon-box">
                      <JoditEditor
                        value={formValues.coverLetter}
                        onChange={(newContent) => handleInputChange({ target: { name: 'coverLetter', value: newContent } })}
                        config={{
                          placeholder: "Cover Letter",
                          toolbarAdaptive: false,
                        }}
                      />
                      {formErrors.coverLetter && (
                        <span className="error-message">
                          {formErrors.coverLetter}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )} */}
              {role_type.includes("ROLE_USER") && (
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="form-group city-outer-bx has-feedback">
                    <label>Resume / Cover Letter</label>
                    <div className="ls-inputicon-box">
                      <ReactQuill
                        theme="snow"
                        value={formValues.coverLetter}
                        onChange={(value) => handleInputChange({ target: { name: 'coverLetter', value } })}
                        placeholder="Cover Letter"
                        modules={{
                          toolbar: [
                            [{ 'font': [] }],
                            [{size: []}],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{'list': 'ordered'}, {'list': 'bullet'}],
                            ['link', 'image'],
                            ['clean']
                          ]
                        }}
                        formats={[
                          'header', 'font', 'size',
                          'bold', 'italic', 'underline', 'strike', 'blockquote',
                          'list', 'bullet',
                          'link', 'image', 'video'
                        ]}
                      />
                      {formErrors.coverLetter && (
                        <span className="error-message">
                          {formErrors.coverLetter}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {/* <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group city-outer-bx has-feedback">
                  <div className="ls-inputicon-box">
                    <input
                      type="checkbox"
                      name="privacyPolicyId"
                      checked={formValues.privacyPolicyId}
                      onChange={(e) => onPrivacyPolicyChange(e)}
                    />
                    <a
                      className="twm-nav-post-a-job"
                      data-bs-toggle="modal"
                      href="#privacy_policy"
                      role="button"
                      style={{ marginLeft: 10 }}
                    >
                      Privacy Policy
                    </a>
                    {formErrors.privacyPolicyId && (
                      <div className="error-message">
                        {formErrors.privacyPolicyId}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group city-outer-bx has-feedback">
                  <div className="ls-inputicon-box">
                    <input
                      type="checkbox"
                      name="termsAndConditionId"
                      checked={formValues.termsAndConditionId}
                      onChange={(e) => onTermsConditionChange(e)}
                    />
                    <a
                      className="twm-nav-post-a-job"
                      data-bs-toggle="modal"
                      href="#terms_and_condition_id"
                      role="button"
                      style={{ marginLeft: 10 }}
                    >
                      Terms & Condition
                    </a>
                    {formErrors.termsAndConditionId && (
                      <div className="error-message">
                        {formErrors.termsAndConditionId}
                      </div>
                    )}
                  </div>
                </div>
              </div> */}

              {
                !role_type.includes('ROLE_INSTITUTION_ADMIN') && 
                <div className="col-lg-12 col-md-12">
                <div className="text-left">
                  <button type="submit" className="site-button">
                    {isLoading ? <Spinner /> : "Save Changes"}
                  </button>
                </div>
              </div>
              }

             
              {/* <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="form-group">
                                    <label>Qualification</label>
                                    <div className="ls-inputicon-box">
                                        <input className="form-control" name="company_since" type="text" placeholder="BTech" />
                                        <i className="fs-input-icon fa fa-user-graduate" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="form-group">
                                    <label>Language</label>
                                    <div className="ls-inputicon-box">
                                        <input className="form-control" name="company_since" type="text" placeholder="e.x English, Spanish" />
                                        <i className="fs-input-icon fa fa-language" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="form-group city-outer-bx has-feedback">
                                    <label>Job Category</label>
                                    <div className="ls-inputicon-box">
                                        <input className="form-control" name="company_since" type="text" placeholder="IT & Software" />
                                        <i className="fs-input-icon fa fa-border-all" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="form-group city-outer-bx has-feedback">
                                    <label>Experience</label>
                                    <div className="ls-inputicon-box">
                                        <input className="form-control" name="company_since" type="text" placeholder="05 Years" />
                                        <i className="fs-input-icon fa fa-user-edit" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-12">
                                <div className="form-group city-outer-bx has-feedback">
                                    <label>Current Salary</label>
                                    <div className="ls-inputicon-box">
                                        <input className="form-control" name="company_since" type="text" placeholder="65K" />
                                        <i className="fs-input-icon fa fa-dollar-sign" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-12">
                                <div className="form-group city-outer-bx has-feedback">
                                    <label>Expected Salary</label>
                                    <div className="ls-inputicon-box">
                                        <input className="form-control" name="company_since" type="text" placeholder="75K" />
                                        <i className="fs-input-icon fa fa-dollar-sign" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-12 col-md-12">
                                <div className="form-group city-outer-bx has-feedback">
                                    <label>Age</label>
                                    <div className="ls-inputicon-box">
                                        <input className="form-control" name="company_since" type="text" placeholder="35 Years" />
                                        <i className="fs-input-icon fa fa-child" />
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </form>
      <DeleteProfileConfirmation />
      {sessionExpired && <SessionExpired />}
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default SectionCandicateBasicInfo;
