import React, { useState, useEffect } from "react";
import GooglePlacesAutocomplete from "react-google-autocomplete";
// import { GOOGLE_API } from "../../../../../config";
import { useSelector, useDispatch } from "react-redux";
import apiCall from "../../../../../apicall";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "../../../../common/spinner";
import SessionExpired from "../../../../common/sessionExpired";
import Toast from "../../../../common/toast";
import { userDetails } from "../../../../../store/reducers/menu";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";

function SectionCandidateBankInfo() {
  const dispatch = useDispatch();
  const { userInfo, TOKEN, role_type } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [bankInfo, setBankInfo] = useState({
    last4SSN: userInfo?.last4SSN ? userInfo.last4SSN : "",
    dob: userInfo?.dob ? userInfo.dob : "",
  });
  const [sessionExpired, setSessionExpired] = useState(false);
  //   const [formValues, setFormValues] = useState({
  //     userName: userInfo?.userName ? userInfo.userName : "",
  //     bankAccountNumber: userInfo?.bankAccountNumber ? userInfo.bankAccountNumber : "",
  //     bankAccountNumber: userInfo?.bankAccountNumber ? userInfo.bankAccountNumber : "",
  //     phoneNumber: userInfo?.phoneNumber ? userInfo.phoneNumber : "",
  //     email: userInfo?.email ? userInfo.email : "",
  //     last4SSN: userInfo?.last4SSN ? userInfo.last4SSN : "",
  //     dob: userInfo?.dob ? userInfo.dob : "",
  //     address: userInfo?.address ? userInfo.address : "",
  //     city: userInfo?.city ? userInfo.city : "",
  //     state: userInfo?.state ? userInfo.state : "",
  //     country: userInfo?.country ? userInfo.country : "",
  //     zipCode: userInfo?.zipCode ? userInfo.zipCode : "",
  //     coverLetter: userInfo?.coverLetter ? userInfo.coverLetter : "",
  //   });
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const [formErrors, setFormErrors] = useState({});

  // const formatPhoneNumber = (phoneNumber) => {
  //   const cleaned = phoneNumber.replace(/\D/g, ""); // Remove non-numeric characters
  //   const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  //   if (match) {
  //     return `${match[1]}-${match[2]}-${match[3]}`;
  //   }
  //   return phoneNumber; // Return original value if not 10 digits
  // };

  const handleInputChange = (e, fieldName) => {
    const { name, value } = e.target;
    // if (fieldName === "phoneNumber") {
    // Format phone number when 10 digits are completed
    // const formattedPhoneNumber = formatPhoneNumber(value);
    // setFormValues((prevValues) => ({
    //   ...prevValues,
    //   [fieldName || name]: formattedPhoneNumber,
    // }));
    // } else {

    setBankInfo((prevValues) => ({
      ...prevValues,
      [fieldName || name]: value,
    }));
    // }
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName || name]: "",
    }));
  };

  const handleDateChange = (date, fieldName) => {
    setBankInfo((prevValues) => ({
      ...prevValues,
      [fieldName]: date,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };
  const today = new Date();

  // Calculate the latest birth date for 18-year-olds
  const min18Date = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   const month = date.getMonth() + 1;
  //   const day = date.getDate();
  //   const year = date.getFullYear();
  //   return `${month}/${day}/${year}`;
  // };

  const validateForm = () => {
    const errors = {};
    // Validate required fields
    Object.keys(bankInfo).forEach((key) => {
      if (
        !bankInfo[key] &&
        key !== "payoutEnabled" &&
        key !== "ssnLast4Provided" && 
        key !== 'bankProvided'
      ) {
        errors[key] = `${key} is required.`;
      }
    });

    // Validate Account Number and Confirmation Account Number match
    if (bankInfo.bankAccountNumber !== bankInfo.confirmationBankAccountNumber) {
      errors.bankAccountNumber =
        "Account Number and Confirmation Account Number must match.";
      errors.confirmationBankAccountNumber =
        "Account Number and Confirmation Account Number must match.";
    }

    // Validate phone number format
    // if (formValues.phoneNumber && formValues.phoneNumber.length !== 12) {
    //   errors.phoneNumber = "Phone number must be in the format 222-222-2222.";
    // }
    // Validate zip code format

    // Validate Date of Birth to ensure user is at least 18 years old
    if (bankInfo.dob) {
      const dob = new Date(bankInfo.dob);
      const today = new Date();
      const min18Date = new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate()
      );

      if (dob > min18Date) {
        errors.dob = "You must be at least 18 years old.";
      }
    } else {
      errors.dob = "Date of Birth is required.";
    }

    console.log("error for bank details", errors);

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
  };

  const displaySuccessMsg = (accountResponse) => {
    setIsLoading(false);
    setSessionExpired(false);
    setErrSuccessMsg({
      message: "Bank Details updated successfully!",
      status: true,
    });
    dispatch(userDetails(accountResponse?.data));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      const formattedData = {
        bankRoutingNumber: bankInfo.bankRoutingNumber,
        bankAccountNumber: bankInfo.bankAccountNumber,
        last4SSN: bankInfo.last4SSN,
        dob: moment(bankInfo.dob).format("YYYY-MM-DD"),
      };
      try {
        const bankResponse = await apiCall(
          "applicants",
          "PUT",
          formattedData,
          TOKEN
        );
        console.log("bankResponse-----", bankResponse);
        if (bankResponse?.status === 200) {
          setIsLoading(false);
          setSessionExpired(false);
          // displayErrMsg("Bank detail added successfully!!");
          setErrSuccessMsg({
            message: "Bank Details added successfully!",
            status: true,
          });
          //   const accountResponse = await apiCall("account", "GET", null, TOKEN);
          //   if (originalCoverLetter !== formValues?.coverLetter) {
          //     updateCoverLetter(accountResponse);
          //     displaySuccessMsg(accountResponse);
          //   } else {
          //     setIsLoading(false);
          //   }
        } else if (bankResponse?.response?.status == 401) {
          setSessionExpired(true);
          displayErrMsg("Your session has been expired!!");
        } else if (bankResponse?.response?.status == 406) {
          setSessionExpired(false);
          displayErrMsg(bankResponse?.response?.data?.errorMessage);
        } else {
          setSessionExpired(false);
          // displayErrMsg("Something went wrong!");
          displayErrMsg(bankResponse?.response?.data?.message)
        }
        // console.log("bankResponse--", bankResponse);
      } catch (error) {
        setSessionExpired(false);
        displayErrMsg("Something went wrong!");
        console.error("Error:", error);
      }
    } else {
      setSessionExpired(false);
      displayErrMsg("Check the form validation!");
      // console.log("Form validation failed.");
    }
  };
  const displayBankErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setBankInfo({});
    // setLocationLoader(false);
  };

  const getBankDetails = async () => {
    try {
      // setLocationLoader(true);
      const api = `applicants`;
      const bankDetailsResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("bankDetailsResponse---", bankDetailsResponse);

      if (bankDetailsResponse?.data) {
        setSessionExpired(false);
        setBankInfo(bankDetailsResponse.data);
        // setPaginationLocationData({
        //     totalElements: locationResponse.data.totalElements,
        //     totalPages: locationResponse.data.totalPages,
        // });
        // setLocationLoader(false);
      } else if (bankDetailsResponse?.data.content?.length == 0) {
        setBankInfo({});
        // setLocationLoader(false);
      } else if (bankDetailsResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayBankErrMsg("Your session has been expired!!");
      } else {
        setSessionExpired(false);
        // displayBankErrMsg("Something went wrong!");
        displayBankErrMsg(bankDetailsResponse?.response?.data?.message)
      }
    } catch (err) {
      //   setSessionExpired(false);
      //   displayLocationErrMsg("Something went wrong!");
    }
  };

  useEffect(() => {
    getBankDetails();
    logEvent_Analytics('bankinfo_screen_web');
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit} id="jobseeker_profile">
        <div className="panel panel-default">
          <div className="panel-heading wt-panel-heading p-a20">
            <h4 className="panel-tittle m-a0">
              Bank Account Information (For Payments)
            </h4>
          </div>
          <div className="panel-body wt-panel-body p-a20 m-b30 ">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Routing Number</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="bankRoutingNumber"
                      type="text"
                      placeholder="Routing Number"
                      value={bankInfo.bankRoutingNumber}
                      onChange={(e) =>
                        handleInputChange(e, "bankRoutingNumber")
                      }
                    />
                    <i className="fs-input-icon fa fa-file" />
                  </div>
                  {formErrors.bankRoutingNumber && (
                    <span className="error-message">
                      {formErrors.bankRoutingNumber}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Account Number</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="bankAccountNumber"
                      type="text"
                      placeholder="Account Number"
                      value={bankInfo.bankAccountNumber}
                      onChange={(e) =>
                        handleInputChange(e, "bankAccountNumber")
                      }
                    />
                    <i className="fs-input-icon fa fa-user-circle" />
                  </div>
                  {formErrors.bankAccountNumber && (
                    <span className="error-message">
                      {formErrors.bankAccountNumber}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Confirm Account Number</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="confirmationBankAccountNumber"
                      type="text"
                      placeholder="Confirm Account Number"
                      value={bankInfo.confirmationBankAccountNumber}
                      onChange={(e) =>
                        handleInputChange(e, "confirmationBankAccountNumber")
                      }
                    />
                    <i className="fs-input-icon fa fa-user-circle" />
                  </div>
                  {formErrors.confirmationBankAccountNumber && (
                    <span className="error-message">
                      {formErrors.confirmationBankAccountNumber}
                    </span>
                  )}
                </div>
              </div>
              {role_type.includes("ROLE_USER") && (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>SSN Last 4</label>
                      <div className="ls-inputicon-box">
                        <input
                          className="form-control"
                          name="last4SSN"
                          type="text"
                          placeholder="Last 4 SSN"
                          value={bankInfo.last4SSN}
                          onChange={(e) => handleInputChange(e, "last4SSN")}
                          maxLength={4}
                        />
                        <i className="fs-input-icon fa fa-id-card" />
                      </div>
                      {formErrors.last4SSN && (
                        <span className="error-message">
                          {formErrors.last4SSN}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>DOB</label>
                      <div className="ls-inputicon-box">
                        <ReactDatePicker
                          className="form-control"
                          name="dob"
                          id="dob"
                          selected={bankInfo.dob}
                          onChange={(date) => handleDateChange(date, "dob")}
                          dateFormat="MM-dd-yyyy"
                          placeholderText="Date Of Birth"
                          maxDate={min18Date}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                        <i className="fs-input-icon fa fa-calendar-alt" />
                      </div>
                      {formErrors.dob && (
                        <span className="error-message">{formErrors.dob}</span>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div className="col-lg-12 col-md-12">
                <div className="text-left">
                  <button type="submit" className="site-button">
                    {isLoading ? <Spinner /> : "Save Changes"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {sessionExpired && <SessionExpired />}
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default SectionCandidateBankInfo;
