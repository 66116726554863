import { NavLink, useNavigate } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import { useState, useEffect } from "react";
import SectionPagination from "../../sections/common/section-pagination";
import SectionRecordsFilter from "../../sections/common/section-records-filter";
import SectionJobsSidebar1 from "../../sections/jobs/sidebar/section-jobs-sidebar1";
import { loadScript } from "../../../../../globals/constants";
import apiCall from "../../../../../apicall";
import { useDispatch, useSelector } from "react-redux";
import TableLoader from "../../../../common/tableLoader";
import moment from "moment";
import Toast from "../../../../common/toast";
import SessionExpired from "../../../../common/sessionExpired";
import GetProfileImage from "../../../../common/getProfileImage";
import { candidateDetails } from "../../../../../store/reducers/menu";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../../globals/fomat-comapny-name";
import JobViewTabs from "../jobs/job-view-tabs";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";

function CandidateGridPage() {
  const { role_type , TOKEN } = useSelector((state) => state.menu);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [applicantListData, setApplicantListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [paginationData, setPaginationData] = useState({
    totalPages: 0,
    totalElements: 0,
  });
  const dispatch = useDispatch();
  const [tableFilter, setTableFilter] = useState({
    status: "All",
    applicant: "",
    customer: "",
    fromDate: "",
    toDate: "",
    sortType: "recent",
    sortDirection: "desc",
    searchKey: "",
    jobType: "",
    jobStatus:
      (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_SEEKER_USER_ADMIN"))
        ? "All"
        : role_type.includes("ROLE_USER") || role_type.includes("ROLE_INSTITUTION_ADMIN")
          ? "eOpen"
          : "All",
  });
  const [sessionExpired, setSessionExpired] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const _filterConfig = {
    prefix: "Showing",
    type: "records",
    total: paginationData?.totalElements,
    showRange: false,
    showingUpto: "",
  };

  const handleRecordsPerPageChange = (event) => {
    setCurrentPage(1);
    setRowPerPage(event.target.value);
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setApplicantListData([]);
    setPaginationData({
      totalElements: 0,
      totalPages: 0,
    });
  };

  const fetchApplicantList = async () => {
    let data = null;
    const api = `${(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_SEEKER_USER_ADMIN"))
        ? `openstaff/applicants?offset=0&sortBy=${tableFilter?.sortType}&sortDirection=${tableFilter?.sortDirection
        }&offset=0&page=${currentPage - 1}&size=${rowPerPage}`
        : role_type.includes("ROLE_USER")
          ? `applicants&sortBy=${tableFilter?.sortType}&sortDirection=${tableFilter?.sortDirection
          }&offset=0&page=${currentPage - 1}&size=${rowPerPage}`
          : role_type.includes("ROLE_INSTITUTION_ADMIN")
            ? `institutions/applicants&sortBy=${tableFilter?.sortType}&sortDirection=${tableFilter?.sortDirection
            }&offset=0&page=${currentPage - 1}&size=${rowPerPage}`
            : ``
      }&searchkey=${tableFilter?.searchKey}`;
    try {
      setIsLoading(true);
      const result = await apiCall(api, "GET", data, TOKEN);
      if (result?.data?.content?.length > 0) {
      console.log("setIsLoading----------",result)

        setIsLoading(false);
        setSessionExpired(false);
        setApplicantListData(result.data.content);
        setPaginationData({
          totalElements: result.data.totalElements,
          totalPages: result.data.totalPages,
        });
      } else if (result?.response?.status == 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!");
      }else if(result?.data?.content?.length === 0 && result?.status === 200) {
        setIsLoading(false);
        setSessionExpired(false);
        setApplicantListData([]);
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(result?.response?.data?.message)
        setSessionExpired(false);
      }
    } catch (error) {
      displayErrMsg("Something went wrong!");
      setSessionExpired(false);
      console.error("Error fetching user data:", error);
    }
  };

  const getCreatedDate = (createdDate) => {
    return moment(createdDate, "YYYY-MM-DD HH:mm:ss").format(
      "MM/DD/YYYY h:mm A"
    );
  };

  const navigateToApplicantDetails = (item) => {
    console.log("item@@@@@@@@", item)
    dispatch(candidateDetails(item))
    navigate(`/can-detail/${item.userEntityId}`, { state: item });
  };

  useEffect(() => {
    fetchApplicantList();
  }, [currentPage, tableFilter, rowPerPage]);

  useEffect(() => {
    loadScript("js/custom.js");
  });

  useEffect(() =>{
    logEvent_Analytics('candidates_grid_web');
  },[])

  return (
    <>
    <div>
        <Helmet>
          <title>{`Candidate Grid | Qualified Candidates | Candidate Profiles | ${formatCompanyName()}`}</title>
          <meta name="description" content="Welcome to the Candidate Grid Page! Here, you can browse through a curated list of qualified candidates actively seeking job opportunities. This section provides an overview of potential hires, helping employers find the right talent for their needs." />
          <meta name="keywords" content="candidate profiles, qualified applicants, candidate search, employment seekers, talent listings"/>
        </Helmet>
      </div>
      <div className="section-full p-t120  p-b90 site-bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 rightSidebar">
              <SectionJobsSidebar1
                setTableFilter={setTableFilter}
                tableFilter={tableFilter}
                setCurrentPage={setCurrentPage}
              />
            </div>
            <div className="col-lg-8 col-md-12">
              <div class="panel-heading wt-panel-heading p-a20 mb-4">
                <h3 class="wt-title mt-4">Candidates Grid</h3>
              </div>
              <JobViewTabs />
              {/*Filter Short By*/}
              <SectionRecordsFilter
                _config={_filterConfig}
                paginationData={paginationData}
                handleRecordsPerPageChange={handleRecordsPerPageChange}
                setTableFilter={setTableFilter}
                tableFilter={tableFilter}
                setCurrentPage={setCurrentPage}
              />

              <div className="twm-candidates-grid-wrap">
                <div className="row">
                  {!isLoading ? (
                    applicantListData?.length > 0 ? (
                      applicantListData?.map((item) => (
                        <div className="col-lg-6 col-md-6">
                          <div className="twm-candidates-grid-style1 mb-5">
                            <div className="twm-media">
                              <div className="twm-media-pic">
                                <GetProfileImage
                                  id={item?.userEntityId}
                                  type={'userAvatar'}
                                />
                              </div>
                              <div className="twm-candidates-tag">
                                <span>
                                  {item?.applicationStatus ===
                                    "WaitingForDocuments"
                                    ? "Documents Pending"
                                    : item?.applicationStatus ===
                                      "WaitingForApproval"
                                      ? "Approval Pending"
                                      : item?.applicationStatus === "Approved"
                                        ? "Approved"
                                        : ""}
                                </span>
                              </div>
                            </div>
                            <div className="twm-mid-content">
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => navigateToApplicantDetails(item)}
                                className="twm-job-title"
                              >
                                <h4>{`${item?.firstName} ${item?.lastName}`}</h4>
                              </span>
                              <p>{getCreatedDate(item.createdDate)}</p>
                              <p><b>{item.status}</b></p>
                              <div className="twm-jobs-category green">
                                <span className="twm-bg-green">
                                    {item?.distance?.toFixed(2)} Miles
                                </span>
                            </div>
                              <span
                                // to={publicUser.candidate.DETAIL1}
                                className="twm-view-prifile site-text-primary"
                                onClick={() => navigateToApplicantDetails(item)}
                              >
                                View Profile
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      "No Record Found"
                    )
                  ) : (
                    <TableLoader />
                  )}
                </div>
              </div>

              {/* <div className="twm-candidates-grid-wrap">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-candidates-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <div className="twm-media-pic">
                                                    <JobZImage src="images/candidates/pic1.jpg" alt="#" />
                                                </div>
                                                <div className="twm-candidates-tag"><span>Featured</span></div>
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-job-title">
                                                    <h4>Wanda Montgomery </h4>
                                                </NavLink>
                                                <p>Charted Accountant</p>
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-view-prifile site-text-primary">View Profile</NavLink>
                                                <div className="twm-fot-content">
                                                    <div className="twm-left-info">
                                                        <p className="twm-candidate-address"><i className="feather-map-pin" />New York</p>
                                                        <div className="twm-jobs-vacancies">$20<span>/ Day</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-candidates-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <div className="twm-media-pic">
                                                    <JobZImage src="images/candidates/pic2.jpg" alt="#" />
                                                </div>
                                                <div className="twm-candidates-tag"><span>Featured</span></div>
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-job-title">
                                                    <h4>Peter Hawkins</h4>
                                                </NavLink>
                                                <p>Medical Professed</p>
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-view-prifile site-text-primary">View Profile</NavLink>
                                                <div className="twm-fot-content">
                                                    <div className="twm-left-info">
                                                        <p className="twm-candidate-address"><i className="feather-map-pin" />New York</p>
                                                        <div className="twm-jobs-vacancies">$7<span>/ Hour</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-candidates-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <div className="twm-media-pic">
                                                    <JobZImage src="images/candidates/pic3.jpg" alt="#" />
                                                </div>
                                                <div className="twm-candidates-tag"><span>Featured</span></div>
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-job-title">
                                                    <h4>Ralph Johnson</h4>
                                                </NavLink>
                                                <p>Bank Manger</p>
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-view-prifile site-text-primary">View Profile</NavLink>
                                                <div className="twm-fot-content">
                                                    <div className="twm-left-info">
                                                        <p className="twm-candidate-address"><i className="feather-map-pin" />New York</p>
                                                        <div className="twm-jobs-vacancies">$180<span>/ Day</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-candidates-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <div className="twm-media-pic">
                                                    <JobZImage src="images/candidates/pic4.jpg" alt="#" />
                                                </div>
                                                <div className="twm-candidates-tag"><span>Featured</span></div>
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-job-title">
                                                    <h4>Randall Henderson </h4>
                                                </NavLink>
                                                <p>IT Contractor</p>
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-view-prifile site-text-primary">View Profile</NavLink>
                                                <div className="twm-fot-content">
                                                    <div className="twm-left-info">
                                                        <p className="twm-candidate-address"><i className="feather-map-pin" />New York</p>
                                                        <div className="twm-jobs-vacancies">$90<span>/ Week</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-candidates-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <div className="twm-media-pic">
                                                    <JobZImage src="images/candidates/pic5.jpg" alt="#" />
                                                </div>
                                                <div className="twm-candidates-tag"><span>Featured</span></div>
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-job-title">
                                                    <h4>Randall Warren</h4>
                                                </NavLink>
                                                <p>Digital &amp; Creative</p>
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-view-prifile site-text-primary">View Profile</NavLink>
                                                <div className="twm-fot-content">
                                                    <div className="twm-left-info">
                                                        <p className="twm-candidate-address"><i className="feather-map-pin" />New York</p>
                                                        <div className="twm-jobs-vacancies">$95<span>/ Day</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-candidates-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <div className="twm-media-pic">
                                                    <JobZImage src="images/candidates/pic6.jpg" alt="#" />
                                                </div>
                                                <div className="twm-candidates-tag"><span>Featured</span></div>
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-job-title">
                                                    <h4>Christina Fischer </h4>
                                                </NavLink>
                                                <p>Charity &amp; Voluntary</p>
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-view-prifile site-text-primary">View Profile</NavLink>
                                                <div className="twm-fot-content">
                                                    <div className="twm-left-info">
                                                        <p className="twm-candidate-address"><i className="feather-map-pin" />New York</p>
                                                        <div className="twm-jobs-vacancies">$19<span>/ Hour</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-candidates-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <div className="twm-media-pic">
                                                    <JobZImage src="images/candidates/pic7.jpg" alt="#" />
                                                </div>
                                                <div className="twm-candidates-tag"><span>Featured</span></div>
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-job-title">
                                                    <h4>Wanda Willis </h4>
                                                </NavLink>
                                                <p>Marketing &amp; PR</p>
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-view-prifile site-text-primary">View Profile</NavLink>
                                                <div className="twm-fot-content">
                                                    <div className="twm-left-info">
                                                        <p className="twm-candidate-address"><i className="feather-map-pin" />New York</p>
                                                        <div className="twm-jobs-vacancies">$12<span>/ Day</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-candidates-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <div className="twm-media-pic">
                                                    <JobZImage src="images/candidates/pic8.jpg" alt="#" />
                                                </div>
                                                <div className="twm-candidates-tag"><span>Featured</span></div>
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-job-title">
                                                    <h4>Peter Hawkins</h4>
                                                </NavLink>
                                                <p>Public Sector</p>
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-view-prifile site-text-primary">View Profile</NavLink>
                                                <div className="twm-fot-content">
                                                    <div className="twm-left-info">
                                                        <p className="twm-candidate-address"><i className="feather-map-pin" />New York</p>
                                                        <div className="twm-jobs-vacancies">$7<span>/ Hour</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-candidates-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <div className="twm-media-pic">
                                                    <JobZImage src="images/candidates/pic9.jpg" alt="#" />
                                                </div>
                                                <div className="twm-candidates-tag"><span>Featured</span></div>
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-job-title">
                                                    <h4>Kathleen Moreno </h4>
                                                </NavLink>
                                                <p>Sales &amp; Marketing</p>
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-view-prifile site-text-primary">View Profile</NavLink>
                                                <div className="twm-fot-content">
                                                    <div className="twm-left-info">
                                                        <p className="twm-candidate-address"><i className="feather-map-pin" />New York</p>
                                                        <div className="twm-jobs-vacancies">$125<span>/ Week</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="twm-candidates-grid-style1 mb-5">
                                            <div className="twm-media">
                                                <div className="twm-media-pic">
                                                    <JobZImage src="images/candidates/pic10.jpg" alt="#" />
                                                </div>
                                                <div className="twm-candidates-tag"><span>Featured</span></div>
                                            </div>
                                            <div className="twm-mid-content">
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-job-title">
                                                    <h4>Kathleen Moreno </h4>
                                                </NavLink>
                                                <p>Sales &amp; Marketing</p>
                                                <NavLink to={publicUser.candidate.DETAIL1} className="twm-view-prifile site-text-primary">View Profile</NavLink>
                                                <div className="twm-fot-content">
                                                    <div className="twm-left-info">
                                                        <p className="twm-candidate-address"><i className="feather-map-pin" />New York</p>
                                                        <div className="twm-jobs-vacancies">$125<span>/ Week</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

              <SectionPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={paginationData?.totalPages}
              />
            </div>
          </div>
        </div>
      </div>
      {sessionExpired && <SessionExpired />}
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default CandidateGridPage;
