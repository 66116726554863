// import React, { useState, useCallback, useEffect } from "react";
// import {
//   GoogleMapsProvider,
//   useGoogleMap,
//   useMarker,
// } from "@ubilabs/google-maps-react-hooks";
// import { useSelector } from "react-redux";
// import ProfileIcon from "../common/marker/userImage.png";

// const GoogleMapCotainer = ({ mapDetails }) => {
//   const { location, address } = mapDetails;
//   const { CONFIGKEY } = useSelector((state)=>state.menu)
//   const [mapContainer, setMapContainer] = useState(null);
//   const mapRef = useCallback((node) => {
//     node && setMapContainer(node);
//   }, []);
// console.log("location-------------",location, mapDetails)
//   const mapOptions = {
//     center: { lat: location?.latitude || 0, lng: location?.longitude || 0 },
//     zoom: 14,
//   };

//   return (
//     <GoogleMapsProvider
//       googleMapsAPIKey={CONFIGKEY?.google_map_key}
//       mapContainer={mapContainer}
//       mapOptions={mapOptions}
//     >
//       <React.StrictMode>
//         <div ref={mapRef} style={{ height: "100%" }} />
//         {location && location.latitude && location.longitude && (
//           <MapWithMarker location={location} address={address} />
//         )}
//       </React.StrictMode>
//     </GoogleMapsProvider>
//   );
// };

// const MapWithMarker = ({ location, address }) => {
//   const map = useGoogleMap();
//   const { CONFIGKEY } = useSelector((state)=>state.menu)
//   const [locationName, setLocationName] = useState("");

//   const fetchLocationName = async (lat, lng) => {
//     const response = await fetch(
//       `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${CONFIGKEY?.google_map_key}`
//     );
//     const data = await response.json();
//     if (data.results && data.results.length > 0) {
//       setLocationName(data.results[0].formatted_address);
//     } else {
//       setLocationName("Unknown Location");
//     }
//   };

//   useEffect(() => {
//     if (map) {
//       fetchLocationName(location.latitude, location.longitude);
//       const markerOptions = {
//         position: { lat: location.latitude, lng: location.longitude },
//         map,
//         icon: {
//           url: ProfileIcon,
//           scaledSize: new window.google.maps.Size(40, 40),
//         },
//       };
//       const marker = new window.google.maps.Marker(markerOptions);
//       const infoWindow = new window.google.maps.InfoWindow();

//       // marker.addListener("mouseover", () => {
//       //   infoWindow.setContent(`
//       //     <div>
//       //       <p><strong>Location :</strong> ${locationName}</p>
//       //       <button style={{align:"center}} onclick="window.open('https://www.google.com/maps?q=${location.latitude},${location.longitude}', '_blank', 'noopener,noreferrer')">Go</button>     
//       //     </div>

//       //   `);
//       //   infoWindow.open(map, marker);
//       // });
//       marker.addListener("mouseover", () => {
//         infoWindow.setContent(`
//           <div style="height: 60px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
//             <p style="margin: 0;"><strong>Location :</strong> ${address}</p>
//             <button style="margin-top: 5px; padding: 5px 10px; cursor: pointer;" onclick="window.open('https://www.google.com/maps?q=${location.latitude},${location.longitude}', '_blank', 'noopener,noreferrer')">Go</button>
//           </div>
//         `);
//         infoWindow.open(map, marker);
//       });
//       // marker.addListener("mouseout", () => {
//       //   infoWindow.close();
//       // });

//       map.setCenter(markerOptions.position);

//       return () => {
//         marker.setMap(null);
//       };
//     }
//   }, [map, location, locationName]);

//   return null;
// };

// export default GoogleMapCotainer;
import React, { useState, useCallback, useEffect } from "react";
import {
  GoogleMapsProvider,
  useGoogleMap,
  useMarker,
} from "@ubilabs/google-maps-react-hooks";
import { useSelector } from "react-redux";
import ProfileIcon from "../common/marker/userImage.png";

const GoogleMapContainer = ({ mapDetails }) => {
  const { location, address } = mapDetails;
  const { CONFIGKEY } = useSelector((state) => state.menu);
  const [mapContainer, setMapContainer] = useState(null);
  const mapRef = useCallback((node) => {
    node && setMapContainer(node);
  }, []);

  const mapOptions = {
    center: { lat: location?.latitude || 0, lng: location?.longitude || 0 },
    zoom: 14,
  };

  return (
    <GoogleMapsProvider
      googleMapsAPIKey={process.env.REACT_APP_GOOGLE_API}
      mapContainer={mapContainer}
      mapOptions={mapOptions}
    >
      <React.StrictMode>
        <div ref={mapRef} style={{ height: "100%" }} />
        {location && location.latitude && location.longitude && (
          <MapWithMarker location={location} address={address} />
        )}
      </React.StrictMode>
    </GoogleMapsProvider>
  );
};

const MapWithMarker = ({ location, address }) => {
  const map = useGoogleMap();
  const { CONFIGKEY } = useSelector((state) => state.menu);
  const [locationName, setLocationName] = useState("");

  const fetchLocationName = async (lat, lng) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API}`
    );
    const data = await response.json();
    if (data.results && data.results.length > 0) {
      setLocationName(data.results[0].formatted_address);
    } else {
      setLocationName("Unknown Location");
    }
  };

  useEffect(() => {
    if (map) {
      fetchLocationName(location.latitude, location.longitude);
      const markerOptions = {
        position: { lat: location.latitude, lng: location.longitude },
        map,
        icon: {
          url: ProfileIcon,
          scaledSize: new window.google.maps.Size(40, 40),
        },
      };
      const marker = new window.google.maps.Marker(markerOptions);
      const infoWindow = new window.google.maps.InfoWindow();

      marker.addListener("mouseover", () => {
        const displayAddress = address || locationName; // Use address if present, otherwise use locationName
        infoWindow.setContent(`
          <div style="height: 60px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
            <p style="margin: 0;"><strong>Location :</strong> ${displayAddress}</p>
            <button style="margin-top: 5px; padding: 5px 10px; cursor: pointer;" onclick="window.open('https://www.google.com/maps?q=${location.latitude},${location.longitude}', '_blank', 'noopener,noreferrer')">Go</button>
          </div>
        `);
        infoWindow.open(map, marker);
      });

      map.setCenter(markerOptions.position);

      return () => {
        marker.setMap(null);
      };
    }
  }, [map, location, locationName, address]);

  return null;
};

export default GoogleMapContainer;
