import { NavLink, useLocation } from "react-router-dom";
import SectionSideAdvert from "./section-side-advert";
import JobZImage from "../../../../../common/jobz-img";
import moment from "moment";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  candidate,
  pubRoute,
  publicUser,
} from "../../../../../../globals/route-names";
// import { loadScript, setMenuActive } from "../../../../../../globals/constants";
import { loadScript } from "../../../../../../globals/constants";
import { admin } from "../../../../../../globals/route-names";
import GetProfileImage from "../../../../../common/getProfileImage";
import JobReviewRate from "../../../components/jobs/jobRatingReview";
import JobReviewPopUp from "../../../../../common/popups/popup-job-review";
import apiCall from "../../../../../../apicall";
import TableLoader from "../../../../../common/tableLoader";
import { canActiveTabFunc } from "../../../../../../store/reducers/menu";
import Spinner from "../../../../../common/spinner";

function SectionJobsSidebar2({
  _config,
  jobDetailsInfo,
  seekerRating,
  seekerReview,
  posterRating,
  posterReview,
  jobDetailId,
  jobRating,
  selectedFields,
  ansValues,
  setErrSuccessMsg,
  setActiveTab,
  empUsersInfo,
  usersLoader,
  getJobDetailsData
}) {
  console.log("jobDetailsInfo====", jobDetailsInfo);
  console.log("empUsersInfo00",empUsersInfo)
  console.log("condif", _config.empTitle);
  const [isLoading, setIsLoading] = useState(false);
  const { role_type , isLoggedIn, userInfo, TOKEN, jobDetailsId } = useSelector((state) => state.menu);
  const isACHEnalbed = process.env.REACT_APP_ENABLED_ACH === "true";

  const dispatch = useDispatch()
  const location = useLocation()
  const currentpath = useLocation().pathname;
  const jobStatus = jobDetailsInfo?.jobStatus;
  const [stripePaymentMethod, setStripePaymentMethod] = useState(true);
  const [formValues, setFormValues] = useState({
    stripeMethodId: 'stripe',
    serviceFee: jobDetailsInfo?.serviceFee !== undefined ? jobDetailsInfo?.serviceFee : '',
    card: true,
    usBankAccount: false,
    jobStatusChange: jobDetailsInfo.jobStatus
  });
  const [formErrors, setFormErrors] = useState({});

  // const StarRating = (rating) => {
  //   const stars = [];
  //   for (let i = 0; i < 5; i++) {
  //     if (i < rating?.toFixed(0)) {
  //       stars.push(
  //         <i
  //           key={i}
  //           className="feather feather-star"
  //           style={{ color: "#128c5e", position: "relative", top: 0 }}
  //         />
  //       );
  //     } else {
  //       stars.push(
  //         <i
  //           key={i}
  //           className="feather feather-star"
  //           style={{ color: "lightgrey", position: "relative", top: 0 }}
  //         />
  //       );
  //     }
  //   }
  //   return stars;
  // };
  const onCheckboxChange =async (checked) =>{
    try {
        const api = `${(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN"))
            ? `openstaff/institutions/${jobDetailsInfo?.id}/enableACH`
            : ``
          }`;
        const paymentMethodUpdateResponse = await apiCall(api, "PUT", checked, TOKEN);
        console.log("paymentMethodUpdateResponse---",paymentMethodUpdateResponse)
        if (paymentMethodUpdateResponse?.status === 200) {
          setErrSuccessMsg({
            message: "Payment method updated successfully!",
            status: true,
          });
          setFormValues({
            ...formValues,
            usBankAccount: checked,
          });

        } else if(paymentMethodUpdateResponse?.response?.status == 401) {
          setErrSuccessMsg({
            message: "Your session has been expired!!",
            status: false,
          });
        } else {
          setErrSuccessMsg({
            message: paymentMethodUpdateResponse?.response?.data?.message,
            status: false,
          });
        }
    } catch (err) {
      setErrSuccessMsg({
        message: "Network Error",
        status: false,
      });
    }
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event?.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));

    // Trigger the API call here with the updated checkbox status
    onCheckboxChange(checked);
  };

  const StarRating = (rating) => {
    const stars = [];
    const roundedRating = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;

    for (let i = 0; i < 5; i++) {
      if (i < roundedRating) {
        stars.push(
          <i
            key={i}
            className="fas fa-star"
            style={{ color: "#128c5e", position: "relative", top: 0 }}
          />
        );
      } else if (i === roundedRating && hasHalfStar) {
        stars.push(
          <i
            key={i}
            className="fas fa-star-half-alt"
            style={{ color: "#128c5e", position: "relative", top: 0 }}
          />
        );
      } else {
        stars.push(
          <i
            key={i}
            className="fas fa-star"
            style={{ color: "lightgrey", position: "relative", top: 0 }}
          />
        );
      }
    }
    return stars;
  };

  const getJobStatus = (status) => {
    if (typeof status !== "string") {
      return ""; // Return an empty string or handle it as needed
    }
    return status.startsWith("e") ? status.substring(1) : status;
  };
  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
  };
  const validateForm = () => {
    const errors = {};
  
    // Validate fields common to both roles
    if (!formValues.serviceFee || formValues.serviceFee.trim() === "") {
      errors.serviceFee = "Service Fee is required.";
    }
  
    // Log errors for debugging
    console.log("Validation Errors:", errors);
  
    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors, false if there are errors
  };
  
  const handleServiceFeeSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setIsLoading(true);
    try {
      // if (selectedPaymentMethod !== "") {
        const api = `${(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN"))
            ? `openstaff/institutions/${empUsersInfo[0]?.institutionId}/updateServiceFee`
            : ``
          }`;
        const serviceFeeUpdateResponse = await apiCall(api, "PUT", formValues?.serviceFee, TOKEN, '', '', true);
        console.log("serviceFeeUpdateResponse", serviceFeeUpdateResponse)
        if (serviceFeeUpdateResponse?.status === 200) {
          setErrSuccessMsg({
            message: "Service Fee updated successfully!",
            status: true,
          });
          setIsLoading(false);
        } else if (serviceFeeUpdateResponse?.response?.status == 401) {
          setErrSuccessMsg({
            message: "Your session has been expired!!",
            status: false,
          });
        } else {
          setErrSuccessMsg({
            message: serviceFeeUpdateResponse?.response?.data?.message,
            status: false,
          });
        }
      // }
    } catch (err) {
      setErrSuccessMsg({
        message: "Something went wrong!",
        status: false,
      });
    }
  }else{
    displayErrMsg("Check the form validation!");
  }
  };

  const onStripePaymentChange = async (input) => {
    // setFormValues({
    //   ...formValues,
    //   stripeMethodId: e.target.checked,
    // });
    //  let methodType=e.target.checked?'Stripe':'Non-Stripe'

    let selectedPaymentMethod;

    if (typeof input === 'object' && input.target) {
      selectedPaymentMethod = input.target.value;
    } else {
      selectedPaymentMethod = input;
    }


    try {
      if (selectedPaymentMethod !== "") {
        const api = `${(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN"))
            ? `openstaff/jobs/${jobDetailsInfo?.id}/paymentMethod`
            : ``
          }`;
        const paymentUpdateResponse = await apiCall(api, "PUT", selectedPaymentMethod, TOKEN, '', '', true);
        if (paymentUpdateResponse?.status === 200) {
          setErrSuccessMsg({
            message: "Payment method updated successfully!",
            status: true,
          });
          setFormValues({
            ...formValues,
            stripeMethodId: selectedPaymentMethod,
          });

        } else if (paymentUpdateResponse?.response?.status == 401) {
          setErrSuccessMsg({
            message: "Your session has been expired!!",
            status: false,
          });
        } else {
          setErrSuccessMsg({
            message: paymentUpdateResponse?.response?.data?.message,
            status: false,
          });
        }
      }
    } catch (err) {
      setErrSuccessMsg({
        message: "Something went wrong!",
        status: false,
      });
    }

  };

  const onJobStatusChange = async (input) => {

    let selectedJobStatus = input.target.value;

    try {
      if (selectedJobStatus !== "") {
        const api = `${(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN"))
            && `openstaff/jobs/${jobDetailsInfo?.id}/changeStatus/${selectedJobStatus}`
          }`;
        const jobStatusChangeResponse = await apiCall(api, "PUT", selectedJobStatus, TOKEN, '', '', true);
        if (jobStatusChangeResponse?.status === 200) {
          setErrSuccessMsg({
            message: "Job status changed successfully!",
            status: true,
          });
          setFormValues({
            ...formValues,
            jobStatusChange: selectedJobStatus,
          });
          getJobDetailsData()
        } else if (jobStatusChangeResponse?.response?.status == 401) {
          setErrSuccessMsg({
            message: "Your session has been expired!!",
            status: false,
          });
        } else {
          setErrSuccessMsg({
            message: jobStatusChangeResponse?.response?.data?.message,
            status: false,
          });
        }
      }
    } catch (err) {
      setErrSuccessMsg({
        message: "Network Error!",
        status: false,
      });
    }

  };

  const ConvertInto_Hrs = (hrs) => {
    var num = hrs
    var str = num.toString();
    var numarray = str.split('.');
    var a = new Array();
    a = numarray;
    let minuteVal = Number(a[1]) / 60
    let strFnl = a[0] + (minuteVal.toString()).substring(1)
    return Number(strFnl).toFixed(2);
  }
  const handleInputChange = (e, fieldName) => {
    const value = e.target.value;
  
    // Allow only numbers and check if the value is less than or equal to 100
    if (/^\d*$/.test(value) || value === "") {
      const numericValue = Number(value);
      if (numericValue <= 100 || value === "") {
        setFormValues({
          ...formValues,
          [fieldName]: value,
        });
      }
    }
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };

  const getPaymentMethod = (paymentType) => {
    const doubleQuoteRegex = /^\\\"(.*)\\\"$/;  // Matches \"...\" with any content inside
    const singleQuoteRegex = /^\\\'(.*)\\\'$/;  // Matches \'...\' with any content inside
    let paymentMethod = '';

    console.log("Regex Test:", doubleQuoteRegex.test(paymentType) || singleQuoteRegex.test(paymentType));

    if (doubleQuoteRegex.test(paymentType)) {
      paymentMethod = paymentType.replace(doubleQuoteRegex, '$1');
    } else if (singleQuoteRegex.test(paymentType)) {
      paymentMethod = paymentType.replace(singleQuoteRegex, '$1');
    } else {
      paymentMethod = paymentType;
    }

    return paymentMethod?.replace(/^"(.*)"$/, '$1')
  }

  useEffect(() => {
    console.log("empUsersInfo[0]?.paymentMethod", empUsersInfo)

    if (!usersLoader) {
      // if(empUsersInfo?.length>0&&empUsersInfo[0]?.paymentMethod != undefined){
      //   const regex = /\\\".*\\\"/;   
      //   let paymentMethod = '';
      //   console.log("dksldfdskjl",typeof
      //   /^"\\*".*"$/.
      //   test
      //   (empUsersInfo[0]?.paymentMethod))
      //   console.log("regex.test(empUsersInfo[0]?.paymentMethod)",regex.test(empUsersInfo[0]?.paymentMethod))
      //   if(regex.test(empUsersInfo[0]?.paymentMethod)){
      //     paymentMethod = empUsersInfo[0]?.paymentMethod?.replace(/^"(.*)"$/, '$1');
      //   }else{
      //     paymentMethod =  empUsersInfo[0]?.paymentMethod
      //   }

      //   setFormValues({
      //     ...formValues,
      //     stripeMethodId: paymentMethod
      //   })
      //   // setFormValues({
      //   //   ...formValues,
      //   //   stripeMethodId:  ? empUsersInfo[0]?.paymentMethod?.replace(/^"(.*)"$/, '$1') :'non-stripe',
      //   // });
      // }
      if (empUsersInfo?.length > 0 && empUsersInfo[0]?.paymentMethod !== undefined) {
        const doubleQuoteRegex = /^\\\"(.*)\\\"$/;  // Matches \"...\" with any content inside
        const singleQuoteRegex = /^\\\'(.*)\\\'$/;  // Matches \'...\' with any content inside
        let paymentMethod = '';

        if (doubleQuoteRegex.test(empUsersInfo[0]?.paymentMethod)) {
          paymentMethod = empUsersInfo[0]?.paymentMethod.replace(doubleQuoteRegex, '$1');
        } else if (singleQuoteRegex.test(empUsersInfo[0]?.paymentMethod)) {
          paymentMethod = empUsersInfo[0]?.paymentMethod.replace(singleQuoteRegex, '$1');
        } else {
          paymentMethod = empUsersInfo[0]?.paymentMethod;
        }

        // console.log("Processed Payment Method:", paymentMethod?.replace(/^"(.*)"$/, '$1'));
        setFormValues({
          ...formValues,
          stripeMethodId: paymentMethod?.replace(/^"(.*)"$/, '$1'),
          usBankAccount: empUsersInfo[0]?.enabledACH ?  empUsersInfo[0]?.enabledACH : false
        });
      } else {
        setFormValues({
          ...formValues,
          stripeMethodId: "stripe"
        });
        if (jobDetailsInfo?.id) {
          onStripePaymentChange("stripe")
        }
      }
    }

  }, [usersLoader])

  // useEffect(() => {
  //   loadScript("js/custom.js");
  // });

  return (
    <>
      <div className="side-bar mb-4 employee-details">
        <div className="twm-s-info2-wrap mb-5">
          <div className="twm-s-info2">
            <h4 className="section-head-small mb-4">
              {/* {_config.empTitle ? "Employer Information" : "Job Information" } */}
              {_config.empTitle ? (
                "Employer Information"
              ) : (
                <span>
                  Job Information
                  <span className="badge-custom">
                    {getJobStatus(jobDetailsInfo.jobStatus)}
                  </span>
                </span>
              )}
            </h4>

            {role_type.includes("ROLE_USER") &&
              (!posterRating || !posterReview) &&
              !_config.empTitle &&
              jobStatus === "eCompleted" && !location?.state?.instituteRating && (
                <div className="twm-tabs-style-2">
                  <ul className="nav nav-tabs" id="myTab2" role="tablist">
                    <li className="nav-item" style={{ width: "100%" }}>
                      <a
                        className="nav-link"
                        data-bs-toggle="modal"
                        href={"#job-review-popup"}
                        role="button"
                      >
                        <i className="fas fa-edit" /> Write Review
                      </a>
                    </li>
                  </ul>
                </div>
              )}

            {(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN")) && _config.empTitle && (
              <div className="twm-tabs-style-2">
                <ul className="nav nav-tabs" id="myTab2" role="tablist">
                  {/*Login Candidate*/}

                  {/*Login Employer*/}
                  {
                    !role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") && 
                      <li className="nav-item" style={{ width: "100%" }}>
                      <button
                        className="nav-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#location-employer"
                        type="button"
                        style={{ width: "100%" }}
                        onClick={() => {
                          dispatch(canActiveTabFunc("#location-employer"));
                          setActiveTab("#location-employer")
                        }
                        }
                      >
                        <i className="fas fa-map-marker-alt" />
                        Locations
                      </button>
                    </li>
                  }
                 
                 {
                    (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")) && 
                    <li className="nav-item" style={{ width: "100%" }}>
                    <button
                      className={`nav-link ${role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") ? 'active' : ''}`}
                      data-bs-toggle="tab"
                      data-bs-target="#payment-employer"
                      type="button"
                      style={{ width: "100%" }}
                      onClick={() => {
                        dispatch(canActiveTabFunc("#payment-employer"));
                        setActiveTab("#payment-employer")
                      }
                      }
                    >
                      <i className="fas fa-building" />
                      Payment Details
                    </button>
                  </li>
                  
                 }
                 
                  {role_type.includes("ROLE_OPENSTAFF_ADMIN") && (
                  <li className="nav-item" style={{ width: "100%" }}>
                    <button
                      className="nav-link"
                      data-bs-toggle="tab"
                      data-bs-target="#users-employer"
                      type="button"
                      style={{ width: "100%" }}
                      onClick={() => {
                        dispatch(canActiveTabFunc("#users-employer"));
                        setActiveTab("#users-employer")
                      }
                      }
                    >
                      <i className="fas fa-user" />
                      Users
                    </button>
                  </li>
                  )}

                  {
                     (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")) && 
                     <li className="nav-item" style={{ width: "100%" }}>
                    <button
                      className="nav-link"
                      data-bs-toggle="tab"
                      data-bs-target="#jobs-employer"
                      type="button"
                      style={{ width: "100%" }}
                      onClick={() => {
                        dispatch(canActiveTabFunc("#jobs-employer"));
                        setActiveTab("#jobs-employer")
                      }
                      }
                    >
                      <i className="fas fa-shopping-bag" />
                      Jobs
                    </button>
                  </li>
                  }
                  
                  
                </ul>
              </div>
            )}
            {role_type.includes("ROLE_INSTITUTION_ADMIN") &&
              !_config.empTitle &&
              jobDetailsInfo.jobStatus != "eOpen" && jobDetailsInfo.jobStatus != "eAccepted" && (
                <div className="twm-tabs-style-2">
                  <ul className="nav nav-tabs" id="myTab2" role="tablist">
                    {/*Login Candidate*/}
                    {/* {jobDetailsInfo.jobStatus != "eInProgress" && jobDetailsInfo.jobStatus != "eAccepted" && (
                      <li className="nav-item" style={{ width: "100%" }}>
                        <button
                          className="nav-link active"
                          data-bs-toggle="tab"
                          data-bs-target="#applied-applicant"
                          type="button"
                          style={{ width: "100%" }}
                        >
                          <i className="fas fa-user-tie" />
                          Applicants
                        </button>
                      </li>
                    )} */}
                    {!role_type.includes('ROLE_INSTITUTION_ADMIN') && ( <li className="nav-item" style={{ width: "100%" }}>
                      <button
                        className="nav-link"
                        // className={`nav-link ${role_type === 'ROLE_INSTITUTION_ADMIN' ? 'active' : ''}`}
                        data-bs-toggle="tab"
                        data-bs-target="#clockin-clockout"
                        type="button"
                        style={{ width: "100%" }}
                      >
                        <i className="fas fa-building" />
                        Clock In/Clock Out
                      </button>
                    </li>
                    )}
                  </ul>
                </div>
              )}

            {(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")) && !_config.empTitle && (
              <div className="twm-tabs-style-2">
                <ul className="nav nav-tabs" id="myTab2" role="tablist">
                  {/*Login Candidate*/}
                  <li className="nav-item" style={{ width: "100%" }}>
                    <button
                      className="nav-link active"
                      data-bs-toggle="tab"
                      data-bs-target="#applied-applicant"
                      type="button"
                      style={{ width: "100%" }}
                    >
                      <i className="fas fa-user-tie" />
                      Applicants
                    </button>
                  </li>
                  {/*Login Employer*/}
                  {role_type.includes("ROLE_OPENSTAFF_ADMIN") && (<li className="nav-item" style={{ width: "100%" }}>
                    <button
                      className="nav-link"
                      data-bs-toggle="tab"
                      data-bs-target="#invite-applicant"
                      type="button"
                      style={{ width: "100%" }}
                    >
                      <i className="fas fa-building" />
                      Invite
                    </button>
                  </li>
                  )}
                  <li className="nav-item" style={{ width: "100%" }}>
                    <button
                      className="nav-link"
                      data-bs-toggle="tab"
                      data-bs-target="#clockin-clockout"
                      type="button"
                      style={{ width: "100%" }}
                    >
                      <i className="fas fa-building" />
                      Clock In/Clock Out
                    </button>
                  </li>
                  {jobDetailsInfo.jobStatus === "eOpen" && role_type.includes("ROLE_OPENSTAFF_ADMIN") && (
                    <li className="nav-item" style={{ width: "100%" }}>
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#add-to-feature"
                        type="button"
                        style={{ width: "100%" }}
                      >
                        <i className="fas fa-plus" />
                        Add to Feature
                      </button>
                    </li>
                  )}

                  {/* {jobDetailsInfo.jobStatus === "eCompleted" && (
                    <li className="nav-item" style={{ width: "100%" }}>
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#payment-status"
                        type="button"
                        style={{ width: "100%" }}
                      >
                       <i className="fas fa-money-bill-wave" />
                        Payment Status
                      </button>
                      </li>
                    )} */}
                  </ul>
                </div>
            )}

          
            {/* {(role_type === "ROLE_OPENSTAFF_ADMIN" ||
              role_type === "ROLE_INSTITUTION_ADMIN" ||
              role_type === "ROLE_USER") &&
              !_config.empTitle && ( */}
            {
              !currentpath.includes('/emp-detail') &&
              <ul className="twm-job-hilites2">
                <li>
                  <div className="twm-s-info-inner">
                    <i className="fas fa-user-tie" />
                    <span className="twm-title">Discipline</span>
                    <div className="twm-s-info-discription">
                      {jobDetailsInfo?.jobPosition}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="twm-s-info-inner">
                    <i className="fas fa-user-tie" />
                    <span className="twm-title">Job Title</span>
                    <div className="twm-s-info-discription">
                      {jobDetailsInfo?.jobTitle}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="twm-s-info-inner">
                    <i className="fas fa-user-tie" />
                    <span className="twm-title">Job Type</span>
                    <div className="twm-s-info-discription">
                      {jobDetailsInfo?.multipleDayJob
                        ? "Multiple Day"
                        : "Single Day"}
                    </div>
                  </div>
                </li>
                {/* <li>
                    <div className="twm-s-info-inner">
                      <i className="fas fa-calendar-alt" />
                      <span className="twm-title">Date Posted</span>
                      <div className="twm-s-info-discription">
                        {moment.utc(
                          jobDetailsInfo?.createdDate,
                          "YYYY-MM-DD HH:mm:ss"
                        ).local().format("MM/DD/YYYY")}
                      </div>
                    </div>
                  </li> */}
                {jobDetailsInfo?.multipleDayJob ? (
                  <>
                    <li>
                      <div className="twm-s-info-inner">
                        <i className="fas fa-calendar-alt" />
                        <span className="twm-title">Start Date</span>
                        <div className="twm-s-info-discription">
                          {moment
                            (jobDetailsInfo?.startTime, "YYYY-MM-DD HH:mm:ss")
                            .local()
                            .format("MM/DD/YYYY")}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="twm-s-info-inner">
                        <i className="fas fa-calendar-alt" />
                        <span className="twm-title">End Date</span>
                        <div className="twm-s-info-discription">
                          {moment
                            (jobDetailsInfo?.endTime, "YYYY-MM-DD HH:mm:ss")
                            .local()
                            .format("MM/DD/YYYY")}
                        </div>
                      </div>
                    </li>
                    {/* <li>
                      <div className="twm-s-info-inner">
                        <i className="fas fa-money-bill-wave" />
                        <span className="twm-title">Work Hours per Day</span>
                        <div className="twm-s-info-discription">
                          {jobDetailsInfo?.hoursPerDay}
                        </div>
                      </div>
                    </li> */}
                  </>
                ) : (
                  <>
                    <li>
                      <div className="twm-s-info-inner">
                        <i className="fas fa-calendar-alt" />
                        <span className="twm-title">Date</span>
                        <div className="twm-s-info-discription">
                          {moment
                            .utc(jobDetailsInfo?.startTime, "YYYY-MM-DD HH:mm:ss")
                            .local()
                            .format("MM/DD/YYYY")}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="twm-s-info-inner">
                        <i className="fs-input-icon fa fa-clock" />
                        <span className="twm-title">Time</span>
                        <div className="twm-s-info-discription">
                          {moment
                            .utc(jobDetailsInfo?.startTime, "YYYY-MM-DD HH:mm:ss")
                            .local()
                            .format("hh:mm A")}{" "}
                          -{" "}
                          {moment
                            .utc(jobDetailsInfo?.endTime, "YYYY-MM-DD HH:mm:ss")
                            .local()
                            .format("hh:mm A")}
                        </div>
                      </div>
                    </li>
                  </>
                )}

                <li>
                      <div className="twm-s-info-inner">
                        <i className="fs-input-icon fa fa-globe-americas" />
                        <span className="twm-title">Time Zone</span>
                        <div className="twm-s-info-discription">
                          {jobDetailsInfo?.timeZoneId}
                        </div>
                      </div>
                 </li>

                 {/* {!jobDetailsInfo?.multipleDayJob && ( */}
                  <li>
                      <div className="twm-s-info-inner">
                      <i className="fs-input-icon fa fa-clock" />
                      {jobDetailsInfo?.multipleDayJob && (<span className="twm-title">Work Hours per Day</span>)}
                      {!jobDetailsInfo?.multipleDayJob && (<span className="twm-title">Work Hours</span>)}
                        <div className="twm-s-info-discription">
                          {/* {jobDetailsInfo?.totalHours ? jobDetailsInfo?.totalHours.toString().replace(".", ":") : "-"} */}
                          {jobDetailsInfo?.workHours ? jobDetailsInfo?.workHours : "-"}
                        </div>
                        </div>
                    </li>
                 {/* )} */}

                <li>
                  <div className="twm-s-info-inner">
                    <i className="fas fa-money-bill-wave" />
                    <span className="twm-title">Offered Salary</span>
                    <div className="twm-s-info-discription">
                      $
                      {jobDetailsInfo?.salary
                          ? `${(jobDetailsInfo?.salary / 100)?.toFixed(2)} / hr`
                          : 0}
                    </div>
                  </div>
                </li>
                {(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_INSTITUTION_ADMIN")) && (
                  <>
                    <li>
                      <div className="twm-s-info-inner">
                        <i className="fas fa-money-bill-wave" />
                        <span className="twm-title">Service Fee</span>
                        <div className="twm-s-info-discription">
                          $
                          {jobDetailsInfo?.serviceFee
                            ? (jobDetailsInfo?.serviceFee / 100)?.toFixed(2)
                            : 0}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="twm-s-info-inner">
                        <i className="fas fa-money-bill-wave" />
                        <span className="twm-title">
                          Booking and Processing Fee
                        </span>
                        <div className="twm-s-info-discription">
                          $
                          {jobDetailsInfo?.bookingAndProcessingFee
                            ? (
                              jobDetailsInfo?.bookingAndProcessingFee / 100
                            )?.toFixed(2)
                            : 0}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="twm-s-info-inner">
                        <i className="fas fa-money-bill-wave" />
                        <span className="twm-title">Authorized Amount</span>
                        <div className="twm-s-info-discription">
                          $
                          {jobDetailsInfo?.totalCharges
                            ? (jobDetailsInfo?.totalCharges / 100)?.toFixed(2)
                            : 0}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="twm-s-info-inner">
                        <i className="fas fa-money-bill-wave" />
                        <span className="twm-title">Charged Amount</span>
                        <div className="twm-s-info-discription">
                        $
                          {jobDetailsInfo?.actualTotalCharges
                            ? `${((jobDetailsInfo?.actualTotalCharges / 100)?.toFixed(2))}`
                            : 0}
                        </div>
                      </div>
                    </li>
                  </>
                )}

                

                {jobDetailsInfo?.multipleDayJob && (<li>
                  <div className="twm-s-info-inner">
                    <i className="fas fa-book-open" />
                    <span className="twm-title">Notes</span>
                    <div className="twm-s-info-discription">
                      {jobDetailsInfo?.notes ? jobDetailsInfo?.notes : '-'}
                    </div>
                  </div>
                </li>
                )}
                
                {(role_type.includes('ROLE_OPENSTAFF_ADMIN') && jobDetailsInfo.jobStatus !== "eCompleted")   &&
                   <li>
                    <div className="twm-s-info-inner">
                      <i className="fas fa-money-bill-wave" />
                      <span className="twm-title">Change Job Status</span>
                      <div className="twm-s-info-discription">
                      <select
                        className="wt-select-bar-large selectpicker"
                        data-live-search="true"
                        data-bv-field="size"
                        value={formValues?.jobStatusChange}
                        onChange={onJobStatusChange}
                      >
                        <option value="">Select Job Status</option>
                        <option value="eCompleted">Complete</option>
                      </select>
                    </div>
                  </div>
                    </li>
                }

                {role_type.includes("ROLE_USER") && jobDetailsInfo?.paymentMethod && (<li>
                  <div className="twm-s-info-inner">
                    <i className="fs-input-icon fa fa-money-check-alt" />
                    <span className="twm-title">Payment Method</span>
                    <div className="twm-s-info-discription">
                      {getPaymentMethod(jobDetailsInfo?.paymentMethod)}
                    </div>
                  </div>
                </li>
                )}
                {selectedFields?.length > 0 &&
                  selectedFields.map((field) => (
                    <li><div className="twm-s-info-inner">
                      <i className="fas fa-comment" />
                      <div key={field.mapField} className="form-group">
                        <label>{field.title}</label>
                        <div className="ls-inputicon-box">
                          <input
                            type="text"
                            className="form-control"
                            name={field.mapField}
                            value={ansValues[field.mapField] || ""}
                            required={field.required}
                            disabled
                          />

                        </div>
                      </div>
                    </div>
                    </li>
                  ))}
                {((role_type.includes("ROLE_USER") || role_type.includes("ROLE_INSTITUTION_ADMIN")) && (jobDetailsInfo.jobStatus === "eInProgress" || jobDetailsInfo.jobStatus === "eAccepted")) && (
                  <>
                    <h4 className="section-head-small mt-4">
                      <span>Provider Details</span>
                    </h4>
                    <li>
                      <div className="twm-s-info-inner">
                        <i class="fas fa-regular fa-user"></i>
                        <span className="twm-title">Name</span>
                        <div className="twm-s-info-discription">
                          <span>
                            {(role_type.includes("ROLE_USER"))
                              ? `${userInfo?.firstName ? userInfo?.firstName : "-"} ${userInfo?.lastName ? userInfo?.lastName : "-"}`
                              : (role_type.includes("ROLE_INSTITUTION_ADMIN"))
                                ? `${jobDetailsInfo?.hirePerson?.firstName ? jobDetailsInfo?.hirePerson?.firstName : "-"} ${jobDetailsInfo?.hirePerson?.lastName ? jobDetailsInfo?.hirePerson?.lastName : "-"}`
                                : null}
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      {/* {console.log("jobDetailsInfo?.hirePersonnumberOfReview", jobDetailsInfo?.hirePerson.numberOfReview, jobDetailsInfo?.hirePerson.averageNumStars, jobDetailsInfo?.hirePerson.firstName, jobDetailsInfo?.hirePerson.lastName)} */}
                      <div className="twm-s-info-inner">
                        <i class="fas fa-regular fa-star"></i>
                        <span className="twm-title">Rating & Reviews</span>
                        <div className="twm-s-info-discription">
                          {StarRating(
                            role_type.includes("ROLE_USER")
                              ? userInfo?.applicantAverageRating
                              : role_type.includes("ROLE_INSTITUTION_ADMIN")
                                ? jobDetailsInfo?.hirePerson.averageNumStars
                                : null
                          )}{" "}
                          <span style={{ fontSize: "12px" }}>
                            {
                              (role_type.includes("ROLE_USER"))
                                ? `${userInfo?.applicantNumberOfReviews ? userInfo?.applicantNumberOfReviews : 0} Reviews`
                                : (role_type.includes("ROLE_INSTITUTION_ADMIN"))
                                  ? `${jobDetailsInfo?.hirePerson?.numberOfReview ? jobDetailsInfo?.hirePerson?.numberOfReview : 0} Reviews`
                                  : null
                            }
                          </span>
                        </div>
                      </div>
                    </li>
                  </>
                )}

                {/* {
                      <li>
                        <div className="twm-s-info-inner">
                          <i class="fas fa-regular fa-star"></i>
                          <span className="twm-title">Rating & Reviews</span>
                          <div className="twm-s-info-discription">
                            {StarRating(
                              jobDetailsInfo
                                ? jobDetailsInfo?.averageRating
                                : jobDetailsInfo?.averageRating
                            )}{" "}
                            <span style={{ fontSize: "12px" }}>
                              {jobDetailsInfo?.numberOfReview
                                ? jobDetailsInfo?.numberOfReview
                                : 0}{" "}
                              Reviews
                            </span>
                          </div>
                        </div>
                      </li>
                    } */}
              </ul>
            }

            {/* )} */}
          </div>
        </div>
        {/* <div className="widget tw-sidebar-tags-wrap">
                    <h4 className="section-head-small mb-4">Job Skills</h4>
                    <div className="tagcloud">
                        <a href="#">Html</a>
                        <a href="#">Python</a>
                        <a href="#">WordPress</a>
                        <a href="#">JavaScript</a>
                        <a href="#">Figma</a>
                        <a href="#">Angular</a>
                        <a href="#">Reactjs</a>
                        <a href="#">Drupal</a>
                        <a href="#">Joomla</a>
                    </div>
                </div> */}
      </div>
      {console.log("_config-----", _config)}
      {_config.showEmpInfo && (
        <div className="twm-s-info3-wrap mb-5">
          <div className="twm-s-info3">
            <div className="twm-s-info-logo-section">
              <div className="twm-media">
                {console.log(
                  "jobDetailsInfojobDetailsInfojobDetailsInfo",
                  jobDetailsInfo
                )}
                {/* <JobZImage src="images/jobs-company/pic1.jpg" alt="#" /> */}
                <GetProfileImage id={jobDetailsInfo?.id} type={"jobList"} />
              </div>
              <h4 className="twm-title">Company Details</h4>
            </div>
            <ul>
              <li>
                <div className="twm-s-info-inner">
                  <i className="fas fa-building" />
                  <span className="twm-title">Company</span>
                  <div className="twm-s-info-discription">
                    {jobDetailsInfo?.name}
                  </div>
                </div>
              </li>
              {
                currentpath.includes('emp-detail') &&
                <li>
                  <div className="twm-s-info-inner">
                    <i className="fas fa-building" />
                    <span className="twm-title">Address</span>
                    <div className="twm-s-info-discription">
                      {jobDetailsInfo?.address}
                    </div>
                  </div>
                </li>
              }
              <li>
                <div className="twm-s-info-inner">
                  <i className="fas fa-user" />
                  <span className="twm-title">Contact Person</span>
                  <div className="twm-s-info-discription">
                    {jobDetailsInfo?.firstName + " " + jobDetailsInfo?.lastName || "-"}
                  </div>
                </div>
              </li>
              <li>
                <div className="twm-s-info-inner">
                  <i className="fas fa-calendar" />
                  <span className="twm-title">Created Date</span>
                  <div className="twm-s-info-discription">
                    {/* {jobDetailsInfo?.createdDate || "-"} */}
                    {moment
                      .utc(jobDetailsInfo?.createdDate, "YYYY-MM-DD HH:mm:ss")
                      .local()
                      .format("MM/DD/YYYY")}
                  </div>
                </div>
              </li>
              <li>
                <div className="twm-s-info-inner">
                  <i className="fas fa-mobile-alt" />
                  <span className="twm-title">Phone</span>
                  <div className="twm-s-info-discription">
                    {jobDetailsInfo?.phoneNumber || "999-999-9999"}
                  </div>
                </div>
              </li>
              <li>
                <div className="twm-s-info-inner">
                  <i className="fas fa-at" />
                  <span className="twm-title">Email</span>
                  <div className="twm-s-info-discription">{jobDetailsInfo?.email}</div>
                </div>
              </li>
              {
                !currentpath.includes("/emp-detail") && (
                  <li>
                    <div className="twm-s-info-inner">
                      <i className="fas fa-map-marker-alt" />
                      <span className="twm-title">Distance</span>
                      <div className="twm-s-info-discription">
                        {jobDetailsInfo?.distance
                          ? jobDetailsInfo?.distance?.toFixed(2)
                          : 0}{" "}
                        Miles
                      </div>
                    </div>
                  </li>
                )
              }
              <li>
                <div className="twm-s-info-inner">
                  <i class="fas fa-regular fa-star"></i>
                  <span className="twm-title">Rating & Reviews</span>
                  <div className="twm-s-info-discription">
                    {StarRating(
                      jobDetailsInfo?.hirePerson
                        ? jobDetailsInfo?.hirePerson?.averageNumStars?.toFixed(
                          1
                        )
                        : jobDetailsInfo?.averageNumStars?.toFixed(1)
                    )}{" "}
                    <span style={{ fontSize: "12px" }}>
                      {jobDetailsInfo?.hirePerson
                        ? `${jobDetailsInfo?.hirePerson?.numberOfReview} Reviews`
                        : `${jobDetailsInfo?.numberOfReview
                          ? jobDetailsInfo?.numberOfReview
                          : 0
                        } Reviews`}
                    </span>
                  </div>
                </div>
              </li>
              {/* <li>
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="form-group city-outer-bx has-feedback">
                    <div className="ls-inputicon-box">
                      <input
                        type="checkbox"
                        name="privacyPolicyId"
                        checked={formValues.stripeMethodId}
                        onChange={(e) => onStripePaymentChange(e)}
                      />
                      <a
                        href="#"
                        role="button"
                        style={{ marginLeft: 10 }}
                      // onClick={() => setShowPrivacyPolicy(true)}
                      >
                        Use Stripe payment method
                      </a>
                      {formErrors.stripeMethodId && (
                        <div className="error-message">
                          {formErrors.stripeMethodId}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </li> */}

              {console.log("formValues?.stripeMethodId-----", formValues?.stripeMethodId)}
              {role_type.includes('ROLE_OPENSTAFF_ADMIN') &&
                <li>
                  {/* {console.log("formValues?.stripeMethodId",formValues?.stripeMethodId)} */}
                  <form id="change_service_fee">
                  <div className="row">
                    <label>Service Fee Amount (%)</label>
                  <div className="col-xl-8 col-lg-8 col-md-6 col-sm-6">
                    <div className="form-group mb-2">
                      
                      <div className="ls-inputicon-box">
                        <input
                          className="form-control"
                          name="serviceFee"
                          type="text"
                          placeholder="Service Fee"
                          value={formValues.serviceFee}
                          onChange={(e) => handleInputChange(e, "serviceFee")}
                        />
                      </div>
                      {formErrors.serviceFee && (
                        <div className="error-message">
                          {formErrors.serviceFee}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <div className="text-center">
                      <button
                        type="submit"
                        className="site-button"
                        onClick={handleServiceFeeSubmit}
                      >
                        {isLoading ? <Spinner /> : "Save"}
                      </button>
                    </div>
                  </div>
                  </div>
                  </form>
                </li>
              }
              {/* {
                formValues?.stripeMethodId ?  */}
                {
                  !role_type.includes('ROLE_INSTITUTION_POSTER_ADMIN')  &&
                  <>
                  <li>
                {/* {console.log("formValues?.stripeMethodId",formValues?.stripeMethodId)} */}
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <span className="twm-title">Use selected payment method</span>
                  <select
                    className="wt-select-bar-large selectpicker"
                    data-live-search="true"
                    data-bv-field="size"
                    value={formValues?.stripeMethodId}
                    onChange={onStripePaymentChange}
                  >
                    <option value="">Select Payment Method</option>
                    <option value="stripe">Stripe</option>
                    <option value="other">Other</option>
                  </select>
                  {formErrors.stripeMethodId && (
                    <div className="error-message">
                      {formErrors.stripeMethodId}
                    </div>
                  )}
                </div>
              </li> 

                  {
                    isACHEnalbed && 
                    <li>
                        <div className='row'>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                          <label className="custom-checkbox">
                              <input
                                type="checkbox"
                                name="card"
                                checked={formValues?.card}
                                disabled
                              />
                              <span className="custom-checkbox-label ml-3">Card</span>
                              </label>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6">
                            <label className="custom-checkbox">
                                <input
                                  type="checkbox"
                                  name="usBankAccount"
                                  checked={formValues?.usBankAccount}
                                  onChange={handleCheckboxChange}
                                />
                                  <span className="custom-checkbox-label ml-3">Us Bank Account</span>
                              </label>
                          </div>
                        </div>
                </li> 
                  }
                
                </>
                }
              

             
              {/* : 
              <TableLoader />
              }   */}

              {/* <li>
                                <div className="twm-s-info-inner">
                                    <i className="fas fa-map-marker-alt" />
                                    <span className="twm-title">Address</span>
                                    <div className="twm-s-info-discription">1363-1385 Sunset Blvd Angeles, CA
                                        90026 ,USA</div>
                                </div>
                            </li> */}
            </ul>
            {/* <NavLink to={publicUser.pages.ABOUT} className=" site-button">Vew Profile</NavLink> */}
          </div>
        </div>
      )}
      {_config.showJobInfo && (
        <div className="twm-s-info3-wrap mb-5">
          <div className="twm-s-info3">
            <div className="twm-s-info-logo-section">
              <div className="twm-media">
                {console.log(
                  "jobDetailsInfojobDetailsInfojobDetailsInfo",
                  jobDetailsInfo
                )}
                {/* <JobZImage src="images/jobs-company/pic1.jpg" alt="#" /> */}
                <GetProfileImage
                  id={jobDetailsInfo?.institutionEntityId}
                  type={"jobList"}
                />
              </div>
              <h4 className="twm-title">Company Details</h4>
            </div>
            <ul>
              <li>
                <div className="twm-s-info-inner">
                  <i className="fas fa-building" />
                  <span className="twm-title">Company</span>
                  <div className="twm-s-info-discription">
                    {jobDetailsInfo?.institutionName}
                  </div>
                </div>
              </li>
              <li>
                <div className="twm-s-info-inner">
                  <i className="fas fa-user" />
                  <span className="twm-title">Contact Person</span>
                  <div className="twm-s-info-discription">
                    {jobDetailsInfo?.contactPerson}
                  </div>
                </div>
              </li>
              <li>
                <div className="twm-s-info-inner">
                  <i className="fas fa-mobile-alt" />
                  <span className="twm-title">Phone</span>
                  <div className="twm-s-info-discription">
                    {jobDetailsInfo?.contactPhone}
                  </div>
                </div>
              </li>
              {/* <li>
                <div className="twm-s-info-inner">
                  <i className="fas fa-at" />
                  <span className="twm-title">Email</span>
                  <div className="twm-s-info-discription">dummy@gmail.com</div>
                </div>
              </li> */}
              {role_type.includes("ROLE_USER") || !isLoggedIn && (<li>
                <div className="twm-s-info-inner">
                  <i className="fas fa-map-marker-alt" />
                  <span className="twm-title">Distance</span>
                  <div className="twm-s-info-discription">
                    {jobDetailsInfo?.distance
                      ? jobDetailsInfo?.distance?.toFixed(2)
                      : 0}{" "}
                    Miles
                  </div>
                </div>
              </li>
              )}
              <li>
                <div className="twm-s-info-inner">
                  <i class="fas fa-regular fa-star"></i>
                  <span className="twm-title">Rating & Reviews</span>
                  <div className="twm-s-info-discription">
                    {StarRating(
                      !isLoggedIn ?
                        jobDetailsInfo?.averageRating :

                        (jobDetailsInfo.jobStatus === "eOpen" || jobDetailsInfo.jobStatus === "eAccepted" || jobDetailsInfo.jobStatus === "eInProgress" || jobDetailsInfo.jobStatus === "eCompleted") && role_type.includes("ROLE_USER")
                          ? (jobDetailsInfo?.instituteAvgRating ? jobDetailsInfo?.instituteAvgRating : jobDetailsInfo?.averageRating ? jobDetailsInfo?.averageRating : 0)
                          : (jobDetailsInfo.jobStatus === "eOpen" || jobDetailsInfo.jobStatus === "eAccepted" || jobDetailsInfo.jobStatus === "eInProgress" || jobDetailsInfo.jobStatus === "eCompleted" || jobDetailsInfo.jobStatus === "eDeleted" || jobDetailsInfo.jobStatus === "eCancelled") && role_type.includes("ROLE_INSTITUTION_ADMIN")
                            ? jobDetailsInfo?.averageRating ? jobDetailsInfo?.averageRating : 0
                            : (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN") || role_type?.length === 0) ?
                              jobDetailsInfo?.averageRating
                              : jobDetailsInfo?.instituteRating
                    )}{" "}
                    <span style={{ fontSize: "12px" }}>
                      {
                        !isLoggedIn ?
                          jobDetailsInfo?.numberOfReview :

                          (jobDetailsInfo.jobStatus === "eOpen" || jobDetailsInfo.jobStatus === "eAccepted" || jobDetailsInfo.jobStatus === "eInProgress" || jobDetailsInfo.jobStatus === "eCompleted") && role_type.includes() === "ROLE_USER"
                            ? `${jobDetailsInfo?.instituteReview ? jobDetailsInfo?.instituteReview : jobDetailsInfo?.numberOfReview ? jobDetailsInfo?.numberOfReview : 0} Reviews`
                            : (jobDetailsInfo.jobStatus === "eOpen" || jobDetailsInfo.jobStatus === "eAccepted" || jobDetailsInfo.jobStatus === "eInProgress" || jobDetailsInfo.jobStatus === "eDeleted" || jobDetailsInfo.jobStatus === "eCompleted" || jobDetailsInfo.jobStatus === "eCancelled") && role_type.includes() === "ROLE_INSTITUTION_ADMIN"
                              ? `${jobDetailsInfo?.numberOfReview ? jobDetailsInfo?.numberOfReview : 0} Reviews`
                              : role_type.includes("ROLE_OPENSTAFF_ADMIN") ?
                                jobDetailsInfo?.numberOfReview
                                : `${jobDetailsInfo?.instituteReview
                                  ? jobDetailsInfo?.instituteReview
                                  : 0
                                } Reviews`}
                    </span>
                  </div>
                </div>
              </li>
              {/* <li>
                                <div className="twm-s-info-inner">
                                    <i className="fas fa-map-marker-alt" />
                                    <span className="twm-title">Address</span>
                                    <div className="twm-s-info-discription">1363-1385 Sunset Blvd Angeles, CA
                                        90026 ,USA</div>
                                </div>
                            </li> */}
            </ul>
            {/* <NavLink to={publicUser.pages.ABOUT} className=" site-button">Vew Profile</NavLink> */}
          </div>
        </div>
      )}
      <JobReviewPopUp
        jobDetailsInfo={jobDetailsInfo}
        jobDetailId={jobDetailId}
        jobRating={role_type.includes("ROLE_USER") ? posterRating : 0}
        currentPage={"approveFinish"}
      //jobReview={role_type == "ROLE_USER" ? posterReview : ""}
      />
      {/* {!isLoggedIn && (<SectionSideAdvert />)} */}
    </>
  );
}

export default SectionJobsSidebar2;
