import React from "react";
import CertificateList from "./certificateList";
import DocumentViewUpdatePopup from "./documentViewUpdatePopup";
import BackgroundForm from "./backgroundForm";
import BackgroundFormUpload from "./backgroundFormUpload";


const ViewDocumentPopup = ({
  viewDocId,
  documentType,
  certificateList,
  backgroundScreenFormData,
  getCertificates,
  getDocuments,
  getBackgroundForms,
}) => {
  
const isEnabledBackgroundForm = process.env.REACT_APP_ENABLED_BACKGROUNDFORM === "true";
console.log("bbbbbbbbbb", isEnabledBackgroundForm, process.env.REACT_APP_ENABLED_BACKGROUNDFORM)
  return (
    <div
      className="modal fade twm-sign-up"
      id={`${
        (documentType === "document" || documentType === "background")
          ? "view_app_doc"
          : documentType === "certificate"
          ? "view_app_certificate"
          : ""
      }`}
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>

          <div className="modal-body" style={{ textAlign: "center" }}>
            {documentType === "document" ? (
              <DocumentViewUpdatePopup
                viewDocId={viewDocId}
                documentType={documentType}
                getDocuments={getDocuments}
              />
            ) : documentType === "certificate" ? (
              <CertificateList
                certificateList={certificateList}
                getCertificates={getCertificates}
              />
            ) : documentType === "background" ? (
              isEnabledBackgroundForm ?
              <BackgroundForm
                getBackgroundForms={getBackgroundForms}
                backgroundScreenFormData={backgroundScreenFormData}
              />
              :
              <BackgroundFormUpload 
               getBackgroundForms={getBackgroundForms}
               backgroundScreenFormData={backgroundScreenFormData}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDocumentPopup;
