import React, { useState } from "react";
import SectionCanChat from "../../../candidate/sections/chat/section-can-chat";

function ChatJobPopup({ jobDetailsInfo }) {
    console.log('userEntityId::::::::::::11',jobDetailsInfo)
  return (
   <>
    <div
    className="modal fade twm-sign-up"
    id='chatModal'
    aria-hidden="true"
    aria-labelledby="sign_up_popupLabel2"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabIndex={-1}
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
          <div className="modal-body">
          <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{float:'right', marginTop:-20}}
            />
          {jobDetailsInfo?.hirePerson?.userEntityId && (
              <div className="twm-right-section-panel site-bg-gray">
                <div className="wt-admin-dashboard-msg-2 twm-dashboard-style-2">
                  <SectionCanChat
                    jobId={jobDetailsInfo.id}
                    jobItem={jobDetailsInfo}
                    forApplied={true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default ChatJobPopup;
