import React, { useEffect } from "react";
import JobZImage from "../../../../common/jobz-img";
import moment from "moment";
import { useSelector } from "react-redux";
import Avatar from "../../../../common/chat-avatar-by-name";
import { NavLink, useNavigate, useParams } from "react-router-dom";

const ActiveColor={backgroundColor:'#eee'}
function SectionCanChatList({jobId,chatList, handleSelectionChat,setIsJobList,isJobList,setSearchValue,searchValue,getAllChats}) {

  const { id } = useParams();
    const { isLoggedIn, userInfo, role_type } = useSelector(
        (state) => state.menu
      );
      const isAdmin = userInfo?.roleEntities[0]?.roleType=="ROLE_OPENSTAFF_ADMIN"
    const formatDate = (date) => {
      const now = moment();
      const chatDate = moment(date);
    
      if (chatDate.isSame(now, 'day')) {
        return chatDate.format('HH:mm');
      } else if (chatDate.isSame(now.subtract(1, 'day'), 'day')) {
        return 'Yesterday';
      } else {
        return chatDate.format('MM/DD/YYYY');
      }
    };
    const navigate = useNavigate();
    
    const handleSelectionChatNav = (chat) => {
      handleSelectionChat(chat?.jobId)
      navigate(`/chats/${chat?.jobId}`);
    };
    useEffect(() => {
      console.log("id:::::", id)
      handleSelectionChat(id);
    }, [])
    return (
      <>
        <div className="wt-dashboard-msg-user-list" style={{ width: "100%" }}>
          <div className="user-msg-list-btn-outer">
            <button className="user-msg-list-btn-close">Close</button>
            <button className="user-msg-list-btn-open">User Message</button>
          </div>

          {/* Search Section Start*/}

          <div className="wt-dashboard-msg-search">
            {!isAdmin&&
          <div className="d-flex justify-content-end">
            <button className="site-button button-sm my-3 float-right" onClick={()=>setIsJobList(!isJobList)}>
            {isJobList?(<>
            <i className="fa fa-times" />
            Close
            </> )
            :
            (<>
             <i className="fa fa-plus" />
             New Chat
                </> )
          }
          </button>
         
            
            </div>
          }
            <div className="input-group">
              <input
                className="form-control"
                placeholder="Search Messages"
                type="text"
                value={searchValue}
                onChange={(e)=>setSearchValue(e.target.value)}
              />
              {searchValue?
              <button className="btn" type="button" onClick={()=>setSearchValue('')}>
                <i className="fa fa-times" />
              </button>
             :<button className="btn" type="button" onClick={getAllChats}>
             <i className="fa fa-search" />
           </button>}
              
            </div>
          </div>
          {/* Search Section End*/}
          {/* Search Section End*/}
          <div className="msg-find-list">
            <select className="wt-select-box bs-select-hidden">
              <option>Recent Chats</option>
              <option>Short by Time</option>
              <option>Short by Unread</option>
            </select>
          </div>
          {/* Search Section End*/}
          {/* user msg list start*/}
          <div
            id="msg-list-wrap"
            className="wt-dashboard-msg-search-list scrollbar-macosx active"
          >
            {chatList?.length > 0 ? (
              chatList?.map((chat) => {
                let senderName = {
                  firstName: "",
                  lastName: "",
                  fullName: "",
                };
                if (isAdmin) {
                  senderName = {
                    ...senderName,
                    fullName:
                      chat?.senderFirstname +
                      " " +
                      chat?.senderLastname +
                      ", " +
                      chat?.receiverFirstName +
                      " " +
                      chat?.receiverLastName,
                  };
                } else if (userInfo?.id == chat?.senderId) {
                  senderName = {
                    ...senderName,
                    firstName: chat?.receiverFirstName,
                    lastName: chat?.receiverLastName,
                  };
                } else if (userInfo?.id == chat?.receiverId) {
                  senderName = {
                    ...senderName,
                    firstName: chat?.senderFirstname,
                    lastName: chat?.senderLastname,
                  };
                }
                // let latestDate = getLatestMessageDate(chat?.message);
                // console.log("latestDate::::::::::::::::", latestDate);
                return (
                  <div
                    className="wt-dashboard-msg-search-list-wrap"
                    
                    onClick={() => handleSelectionChatNav(chat)}
                  >
                    <NavLink className="msg-user-info clearfix" style={chat?.jobId==jobId?ActiveColor:{}}>
                      <div className="msg-user-timing">
                        {formatDate(new Date(chat?.modifiedOn?.toDate()))}
                      </div>
                      <div
                        style={{ display: "flex" }}
                      >
                        <div className="my-10">
                          <Avatar
                            firstName={senderName?.firstName}
                            lastName={senderName?.lastName}
                            isAdmin={isAdmin}
                          />{" "}
                        </div>
                        <div className="msg-user-info-text">
                          <div className="msg-user-name">
                            {isAdmin
                              ? senderName?.fullName
                              : senderName?.firstName +
                                " " +
                                senderName?.lastName}
                          </div>
                          <div className="msg-user-discription">
                            {chat?.jobTitle}
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh", // Make the container take the full height of the viewport
                  textAlign: "center",
                }}
              >
                no chat in list
              </div>
            )}

            {/* <div className="wt-dashboard-msg-search-list-wrap">
                        <a href="#" className="msg-user-info clearfix">
                            <div className="msg-user-timing">16/07/2023</div>
                            <div className="msg-user-info-pic"><JobZImage src="images/user-avtar/pic2.jpg" alt="" /></div>
                            <div className="msg-user-info-text">
                                <div className="msg-user-name">Christina Fischer </div>
                                <div className="msg-user-discription">All created by our Global</div>
                            </div>
                        </a>
                    </div>
                    <div className="wt-dashboard-msg-search-list-wrap">
                        <a href="#" className="msg-user-info clearfix">
                            <div className="msg-user-timing">16/07/2023</div>
                            <div className="msg-user-info-pic"><JobZImage src="images/user-avtar/pic3.jpg" alt="" /></div>
                            <div className="msg-user-info-text">
                                <div className="msg-user-name">Wanda Willis</div>
                                <div className="msg-user-discription">All created by our Global</div>
                            </div>
                        </a>
                    </div>
                    <div className="wt-dashboard-msg-search-list-wrap">
                        <a href="#" className="msg-user-info clearfix">
                            <div className="msg-user-timing">16/07/2023</div>
                            <div className="msg-user-info-pic"><JobZImage src="images/user-avtar/pic4.jpg" alt="" /></div>
                            <div className="msg-user-info-text">
                                <div className="msg-user-name">Peter Hawkins</div>
                                <div className="msg-user-discription">All created by our Global</div>
                            </div>
                        </a>
                    </div>
                    <div className="wt-dashboard-msg-search-list-wrap">
                        <a href="#" className="msg-user-info clearfix">
                            <div className="msg-user-timing">16/07/2023</div>
                            <div className="msg-user-info-pic"><JobZImage src="images/user-avtar/pic1.jpg" alt="" /></div>
                            <div className="msg-user-info-text">
                                <div className="msg-user-name">Kathleen Moreno</div>
                                <div className="msg-user-discription">All created by our Global</div>
                            </div>
                        </a>
                    </div>
                    <div className="wt-dashboard-msg-search-list-wrap">
                        <a href="#" className="msg-user-info clearfix">
                            <div className="msg-user-timing">16/07/2023</div>
                            <div className="msg-user-info-pic"><JobZImage src="images/user-avtar/pic2.jpg" alt="" /></div>
                            <div className="msg-user-info-text">
                                <div className="msg-user-name">Wanda Montgomery </div>
                                <div className="msg-user-discription">All created by our Global</div>
                            </div>
                        </a>
                    </div> */}
          </div>
          {/* user msg list End*/}
        </div>
      </>
    );
}
export default SectionCanChatList;