import React, { useState } from "react";
import Toast from "../../../../../common/toast";
import Spinner from "../../../../../common/spinner";
import apiCall from "../../../../../../apicall";
import { useSelector } from "react-redux";
import moment from "moment";

const ConfirmationPopUp = ({ userId }) => {
  const { TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserDeteleted, setIsUserDeteleted] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setIsUserDeteleted(false);
  };

  const handleUserDelete = async () => {
    try {
      setIsLoading(true);
      const deleteUserResponse = await apiCall(
        `openstaff/${userId}/deleteAccount`,
        "PUT",
        null,
        TOKEN
      );
      console.log("deleteUserResponse----", deleteUserResponse);
      if (deleteUserResponse?.status === 200) {
        setIsLoading(false);
        setIsUserDeteleted(true);
        setErrSuccessMsg({
          message: "User deleted successfully!",
          status: true,
        });
      } else if (deleteUserResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(deleteUserResponse?.response?.data?.message)
      }
    } catch (err) {
      console.log("setSessionExpired--", err);
      displayErrMsg("Something went wrong!");
    }
  };

  const getTheOneWeekAfterDate = () => {
    const currentDate = moment();
    const oneWeekLater = moment(currentDate).add(1, "weeks");
    return oneWeekLater.format("MM/DD/YYYY");
  };

  return (
    <div
      className="modal fade twm-sign-up"
      id="delete_confirmation_popup"
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="sign_up_popupLabel2">
              {isUserDeteleted
                ? "User Deleted Successfully"
                : "Are you sure you want to delete user ?"}
            </h2>
            <p>
              {isUserDeteleted
                ? `The account wiil be deleted on
              ${getTheOneWeekAfterDate()}`
                : `if you confirm to 'delete account' the account wiil be deleted on
              ${getTheOneWeekAfterDate()}`}
            </p>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={()=> setIsUserDeteleted(false)}
            />
          </div>
          <div className="modal-footer">
            {isUserDeteleted ? (
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={()=> setIsUserDeteleted(false)}
              >
                Ok
              </button>
            ) : (
              <>
                {/* <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button> */}
                <button
                  type="button"
                  id="deleteConfirmationModal"
                  className="btn btn-primary"
                  // data-bs-dismiss="modal"
                  onClick={handleUserDelete}
                >
                  {isLoading ? <Spinner /> : "Delete"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </div>
  );
};

export default ConfirmationPopUp;
