import apiCall from "../../../../../apicall";
import { themeColors, companyLogo, smallcompanyLogo } from "../../../../../store/reducers/menu";

export const fetchThemeColor = async (dispatch) => {
    try {
        const themeResponse = await apiCall(
            "config/colorTheme?keyName=portalTheme",
            "GET",
            null
        );
        console.log('themes::::::::::::::', themeResponse?.data?.themes);
        if (themeResponse?.data?.themes?.length > 0) {
            return dispatch(themeColors(themeResponse?.data?.themes));
        }
    } catch (err) {
        // Handle error if necessary
        console.error('Error fetching theme colors:', err);
    }
};

export const fetchCompanyLogo = async (dispatch) => {
    const response = await apiCall(
      "config/companyLogoLarge",
      "GET",
      null,
      null,
      false,
      true
    );
    const smallLogoResponse = await apiCall(
      "config/companyLogo",
      "GET",
      null,
      null,
      false,
      true
    );
    console.log("logooo", response, smallLogoResponse)
    if (response.status === 200) {
      const blobResponse = await response?.data;
      const reader = new FileReader();
      reader.readAsDataURL(blobResponse);

      reader.onloadend = () => {
        const imageResponse = reader.result;
        dispatch(companyLogo(imageResponse));;
      };
    };
    if (smallLogoResponse.status === 200) {
      const blobResponse = await smallLogoResponse?.data;
      const reader = new FileReader();
      reader.readAsDataURL(blobResponse);

      reader.onloadend = () => {
        const imageResponse = reader.result;
        dispatch(smallcompanyLogo(imageResponse));;
      };
    };
  }
  
