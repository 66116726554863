import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import SessionExpired from "../../../../common/sessionExpired";
import Toast from "../../../../common/toast";
import { userDetails } from "../../../../../store/reducers/menu";
import { loadScript } from "../../../../../globals/constants";
import DeleteAccountConfirmation from "./deleteAccountConfirmation";
import apiCall from "../../../../../apicall";
import { fetchThemeColor, fetchCompanyLogo } from "./themeColor";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../../globals/fomat-comapny-name";

function DeleteAccount() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    console.log("delete account-------")
    const [sessionExpired, setSessionExpired] = useState(false);
    const [formValues, setFormValues] = useState({
        firstName: "",
        lastName: "",
        email: ""
    });
    const [errSuccessMesg, setErrSuccessMsg] = useState({
        message: "",
        status: "error",
    });

    const [formErrors, setFormErrors] = useState({});
    const handleInputChange = (e, fieldName) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [fieldName || name]: value,
        }));

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName || name]: "",
        }));
    };

    const validateForm = () => {
        const errors = {};
        ["firstName", "lastName", "email"].forEach((key) => {
            if (!formValues[key]) {
                errors[key] = `${key} is required.`;
            }
        });
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const handleDeleteAccountClick = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Trigger the modal manually
            const modalElement = document.getElementById('delete_profile_popup');
            const modal = new window.bootstrap.Modal(modalElement);
            modal.show();
        } else {
            // displayErrMsg("Please fill in all required fields.");
        }
    };
    const clearFormValues = () => {
        setFormValues({
            firstName: "",
            lastName: "",
            email: "",
        });
    };

    useEffect(() => {
        loadScript("js/custom.js");
    });
    useEffect(() => {
        fetchThemeColor(dispatch);
        fetchCompanyLogo(dispatch);
    }, []);

    return (
        <>
            <div>
                <Helmet>
                    <title>{`Delete Account | Delete Your Profile | ${formatCompanyName()}`}</title>
                    <meta name="description" content="Welcome to the Delete Account page. Here, you can permanently remove your account and all associated data from our platform. Please note that this action is irreversible and will result in the loss of all your information, including any saved preferences and history." />
                    <meta name="keywords" content="delete account, delete your profile" />
                </Helmet>
            </div>
            <div className="section-full p-t120  p-b90 site-bg-white">
                <div className="container">
                    <div className="row">
                        <form id="jobseeker_profile">
                            <div className="panel panel-default">
                                <div className="panel-heading d-flex justify-content-between align-items-center wt-panel-heading p-a20">
                                    <h4 className="panel-tittle mt-5">Delete Account</h4>
                                </div>
                                <div className="panel-body wt-panel-body p-a20 m-b30 ">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <label>First Name</label>
                                                <div className="ls-inputicon-box">
                                                    <input
                                                        className="form-control"
                                                        name="firstName"
                                                        type="text"
                                                        placeholder="First Name"
                                                        value={formValues.firstName}
                                                        onChange={(e) => handleInputChange(e, "firstName")}
                                                    />
                                                    <i className="fs-input-icon fa fa-user " />
                                                </div>
                                                {formErrors.firstName && (
                                                    <span className="error-message">
                                                        {formErrors.firstName}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <label>Last Name</label>
                                                <div className="ls-inputicon-box">
                                                    <input
                                                        className="form-control"
                                                        name="lastName"
                                                        type="text"
                                                        placeholder="Last Name"
                                                        value={formValues.lastName}
                                                        onChange={(e) => handleInputChange(e, "lastName")}
                                                    />
                                                    <i className="fs-input-icon fa fa-user " />
                                                </div>
                                                {formErrors.lastName && (
                                                    <span className="error-message">{formErrors.lastName}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <label>Email Address</label>
                                                <div className="ls-inputicon-box">
                                                    <input
                                                        className="form-control"
                                                        name="email"
                                                        type="email"
                                                        placeholder="Email"
                                                        value={formValues.email}
                                                        onChange={(e) => handleInputChange(e, "email")}
                                                    />
                                                    <i className="fs-input-icon fas fa-at" />
                                                </div>
                                                {formErrors.email && (
                                                    <span className="error-message">{formErrors.email}</span>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="text-left">
                                                {/* <button type="submit" className="site-button">
                    {isLoading ? <Spinner /> : "Save Changes"}
                  </button> */}
                                                <a
                                                    className="site-button"
                                                    // data-bs-toggle="modal"
                                                    // href="#delete_profile_popup"
                                                    role="button"
                                                    onClick={handleDeleteAccountClick}
                                                >
                                                    Delete Account
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <DeleteAccountConfirmation email={formValues.email} clearFormValues={clearFormValues} />
            {sessionExpired && <SessionExpired />}
            {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
        </>
    );
}

export default DeleteAccount;
