import { publicUrlFor } from "../../globals/constants";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function InnerPageBanner({ _data }) {
  const { isLoggedIn, role_type } = useSelector((state) => state.menu);
  const location = useLocation();
  const currentUrl = location.pathname;

  return (
    <>
      {/* <div className="wt-bnr-inr overlay-wraper bg-center" style={{ backgroundImage: `url(${publicUrlFor("images/banner/1.jpg")})` }}> */}
      {isLoggedIn && currentUrl === "/search-job" && ( <div
        className="wt-bnr-inr overlay-wraper bg-center"
        style={{
          backgroundImage: `url(https://thewebmax.org/react/jobzilla/assets/images/banner/1.jpg)`,
        }}
      >
        <div className="overlay-main site-bg-white opacity-01" />
        <div className="container">
          <div className="wt-bnr-inr-entry">
            <div className="banner-title-outer">
              <div className="banner-title-name">
                {/* <h2 className="wt-title">{_data.title}</h2> */}
              </div>
            </div>
            {/* BREADCRUMB ROW */}
            <div>
              <ul className="wt-breadcrumb breadcrumb-style-2">
                {/* <li>
                  <NavLink
                    to={
                      role_type === "ROLE_OPENSTAFF_ADMIN"
                        ? "/job-list"
                        : role_type === "ROLE_USER" ||
                          role_type === "ROLE_INSTITUTION_ADMIN"
                        ? "search-job"
                        : "/index"
                    }
                  >
                    Home
                  </NavLink>
                </li> */}
                {/* <li>{_data.crumb}</li> */}
              </ul>
            </div>
            {/* BREADCRUMB ROW END */}
          </div>
        </div>
      </div>
      )}
    </>
  );
}

export default InnerPageBanner;
