import React,{ useEffect, useState} from "react";
import SectionCanChat from "../sections/chat/section-can-chat";
import SectionCanChatList from "../sections/chat/section-can-chat-list";
import { loadScript } from "../../../../globals/constants";
import {getDocs, collection, query, where, onSnapshot} from 'firebase/firestore';
import { db } from "../../../common/firebase/firebase";
import { useSelector } from "react-redux";
import CanJobList from "./can-chat-joblists";
import apiCall from "../../../../apicall";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../globals/fomat-comapny-name";
import { logEvent_Analytics } from "../../../common/firebase/firebase-analytics-service";

function CanChatPage() {
    const { id } = useParams();

    const [jobId,setJobId]=useState(null)
    const [selectedChatJobId, setSelectedChatJobId] = useState(null)
    const [chatList,setChatList]=useState([])
    const [jobLists,setJobLists]=useState([])
    const [isJobList,setIsJobList]=useState(false)
    const [searchValue,setSearchValue]=useState('')
    const { isLoggedIn, userInfo, role_type,TOKEN, } = useSelector(
      (state) => state.menu
    );
    const isAdmin = userInfo?.roleEntities[0]?.roleType=="ROLE_OPENSTAFF_ADMIN";
    const userId = userInfo?.id
    useEffect(() => {
        fetchJobList()
         logEvent_Analytics('chat_list_web');
      }, []);
      useEffect(() => {
        // Call getAllChats when component mounts or dependencies change
        const unsubscribe = getAllChats(isAdmin, userId, searchValue, setIsJobList, setJobId, setChatList);
        return () => {
          if (unsubscribe instanceof Promise) {
            unsubscribe.then(unsub => {
              if (typeof unsub === 'function') {
                unsub();
              }
            });
          } else if (typeof unsubscribe === 'function') {
            unsubscribe();
          }
        };
      }, [isAdmin, userId, searchValue]);
    
      const getAllChats = async (isAdmin, userId, searchValue, setIsJobList, setJobId, setChatList) => {
        try {
          const chatsRef = collection(db, 'chats');
          let chatDocs = [];
          let q1, q2;
      
          if (isAdmin) {
            // Admin: Fetch all chat documents based on searchValue (if provided)
            if (searchValue) {
              q1 = query(chatsRef, where("jobTitle", ">=", searchValue), where("jobTitle", "<=", searchValue + "\uf8ff"));
            } else {
              q1 = chatsRef; // Fetch all chats if no searchValue is provided
            }
          } else {
            // Non-admin: Fetch chats where senderId or receiverId matches the userId based on searchValue (if provided)
            if (searchValue) {
              q1 = query(chatsRef, where("jobTitle", ">=", searchValue), where("jobTitle", "<=", searchValue + "\uf8ff"));
              q2 = query(chatsRef, where("jobTitle", ">=", searchValue), where("jobTitle", "<=", searchValue + "\uf8ff"));
            } else {
              q1 = query(chatsRef, where("senderId", "==", userId));
              q2 = query(chatsRef, where("receiverId", "==", userId));
            }
          }
      
          // Fetch initial data
          if (searchValue) {
            const searchSnapshot = await getDocs(q1);
            chatDocs = searchSnapshot.docs;
          } else if (!isAdmin) {
            const [senderSnapshot, receiverSnapshot] = await Promise.all([getDocs(q1), getDocs(q2)]);
            chatDocs = [...senderSnapshot.docs, ...receiverSnapshot.docs];
          } else {
            const allChatsSnapshot = await getDocs(q1);
            chatDocs = allChatsSnapshot.docs;
          }
      
          // Extract data from initial chat documents
          let chats = chatDocs.map(doc => doc.data());
      
          // Sort chats based on the modifiedOn field
          const sortedChats = chats.sort((a, b) => b?.modifiedOn?.toDate() - a?.modifiedOn?.toDate());
      
          // Filter chats based on senderId and receiverId
          let filteredChats = [];
          if(isAdmin){
            filteredChats = sortedChats;
          }else{
            filteredChats = sortedChats.filter(chat => chat.senderId === userId || chat.receiverId === userId);
          }
          // const filteredChats = sortedChats.filter(chat => chat.senderId === userId || chat.receiverId === userId);
          // Set initial state with sorted and filtered chats
          setJobId(id ? id : filteredChats.length > 0 ? filteredChats[0]?.jobId : null);
          setChatList(filteredChats);
          setIsJobList(false);
      
          // Real-time listeners
          const unsubscribeListeners = [];
      
          const handleSnapshot = (snapshot) => {
            snapshot.docChanges()?.forEach(change => {
              const updatedChat = change.doc.data();
              if (change.type === 'added' || change.type === 'modified') {
                setChatList(prevChats => {
                  // Update chats if they already exist or add new chats if not present
                  let updatedChats = prevChats.map(chat => {
                    if (chat.jobId === updatedChat.jobId) {
                      return updatedChat; // Replace existing chat with updated one
                    }
                    return chat; // Keep other chats unchanged
                  });
          
                  // Add the chat if it doesn't already exist
                  if (!prevChats.some(chat => chat.jobId === updatedChat.jobId)) {
                    updatedChats.push(updatedChat);
                  }
          
                  // Sort chats based on the modifiedOn field
                  updatedChats = updatedChats.sort((a, b) => b?.modifiedOn?.toDate() - a?.modifiedOn?.toDate());
          
                  // Conditionally filter chats based on isAdmin status
                  if (!isAdmin) {
                    // Apply filter when not an admin
                    return updatedChats.filter(chat => chat.senderId === userId || chat.receiverId === userId);
                  }
          
                  // When isAdmin is true, don't filter the chats
                  return updatedChats;
                });
              }
              if (change.type === 'removed') {
                setChatList(prevChats => prevChats.filter(chat => chat.jobId !== updatedChat.jobId));
              }
            });
          };
      
          if (isAdmin) {
            const unsubscribeAdmin = onSnapshot(q1, handleSnapshot);
            unsubscribeListeners.push(unsubscribeAdmin);
          } else {
            const unsubscribeSender = onSnapshot(q1, handleSnapshot);
            unsubscribeListeners.push(unsubscribeSender);
            if (!searchValue) {
              const unsubscribeReceiver = onSnapshot(q2, handleSnapshot);
              unsubscribeListeners.push(unsubscribeReceiver);
            }
          }
      
          // Clean up listeners on component unmount or as needed
          return () => unsubscribeListeners?.forEach(unsubscribe => unsubscribe());
        } catch (error) {
          console.error("Error getting documents:", error);
        }
      };
      
  
  // console.log('jobId:::::::::::::::::::::',jobId)
      const handleSelectionChat=(job_id)=>{
        console.log("jobbbbbbbiidddd",job_id)
        setJobId(job_id)
        setSelectedChatJobId(job_id)
      }
    useEffect(()=>{
        loadScript("js/custom.js")
    })
    const fetchJobList=async()=>{
      let result={
        data:{
          content:[]
        }
      }
      if(userInfo?.roleEntities[0]?.roleType=='ROLE_USER'){
      const fetchJobs = async (api) => {
        const commonParams=`&category=All&sort=createdDate&offset=0&page=$1&size=100&searchkey=${
        searchValue
      }`
        return apiCall(api + commonParams, "GET", null, TOKEN);
      };
      const api1 = `jobs/applied2?jobEntity.jobStatus=eOpen`;
      const api2 = `jobs/applied2?jobEntity.jobStatus=eAccepted&hired=true`;
      const api3 = `jobs/applied2?jobEntity.jobStatus=eInProgress`;
      const [result1, result2, result3] = await Promise.all([
        fetchJobs(api1),
        fetchJobs(api2),
        fetchJobs(api3),
      ]);

      result = {
        data: {
          content: [
            ...(Array.isArray(result1?.data?.content) ? result1.data.content : []),
            ...(Array.isArray(result2?.data?.content) ? result2.data.content : []),
            ...(Array.isArray(result3?.data?.content) ? result3.data.content : []),
          ],
          totalElements:
            (result1?.data?.totalElements || 0) + 
            (result2?.data?.totalElements || 0) + 
            (result3?.data?.totalElements || 0),
          totalPages: Math.max(
            result1?.data?.totalPages || 0,
            result2?.data?.totalPages || 0,
            result3?.data?.totalPages || 0
          ),
        },
      }
    }else if(userInfo?.roleEntities[0]?.roleType=='ROLE_INSTITUTION_ADMIN'){
        let apiBase = `${`institutions/jobs?jobStatus=eInProgress&jobStatus=eAccepted&category=All&sort=createdDate,&offset=0&page=$1&size=100&searchkey=${
          searchValue
          }`}`
          result = await apiCall(apiBase, "GET", null, TOKEN);
      }
      setJobLists(result?.data?.content)
      // console.log('')
    }
    // console.log('jobId:::::::::::::::::',jobId)
    return (
        <>
        <div>
          <Helmet>
            <title>{`Chat | ${formatCompanyName()}`}</title>
            <meta name="description" content="" />
            <meta name="keywords" content="chat" />
          </Helmet>
        </div>
        <div className="section-full p-t120  p-b90 site-bg-white">
            <div className="twm-right-section-panel site-bg-gray">
                {/* <div className="wt-admin-dashboard-msg-2  twm-dashboard-style-2" style={{maxWidth:"1470px", margin:"0 auto"}}> */}
                <div className="wt-admin-dashboard-msg-2  twm-dashboard-style-2" style={{maxWidth:"1470px", margin:"0 auto"}}> 
                    {/*Left Msg section*/}
                    <SectionCanChatList jobId={jobId} chatList={chatList} handleSelectionChat={handleSelectionChat} setIsJobList={setIsJobList} isJobList={isJobList} setSearchValue={setSearchValue} searchValue={searchValue} getAllChats={getAllChats}/>

                    {/*Right Msg section*/}
                    {isJobList?
                    <div className="twm-right-section-panel candidate-save-job site-bg-gray" style={{width:'100%'}}>
                    <CanJobList isLoading={false} jobLists={jobLists} getAllChats={getAllChats} setIsJobList={setIsJobList} isJobList={isJobList} setJobId={setJobId}/>
                    </div>
                    :jobId?<SectionCanChat jobId={jobId}  getAllChats={getAllChats} selectedChatJobId={selectedChatJobId}/>
                    : <div className="wt-dashboard-msg-box">
                      <div style={{ display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh', // Make the container take the full height of the viewport
                        textAlign: 'center'}}>
                        No conversations available here </div>
                        </div>
                    }

                </div>
            </div>
        </div>

        </>
    )
}

export default CanChatPage;