// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { isTokenExpired } from "./authUtilities";

// const TokenChecker = ({ children }) => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const checkToken = () => {
//       if (isTokenExpired()) {
//         navigate("/session-expire");
//       }
//     };

//     checkToken();
//   }, [navigate]);

//   return <>{children}</>;
// };

// export default TokenChecker;

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { isTokenExpired } from "./authUtilities";
import { checkSession } from "../../../App";
import { useDispatch, useSelector } from "react-redux";
 
const TokenChecker = ({ children }) => {
  const { loginTimeStamp } = useSelector(state => state.menu);

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation();
  const [redirected, setRedirected] = useState(false);
 
  const unrestrictedRoutes = [
    "/",
    "/index",
    "/sign-up",
    "/sign-up/",
    "/app/user/activate/",
    "/app/user/activate",
    "/app/user/reset-password/",
    "/app/user/reset-password",
    "/job-list",
    "/job-grid",
    "/job-grid-with-map",
    "/job-list/",
    "/job-grid/",
    "/job-grid-with-map/",
    "/delete-account",
    "/delete-account/",
    "/job-detail",
    "/job-detail/",
    "/privacy-policy",
    "/privacy-policy/",
    "/contact-us",
    "/contact-us/",
  ];
 
  // Regular expression to match /job-detail/:id pattern
  const jobDetailRegex = /^\/job-detail\/[^/]+\/?$/;
 
  const isUnrestrictedRoute = (pathname) => {
    return unrestrictedRoutes.includes(pathname) || jobDetailRegex.test(pathname);
  };
 
  useEffect(() => {
    const checkToken = () => {
        console.log("session token checker",checkSession(loginTimeStamp,dispatch))
        if (checkSession(loginTimeStamp,dispatch) && !isUnrestrictedRoute(location.pathname)) {
        if (!redirected) {
          setRedirected(true);
          navigate("/session-expire");
        }
      } else {
        setRedirected(false);
      }
    };
 
    checkToken();
  }, [navigate, redirected, location.pathname]);
 
  useEffect(() => {
    // Reset the redirected state when the user navigates to an unrestricted route
    if (isUnrestrictedRoute(location.pathname)) {
      setRedirected(false);
    }
  }, [location.pathname]);
 
  return <>{children}</>;
};
 
export default TokenChecker;