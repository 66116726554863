import React, { useState } from "react";
import Toast from "../../../../common/toast";
import Spinner from "../../../../common/spinner";
import { useSelector } from "react-redux";
import apiCall from "../../../../../apicall";

const ConfirmationDeleteJobPopUp = ({ jobId, fetchjobList, setErrSuccessMsg }) => {
  const { TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [isJobDeteleted, setIsJobDeteleted] = useState(false);

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setIsJobDeteleted(false);
  };

  const handleJobDelete = async () => {
    try {
      setIsLoading(true);
      const deleteJobResponse = await apiCall(
        `institutions/jobs/${jobId}`,
        "DELETE",
        null,
        TOKEN
      );
      console.log("deleteJobResponse----", deleteJobResponse);
      if (deleteJobResponse?.status === 200) {
        setIsLoading(false);
        setIsJobDeteleted(true);
        const modalElement = document.getElementById('delete_job_confirmation_popup');
          if (modalElement) {
            const modal = window.bootstrap.Modal.getInstance(modalElement);
            if (modal) {
              modal.hide();
            }
          }

          setErrSuccessMsg({
            message: "Job deleted successfully!",
            status: true,
          });
        
        setTimeout(() => {
          fetchjobList();
        }, 1000);
      } else if (deleteJobResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
      } else {
        displayErrMsg(deleteJobResponse?.response?.data?.message);
      }
    } catch (err) {
      console.log("setSessionExpired--", err);
      displayErrMsg("Something went wrong!");
    }
  };

//   const getTheOneWeekAfterDate = () => {
//     const currentDate = moment();
//     const oneWeekLater = moment(currentDate).add(1, "weeks");
//     return oneWeekLater.format("MM/DD/YYYY");
//   };

  return (
    <div
      className="modal fade twm-sign-up"
      id="delete_job_confirmation_popup"
      aria-hidden="true"
      aria-labelledby="deleteJobPopupLabel"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="deleteJobPopupLabel">
              Are you sure you want to delete the job?
            </h2>
            {/* <h2 className="modal-title" id="sign_up_popupLabel2">
              {isJobDeteleted
                ? "Job Deleted Successfully"
                : "Are you sure you want to delete the job?"}
            </h2> */}
            {/* <p>
              {isJobDeteleted
                ? `The account wiil be deleted on
              ${getTheOneWeekAfterDate()}`
                : `if you confirm to 'delete account' the account wiil be deleted on
              ${getTheOneWeekAfterDate()}`}
            </p> */}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={()=> setIsJobDeteleted(false)}
            />
          </div>
          <div className="modal-footer">
            <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={()=> setIsJobDeteleted(false)}
              >
                No
            </button>
            {/* {isJobDeteleted ? (
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={()=> setIsJobDeteleted(false)}
              >
                Ok
              </button>
            ) : ( */}
              <>
                {/* <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button> */}
                <button
                  type="button"
                  id="deleteConfirmationModal"
                  className="btn btn-success"
                  // data-bs-dismiss="modal"
                  onClick={handleJobDelete}
                >
                  {isLoading ? <Spinner /> : "Yes"}
                </button>
              </>
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDeleteJobPopUp;
