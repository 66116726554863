import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  logout,
  userDetails,
  roleType,
  navigateVisitedPage,
  addressSelectedStatus,
  selectedCategoryType,
  jobStatusDetails,
} from "../../../store/reducers/menu";

const ConfirmationLogoutAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout(null));
    dispatch(userDetails(null));
    dispatch(roleType([]));
    dispatch(navigateVisitedPage(null));
    dispatch(addressSelectedStatus(null));
    dispatch(selectedCategoryType("All"));
    dispatch(jobStatusDetails(null));
    // Close the popup synchronously
    document.body.style.overflow = "unset";
    document.body.style.paddingRight = "0";
    document
      .getElementById("logout_account_confirmation_popup")
      .classList.remove("show");
    document.body.classList.remove("modal-open");

    let modalBackdrop = document.querySelector(".modal-backdrop");
    if (modalBackdrop) {
      modalBackdrop.remove();
    }

    document
      .getElementById("logout_account_confirmation_popup")
      .setAttribute("aria-hidden", "true");
    document
      .getElementById("logout_account_confirmation_popup")
      .removeAttribute("aria-modal");
    document.getElementById("logout_account_confirmation_popup").style.display =
      "none";
    // localStorage.clear();

    const modal = document.getElementById("logout_account_confirmation_popup");
    const modalInstance = window.bootstrap.Modal.getInstance(modal);
    modalInstance.hide();


    navigate("/index");
  };

  

  return (
    <div
      className="modal fade twm-sign-up"
      id="logout_account_confirmation_popup"
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="sign_up_popupLabel2">
              Are you sure you want to<br></br> log out ?
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              id="deleteConfirmationModal"
              className="btn btn-success "
              onClick={handleLogout}
              style={{width:'', display:''}}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationLogoutAccount;
