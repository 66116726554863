import React, { useEffect } from "react";
import { userDetails, roleType, logout } from "../../store/reducers/menu";
import { useDispatch } from "react-redux";

const SessionExpired = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    //to logout user automatically
    dispatch(logout(null));
    dispatch(userDetails(null));
    dispatch(roleType([]));

    const body = document.body;
    const backdrop = document.createElement("div");
    backdrop.className = "modal-backdrop fade show";
    body.appendChild(backdrop);

  }, []);

  const handleLoginClick = () => {
    // Close the SessionExpired modal
    const sessionExpiredModal = document.getElementById("session_expired");
    if (sessionExpiredModal) {
      sessionExpiredModal.classList.remove("show");
      sessionExpiredModal.style.display = "none";
    }

    // Show the SignInPopup modal
    const signInPopupModal = document.getElementById("sign_up_popup2");
    if (signInPopupModal) {
      signInPopupModal.classList.add("show");
      signInPopupModal.style.display = "block";
    }
  };

  return (
    <div
      className="modal fade twm-sign-up show"
      id="session_expired"
      aria-hidden="true"
      aria-labelledby="session_expired_popupLabel2"
      tabIndex={-1}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      style={{ display: "block", padding: 0 }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="session_expired_popupLabel2">
              Session Expired
            </h2>
            <p>
              Your session is expired. Please log back in to continue accessing
              the platform.
            </p>
          </div>
          <div className="modal-body">
            <div className="twm-tabs-style-2">
              <div className="row">
                <div className="col-lg-12"></div>
                <div className="col-md-12">
                  <button
                    type="submit"
                    className="site-button"
                    data-bs-dismiss="modal"
                    onClick={handleLoginClick}
                  >
                    Log in
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionExpired;
