import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { HashRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider as ReduxProvider } from "react-redux";
import { persistor, store } from "./store";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import TokenChecker from "./app/common/token-verify/tokenChecker";
import { HelmetProvider } from "react-helmet-async";
const root = ReactDOM.createRoot(document.getElementById("root"));
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


root.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HashRouter basename="/">
      <HelmetProvider>
        {/* <Elements stripe={stripePromise}> */}
          <TokenChecker>
            <App />
          </TokenChecker>
        {/* </Elements> */}
      </HelmetProvider>
      </HashRouter>
    </PersistGate>
  </ReduxProvider>
);
