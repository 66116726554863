import React, { useState } from "react";
import { useSelector } from "react-redux";
import Toast from "../../../../common/toast";
import apiCall from "../../../../../apicall";
import Spinner from "../../../../common/spinner";

const SendNotification = ({ applicantId }) => {
  const { TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
  };

  const handleUserDelete = async () => {
    try {
      setIsLoading(true);
      const notifyUserResponse = await apiCall(
        `openstaff/jobs/notifyUser`,
        "POST",
        { title, message, applicantId }, // Pass title, message, and applicantId
        TOKEN
      );
      console.log("notifyUserResponse----", notifyUserResponse);
      if (notifyUserResponse?.status === 200) {
        setIsLoading(false);
        setErrSuccessMsg({
          message: "Notification sent successfully!",
          status: true,
        });
        setTitle("");
        setMessage("");
      } else if (notifyUserResponse?.response?.status == 401) {
        displayErrMsg("Your session has expired!");
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(notifyUserResponse?.response?.data?.message)
      }
    } catch (err) {
      console.log("handleUserDelete error--", err);
      displayErrMsg("Something went wrong!");
    }
  };

  return (
    <div
      className="modal fade twm-sign-up"
      id="send_notification"
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="sign_up_popupLabel2">
              Send Notification
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Title:
              </label>
              <input
                type="text"
                id="title"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message:
              </label>
              <textarea
                id="message"
                className="form-control"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleUserDelete}
              disabled={!title || !message}
            >
              {isLoading ? <Spinner /> : "Send"}
            </button>
          </div>
        </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </div>
  );
};

export default SendNotification;
