import React from "react";
import JobZImage from "../../../../common/jobz-img";
import { publicUser } from "../../../../../globals/route-names";
import { NavLink } from "react-router-dom";

const JobNotFound = () => {
  const sliderValue = localStorage.getItem("sliderValue");

  return (
    <div className="section-full p-t120  p-b90 site-bg-white">
      <div className="container">
        <div className="twm-error-wrap">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="twm-error-image">
                <JobZImage src="images/error-404.png" alt="#" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="twm-error-content">
                <h2 className="twm-error-title" style={{ fontSize: "60px" }}>
                  Job Not Found
                </h2>
                <h4 className="twm-error-title2 site-text-primary">
                  We Are Sorry, Job Not Found
                </h4>
                <p>
                We regret to inform you that the position you are looking for is not currently available within the radius you have selected. Please follow the link below to view job opportunities in your nearby area.
                </p>
                <NavLink to="/search-job" className="site-button">
                  Go To Nearest Jobs
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobNotFound;
