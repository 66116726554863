import BASE_URL from './config'
import axios from 'axios';

const apiCall = async (endpoint, method, data, TOKEN, isFormData ,isBlob, isTextType) => {
  const url = `${BASE_URL}${endpoint}`;
  const headers = {
    // Accept: "*/*",
    "Content-Type": isFormData ? "multipart/form-data" : isTextType?'text/plain':"application/json",
   
  };
  console.log("token-----",isTextType)
  if (TOKEN) {
    headers.Authorization = "Bearer " + TOKEN;
  }
  // if(roleRequired){
  //   headers.role = localStorage?.role_type
  // }

  try {
    const response = await axios({
      method,
      url,
      headers,
      data: method !== 'GET' ? (isTextType ? data : (isFormData ? data : JSON.stringify(data))) : null,
      responseType: isBlob ? 'blob' : 'json', 
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 403&&error.response?.data?.message=="Access Denied") {
      // Navigate to the home page if the status is 403
      setTimeout(() => {
        localStorage.clear()
        window.location.href='/'
      }, 1000); // 1-second delay
      console.log('error::::::::::::::::',error)
      return {response:{data:{message:'Your login session has been expired!!', status:403}}};
    }
    else{
      return error;
    }
  }
};

export default apiCall;
