import React, { useState } from "react";
import Toast from "../toast";
import Spinner from "../spinner";
import { useSelector } from "react-redux";
import apiCall from "../../../apicall";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";

const ConfirmationRegistrationPopUp = () => {
  const { TOKEN } = useSelector((state) => state.menu);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isJobDeteleted, setIsJobDeteleted] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  // const navigateToJobDetail = () => {
  //   console.log("navigateToJobDetail=====")
  //   navigate("/job-detail", { state: {id: registerResponseData} });
  // };
  const handleAddMoreJobs = () => {
    const ConfirmationBackDrop = document.getElementById("modalBackdropregister");
    const ConfirmationPopupModal = document.getElementById("confirmationRegistrationPopup");
        if (ConfirmationPopupModal) {
          ConfirmationPopupModal.classList.remove("show");
          ConfirmationBackDrop.classList.remove("show");
          ConfirmationBackDrop.style.zIndex = "0";
          ConfirmationBackDrop.style.display = "none";
          ConfirmationPopupModal.style.display = "none";
        }

        navigate("/");
  };
  const navigateToHomePage = () => {
    navigate("/");
  };
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      <div
      className="modal fade twm-sign-up"
      id="confirmationRegistrationPopup"
      tabIndex={-1}
      // role="dialog"
      aria-labelledby="sign_up_popupLabel2"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content" style={{ zIndex: "1000" }}>
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={handleAddMoreJobs}
              aria-label="Close"
            />
            <h2 className="modal-title" id="sign_up_popupLabel2">
              Thank You !
            </h2>
            <h3>User Registered Successfully</h3>
          </div>
          <div className="modal-body" style={{"textAlign": "center"}}>
            <h4>We have sent you an email with a link to create your password and activate your account. Please check your inbox and follow the instructions provided.</h4>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
              onClick={navigateToHomePage}
            >
              Ok
            </button>
            {/* <a
              className="twm-nav-post-a-job site-button"
              data-bs-toggle="modal"
              href="#sign_up_popup2"
              role="button"
              onClick={handleConfirmationPopup}
            >
              <i className="feather-log-in" /> Sign In
            </a> */}


          </div>
        </div>
      </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
      <div id="modalBackdropregister" class="modal-backdrop fade" style={{zIndex: "0", display: "none"}}></div>
    </>

  );
};

export default ConfirmationRegistrationPopUp;
