import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  TOKEN: null,
  userInfo: null,
  role_type: [],
  isAddressSelected: false,
  CONFIGKEY: null,
  selectedCategoryType: "All",
  cityListData: [],
  navigate_visited_page: null,
  theme_colors:[],
  company_logo:'',
  small_company_logo:'',
  base_url:process.env.REACT_APP_BASE_URL,
  jobDetailsData: {},
  candidateDetails: {},
  jobDetailsId: {},
  employerDetails: {},
  contactPersonInfo: {
    contactPersonPhone : '',
    contactPerson: ''
  },
  loginTimeStamp: "",
};

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.TOKEN = action.payload;
    },
    logout: (state, action) => {
      state.isLoggedIn = false;
      state.TOKEN = action.payload;
    },
    userDetails: (state, action) => {
      state.userInfo = action.payload;
    },
    roleType: (state, action) => {
      state.role_type = action.payload;
    },
    navigateVisitedPage: (state, action) => {
      state.navigate_visited_page = action.payload;
    },
    addressSelectedStatus: (state, action) => {
      state.isAddressSelected = action.payload;
    },
    selectedCategoryType: (state, action) => {
      state.selectedCategoryType = action.payload;
    },
    cityListDetails: (state, action) => {
      state.cityListData = action.payload;
    },
    jobStatusDetails: (state, action) => {
      state.job_status = action.payload;
    },
    currentUrlDetails: (state, action) => {
      state.current_url = action.payload;
    },
    canActiveTabFunc: (state, action) => {
      state.canActiveTab = action.payload;
    },
    configKey: (state, action) => {
      state.CONFIGKEY = action.payload;
    },
    themeColors: (state, action) => {
      state.theme_colors = action.payload;
    },
    companyLogo: (state, action) => {
      state.company_logo = action.payload;
    },
    smallcompanyLogo: (state, action) => {
      state.small_company_logo = action.payload;
    },
    baseURL: (state, action) => {
      state.base_url = action.payload;
    },
    jobDetailsData: (state, action) => {
      state.jobDetailsData = action.payload;
    },
    candidateDetails: (state, action) => {
      state.candidateDetails = action.payload;
    },
    jobDetailsId: (state, action) => {
      state.jobDetailsId = action.payload;
    },
    employerDetails: (state, action) => {
      state.employerDetails = action.payload;
    },
    postJobContactPersonDetails: (state, action) => {
      state.contactPersonInfo = action.payload;
    },
    setLoginTimeStamp: (state, action) => {
      state.loginTimeStamp = action.payload;
    },
  },
})

export default menu.reducer;

export const {
  login,
  logout,
  userDetails,
  roleType,
  addressSelectedStatus,
  selectedCategoryType,
  cityListDetails,
  jobStatusDetails,
  currentUrlDetails,
  navigateVisitedPage,
  configKey,
  companyLogo,
  smallcompanyLogo,
  themeColors,
  baseURL,
  canActiveTabFunc,
  jobDetailsData,
  candidateDetails,
  jobDetailsId,
  employerDetails,
  postJobContactPersonDetails,
  setLoginTimeStamp
} = menu.actions;
