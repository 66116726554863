import React from 'react';
import { NavLink } from 'react-router-dom';

const SectionPagination = ({ currentPage, setCurrentPage, totalPages }) => {
  const handleClick = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // Maximum number of pages to show in the pagination
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (totalPages > maxPagesToShow) {
      if (endPage - startPage + 1 < maxPagesToShow) {
        startPage = endPage - maxPagesToShow + 1;
      }
      if (startPage > 1) {
        pageNumbers.push(
          <li key="1">
            <NavLink to={`#`}  onClick={() => handleClick(1)}>1</NavLink>
          </li>
        );
        if (startPage > 2) {
          pageNumbers.push(
            <li key="ellipsis1" className="ellipsis">
              <span>...</span>
            </li>
          );
        }
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i} className={currentPage === i ? 'active' : ''}>
          <NavLink to={`#`}  onClick={() => handleClick(i)}>{i}</NavLink>
        </li>
      );
    }

    if (totalPages > maxPagesToShow) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <li key="ellipsis2" className="ellipsis">
            <span>...</span>
          </li>
        );
      }
      if (endPage < totalPages) {
        pageNumbers.push(
          <li key={totalPages}>
            <NavLink to={`#`}  onClick={() => handleClick(totalPages)}>{totalPages}</NavLink>
          </li>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <div className="pagination-outer">
      <div className="pagination-style1">
        <ul className="clearfix">
          <li className={currentPage === 1 ? 'prev disabled' : 'prev'}>
            <NavLink to={`#`}  onClick={() => handleClick(currentPage - 1)}>
              <span><i className="fa fa-angle-left" /></span>
            </NavLink>
          </li>
          {renderPageNumbers()}
          <li className={currentPage === totalPages ? 'next disabled' : 'next'}>
            <NavLink to={`#`}  onClick={() => handleClick(currentPage + 1)}>
              <span><i className="fa fa-angle-right" /></span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionPagination;
