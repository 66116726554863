import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Toast from "../../../../common/toast";
import SessionExpired from "../../../../common/sessionExpired";
import apiCall from "../../../../../apicall";
import ClockInClockOutAdmin from "./clockin-clockout-admin";
import moment from "moment";
import Spinner from "../../../../common/spinner";
import ClockinClockoutPopup from "./clockin-clockout-popup";


const ClockInClockOut = ({ jobDetailsInfo, getJobDetailsData }) => {
  const { TOKEN, role_type } = useSelector((state) => state.menu);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [clockinClockoutLoader, setClockinClockoutLoader] = useState(false);
  const [isClockInLoading, setIsClockInLoading] = useState(false);
  const [isClockOutLoading, setIsClockoutLoading] = useState(false);
  const [clockinClockoutList, setClockInClockOutList] = useState([]);
  const [lastClockinClockoutItem, setLastClockinClockoutItem] = useState({});
  const[selectedClockinClockout, setSelectedClockinClockout] = useState([])
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  // console.log("hhhhh", jobDetailsInfo?.totalHours)

  const calculateTotalWorkingHour = (jobDetails_Data) => {
    const startDate = moment.utc(jobDetails_Data?.startTime); // Assuming start date is April 11, 2024, at 12:00 PM
    const endDate = moment.utc(jobDetails_Data?.endTime); // Assuming end date is April 12, 2024, at 2:30 PM
    const diffDuration = moment.duration(endDate.diff(startDate));
    const hours = diffDuration.hours();
    const minutes = diffDuration.minutes();
    return hours + " hours " + minutes + " minutes";
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
  };

  const handleEditClick = (record) =>{
      console.log("recordd=======",record)
      setSelectedClockinClockout(record)
  }

  const handleDeleteClick = (record) =>{
    console.log("recordd=======",record)
    setSelectedClockinClockout(record)
}

  const displayClockinoutErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
  };
  const ConvertInto_Hrs = (hrs) => {
    var num = hrs
    console.log("num:::::", num)
    var str = num.toString();
    var numarray = str.split('.');
    var a = new Array();
    a = numarray;
    let minuteVal = Number(a[1]) / 60
    let strFnl = a[0] + (minuteVal.toString()).substring(1)
    return Number(strFnl).toFixed(2);
  }

  const getClockinClockout = async () => {
    try {
      setClockinClockoutLoader(true);
      const url = `${role_type.includes('ROLE_USER') ?
          `jobs/${jobDetailsInfo?.id}/clockinClockouts` :
          (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN"))
            ? `openstaff/jobs/${jobDetailsInfo?.id}/applicantClockinClockouts`
            : role_type.includes("ROLE_INSTITUTION_ADMIN")
              ? `institutions/jobs/${jobDetailsInfo?.id}/applicantClockinClockouts`
              : ""
        }`;

      // const url = `jobs/${jobDetailsInfo?.id}/clockinClockouts`;
      const clockinClockoutResponse = await apiCall(url, "GET", null, TOKEN);
      console.log("add clockin clockout response", clockinClockoutResponse);
      if (clockinClockoutResponse?.status === 200) {
        const clockinoutres = clockinClockoutResponse.data.content;
        setClockinClockoutLoader(false);
        setClockInClockOutList(clockinoutres);
        if (clockinoutres?.length > 0) {
          setLastClockinClockoutItem(clockinoutres[clockinoutres.length - 1]);
        }
      } else if (clockinClockoutResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
        setSessionExpired(true);
        setClockinClockoutLoader(false);
      } else {
        setSessionExpired(false);
        // displayErrMsg("Something went wrong!");
        displayErrMsg(clockinClockoutResponse?.response?.data?.message);
        setClockinClockoutLoader(false);
      }
      // Clear the map and address
    } catch (err) {
      setSessionExpired(false);
      setClockinClockoutLoader(false);

      displayErrMsg("Something went wrong!");
    }
  };

  const clockinClick = async (timeinout) => {
    const url = `openstaff/jobs/${jobDetailsInfo?.id}/clockIn/${jobDetailsInfo?.hirePerson?.id}`;

    const clockinData = {
      // arrivalTime: moment.utc(new Date()).format('yyyy-MM-DD HH:mm:ss'),
      arrivalTime: moment.utc(timeinout).format('yyyy-MM-DD HH:mm:ss'),
      // location: { latitude: 29.749907, longitude: -95.358421 },
      notes: "",
    };

    console.log("clockin param",clockinData)

    try {
      setIsClockInLoading(true);
      const clockinResponse = await apiCall(url, "PUT", clockinData, TOKEN);
      console.log("clockinResponse--", clockinResponse);
      if (clockinResponse?.status === 200) {
        setIsClockInLoading(false);
        setSessionExpired(false);
        setErrSuccessMsg({
          message: "Clocked in Successfully!",
          status: "Success",
        });
        // getClockinClockout();
        getJobDetailsData();
        return true;
      } else if (clockinResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
        setSessionExpired(true);
        setIsClockInLoading(false);
        return false;
      } else if (clockinResponse?.response?.status === 409) {
        setSessionExpired(false);
        displayErrMsg(clockinResponse?.response?.data?.errorMessage);
        setIsClockInLoading(false);
        return false;
      } else {
        setSessionExpired(false);
        // displayErrMsg("Something went wrong!");
        displayErrMsg(clockinResponse?.response?.data?.message);
        setIsClockInLoading(false);
        return false;
      }
    } catch (err) {
      setSessionExpired(false);
      displayErrMsg("Something went wrong!");
      setIsClockInLoading(false);
      return false;
    }
  };

  const clockoutClick = async (timeinout) => {
    const url = `openstaff/jobs/${jobDetailsInfo?.id}/clockOut/${jobDetailsInfo?.hirePerson?.id}`;
    const clockoutData = {
      // endTime: moment.utc(new Date()).format('yyyy-MM-DD HH:mm:ss'),
      endTime: moment.utc(timeinout).format('yyyy-MM-DD HH:mm:ss'),
      // location: { latitude: 29.749907, longitude: -95.358421 },
    };

    console.log("clockout param",clockoutData)


    try {
      setIsClockoutLoading(true);
      const clockoutResponse = await apiCall(url, "PUT", clockoutData, TOKEN);
      console.log("clockoutResponse====", clockoutResponse)
      if (clockoutResponse?.status === 200) {
        setIsClockoutLoading(false);
        setSessionExpired(false);
        setErrSuccessMsg({
          message: "Clocked out Successfully!",
          status: "Success",
        });
        // getClockinClockout();
        getJobDetailsData();
        return true;
      } else if (clockoutResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
        setSessionExpired(true);
        setIsClockoutLoading(false);
        return false;
      } else if (clockoutResponse?.response?.status === 409) {
        setSessionExpired(false);
        displayErrMsg(clockoutResponse?.response?.data?.errorMessage);
        setIsClockoutLoading(false);
        return false;
      } else {
        setSessionExpired(false);
        // displayErrMsg("Something went wrong!");
        displayErrMsg(clockoutResponse?.response?.data?.message);
        setIsClockoutLoading(false);
        return false;
      }
    } catch (err) {
      setSessionExpired(false);
      displayErrMsg("Something went wrong!");
      setIsClockoutLoading(false);
      return false;
    }
  };

  // Function to check if current time is between startTime and endTime
  const isCurrentTimeBetween = (startTimeISO, endTimeISO) => {
    const currentDate = new Date();
    const startTime = new Date(startTimeISO);
    const endTime = new Date(endTimeISO);
    return currentDate >= startTime && currentDate <= endTime;
  };

  // Function to handle clock-out based on job details
  const handleClockInClockOut = (clockinout_type, timeinout) => {
    const startTime = moment.utc(jobDetailsInfo?.startTime).local();
    const endTime = moment.utc(jobDetailsInfo?.endTime).local();
    const singleDay = moment(startTime).isSame(moment(endTime), "day");

    console.log("singleDay=====", singleDay, clockinout_type)
    // if (isCurrentTimeBetween(startTime, endTime) && singleDay) {
    if (singleDay) {
      // If single-day job, check if current time is between start and end time
      if (clockinout_type === "clockin") {
        return clockinClick(timeinout);
      } else if (clockinout_type === "clockout") {
        return clockoutClick(timeinout);
      }
      // } else if (
      //   !singleDay &&
      //   moment().isBetween(
      //     startTime.startOf("day"),
      //     endTime.endOf("day"),
      //     null,
      //     "[]"
      //   )
      // ) {
    } else if (
      !singleDay
      // moment().isBetween(
      //   startTime.startOf("day"),
      //   endTime.endOf("day"),
      //   null,
      //   "[]"
      // )
    ) {
      // If multi-day job, check if current time is between or equal to start time or end time
      if (clockinout_type === "clockin") {
        return clockinClick(timeinout);
      } else if (clockinout_type === "clockout") {
        return clockoutClick(timeinout);
      }
    }
    // else {
    //   displayClockinoutErrMsg(
    //     `${
    //       clockinout_type === "clockin" ? "Clock in" : "Clock out"
    //     } time must be between ${moment
    //       .utc(jobDetailsInfo?.startTime, "YYYY-MM-DD HH:mm:ss")
    //       .local()
    //       .format("hh:mm A")} and ${moment
    //         .utc(jobDetailsInfo?.endTime, "YYYY-MM-DD HH:mm:ss")
    //         .local()
    //         .format("hh:mm A")}!`
    //   );
    // }
  }

  const handleUpdateClockinClockout =async (formValues, initialValues,selectedClockinClockout) =>{
    const requests = []; // Collect all the requests to send
    // Check if timeIn was updated
    if (formValues.timeIn && !moment(formValues.timeIn).isSame(initialValues?.timeIn)) {
      
      const updatedTimeInData = {
        arrivalTime: moment.utc(formValues.timeIn)
          .format("yyyy-MM-DD HH:mm:ss"),
        // location: {
        //   latitude: 29.749907,
        //   longitude: -95.358421,
        // },
        notes: "",
      };

      console.log("clockin params",updatedTimeInData)

      const timeInRequest = apiCall(
        `openstaff/jobs/${selectedClockinClockout?.checkinData?.id}/updateClockIn`,
        "PUT",
        updatedTimeInData,
        TOKEN
      );
      requests.push(timeInRequest);
    }

    // Check if timeOut was updated
    if (formValues.timeOut && !moment(formValues.timeOut).isSame(initialValues?.timeOut)) {
      const updatedTimeOutData = {
        endTime: moment.utc(formValues.timeOut).format("yyyy-MM-DD HH:mm:ss"),
        // location: { latitude: 29.749907, longitude: -95.358421 },
      };

      console.log("clockout params",updatedTimeOutData)


      const timeOutRequest = apiCall(
        `openstaff/jobs/${selectedClockinClockout?.checkoutData?.id}/updateClockIn`,
        "PUT",
        updatedTimeOutData,
        TOKEN
      );
      requests.push(timeOutRequest);
    }

    // Ensure at least one field is updated
    if (requests.length === 0) {
      displayErrMsg("Please update either Time In or Time Out.");
      return;
    }

    try {
      // Perform all API requests
      const responses = await Promise.all(requests);
      console.log("response of time in time out", responses);
      // Check responses for success or failure
      const allSuccessful = responses.every(
        (response) => response.status === 200
      );
      if (allSuccessful) {
        // getClockinClockout()
        setErrSuccessMsg({
          message: "Clock-in/Clock-out updated successfully.",
          status: true,
        });
      } else {
        displayErrMsg("Failed to update clock-in/clock-out.");
      }
    } catch (error) {
      displayErrMsg("An error occurred while updating the data.");
    }
}

  useEffect(() => {
    if (jobDetailsInfo?.id) {
      if (jobDetailsInfo?.jobStatus !== "eOpen" && jobDetailsInfo?.jobStatus !== "eCancelled") {
        getClockinClockout();
      }
    }
  }, [jobDetailsInfo]);

  return (
    <>
      {
        (role_type.includes("ROLE_OPENSTAFF_ADMIN")) && (jobDetailsInfo?.jobStatus !== "eOpen" && jobDetailsInfo?.jobStatus !== "eCancelled" && jobDetailsInfo?.jobStatus !== "eCompleted") &&
        <ClockinClockoutPopup
          jobDetailsInfo={jobDetailsInfo}
          lastClockinClockoutItem={lastClockinClockoutItem}
          isClockInLoading={isClockInLoading}
          isClockOutLoading={isClockOutLoading}
          handleClockInClockOut={handleClockInClockOut}
          errSuccessMesg={errSuccessMesg}
          clockinClockoutLoader={clockinClockoutLoader}
          selectedClockinClockout={selectedClockinClockout}
          handleUpdateClockinClockout={handleUpdateClockinClockout}
        />
      }
      {/* {
      (role_type.includes("ROLE_OPENSTAFF_ADMIN")) && (jobDetailsInfo?.jobStatus !== "eOpen" && jobDetailsInfo?.jobStatus !== "eCancelled" && jobDetailsInfo?.jobStatus !== "eCompleted")  &&
      <div class="container mb-5">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-auto">
          <p class="h4">{moment().format("dddd, MMMM D, YYYY")}</p>
        </div>
      </div>
      <div class="row justify-content-center align-items-center mt-2">
        <div class="col-md-auto text-center">
          <span class="text-muted">Clock-in</span>
          <span class="text-muted d-block" style={{ fontSize: 10 }}>
            {lastClockinClockoutItem?.checkinTime
              ? moment.utc(lastClockinClockoutItem?.checkinTime, "YYYY-MM-DD HH:mm:ss").local().format(
                  "MM-DD-YYYY"
                )
              : ""}
          </span>
          <p class="text-muted h4">
            {lastClockinClockoutItem?.checkinTime
              ? moment.utc(lastClockinClockoutItem?.checkinTime, "YYYY-MM-DD HH:mm:ss").local().format("hh:mm A")
              : "-"}
          </p>
        </div>
        <div class="col-md-auto text-center ml-5">
          {lastClockinClockoutItem?.checkinTime ? (
            <i
              class="fa fa-check-square"
              style={{
                color: "green",
                padding: "0px 30px",
                fontSize: "30px",
              }}
              aria-hidden="true"
            ></i>
          ) : (
            <button
              type="button"
              className="site-button"
              onClick={() => handleClockInClockOut("clockin")}
            >
              {isClockInLoading ? <Spinner /> : "Clock In"}
            </button>
          )}
        </div>
      </div>
      <div class="row justify-content-center align-items-center mt-3">
        <div class="col-md-auto text-center">
          <span class="text-muted">Clock-out</span>
          <p class="text-muted h4">-</p>
        </div>
        <div class="col-md-auto text-center ml-5">
          <button
            type="button"
            className="site-button"
            onClick={() => handleClockInClockOut("clockout")}
            disabled={lastClockinClockoutItem?.checkinTime ? false : true}
          >
            {isClockOutLoading ? <Spinner /> : "Clock Out"}
          </button>
        </div>
      </div>
    </div>
    } */}


      {jobDetailsInfo?.multipleDayJob ? (
        <div className="row m-b10 mb-5">
          <div className="col-md-6 col-12">
            <b>Start Date: </b>
            {moment(jobDetailsInfo?.startTime, "YYYY-MM-DD HH:mm:ss")
              .local()
              .format("MM-DD-YYYY")}
          </div>
          <div className="col-md-6 col-12">
            <b>End Date: </b>
            {moment(jobDetailsInfo?.endTime, "YYYY-MM-DD HH:mm:ss")
              .local()
              .format("MM-DD-YYYY")}
          </div>
          <div className="col-md-6 col-12">
            <b>Total Hours: </b> {jobDetailsInfo?.totalHours}
          </div>
          <div className="col-md-6 col-12">
            <b>Logged Hours: </b> {jobDetailsInfo?.totalWorkedHours ? jobDetailsInfo?.totalWorkedHours : "0"}
          </div>
        </div>
      ) : (
        <div className="row m-b10 mb-5">
          <div className="col-md-6 col-12">
            <b>Date: </b>
            {moment
              .utc(jobDetailsInfo?.startTime, "YYYY-MM-DD HH:mm:ss")
              .local()
              .format("MM-DD-YYYY")}
          </div>
          <div className="col-md-6 col-12">
            <b>Total Hours: </b>
            {jobDetailsInfo?.totalHours ? ConvertInto_Hrs(jobDetailsInfo?.totalHours) : "0"}
          </div>
          <div className="col-md-6 col-12">
            <b>Job Start: </b>
            {moment
              .utc(jobDetailsInfo?.startTime, "YYYY-MM-DD HH:mm:ss")
              .local()
              .format("hh:mm A")}
          </div>
          <div className="col-md-6 col-12">
            <b>Job End: </b>
            {moment
              .utc(jobDetailsInfo?.endTime, "YYYY-MM-DD HH:mm:ss")
              .local()
              .format("hh:mm A")}
          </div>
          {/* {
            (role_type.includes('ROLE_INSTITUTION_ADMIN') || role_type.includes('ROLE_USER')) && */}
          <div className="col-md-6 col-12">
            <b>Logged Hours: </b> {jobDetailsInfo?.totalWorkedHours}
          </div>
          {/* } */}

        </div>
      )}

      {(role_type.includes("ROLE_OPENSTAFF_ADMIN")) && (jobDetailsInfo?.jobStatus !== "eOpen" && jobDetailsInfo?.jobStatus !== "eCancelled" && jobDetailsInfo?.jobStatus !== "eCompleted") &&
        <div className="product-filter-wrap d-flex justify-content-between align-items-center">
          <h4 className="twm-s-title">Clock In/ Clock Out:</h4>
          <a
            className="site-button"
            data-bs-toggle="modal"
            href={"#clockin_clockout_popup"}
            role="button"
          >
            Add
          </a>
        </div>
      }
      {/* <p class="h4">Clock In/ Clock Out</p> */}

      {clockinClockoutList?.length > 0 ? (
        <ClockInClockOutAdmin
          clockinClockoutLoader={clockinClockoutLoader}
          clockinClockoutList={clockinClockoutList}
          jobDetailsInfo={jobDetailsInfo}
          getJobDetailsData={getJobDetailsData}
          selectedClockinClockout={selectedClockinClockout}
          handleDeleteClick={handleDeleteClick}
          handleEditClick={handleEditClick}
          handleUpdateClockinClockout={handleUpdateClockinClockout}
        />
      ) : (
        <div className="tw-faq-section mt-5">
          <div className="accordion tw-faq" id="sf-faq-accordion-2">
            <h3 style={{ color: "red" }}>
              No Job History Found!
            </h3>
          </div>
        </div>
      )}
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
      {sessionExpired && <SessionExpired />}
    </>
  );
};

export default ClockInClockOut;
