import processLogin from "../../form-processing/login";
import { formType } from "../../../globals/constants";
import { useNavigate } from "react-router-dom";
import {
  canRoute,
  candidate,
  empRoute,
  employer,
} from "../../../globals/route-names";
import { useState, useEffect } from "react";
import apiCall from "../../../apicall";
import Spinner from "../spinner";
import Toast from "../toast";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  userDetails,
  roleType,
  logout,
  configKey,
  addressSelectedStatus,
  navigateVisitedPage,
  setLoginTimeStamp
} from "../../../store/reducers/menu";
import { setTokenWithExpiry } from "../token-verify/authUtilities";
import BASE_URL from "../../../config";
import { publicUser } from "../../../globals/route-names";
import { NavLink, useLocation } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail
} from "firebase/auth";
import { auth, database } from "../firebase/firebase";
import { logEvent_Analytics } from "../firebase/firebase-analytics-service";
import {
  getDatabase,
  ref,
  query,
  orderByChild,
  equalTo,
  onValue,
} from "firebase/database";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/firebase";
function SignInPopup() {
  const { TOKEN, role_type, navigate_visited_page } = useSelector((state) => state.menu);
  let keyResponse = {};
  const localStorageUserName = localStorage.getItem("username") 
  const localStoragePassword = localStorage.getItem("password") 

  console.log("localStorageUserName-----",localStorageUserName,localStoragePassword)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "success",
  });
  const [empusername, setEmpUsername] = useState(localStorageUserName ? localStorageUserName : '');
  const [password, setPassword] = useState(localStoragePassword ? localStoragePassword : '');
  const [formData, setFormData] = useState({
    userName: localStorageUserName ? localStorageUserName : null,
    password: localStoragePassword ? localStoragePassword : null,
    appType: "portal",
    deviceToken: "string",
    mobileDeviceType: "IOS",
    rememberMe: true,
  });
  const [errorMessages, setErrorMessages] = useState({
    userName:"",
    password:"",
    userEmail: "",
  });
  const [forgotPasswordShow, setForgotPasswordShow] = useState(false);
  const [forgotPasswordLoader, setForgotPasswordLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [apiKeys, setApiKeys] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.trim(),
    }));
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[!@#$%^&*])(?=.*[a-zA-Z])(?=.*\d).{6,}$/;
    return regex.test(password);
  };

  const displaySomethingWrongErr = () => {
    setIsLoading(false);
    setErrSuccessMsg({
      message: "Something went wrong!",
      status: false,
    });
  };

  // const getApiKeys = async (token, url) => {
  //   const getApiKeysResponse = await apiCall(
  //     `openstaff/configKey/${url}`,
  //     "GET",
  //     null,
  //     token
  //   );
  //   console.log("getApiKeysResponse", getApiKeysResponse);
  //   if (getApiKeysResponse?.status === 200) {
  //     keyResponse = {
  //       ...keyResponse,
  //       [url]: getApiKeysResponse?.data,
  //     };
  //     // console.log("keyResponse----", keyResponse);
  //     dispatch(configKey(keyResponse));
  //   }
  // };

  const handleCandidateLogin = async (event) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    event.preventDefault();
    setIsLoading(true);
    const { userName, password } = formData;
    const errors = {};

    if (localStorage.getItem('sliderValue') === null) {
      localStorage.setItem('sliderValue', '25');
    }

    if (userName.trim() === "") {
      errors.userName = "Username is required.";
    } else {
      errors.userName = "";
    }

    if (!validatePassword(password)) {
      // errors.password = 'Password must contain a special character, a letter, and a digit.';
      errors.password = "";
    } else {
      errors.password = "";
    }

    if (errors.userName || errors.password) {
      setErrorMessages(errors);
      setIsLoading(false);
      return;
    }

    try {
      // const loginResponse = await apiCall("authenticate", "POST", formData);
      // console.log("loginResponse:", loginResponse);
      console.log('regex.test(userName):::::::::::::::::::::',regex.test(userName))
    if(!regex.test(userName)){
      setErrSuccessMsg({
        message: "Invalid email address!",
        status: false,
      });
      setIsLoading(false);
    }
      else{
        // const usersRef = ref(database, "users");
        // const usersQuery = query(
        //   usersRef,
        //   orderByChild("email"),
        //   equalTo(loginResponse?.data?.email)
        // );
    
        // onValue(usersQuery, async (snapshot) => {
        //   const userData = snapshot.val();
        //   console.log("User data:", userData);
    
        //   if (userData) {
            // User exists in Firestore, proceed with Firebase authentication
            try {
              const userCredential = await signInWithEmailAndPassword(auth, userName, password);
              const idToken = userCredential?.user?.accessToken;
              if (idToken) {
                // Successful Firebase authentication, proceed with API call for account details
                // dispatch(login(idToken));
                setTokenWithExpiry(idToken, 1);
                // dispatch(setLoginTimeStamp(Date.now()))
                // const docRef = doc(db, 'users', userCredential?.user?.uid);
                // const unsubscribe = onSnapshot(docRef, async(docSnap) => {
                //   if (docSnap.exists()) {
                    

                  
                const accountResponse = await apiCall(
                  "account",
                  "GET",
                  null,
                  idToken
                );

                console.log("accountResponse----",accountResponse, userName)
                if (accountResponse?.data?.roleEntities?.length > 0) {
                  setIsLoading(false);
                  dispatch(login(idToken));
                  dispatch(setLoginTimeStamp(new Date().getTime().toString()))
                  console.log("role_typeee",accountResponse?.data?.roleEntities.map((item)=>item?.roleType))
                  // dispatch(roleType(accountResponse?.data?.roleEntities[0]?.roleType));
                  const role_type = accountResponse?.data?.roleEntities.map((item)=>item?.roleType);
                  dispatch(roleType(role_type));
                  dispatch(userDetails(accountResponse?.data));
                  await logEvent_Analytics('login_user_details_web', {
                    param1 : role_type, 
                    param2 : accountResponse?.data?.id, 
                    param3 : userName
                  });
                  // const userData = docSnap.data();
                  // dispatch(roleType(accountResponse?.data?.roleEntities[0]));
                  // localStorage.setItem("role_type",userData?.role_type)
                  // console.log('userData::::::::::::::::',userData)
                  dispatch(
                    addressSelectedStatus({
                      latlng: { lat : "", lng: ""},
                      jobType: "",
                      locationName: "",
                    })
                  );
                  setErrSuccessMsg({
                    message: "User login successfully!",
                    status: true,
                  });
    
                  // Fetch additional API keys
                  // ["google_map_key", "stripeKey"].forEach((val) =>
                  //   getApiKeys(idToken, val)
                  // );
    
                  // Store credentials in localStorage if rememberMe is enabled
                  if (rememberMe) {
                    localStorage.setItem("username", userName);
                    localStorage.setItem("password", password);
                    localStorage.setItem("rememberMe", "true");
                  } else {
                    localStorage.removeItem("username");
                    localStorage.removeItem("password");
                    localStorage.removeItem("rememberMe");
                  }
    
                  // Redirect based on user role
                  setTimeout(() => {
                    document.body.style.overflow = "unset";
                    document.body.style.paddingRight = "0";
                    document.body.classList.remove("modal-open");
                    if(document.querySelector(".modal-backdrop")){
                      document.querySelector(".modal-backdrop").remove();
                    }
                    document
                      .getElementById("sign_up_popup2")
                      ?.setAttribute("aria-hidden", "true");
                    document
                      .getElementById("sign_up_popup2")
                      ?.removeAttribute("aria-modal");
                    if(document.getElementById("sign_up_popup2")){
                      document.getElementById("sign_up_popup2").style.display = "none";
                    }
                    document.getElementById("sign_up_popup2")?.classList?.remove("show");
        
                    const roleType = accountResponse?.data?.roleEntities[0]?.roleType

                    console.log("navigate_visited_page",roleType,navigate_visited_page,roleType.includes("ROLE_USER"),roleType.includes("ROLE_INSTITUTION_ADMIN"))
                    if(roleType.includes("ROLE_USER") || roleType.includes("ROLE_INSTITUTION_ADMIN")){
                      if(navigate_visited_page){
                        navigate(navigate_visited_page?.currentPage, { state: navigate_visited_page?.jobDetails });
                      }else{
                        navigate("/search-job");
                      }
                    }else if(roleType.includes("ROLE_OPENSTAFF_ADMIN") || roleType.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || roleType.includes("ROLE_INSTITUTION_POSTER_ADMIN")){
                      navigate("/job-list");
                    }else if(roleType.includes("ROLE_SEEKER_USER_ADMIN")){
                      navigate("/can-list");
                    }
                    // handleAuth(accountResponse?.data?.email)
                  }, 1200);
    
                  console.log("User authenticated as:", roleType);
                }else if(accountResponse?.response?.status === 401){
                  setIsLoading(false);
                  setErrSuccessMsg({
                    message: accountResponse?.response?.data?.message,
                    status: false,
                  });
                }else if(accountResponse?.response?.data?.errorMessage){
                  setIsLoading(false);
                  setErrSuccessMsg({
                    message: accountResponse?.response?.data?.errorMessage,
                    status: false,
                  });
                } else {
                  // No role entities found
                  displaySomethingWrongErr();
                }
              // }
              // else{
              //   setErrSuccessMsg({
              //     message: "Authentication failed. Please check your credentials.",
              //     status: false,
              //   });
              //   setIsLoading(false);
              // }
            // })
              } else {
                // No idToken returned from Firebase authentication
                displaySomethingWrongErr();
              }
    
            } catch (error) {
              // Firebase authentication error
              console.error("Firebase authentication error:", error);
              displaySomethingWrongErr();
              setIsLoading(false);
              setErrSuccessMsg({
                message: "Authentication failed. Please check your credentials.",
                status: false,
              });
            }
    
          // } else {
          //   // User does not exist in Firestore
          //   setIsLoading(false);
          //   setErrSuccessMsg({
          //     message: "User does not exist in Firebase!",
          //     status: false,
          //   });
          // }
        // });
    
      }
      //  else {
      //   // Invalid credentials or user does not exist based on login response
      //   setErrSuccessMsg({
      //     message: "Invalid credentials or user does not exist.",
      //     status: false,
      //   });
      //   setIsLoading(false);
      // }
    
    } catch (error) {
      // API call error
      console.error("API error:", error);
      displaySomethingWrongErr();
      setErrSuccessMsg({
        message: "Authentication failed. Please try again later.",
        status: false,
      });
      setIsLoading(false);
    }
    
  };

  const handleCheckboxChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleEmployerLogin = (event) => {
    event.preventDefault();
  };

  const closeModal = () => {
    document.querySelector(".modal-backdrop").remove();
    setForgotPasswordShow(false);
  };

  const validateEmail = () => {
    if (userEmail != "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      console.log("sdkjfksd", emailRegex.test(userEmail));
      return emailRegex.test(userEmail);
    } else {
      return false;
    }
  };

  const handleForgotPassword = async(event) => {
    event.preventDefault();

    if (validateEmail()) {
      setForgotPasswordLoader(true);
      try {
        const sendEmail = await sendPasswordResetEmail(auth, userEmail);
        console.log('sendEmail:::::::::::::::',sendEmail)
          setErrSuccessMsg({
            message: "A request to recover password has been sent to email!",
            status: true,
          });
          setUserEmail("");
          setTimeout(() => {
            setForgotPasswordShow(false);
          }, 2000);
      } catch (error) {
        console.log('error:::::::::::::::;',error)
        setErrSuccessMsg({
          message: error.message,
          status: false,
        });
      } finally {
        setForgotPasswordLoader(false);
      }
      
      // fetch(`${BASE_URL}forgotPassword`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: userEmail,
      // })
      //   .then(async (forgotPasswordResponse) => {
      //     console.log("responseJson", forgotPasswordResponse);

      //     if (forgotPasswordResponse?.status === 200) {
      //       setErrSuccessMsg({
      //         message: "A request to recover password has been send to email!",
      //         status: true,
      //       });
      //       setForgotPasswordLoader(false);
      //       setUserEmail("");
      //       setTimeout(() => {
      //         setForgotPasswordShow(false);
      //       }, 2000);
      //     } else {
      //       setErrSuccessMsg({
      //         message: "Something went wrong!",
      //         status: false,
      //       });
      //       setForgotPasswordLoader(false);
      //     }
      //   })
      //   .catch((err) => {
      //     setErrSuccessMsg({
      //       message: "Something went wrong!",
      //       status: false,
      //     });
      //     setForgotPasswordLoader(false);
      //   });
    } else {
      setErrorMessages({
        ...errorMessages,
        userEmail: "Please check your email!",
      });
    }
  };

  // const handleForgotPassword = async (event) => {
  //   event.preventDefault();
  //   try {
  //     if (validateEmail()) {
  //       setForgotPasswordLoader(true);

  //       const forgotPasswordResponse = await apiCall(
  //         "forgotPassword",
  //         "POST",
  //         userEmail
  //       );
  //       console.log("forgotPasswordResponse", forgotPasswordResponse);
  //       if (forgotPasswordResponse?.status === 200) {
  //         setErrSuccessMsg({
  //           message: "A request to recover password has been send to email!",
  //           status: true,
  //         });
  //         setForgotPasswordLoader(false);
  //         setUserEmail("");
  //         setTimeout(() => {
  //           setForgotPasswordShow(false);
  //         }, 2000);
  //       } else {
  //         setErrSuccessMsg({
  //           message: "Something went wrong!",
  //           status: false,
  //         });
  //         setForgotPasswordLoader(false);
  //       }
  //     } else {
  //       setErrorMessages({
  //         ...errorMessages,
  //         userEmail: "Please check your email!",
  //       });
  //     }
  //   } catch (err) {
  //     setErrSuccessMsg({
  //       message: "Something went wrong!",
  //       status: false,
  //     });
  //     setForgotPasswordLoader(false);
  //   }
  // };

  useEffect(() => {
      const rememberMeValue = localStorage.getItem("rememberMe");
      setRememberMe(rememberMeValue);
      if (rememberMeValue) {
        const storedUsername = localStorage.getItem("username") || "";
        const storedPassword = localStorage.getItem("password") || "";
        const storedRemember = localStorage.getItem("rememberMe") || "false";
        setFormData((prevState) => ({
          ...prevState,
          userName: storedUsername,
          password: storedPassword,
          rememberMe: storedRemember,
        }));
      }else{
        const rememberMeValue = localStorage.getItem("rememberMe");
        setRememberMe(rememberMeValue);
        setFormData((prevState) => ({
          ...prevState,
          userName: "",
          password: "",
          rememberMe: false,
        }));
        }
  }, [location?.pathname]);

  return (
    <>
      <div
        className="modal fade twm-sign-up"
        id="sign_up_popup2"
        aria-hidden="true"
        aria-labelledby="sign_up_popupLabel2"
        tabIndex={-1}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          {!forgotPasswordShow ? (
            <div className="modal-content">
              {/* <form> */}
              <div className="modal-header">
                <h2 className="modal-title" id="sign_up_popupLabel2">
                  Login
                </h2>
                <p>Login and get access to all the features of {process.env.REACT_APP_COMPANY_NAME}</p>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                />
              </div>
              <div className="modal-body">
                <div className="twm-tabs-style-2">
                  {/* <ul className="nav nav-tabs" id="myTab2" role="tablist">
                                    <li className="nav-item">
                                        <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#login-candidate" type="button"><i className="fas fa-user-tie" />Candidate</button>
                                    </li>
                                    <li className="nav-item">
                                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#login-Employer" type="button"><i className="fas fa-building" />Employer</button>
                                    </li>
                                </ul> */}
                  <div className="tab-content" id="myTab2Content">
                    {/*Login Candidate Content*/}
                    <form
                      onSubmit={handleCandidateLogin}
                      className="tab-pane fade show active"
                      id="login-candidate"
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group mb-3">
                            <input
                              id="username"
                              name="userName"
                              type="text"
                              required
                              className="form-control"
                              placeholder="Email*"
                              value={formData.userName}
                              onChange={handleChange}
                            />
                            <div className="error-message">
                              {errorMessages.userName}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div
                            className="form-group mb-3"
                            style={{ position: "relative" }}
                          >
                            <input
                              id="password"
                              name="password"
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              required
                              placeholder="Password*"
                              value={formData.password}
                              onChange={handleChange}
                            />
                            {/* <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}
                            </span> */}
                            <span
                              className="password-toggle"
                              style={{ paddingRight: "10px" }}
                              onClick={() => setShowPassword((prev) => !prev)}
                            >
                              <i
                                className={`fa ${
                                  showPassword ? "fa-eye" : "fa-eye-slash"
                                }`}
                              ></i>
                            </span>
                            <div className="error-message">
                              {errorMessages.password}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group mb-3">
                            <div className=" form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="Password3"
                                onChange={handleCheckboxChange}
                                checked={rememberMe}
                              />
                              <label
                                className="form-check-label rem-forgot"
                                htmlFor="Password3"
                              >
                                Remember me{" "}
                                <NavLink
                                  onClick={() => setForgotPasswordShow(true)}
                                >
                                  Forgot Password
                                </NavLink>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button
                            type="submit"
                            className="site-button"
                            disabled={isLoading}
                            // data-bs-dismiss="modal"
                          >
                            {isLoading ? <Spinner /> : "Log in"}
                          </button>

                          <div className="mt-3 mb-3">
                            Don't have an account ?
                            <NavLink
                              // to={publicUser.SIGNUP.SIGN_UP}
                              className="ml-5"
                              data-bs-dismiss="modal"
                              style={{ color: "blue", marginLeft: "10px" }}
                              onClick={() => navigate("/sign-up")}
                            >
                              Sign Up
                            </NavLink>
                            {/* <button
                              className="twm-backto-login"
                              data-bs-target="#sign_up_popup"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                            >
                              Sign Up
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </form>
                    {/*Login Employer Content*/}
                    <form
                      onSubmit={handleEmployerLogin}
                      className="tab-pane fade"
                      id="login-Employer"
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group mb-3">
                            <input
                              name="username"
                              type="text"
                              required
                              className="form-control"
                              placeholder="Email*"
                              value={empusername}
                              onChange={(event) => {
                                setEmpUsername(event.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group mb-3">
                            <input
                              name="password"
                              type="password"
                              className="form-control"
                              required
                              placeholder="Password*"
                              value={password}
                              onChange={(event) => {
                                setPassword(event.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group mb-3">
                            <div className=" form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="Password4"
                                onChange={handleCheckboxChange}
                                checked={rememberMe}
                              />
                              <label
                                className="form-check-label rem-forgot"
                                htmlFor="Password4"
                              >
                                Remember me{" "}
                                {/* <a href="#" onClick={handleForgotPasswordShow}>
                                  Forgot Password
                                </a> */}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button
                            type="submit"
                            className="site-button"
                            data-bs-dismiss="modal"
                          >
                            Log in
                          </button>

                          <div className="mt-3 mb-3">
                            Don't have an account ?
                            <button
                              className="twm-backto-login"
                              data-bs-target="#sign_up_popup"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                            >
                              Sign Up
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <span className="modal-f-title">Login or Sign</span>
                {/* <ul className="twm-modal-social">
                  <li>
                    <a
                      href="https://www.facebook.com/"
                      className="facebook-clr"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com/" className="twitter-clr">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://in.linkedin.com/" className="linkedin-clr">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.google.com/" className="google-clr">
                      <i className="fab fa-google" />
                    </a>
                  </li>
                </ul> */}
              </div>
              {/* </form> */}
            </div>
          ) : (
            <div className="modal-content">
              {/* <form> */}
              <div className="modal-header">
                <h2 className="modal-title" id="sign_up_popupLabel2">
                  Forgot Password
                </h2>
                <p>Please enter your email address.</p>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                />
              </div>
              <div className="modal-body">
                <div className="twm-tabs-style-2">
                  <div className="tab-content" id="myTab2Content">
                    <form
                      onSubmit={handleForgotPassword}
                      className="tab-pane fade show active"
                      id="login-candidate"
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group mb-3">
                            <input
                              name="email"
                              type="email"
                              className="form-control"
                              placeholder="Enter Email*"
                              value={userEmail}
                              required
                              onChange={(e) => {
                                setErrorMessages({
                                  userName: "",
                                  password: "",
                                  userEmail: "",
                                });
                                setUserEmail(e.target.value);
                              }}
                            />
                          </div>
                          <div className="error-message">
                            {errorMessages.userEmail}
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6"></div>
                        <div className="col-md-3 col-sm-6">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => setForgotPasswordShow(false)}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={forgotPasswordLoader}
                          >
                            {forgotPasswordLoader ? <Spinner /> : "Send"}
                          </button>
                        </div>
                        <div className="col-md-3 col-sm-6"></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <span className="modal-f-title">Login or Sign up with</span>
                <ul className="twm-modal-social">
                  <li>
                    <a
                      href="https://www.facebook.com/"
                      className="facebook-clr"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com/" className="twitter-clr">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://in.linkedin.com/" className="linkedin-clr">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.google.com/" className="google-clr">
                      <i className="fab fa-google" />
                    </a>
                  </li>
                </ul>
              </div>
              {/* </form> */}
            </div>
          )}
        </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default SignInPopup;
