import { Helmet } from "react-helmet-async";
import SectionCandicateBasicInfo from "../sections/profile/section-can-basic-info";
import SectionCandidateSocialInfo from "../sections/profile/section-can-social-info";
import { formatCompanyName } from "../../../../globals/fomat-comapny-name";

function CanProfilePage() {
    return (
        <>
        <div>
            <Helmet>
            <title>{`User Profile | User Dashboard | User Account Details | ${formatCompanyName()}`}</title>
            <meta name="description" content="The User Profile Page is central dashboard for managing your account and job search activities. Here, you can easily update your personal information, upload and edit your resume, and track the status of your job applications." />
            <meta name="keywords" content="user profile, profile summary" />
            </Helmet>
        </div>
            <div className="twm-right-section-panel site-bg-gray">

                {/*Basic Information*/}
                <SectionCandicateBasicInfo />

                {/*Social Network*/}
                {/* <SectionCandidateSocialInfo /> */}
                
            </div>
        </>
    )
}

export default CanProfilePage;