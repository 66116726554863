import React,{ useEffect, useState,useRef } from "react";
import {doc,getDoc, updateDoc, setDoc, onSnapshot  } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db, storage } from "../../../../common/firebase/firebase";
import { useSelector } from "react-redux";
import moment from "moment";
import Avatar from "../../../../common/chat-avatar-by-name";
import Spinner from "../../../../common/spinner";
import Toast from "../../../../common/toast";
import apiCall from "../../../../../apicall";
import { useParams } from "react-router-dom";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";
function SectionCanChat({jobId,jobItem,getAllChats,forApplied,selectedChatJobId}) {
    const { userInfo, role_type, TOKEN } = useSelector(
        (state) => state.menu
      );
      const { id } = useParams();
      const isAdmin = userInfo?.roleEntities[0]?.roleType=="ROLE_OPENSTAFF_ADMIN";
      const messageContainerRef = useRef(null);
    const [messages, setMessages] = useState({});
    const [jobIds, setJobIds] = useState(jobId ? jobId : id)
    const [newMessage, setNewMessage] = useState('');
    const [senderName, setSenderName] = useState({
        firstName:'',
        lastName:'',
        fullName:''
    })
    const [isLoading,setIsLoading]=useState(false)
    const [groupedChats,setGroupedChats]=useState()
    const [errSuccessMesg, setErrSuccessMsg] = useState({
      message: "",
      status: "error",
    });
  
    useEffect(()=>{
        getChatsByJobId(true);
         logEvent_Analytics('chat_screen_web', {
          param1: jobId,
          param2: userInfo?.id
        });

     },[jobId, jobItem?.hirePerson?.userEntityId, selectedChatJobId])
    
     console.log("chatscreenweb",jobItem)

    useEffect(() => {
        if (messageContainerRef.current) {
          messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
        if(userInfo?.roleEntities[0]?.roleType=="ROLE_INSTITUTION_ADMIN"){
          jobId= jobId+jobItem?.hirePerson?.userEntityId
        }
        groupChatsByDate()
      }, [messages]);


    const getChatsByJobId=async(id_status)=>{
        try {
            setIsLoading(true)
            if(forApplied){
              if(userInfo?.roleEntities[0]?.roleType=="ROLE_USER" && id_status){
              jobId= jobId+userInfo?.id
              }
              else if(userInfo?.roleEntities[0]?.roleType=="ROLE_INSTITUTION_ADMIN"){
                jobId= jobItem?.id+jobItem?.hirePerson?.userEntityId
              }
            }
            const docRef = doc(db, 'chats', jobId);
      console.log('jobId inside chat:::::::::::::',jobId,jobItem)

            const unsubscribe = onSnapshot(docRef,async (docSnap) => {
              console.log("Document data:", docSnap.data());
                if (docSnap.exists()) {
                  const chat = docSnap.data();
                  setMessages(chat);
                 
                    // Update status to "delivered" and "read"
                  const updatedMessages = chat?.message.map((msg) => {
                    if (msg?.status?.delivered !== true || msg?.status?.read !== true) {
                      return {
                        ...msg,
                        status: {
                          delivered: true,
                          read: true,
                        },
                      };
                    }
                    return msg;
                  });

                  console.log("updatedMessages------",updatedMessages,JSON.stringify(chat?.message) !== JSON.stringify(updatedMessages))
                  if (JSON.stringify(updatedMessages) !== JSON.stringify(chat?.message)) {
                    // If the messages were updated, update the document
                    console.log("update the doccc",)
                    await updateDoc(docRef, { message: updatedMessages });
                  }
          
                  if (userInfo?.roleEntities[0]?.roleType === "ROLE_OPENSTAFF_ADMIN") {
                    setSenderName({
                      fullName: `${chat?.senderFirstname} ${chat?.senderLastname}, ${chat?.receiverFirstName} ${chat?.receiverLastName}`
                    });
                  } else if (userInfo?.id === chat?.senderId) {
                    setSenderName({
                      firstName: chat?.receiverFirstName,
                      lastName: chat?.receiverLastName
                    });
                  } else if (userInfo?.id === chat?.receiverId) {
                    setSenderName({
                      firstName: chat?.senderFirstname,
                      lastName: chat?.senderLastname
                    });
                  }
          
                  setIsLoading(false);
                } else {
                  console.log("No such document!", jobItem);
                  setIsLoading(false);
                  let chatObj={}
                  const currentDateInSeconds = Math.floor(new Date().getTime() / 1000);
                  if(userInfo?.roleEntities[0]?.roleType=="ROLE_USER"){
                    chatObj={
                    receiverId: jobItem?.userId|| 'unknown', // Replace with actual receiver ID
                    receiverEmail: jobItem?.email|| 'unknown', // Replace with actual receiver email
                    receiverFirstName: jobItem?.posterFirstName||jobItem?.firstName|| 'unknown', // Replace with actual receiver first name
                    receiverLastName: jobItem?.posterLastName||jobItem?.lastName|| 'unknown', 
                    }
                    setSenderName({
                      firstName: jobItem?.posterFirstName,
                      lastName: jobItem?.posterLastName
                    });
                }
                    else if(userInfo?.roleEntities[0]?.roleType=="ROLE_INSTITUTION_ADMIN"){
                    chatObj={ 
                    receiverId: jobItem?.hirePerson?.userEntityId|| 'unknown', // Replace with actual receiver ID
                    receiverEmail: jobItem?.hirePerson?.email|| 'unknown', // Replace with actual receiver email
                    receiverFirstName: jobItem?.hirePerson?.firstName|| 'unknown', // Replace with actual receiver first name
                    receiverLastName: jobItem?.hirePerson?.lastName|| 'unknown', 
                        }
                        setSenderName({
                          firstName: jobItem?.hirePerson?.firstName,
                          lastName: jobItem?.hirePerson?.lastName
                        });
                    
                  }
                  setMessages({
                    jobId: jobId|| 'unknown',
              jobTitle: jobItem?.jobTitle|| 'unknown', // Replace with actual job title from props or state
              senderId: userInfo?.id|| 'unknown',
              senderEmail: userInfo?.email|| 'unknown',
              senderFirstname: userInfo?.firstName|| 'unknown',
              senderLastname: userInfo?.lastName|| 'unknown',
              startedOn:new Date(),
              _id: userInfo?.id+currentDateInSeconds,
              modifiedOn:new Date(),
              ...chatObj
                   })
                }
              }, (error) => {
                console.error("Error getting document:", error);
                setIsLoading(false);
              });
          
              // Cleanup subscription on unmount
              return () => unsubscribe();
            
          } catch (error) {
            setIsLoading(false)
            console.error("Error getting document:", error);
          }
          
    }
    console.log('jobId insideelsechat:::::::::::::',messages)

    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
        let fileMetadata = null;
    
        // Check if a file is being uploaded
        if (e.target.files && e.target.files[0]) {
          const file = e.target.files[0];
          const storageRef = ref(storage, `chats/${jobId}/${file.name}`);
          const uploadTask = uploadBytesResumable(storageRef, file);
    
          // Monitor upload progress and completion
          uploadTask.on('state_changed', 
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
            }, 
            (error) => {
              console.error('Upload failed:', error);
            }, 
            async () => {
              // Upload completed successfully, get download URL
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              console.log('Download URL:', downloadURL);
    
              // Prepare file metadata for Firestore
              fileMetadata = {
                name: file.name,
                type: file.type,
                size: file.size,
                downloadURL,
                jobId,
                uploadedOn: new Date(),
              };
    
              // Proceed to update Firestore with message including file metadata
              addMessageToFirestore(fileMetadata);
            }
          );
        } else {
          // No file uploaded, proceed with just message
          addMessageToFirestore();
        }
      } catch (error) {
        console.error('Error handling submit:', error);
      }
        }

        const displayErrMsg = (errMsg) => {
          setErrSuccessMsg({
            message: errMsg,
            status: false,
          });
        };


        const chatStartedNotifyApi = async (chatObj) => {
          try{
            let endUrl = ''
            let job_id = selectedChatJobId ? selectedChatJobId : jobIds
            if (role_type.includes('ROLE_USER')) {
              endUrl = `jobs/${job_id}/chatStarted`
            } else if(role_type.includes('ROLE_INSTITUTION_ADMIN')) {
              endUrl = `institutions/jobs/${job_id}/chatStarted`
            }
        
            let requestBody = chatObj?.message 
            console.log("requestBody-----",requestBody,role_type,chatObj)
  
            const chatNotifyResponse = await apiCall(endUrl, "PUT", requestBody, TOKEN);
            console.log("chatNotifyResponse-----",chatNotifyResponse)

            if(chatNotifyResponse?.status === 200){
            }else if (chatNotifyResponse?.response?.status == 401) {
              displayErrMsg("Your session has been expired!");
            }
          }catch(error){
            displayErrMsg("Network Erorr!");
          }
        }

        const addMessageToFirestore=async(fileMetadata)=>{
            try {
              console.log('fileMetadata:::::::::::',fileMetadata)
          if(fileMetadata !== undefined || (newMessage && newMessage.trim().length > 0)){
          const currentDateInSeconds = Math.floor(new Date().getTime() / 1000);
          const messageObj = {
            user: {
              sentBy: userInfo?.id,
              sentByEmail: userInfo?.email,
              _id: userInfo?.id,
              firstName:userInfo?.firstName,
              lastName:userInfo?.lastName
            },
            text: newMessage,
            createdAt: new Date(),
            _id:currentDateInSeconds,
            image:fileMetadata?.downloadURL?fileMetadata?.downloadURL:null,
            document:fileMetadata?fileMetadata:null,
              status: {
                sent: true,        
                delivered: false,  
                read: false,      
              }
          };
          if(forApplied){
            if(userInfo?.roleEntities[0]?.roleType=="ROLE_USER"){
            jobId= jobId+userInfo?.id
            }
            else if(userInfo?.roleEntities[0]?.roleType=="ROLE_INSTITUTION_ADMIN"){
              jobId= jobId+jobItem?.hirePerson?.userEntityId
              console.log('jobId inside:::::::::::::',jobId)
            }
          }
          console.log('jobItem:::::::::::::',jobItem)
          const docRef = doc(db, 'chats', jobId);
          const docSnap = await getDoc(docRef);
          console.log('jobItem:::::::::::::',docSnap.exists())
  
          if (docSnap.exists()) {
            // Document exists, update messages array
            await updateDoc(docRef, {
              message: [...docSnap.data().message, messageObj],
              modifiedOn:new Date()
            });

          } else {
            // Document does not exist, create new document
            let chatObj={}
            if(userInfo?.roleEntities[0]?.roleType=="ROLE_USER"){
              chatObj={
              receiverId: jobItem?.userId|| 'unknown', // Replace with actual receiver ID
              receiverEmail: jobItem?.email|| 'unknown', // Replace with actual receiver email
              receiverFirstName: jobItem?.posterFirstName||jobItem?.firstName|| 'unknown', // Replace with actual receiver first name
              receiverLastName: jobItem?.posterLastName||jobItem?.lastName|| 'unknown', 
              }
          }
              else if(userInfo?.roleEntities[0]?.roleType=="ROLE_INSTITUTION_ADMIN"){
              chatObj={ 
              receiverId: jobItem?.hirePerson?.userEntityId|| 'unknown', // Replace with actual receiver ID
              receiverEmail: jobItem?.hirePerson?.email|| 'unknown', // Replace with actual receiver email
              receiverFirstName: jobItem?.hirePerson?.firstName|| 'unknown', // Replace with actual receiver first name
              receiverLastName: jobItem?.hirePerson?.lastName|| 'unknown', 
                  }
              
            }
            console.log("chat bojecccccccc",chatObj)

            await setDoc(docRef, {
              message: [messageObj],
              jobId: jobId|| 'unknown',
              jobTitle: jobItem?.jobTitle|| 'unknown', // Replace with actual job title from props or state
              senderId: userInfo?.id|| 'unknown',
              senderEmail: userInfo?.email|| 'unknown',
              senderFirstname: userInfo?.firstName|| 'unknown',
              senderLastname: userInfo?.lastName|| 'unknown',
              startedOn:new Date(),
              _id: userInfo?.id+currentDateInSeconds,
              modifiedOn:new Date(),
              ...chatObj
            });

            await chatStartedNotifyApi({ jobId, message : newMessage});

          }
          getChatsByJobId(false)
          setNewMessage('');
          // getAllChats()
      }
      else{
        setErrSuccessMsg({
          message:'Chat input field are required !',
          status:false
        })
      }
     } catch (error) {
        console.error('Error writing document: ', error);
      }
      }
        // console.log('senderName:::::::::::::::::::',senderName)
        const handleKeyPress = (e) => {
            console.log('e:::::::::::::::press:::::::::',e)
            if (e.key == 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSubmit(e);
            }
          };
          const groupChatsByDate = () => {
            const groupedChats = {};
          
            messages?.message?.length>0&&messages?.message?.forEach(chat => {
              const chatDate = moment(new Date(chat?.createdAt?.toDate())).startOf('day');
              const now = moment().startOf('day');
              const yesterday = moment().subtract(1, 'day').startOf('day');
          
              let dateLabel;
              if (chatDate.isSame(now, 'day')) {
                dateLabel = 'Today';
              } else if (chatDate.isSame(yesterday, 'day')) {
                dateLabel = 'Yesterday';
              } else {
                dateLabel = chatDate.format('MM/DD/YYYY');
              }
          
              if (!groupedChats[dateLabel]) {
                groupedChats[dateLabel] = [];
              }
              groupedChats[dateLabel].push(chat);
            });
          
            // Sort dates in ascending order
            const sortedGroupedChats = Object.keys(groupedChats)
              .sort((a, b) => (a === 'Today' ? 1 : b === 'Today' ? -1 : a === 'Yesterday' ? 1 : b === 'Yesterday' ? -1 : moment(a).diff(moment(b))))
              .reduce((acc, key) => {
                // Sort chats within each group in ascending order
                acc[key] = groupedChats[key].sort((a, b) => new Date(a.createdAt.toDate()) - new Date(b.createdAt.toDate()));
                return acc;
              }, {});
          
            setGroupedChats(sortedGroupedChats)
          };

          const MessageStatusIcon = ({ status }) => {
            console.log("status message icon",status)
            if(status?.read){
              return  <span>✓✓</span>
            }else if(status?.delivered){
              return <span>✓✓</span>
            }else if(status?.sent){
              return <span>✓</span>
            }else{
              return <span>✓</span>
            }
          };
          
          console.log('groupedChats:::::::::::',groupedChats)
          const renderChats = (chatGroup, label) => (
            <>
              <div className="single-user-msg-time" style={{textAlign:'center'}}>{label}</div>
              {chatGroup.length > 0 && chatGroup.map(chat => {
                  const fileUrl=chat?.document?.downloadURL
                  const fileType = chat?.document?.type;

                  const isImage = fileType?.startsWith('image');
                  const isPDF = fileType === 'application/pdf';
                  const isVideo = fileType?.startsWith('video');
                  const isAudio = fileType?.startsWith('audio');
                  console.log('fileUrl::::::::::::::',fileUrl,isImage,chat?.document?.downloadURL.startsWith('image'),chat?.document?.type)
              return(<>
              <div id={chat?._id}>
                  {userInfo?.id!==chat?.user?.sentBy?(
                   <div className="single-user-comment-wrap">
                        <div className="row">
                            <div className="col-xl-9 col-lg-12" style={{width:'auto'}}>
                                <div className="single-user-comment-block clearfix">
                                    <div className="single-user-com-pic">
                                        <Avatar firstName={chat?.user?.firstName} lastName={chat?.user?.lastName}/>
                                        </div>
                                    <div className="single-user-com-text">
                                      {isImage?(
                                      <img src={fileUrl} alt="Image" style={{ maxWidth: '100%', maxHeight: '100px' }}/>
                                      ):isPDF?
                                      <embed src={fileUrl} type="application/pdf" width="100%" height="600px" />
                                      :isVideo?
                                      <video controls>
                                      <source src={fileUrl}  />
                                      Your browser does not support the video tag.
                                    </video>
                                    :isAudio&&
                                    <audio controls>
                                    <source src={fileUrl}  />
                                    Your browser does not support the audio tag.
                                  </audio>
              }

                                      
                                      {chat.text}
                                      <div className="single-user-msg-time">{moment(new Date(chat?.createdAt?.toDate())).format('hh:mm A')}</div>
                                      </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                        ):userInfo?.id==chat?.user?.sentBy&&(
                        <div className="single-user-comment-wrap sigle-user-reply">
                        <div className="row justify-content-end">
                            <div className="col-xl-9 col-lg-12" style={{width:'auto'}}>
                                <div className="single-user-comment-block clearfix" style={{display:'grid', gridTemplateColumns:'auto auto', justifyContent:'flex-end',flexDirection:'column',position:'relative'}}>
                                    <div className="single-user-com-pic">
                                        <Avatar firstName={chat?.user?.firstName} lastName={chat?.user?.lastName}/>
                                        </div>
                                    <div className="single-user-com-text">
                                    {isImage?(
                                      <img src={fileUrl} alt="Image" style={{ maxWidth: '100%', maxHeight: '200px' }}/>
                                      ):isPDF?
                                      <embed src={fileUrl} type="application/pdf" width="100%" height="600px" />
                                      :isVideo?
                                      <video controls>
                                      <source src={fileUrl}  />
                                      Your browser does not support the video tag.
                                    </video>
                                    :isAudio&&
                                    <audio controls>
                                    <source src={fileUrl}  />
                                    Your browser does not support the audio tag.
                                  </audio>
              }
                                      {chat.text}
                                      
                                      <div style={{display:'flex', alignItems: 'center'}}>
                                      <div className="single-user-msg-time">{moment(new Date(chat?.createdAt?.toDate())).format('hh:mm A')}</div>
                                      <MessageStatusIcon status={chat?.status} />
                                      </div>
                                     
                                      </div>
                                    
                                </div>
                            </div>
                        </div>
                  </div>
                )}
                </div></>)
              }
              ) }
            </>
          );
    return (
        <>
            <div className="wt-dashboard-msg-box">
            {Object.keys(messages)?.length==0?
        
              <div style={{ display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Make the container take the full height of the viewport
                textAlign: 'center'}}>{isLoading ? 
                <Spinner />
                :'No conversations available here !'}</div>
              :(<>
                <div className="single-msg-user-name-box">
                    <div className="single-msg-short-discription">
                    <div style={{display:'flex', flexDirection:'row',justifyContent:'space-evenly'}}>
                        <div>
                        <Avatar firstName={senderName?.firstName} lastName={senderName?.lastName} isAdmin={isAdmin}/> 
                        </div>
                     <div>

                        <h4 className="single-msg-user-name">{isAdmin?senderName?.fullName:senderName?.firstName+' '+senderName?.lastName} </h4>
                        
                        {messages?.jobTitle}
                     </div>
                        </div>
                    </div>
                    
                                  {/* <a href="#" className="message-action"><i className="far fa-trash-alt" /> Delete Conversation</a> */}
                </div>
              <div ref={messageContainerRef} id="msg-chat-wrap" className="single-user-msg-conversation scrollbar-macosx" style={{overflowY:'auto',marginBottom:60,maxHeight:'659px'}}>
              {Object.keys(groupedChats).map(dateLabel => (
                     groupedChats[dateLabel]?.length>0&&(
                      renderChats(groupedChats[dateLabel], dateLabel)
                     )
                    ))}
                </div>
                </>)}
                <div className="single-msg-reply-comment">
                    <div className="input-group" >
                        <textarea style={{
                          backgroundColor:'#fff',
                          // boxShadow:'0px 0px 1px 2px gray', 
                          borderRadius:'10px', border:"1px solid #d0d0d0"}} className="form-control" placeholder="Type a message here" defaultValue={""} value={newMessage} onChange={(e)=>setNewMessage(e.target.value)}  
                          onKeyPress={handleKeyPress}
                          disabled={isAdmin}
                          />
                       <button className="btn" style={{right:'30px', color: "#c1c1c1"}} type="button" onClick={()=>document.getElementById("upload-pdf").click()}><i className="fa fa-paperclip " /></button>
                        <input  id="upload-pdf" type="file" onChange={handleSubmit} style={{display:'none'}} disabled={isAdmin}/>
                    </div>
                    <button className="btn" type="button" onClick={handleSubmit} disabled={isAdmin} style={{background: "#4caf50", color: "#fff", height: 60, borderRadius: "0 10px 10px 0", padding: "0 20px"}}><i className="fa fa-paper-plane" /></button>
                    
                </div>
            </div>
            {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
        </>
    )
}
export default SectionCanChat;