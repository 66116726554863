// import { combineReducers, configureStore } from '@reduxjs/toolkit';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import  {thunk} from 'redux-thunk'; // Import thunk directly

// import menu from './reducers/menu';

// const persistConfig = {
//   key: 'root',
//   storage
// };

// const persistedReducer = persistReducer(persistConfig, combineReducers({ menu }));

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
// });

// const persistor = persistStore(store);

// export { store, persistor };

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {thunk} from 'redux-thunk'; // Corrected the import for thunk

import menu from './reducers/menu';

// Persist configuration
const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, combineReducers({ menu }));

// Store configuration with middleware adjustments
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore non-serializable values from redux-persist actions
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }).concat(thunk), // Add thunk as middleware
});

const persistor = persistStore(store);

export { store, persistor };
