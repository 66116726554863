import { useState } from "react";
import { useSelector } from "react-redux";
import Toast from "../toast";
import Spinner from "../spinner";
import { useNavigate } from "react-router-dom";
import apiCall from "../../../apicall";

function CoverLetter({ coverLetter }) {
    console.log("coverLetterText`````")

  return (
    <>
      <div
        className="modal fade twm-sign-up cover-letter"
        id="cover_letter"
        aria-hidden="true"
        aria-labelledby="cover_letter_popupLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <h2 className="modal-title" id="cover_letter_popupLabel">
                  Resume / Cover Letter
                </h2>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body" style={{"maxHeight": "500px", "overflowY": "scroll"}}>
                <div className="twm-s-info-inner" dangerouslySetInnerHTML={{ __html: coverLetter }} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CoverLetter;
