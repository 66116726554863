// import ApplicantCertifcatesTab from "./applicant-certificates";
import ApplicantDocumentsTab from "./applicant-document";
import ApplicantNotesTab from "./applicant-notes";

function SectionTabContent({ applicantDetailsId }) {
  return (
    <div className="tab-content" id="myTab2">
      <div className="tab-pane fade show active" id="applicant-document">
        <ApplicantDocumentsTab
          applicantDetailsId={applicantDetailsId}
          tabType={"document"}
        />
      </div>
      <div className="tab-pane fade" id="applicant-certificates">
        <ApplicantDocumentsTab
          applicantDetailsId={applicantDetailsId}
          tabType={"certificate"}
        />
      </div>
      <div className="tab-pane fade" id="applicant-notes">
        <ApplicantNotesTab applicantDetailsId={applicantDetailsId} />
      </div>
    </div>
  );
}

export default SectionTabContent;
