import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import apiCall from "../../../../../../apicall";
import Toast from "../../../../../common/toast";
import SessionExpired from "../../../../../common/sessionExpired";
import TableLoader from "../../../../../common/tableLoader";
import moment from "moment";

function ApplicantNotesTab({ applicantDetailsId }) {
  const { TOKEN, role_type } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [notesList, setNotesList] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const displayErrSuccessMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setNotesList([]);
  };

  const getApplicantNotes = async () => {
    try {
      setIsLoading(true);
      const api = `${`openstaff/notes/users/${applicantDetailsId}?offset=0&pageNumber=0&size=200`}`;
      const applicantNotesResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("applicantNotesResponse----", applicantNotesResponse);
      if (applicantNotesResponse?.data?.content?.length > 0) {
        setSessionExpired(false);
        setIsLoading(false);
        setNotesList(applicantNotesResponse?.data?.content);
      } else if (applicantNotesResponse?.data?.content?.length === 0) {
        setIsLoading(false);
        setNotesList([]);
        setSessionExpired(false);
      } else if (applicantNotesResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayErrSuccessMsg("Your session has been expired!!");
      } else {
        setSessionExpired(false);
        // displayErrSuccessMsg("Something went wrong!");
        displayErrSuccessMsg(applicantNotesResponse?.response?.data?.message)
      }
    } catch (err) {
      setSessionExpired(false);
      displayErrSuccessMsg("Something went wrong!");
    }
  };

  useEffect(() => {
    if(!role_type.includes('ROLE_SEEKER_USER_ADMIN')){
      getApplicantNotes();
    }
  }, []);

  return !isLoading ? (
    notesList?.length > 0 ? (
      <div className="section-content">
        <div className="owl-carousel twm-testimonial-1-carousel owl-btn-bottom-center ">
          {notesList?.map(
            (item) =>
              item?.note && (
                <div className="item" key={item?.id}>
                  {/*Block three*/}
                  <div className="blog-post twm-blog-post-1-outer">
                    <div className="wt-post-info">
                      <div className="wt-post-meta ">
                        <ul>
                          <li className="post-date">
                            {moment(item?.createdDate).format("MM/DD/YYYY")}
                          </li>
                        </ul>
                      </div>
                      <div className="wt-post-title ">
                        <h4 className="post-title">
                          <NavLink to="#">
                            {item?.note ? item.note : "No notes present!"}
                          </NavLink>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
        {sessionExpired && <SessionExpired />}
        {errSuccessMesg?.message != "" ? (
          <Toast message={errSuccessMesg} />
        ) : (
          ""
        )}
      </div>
    ) : (
      "No notes present at that moment!"
    )
  ) : (
    <TableLoader />
  );
}

export default ApplicantNotesTab;
