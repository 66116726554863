import SectionCandidatePaymentInfo from "../sections/profile/section-can-payment-details";

const CanPaymentDetails = () => {
  return (
    <>
      <SectionCandidatePaymentInfo />
    </>
  );
};

export default CanPaymentDetails;
