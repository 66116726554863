import React, { useState, useEffect } from "react";
import GetProfileImage from "../../../../common/getProfileImage";
import BASE_URL from "../../../../../config";
import { useSelector } from "react-redux";
import Toast from "../../../../common/toast";
import Spinner from "../../../../common/spinner";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../../globals/fomat-comapny-name";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";

const CanCompanyLogo = () => {
  const { TOKEN } = useSelector((state) => state.menu);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUploadButtonClick = () => {
    document.getElementById("fileInput-company-logo").click();
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        setIsLoading(true);
        const url = `${BASE_URL}logo`;

        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("fileType", "ePng");
        const config = {
          headers: {
            Accept: "*/*",
            Authorization: `Bearer ${TOKEN}`,
          },
        };

        const response = await axios.post(url, formData, config);
        console.log("Upload successful:", response);

        if (response?.status == 200) {
          setIsLoading(false);
          setErrSuccessMsg({
            message: "Company logo updated successfully!",
            status: true,
          });
        } else {
          // displayErrMsg("Something went wrong!");
          displayErrMsg(response?.response?.data?.message)
        }
      } catch (error) {
        displayErrMsg("Something went wrong!");
        console.error("Upload failed:", error);
        // Handle failure response if needed
      }
    } else {
      console.log("No file selected.");
    }
  };

  useEffect(() =>{
    logEvent_Analytics('edit_company_pic_web');
  }, [])
  return (
    <>
      <div>
        <Helmet>
          <title>{`Company Logo | ${formatCompanyName()}`}</title>
          <meta name="description" content="Welcome to the Company Logo Page! Here, you can upload and manage your company’s logo to enhance your brand visibility across our platform. A well-designed logo helps create a professional image and establishes your company’s identity." />
          <meta name="keywords" content="company logo" />
        </Helmet>
      </div>
    <div className="twm-right-section-panel candidate-save-job site-bg-gray">
      <a href="#" className="twm-job-title">
        <h4>Company Logo</h4>
      </a>
      <p>Chanage your company logo</p>
      <div className="twm-candidate-profile-pic" style={{ height: "150px" }}>
        {selectedFile ? (
          <img src={URL.createObjectURL(selectedFile)} alt="Selected File" />
        ) : (
          <GetProfileImage
            id={null}
            type={"applicant"}
            logoType={"company-logo"}
          />
        )}
        {/* <GetProfileImage  id={null} type={'applicant'}/>     */}
        <div className="upload-btn-wrapper">
          {/* Hidden file input element */}
          <div id="upload-image-grid" />
          <input
            type="file"
            id="fileInput-company-logo"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          {/* Button to trigger file input click */}
          {/* <button
            className="site-button button-sm"
            onClick={handleUploadButtonClick}
            style={{ top: "0px" }}
          >
            Upload Image
          </button> */}
        </div>

      </div>
      <div className="col-xl-12 col-lg-12 col-md-12 text-center">
        <div>
          <button
            className="site-button button-sm"
            onClick={handleUploadButtonClick}
            style={{ top: "0px" }}
          >
            Upload Image
          </button></div>
        <div className="mt-1">
          <button
            className="site-button button-sm"
            // style={{ left: "35%" }}
            onClick={handleUpload}
            disabled={!selectedFile}
          >
            {isLoading ? <Spinner /> : "Save"}
          </button>
        </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </div>
    </>
  );
};

export default CanCompanyLogo;
