// import React, { useState, useEffect, useRef } from "react";
// import apiCall from "../../../../apicall";
// import Toast from "../../../common/toast";
// import Spinner from "../../../common/spinner";
// import { useSelector, useDispatch } from "react-redux";
// import SessionExpired from "../../../common/sessionExpired";
// import { userDetails } from "../../../../store/reducers/menu";
// import CanRemoveProfessionConfirmation from "./can-remove-profession-confirmation";

// const CanProfession = () => {
//   const { userInfo, TOKEN } = useSelector((state) => state.menu);
//   const dispatch = useDispatch();
//   const [dropdownOpen, setDropdownOpen] = useState(false);
//   const [selectedProfession, setSelectedProfession] = useState("");
//   const [selectedTypes, setSelectedTypes] = useState([]);
//   const [positionDropdownList, setPositionDropdownList] = useState([]);
//   const [selectedProfessionList, setSelectedProfessionList] = useState([]);
//   const [additionalProfessions, setAdditionalProfessions] = useState([]);
//   const [formValues, setFormValues] = useState({});
//   const [selectedFields, setSelectedFields] = useState({});
//   const [formErrors, setFormErrors] = useState({});
//   const [isLoadingPositionList, setIsLoadingPositionList] = useState(false);
//   const [shouldRerender, setShouldRerender] = useState(false);
//   const [errSuccessMesg, setErrSuccessMsg] = useState({
//     message: "",
//     status: "error",
//   });
//   const [isLoading, setIsLoading] = useState(false);
//   const [sessionExpired, setSessionExpired] = useState(false);

//   const dropdownRef = useRef(null);

//   useEffect(() => {
//     getPositionDropdownList();
//   }, []);

//   useEffect(() => {
//     getAllProfessions();
//   }, [positionDropdownList]);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setDropdownOpen(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [dropdownRef]);

//   const displayErrMsg = (errMsg) => {
//     setErrSuccessMsg({ message: errMsg, status: false });
//   };

//   const displaySuccessMsg = () => {
//     setErrSuccessMsg({
//       message: "Profession updated successfully!",
//       status: true,
//     });
//     setIsLoading(false);
//     setSessionExpired(false);
//   };

//   const handleInputChange = (e, typeName, fieldName) => {
//     const { name, value } = e.target;
//     setFormValues((prevValues) => ({
//       ...prevValues,
//       [typeName]: {
//         ...prevValues[typeName],
//         [fieldName || name]: value,
//       },
//     }));
//     setFormErrors((prevErrors) => ({
//       ...prevErrors,
//       [typeName]: {
//         ...prevErrors[typeName],
//         [fieldName || name]: "",
//       },
//     }));
//   };

//   const validateForm = () => {
//     const errors = {};

//     if (selectedTypes.length === 0) {
//       errors.profession = "At least one profession is required.";
//     }

//     Object.entries(selectedFields).forEach(([typeName, fields]) => {
//       fields.forEach((field) => {
//         if (
//           field.required &&
//           (!formValues[typeName] ||
//             !formValues[typeName][field.mapField]?.trim())
//         ) {
//           errors[typeName] = errors[typeName] || {};
//           errors[typeName][field.mapField] = `${field.title} is required.`;
//         }
//       });
//     });

//     setFormErrors(errors);
//     return (
//       Object.keys(errors).length === 0 &&
//       Object.values(errors).every((err) => Object.keys(err).length === 0)
//     );
//   };

//   const handlePositionChange = (e, profession) => {
//     const { value, checked } = e.target;
//     setSelectedProfession(profession);
//     setSelectedTypes((prevSelectedTypes) => {
//       const updatedSelectedTypes = checked
//         ? [...prevSelectedTypes, value]
//         : prevSelectedTypes.filter((type) => type !== value);
//       const selectedPositions = updatedSelectedTypes
//         .map((type) =>
//           positionDropdownList.find((pos) => pos.typeName === type)
//         )
//         .filter(Boolean); // Ensure only valid positions are processed

//       const newFields = {};
//       selectedPositions.forEach((pos) => {
//         newFields[pos.typeName] = JSON.parse(pos.relatedFields).fields;
//       });
//       setSelectedFields(newFields);

//       const newFormValues = { ...formValues };
//       updatedSelectedTypes.forEach((typeName) => {
//         if (!newFormValues[typeName]) {
//           newFormValues[typeName] = {};
//           newFields[typeName]?.forEach((field) => {
//             newFormValues[typeName][field.mapField] = "";
//           });
//         }
//       });
//       setFormValues(newFormValues);

//       console.log("updatedSelectedTypes---", updatedSelectedTypes);

//       return updatedSelectedTypes;
//     });
//   };

//   const getPositionDropdownList = async () => {
//     setIsLoadingPositionList(true);
//     try {
//       const positionResponse = await apiCall("config/allJobTypes", "GET", null);
//       if (
//         positionResponse?.status === 200 &&
//         positionResponse?.data?.length > 0
//       ) {
//         setPositionDropdownList(positionResponse.data);
//         setCurrentProfession(positionResponse.data);
//       } else {
//         // displayErrMsg("Something went wrong!");
//         displayErrMsg(positionResponse?.response?.data?.message)
//       }
//     } catch (err) {
//       displayErrMsg("Something went wrong!");
//     } finally {
//       setIsLoadingPositionList(false);
//     }
//   };

//   const getAllProfessions = async () => {
//     try {
//       const professionResponse = await apiCall(
//         `${userInfo.id}/getAdditionalProfessions`,
//         "GET",
//         null,
//         TOKEN
//       );

//       if (
//         professionResponse?.status === 200 &&
//         professionResponse?.data?.length > 0
//       ) {
//         setAdditionalProfessions(professionResponse.data);
//         additionalProfessionFunc(professionResponse.data);
//       }
//     } catch (error) {
//       displayErrMsg("Something went wrong while fetching professions!");
//       console.error("Error fetching professions:", error);
//     }
//   };

//   const additionalProfessionFunc = (professions) => {
//     const newSelectedTypes = professions.map((prof) => prof.profession);
//     setSelectedTypes(newSelectedTypes);
//     setSelectedProfessionList(newSelectedTypes);
//     console.log("newSelectedTypes", newSelectedTypes);
//     const selectedPositions = newSelectedTypes
//       .map((type) => positionDropdownList.find((pos) => pos.typeName === type))
//       .filter(Boolean); // Ensure only valid positions are processed

//     const newFields = {};
//     console.log("selectedPositions", selectedPositions, positionDropdownList);

//     selectedPositions.forEach((pos) => {
//       newFields[pos.typeName] = JSON.parse(pos.relatedFields).fields;
//     });

//     console.log("newFields---", newFields);

//     setSelectedFields(newFields);

//     const newFormValues = {};
//     professions.forEach((prof) => {
//       newFormValues[prof.profession] = {};
//       Object.keys(prof).forEach((key) => {
//         if (key !== "profession") {
//           newFormValues[prof.profession][key] = prof[key];
//         }
//       });
//     });
//     setFormValues(newFormValues);
//   };

//   const handleRegisterSubmit = async (event) => {
//     event.preventDefault();
//     if (validateForm()) {
//       setIsLoading(true);
//       let finalProfessionData = [];

//       // Construct the finalProfessionData array
//       selectedTypes.forEach((profession) => {
//         const formData = formValues[profession] || {};
//         const data = {
//           profession,
//           ...formData,
//         };
//         finalProfessionData.push(data);
//         console.log("data::::::::::::::", data);
//       });

//       try {
//         // Make the API call to register the profession
//         const registerResponse = await apiCall(
//           `${userInfo.id}/addProfession`,
//           "PUT",
//           finalProfessionData,
//           TOKEN
//         );

//         if (registerResponse?.status === 200) {
//           displaySuccessMsg();
//           getPositionDropdownList();
//         } else if (registerResponse?.response?.status === 400) {
//           displayErrMsg(registerResponse?.response?.data?.errorMessage);
//           setSessionExpired(true);
//         } else {
//           // displayErrMsg("Something went wrong!");
//           displayErrMsg(registerResponse?.response?.data?.message)
//         }
//       } catch (error) {
//         displayErrMsg("Something went wrong!");
//         console.error("Error:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     } else {
//       displayErrMsg("Check the form validation!");
//     }
//   };

//   const setCurrentProfession = (positionDropdownList) => {
//     const initialSelectedTypes = userInfo?.profession
//       ? [userInfo?.profession]
//       : [];
//     setSelectedTypes(initialSelectedTypes);

//     const selectedPositions = initialSelectedTypes
//       .map((type) => positionDropdownList.find((pos) => pos.typeName === type))
//       .filter(Boolean); // Ensure only valid positions are processed

//     const newFields = {};
//     selectedPositions.forEach((pos) => {
//       newFields[pos.typeName] = JSON.parse(pos.relatedFields).fields;
//     });
//     setSelectedFields(newFields);
//     const newFormValues = {};
//     Object.entries(newFields).forEach(([typeName, fields]) => {
//       newFormValues[typeName] = {};
//       fields.forEach((field) => {
//         newFormValues[typeName][field.mapField] =
//           userInfo[field.mapField] || "";
//       });
//     });
//     setFormValues(newFormValues);
//   };

//   const toggleDropdown = () => {
//     setDropdownOpen(!dropdownOpen);
//   };

//   const getCheckboxAttributesDisabled = (position) => {
//     const isChecked = selectedTypes.includes(position.typeName);
//     const isOnlySelected = selectedTypes.length === 1 && isChecked;

//     return isOnlySelected ? { disabled: true } : "";
//   };

//   const getCheckboxAttributes = (checked) => {
//     // setSelectedProfession(profession);
//     console.log("profession-----------", checked, selectedTypes);
//     return !checked
//       ? {
//           "data-bs-toggle": "modal",
//           href: "#remove_profession_confirmation",
//         }
//       : {};
//   };

//   const onCloseModal = () => {
//     additionalProfessionFunc(additionalProfessions);
//   };

//   return (
//     <>
//       <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
//         <div className="wt-dashboardbox">
//           <div className="panel-heading wt-panel-heading p-a20 mb-4">
//             <h4 className="panel-tittle m-a0">Professions</h4>
//           </div>
//           {/* <div class="banner-title-name">
//                 <h3 class="wt-title mt-4">Search Jobs</h3>
//           </div> */}
//           <div className="wt-dashboardboxcontent">
//             <form className="wt-formtheme wt-formregister">
//               <div className="form-group">
//                 <label htmlFor="profession">Professions</label>
//                 <div className="custom-dropdown">
//                   <button
//                     type="button"
//                     className="btn dropdown-toggle form-control"
//                     onClick={toggleDropdown}
//                     style={{ color: "black", textAlign: "left", height: "auto", textWrap: "wrap" }}
//                   >
//                     {Object.keys(selectedFields).join(', ')}
//                   </button>
//                   <div
//                     ref={dropdownRef}
//                     className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}
//                   >
//                     {positionDropdownList?.map((position) => (
//                       <div key={position.typeName} className="dropdown-item">
//                         <input
//                           type="checkbox"
//                           style={{ marginRight: 10 }}
//                           value={position.typeName}
//                           checked={selectedTypes.includes(position.typeName)}
//                           onChange={(e) =>
//                             handlePositionChange(e, position.typeName)
//                           }
//                           {...getCheckboxAttributes(
//                             !selectedProfessionList.includes(position.typeName)
//                           )}
//                           {...getCheckboxAttributesDisabled(position)}
//                         />
//                         <label>{position.typeName}</label>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>

//               {Object.entries(selectedFields).map(([typeName, fields]) => (
//                 fields.length > 0 && (
//                 <div key={typeName}>
//                   <h4>{typeName}</h4>
//                   {fields.map((field) => (
//                     <div key={field.mapField} className="form-group">
//                       <label>{field.title}</label>
//                       <input
//                         type="text"
//                         name={field.mapField}
//                         className="form-control"
//                         value={formValues[typeName]?.[field.mapField] || ""}
//                         onChange={(e) => handleInputChange(e, typeName)}
//                       />
//                       {formErrors[typeName]?.[field.mapField] && (
//                         <span className="error-message">
//                           {formErrors[typeName][field.mapField]}
//                         </span>
//                       )}
//                     </div>
//                   ))}
//                 </div>
//                 )
//               ))}
//               <div className="wt-btnarea">
//                 <button
//                   type="submit"
//                   className="site-button"
//                   role="button"
//                   onClick={handleRegisterSubmit}
//                 >
//                   {isLoading ? (
//                     <Spinner />
//                   ) : (
//                     <>
//                       <i className="fa fa-save"></i> Save
//                     </>
//                   )}
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//       {errSuccessMesg.message && <Toast message={errSuccessMesg} />}
//       {sessionExpired && <SessionExpired />}
//       <CanRemoveProfessionConfirmation
//         selectedProfession={selectedProfession}
//         getAllProfessions={getAllProfessions}
//         onCloseModal={onCloseModal}
//         additionalProfessions={additionalProfessions}
//       />
//     </>
//   );
// };

// export default CanProfession;

// import React, { useState, useEffect, useRef } from "react";
// import apiCall from "../../../../apicall";
// import Toast from "../../../common/toast";
// import Spinner from "../../../common/spinner";
// import { useSelector, useDispatch } from "react-redux";
// import SessionExpired from "../../../common/sessionExpired";
// import { userDetails } from "../../../../store/reducers/menu";

// const CanProfession = () => {
//   const { userInfo, TOKEN } = useSelector((state) => state.menu);
//   const dispatch = useDispatch();
//   const [dropdownOpen, setDropdownOpen] = useState(false);
//   const [selectedProfession, setSelectedProfession] = useState("");
//   const [selectedTypes, setSelectedTypes] = useState([]);
//   const [positionDropdownList, setPositionDropdownList] = useState([]);
//   const [selectedProfessionList, setSelectedProfessionList] = useState([]);
//   const [additionalProfessions, setAdditionalProfessions] = useState([]);
//   const [formValues, setFormValues] = useState({});
//   const [selectedFields, setSelectedFields] = useState({});
//   const [formErrors, setFormErrors] = useState({});
//   const [isLoadingPositionList, setIsLoadingPositionList] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [sessionExpired, setSessionExpired] = useState(false);
//   const [errSuccessMsg, setErrSuccessMsg] = useState({
//     message: "",
//     status: "error",
//   });

//   const dropdownRef = useRef(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       await getPositionDropdownList();
//       await getAllProfessions();
//     };
//     fetchData();
//   }, []);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setDropdownOpen(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const displayErrMsg = (errMsg) => {
//     setErrSuccessMsg({ message: errMsg, status: false });
//   };

//   const displaySuccessMsg = () => {
//     setErrSuccessMsg({
//       message: "Profession updated successfully!",
//       status: true,
//     });
//     setIsLoading(false);
//     setSessionExpired(false);
//   };

//   const handleInputChange = (e, typeName, fieldName) => {
//     const { name, value } = e.target;
//     setFormValues((prevValues) => ({
//       ...prevValues,
//       [typeName]: {
//         ...prevValues[typeName],
//         [fieldName || name]: value,
//       },
//     }));
//     setFormErrors((prevErrors) => ({
//       ...prevErrors,
//       [typeName]: {
//         ...prevErrors[typeName],
//         [fieldName || name]: "",
//       },
//     }));
//   };

//   const validateForm = () => {
//     const errors = {};

//     if (selectedTypes.length === 0) {
//       errors.profession = "At least one profession is required.";
//     }

//     Object.entries(selectedFields).forEach(([typeName, fields]) => {
//       fields.forEach((field) => {
//         if (
//           field.required &&
//           (!formValues[typeName] ||
//             !formValues[typeName][field.mapField]?.trim())
//         ) {
//           errors[typeName] = errors[typeName] || {};
//           errors[typeName][field.mapField] = `${field.title} is required.`;
//         }
//       });
//     });

//     setFormErrors(errors);
//     return (
//       Object.keys(errors).length === 0 &&
//       Object.values(errors).every((err) => Object.keys(err).length === 0)
//     );
//   };

//   const handlePositionChange = (e, profession) => {
//     const { value, checked } = e.target;
//     setSelectedProfession(profession);
//     setSelectedTypes((prevSelectedTypes) => {
//       const updatedSelectedTypes = checked
//         ? [...prevSelectedTypes, value]
//         : prevSelectedTypes.filter((type) => type !== value);
//       const selectedPositions = updatedSelectedTypes
//         .map((type) =>
//           positionDropdownList.find((pos) => pos.typeName === type)
//         )
//         .filter(Boolean); // Ensure only valid positions are processed

//       const newFields = {};
//       selectedPositions.forEach((pos) => {
//         newFields[pos.typeName] = JSON.parse(pos.relatedFields).fields;
//       });
//       setSelectedFields(newFields);

//       const newFormValues = { ...formValues };
//       if (!checked) {
//         delete newFormValues[value];
//       } else {
//         updatedSelectedTypes.forEach((typeName) => {
//           if (!newFormValues[typeName]) {
//             newFormValues[typeName] = {};
//             newFields[typeName]?.forEach((field) => {
//               newFormValues[typeName][field.mapField] = "";
//             });
//           }
//         });
//       }
//       setFormValues(newFormValues);

//       return updatedSelectedTypes;
//     });
//   };

//   const getPositionDropdownList = async () => {
//     setIsLoadingPositionList(true);
//     try {
//       const positionResponse = await apiCall("config/allJobTypes", "GET", null);
//       if (positionResponse?.status === 200 && positionResponse?.data?.length > 0) {
//         setPositionDropdownList(positionResponse.data);
//         setCurrentProfession(positionResponse.data);
//       } else {
//         displayErrMsg(positionResponse?.response?.data?.message);
//       }
//     } catch (err) {
//       displayErrMsg("Something went wrong!");
//     } finally {
//       setIsLoadingPositionList(false);
//     }
//   };

//   const getAllProfessions = async () => {
//     try {
//       const professionResponse = await apiCall(
//         `${userInfo.id}/getAdditionalProfessions`,
//         "GET",
//         null,
//         TOKEN
//       );

//       if (professionResponse?.status === 200 && professionResponse?.data?.length > 0) {
//         setAdditionalProfessions(professionResponse.data);
//         additionalProfessionFunc(professionResponse.data);
//       }
//     } catch (error) {
//       displayErrMsg("Something went wrong while fetching professions!");
//       console.error("Error fetching professions:", error);
//     }
//   };

//   const additionalProfessionFunc = (professions) => {
//     const newSelectedTypes = professions.map((prof) => prof.profession);
//     setSelectedTypes(newSelectedTypes);
//     setSelectedProfessionList(newSelectedTypes);
//     const selectedPositions = newSelectedTypes
//       .map((type) => positionDropdownList.find((pos) => pos.typeName === type))
//       .filter(Boolean); // Ensure only valid positions are processed

//     const newFields = {};
//     selectedPositions.forEach((pos) => {
//       newFields[pos.typeName] = JSON.parse(pos.relatedFields).fields;
//     });
//     console.log("newFields---", newSelectedTypes,positionDropdownList,selectedPositions);
//     setSelectedFields(newFields);

//     const newFormValues = {};
//     professions.forEach((prof) => {
//       newFormValues[prof.profession] = {};
//       Object.keys(prof).forEach((key) => {
//         if (key !== "profession") {
//           newFormValues[prof.profession][key] = prof[key];
//         }
//       });
//     });
//     setFormValues(newFormValues);
//   };

//   const handleRegisterSubmit = async (event) => {
//     event.preventDefault();
//     if (validateForm()) {
//       setIsLoading(true);
//       let finalProfessionData = selectedTypes.map((profession) => {
//         const formData = formValues[profession] || {};
//         return {
//           profession,
//           ...formData,
//         };
//       });

//       try {
//         const registerResponse = await apiCall(
//           `${userInfo.id}/addMultipleProfessions`,
//           "POST",
//           finalProfessionData,
//           TOKEN
//         );

//         if (registerResponse?.status === 200) {
//           displaySuccessMsg();
//           getPositionDropdownList();
//         } else if (registerResponse?.response?.status === 400) {
//           displayErrMsg(registerResponse?.response?.data?.errorMessage);
//           setSessionExpired(true);
//         } else {
//           displayErrMsg(registerResponse?.response?.data?.message);
//         }
//       } catch (error) {
//         displayErrMsg("Something went wrong!");
//         console.error("Error:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     } else {
//       displayErrMsg("Check the form validation!");
//     }
//   };

//   const setCurrentProfession = (positionDropdownList) => {
//     const initialSelectedTypes = userInfo?.profession ? [userInfo?.profession] : [];
//     setSelectedTypes(initialSelectedTypes);

//     const selectedPositions = initialSelectedTypes
//       .map((type) => positionDropdownList.find((pos) => pos.typeName === type))
//       .filter(Boolean); // Ensure only valid positions are processed

//     const newFields = {};
//     selectedPositions.forEach((pos) => {
//       newFields[pos.typeName] = JSON.parse(pos.relatedFields).fields;
//     });
//     setSelectedFields(newFields);
//     const newFormValues = {};
//     Object.entries(newFields).forEach(([typeName, fields]) => {
//       newFormValues[typeName] = {};
//       fields.forEach((field) => {
//         newFormValues[typeName][field.mapField] = userInfo[field.mapField] || "";
//       });
//     });
//     setFormValues(newFormValues);
//   };

//   const toggleDropdown = () => {
//     setDropdownOpen(!dropdownOpen);
//   };

//   const getCheckboxAttributesDisabled = (position) => {
//     const isChecked = selectedTypes.includes(position.typeName);
//     const isOnlySelected = selectedTypes.length === 1 && isChecked;

//     return isOnlySelected ? { disabled: true } : {};
//   };

//   const onCloseModal = () => {
//     additionalProfessionFunc(additionalProfessions);
//   };
//   const selectedProfessionNames = selectedTypes
//     .map((type) => positionDropdownList.find((pos) => pos.typeName === type)?.typeName)
//     .filter(Boolean) // Ensure only valid names are included
//     .join(", ");

//   return (
//     <>
//       <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
//         <div className="wt-dashboardbox">
//           <div className="panel-heading wt-panel-heading p-a20 mb-4">
//             <h4 className="panel-tittle m-a0">Professions</h4>
//           </div>
//           <div className="wt-dashboardboxcontent">
//             <form className="wt-formtheme wt-formregister">
//               <div className="form-group">
//                 <label htmlFor="profession">Professions</label>
//                 <div className="custom-dropdown">
//                   <button
//                     type="button"
//                     className="btn dropdown-toggle form-control"
//                     onClick={toggleDropdown}
//                     style={{
//                       color: "black",
//                       textAlign: "left",
//                       height: "auto",
//                       textWrap: "wrap",
//                     }}
//                   >
//                     {console.log("selectedFields", selectedFields)}
//                     {selectedProfessionNames || "Select professions"}
//                   </button>
//                   <div
//                     ref={dropdownRef}
//                     className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}
//                   >
//                     {positionDropdownList?.map((position) => (
//                       <div key={position.typeName} className="dropdown-item">
//                         <input
//                           type="checkbox"
//                           style={{ marginRight: 10 }}
//                           value={position.typeName}
//                           checked={selectedTypes.includes(position.typeName)}
//                           onChange={(e) =>
//                             handlePositionChange(e, position.typeName)
//                           }
//                           {...getCheckboxAttributesDisabled(position)}
//                         />
//                         <label>{position.typeName}</label>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>

//               {Object.entries(selectedFields).map(
//                 ([typeName, fields]) =>
//                   fields.length > 0 && (
//                     <div key={typeName}>
//                       <h4>{typeName}</h4>
//                       {fields.map((field) => (
//                         <div key={field.mapField} className="form-group">
//                           <label>{field.title}</label>
//                           <input
//                             type="text"
//                             name={field.mapField}
//                             className="form-control"
//                             value={
//                               formValues[typeName]?.[field.mapField] || ""
//                             }
//                             onChange={(e) =>
//                               handleInputChange(e, typeName)
//                             }
//                           />
//                           {formErrors[typeName]?.[field.mapField] && (
//                             <span className="error-message">
//                               {formErrors[typeName][field.mapField]}
//                             </span>
//                           )}
//                         </div>
//                       ))}
//                     </div>
//                   )
//               )}
//               <div className="wt-btnarea">
//                 <button
//                   type="submit"
//                   className="site-button"
//                   role="button"
//                   onClick={handleRegisterSubmit}
//                 >
//                   {isLoading ? (
//                     <Spinner />
//                   ) : (
//                     <>
//                       <i className="fa fa-save"></i> Save
//                     </>
//                   )}
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//       {errSuccessMsg.message && <Toast message={errSuccessMsg} />}
//       {sessionExpired && <SessionExpired />}
//     </>
//   );
// };

// export default CanProfession;




import React, { useState, useEffect, useRef } from "react";
import apiCall from "../../../../apicall";
import Toast from "../../../common/toast";
import Spinner from "../../../common/spinner";
import { useSelector, useDispatch } from "react-redux";
import SessionExpired from "../../../common/sessionExpired";
import { userDetails } from "../../../../store/reducers/menu";
import { logEvent_Analytics } from "../../../common/firebase/firebase-analytics-service";

const CanProfession = () => {
  const { userInfo, TOKEN } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedProfession, setSelectedProfession] = useState("");
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [positionDropdownList, setPositionDropdownList] = useState([]);
  const [selectedProfessionList, setSelectedProfessionList] = useState([]);
  const [additionalProfessions, setAdditionalProfessions] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [selectedFields, setSelectedFields] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingPositionList, setIsLoadingPositionList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [errSuccessMsg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const [prevFormValues, setPrevFormValues] = useState({});

  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      await getPositionDropdownList();
      await getAllProfessions();
    };
    fetchData();
    logEvent_Analytics('profile_skills_web');
  }, []);
  useEffect(() => {
    if (positionDropdownList.length > 0 && additionalProfessions.length > 0) {
      additionalProfessionFunc(additionalProfessions);
    }
  }, [positionDropdownList, additionalProfessions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({ message: errMsg, status: false });
  };

  const displaySuccessMsg = () => {
    setErrSuccessMsg({
      message: "Profession updated successfully!",
      status: true,
    });
    setIsLoading(false);
    setSessionExpired(false);
  };

  const handleInputChange = (e, typeName, fieldName) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [typeName]: {
        ...prevValues[typeName],
        [fieldName || name]: value,
      },
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [typeName]: {
        ...prevErrors[typeName],
        [fieldName || name]: "",
      },
    }));
  };


  const validateForm = () => {
    const errors = {};

    if (selectedTypes.length === 0) {
      errors.profession = "At least one profession is required.";
    }

    Object.entries(selectedFields).forEach(([typeName, fields]) => {
      fields.forEach((field) => {
        if (
          field.required &&
          (!formValues[typeName] ||
            !formValues[typeName][field.mapField]?.trim())
        ) {
          errors[typeName] = errors[typeName] || {};
          errors[typeName][field.mapField] = `${field.title} is required.`;
        }
      });
    });

    setFormErrors(errors);
    return (
      Object.keys(errors).length === 0 &&
      Object.values(errors).every((err) => Object.keys(err).length === 0)
    );
  };

  // const handlePositionChange = (e, profession) => {
  //   const { value, checked } = e.target;
  //   setSelectedProfession(profession);
  //   setSelectedTypes((prevSelectedTypes) => {
  //     const updatedSelectedTypes = checked
  //       ? [...prevSelectedTypes, value]
  //       : prevSelectedTypes.filter((type) => type !== value);
  //     const selectedPositions = updatedSelectedTypes
  //       .map((type) =>
  //         positionDropdownList.find((pos) => pos.typeName === type)
  //       )
  //       .filter(Boolean); // Ensure only valid positions are processed

  //     const newFields = {};
  //     selectedPositions.forEach((pos) => {
  //       newFields[pos.typeName] = JSON.parse(pos.relatedFields).fields;
  //     });
  //     setSelectedFields(newFields);

  //     const newFormValues = { ...formValues };
  //     if (!checked) {
  //       delete newFormValues[value];
  //     } else {
  //       updatedSelectedTypes.forEach((typeName) => {
  //         if (!newFormValues[typeName]) {
  //           newFormValues[typeName] = {};
  //           newFields[typeName]?.forEach((field) => {
  //             newFormValues[typeName][field.mapField] = "";
  //           });
  //         }
  //       });
  //     }
  //     setFormValues(newFormValues);

  //     return updatedSelectedTypes;
  //   });
  // };

  const handlePositionChange = (e, profession) => {
    const { value, checked } = e.target;
    setSelectedProfession(profession);
  
    setSelectedTypes((prevSelectedTypes) => {
      let updatedSelectedTypes;
      let newFormValues = { ...formValues };
      let updatedPrevFormValues = { ...prevFormValues };
  
      if (checked) {
        updatedSelectedTypes = [...prevSelectedTypes, value];
  
        // Restore the previously stored values if they exist
        if (updatedPrevFormValues[value]) {
          newFormValues[value] = updatedPrevFormValues[value];
          delete updatedPrevFormValues[value];
        } else {
          const selectedPosition = positionDropdownList.find(
            (pos) => pos.typeName === value
          );
          newFormValues[value] = {};
          JSON.parse(selectedPosition.relatedFields).fields.forEach((field) => {
            newFormValues[value][field.mapField] = "";
          });
        }
      } else {
        updatedSelectedTypes = prevSelectedTypes.filter((type) => type !== value);
  
        // Store the current form values before removing
        updatedPrevFormValues[value] = newFormValues[value];
        delete newFormValues[value];
      }
  
      setPrevFormValues(updatedPrevFormValues);
      setFormValues(newFormValues);
  
      const selectedPositions = updatedSelectedTypes
        .map((type) =>
          positionDropdownList.find((pos) => pos.typeName === type)
        )
        .filter(Boolean);
  
      const newFields = {};
      selectedPositions.forEach((pos) => {
        newFields[pos.typeName] = JSON.parse(pos.relatedFields).fields;
      });
      setSelectedFields(newFields);
  
      return updatedSelectedTypes;
    });
  };
  
  const getPositionDropdownList = async () => {
    setIsLoadingPositionList(true);
    try {
      const positionResponse = await apiCall("config/allJobTypes", "GET", null);
      if (positionResponse?.status === 200 && positionResponse?.data?.length > 0) {
        setPositionDropdownList(positionResponse.data);
        // setCurrentProfession(positionResponse.data);
      } else {
        displayErrMsg(positionResponse?.response?.data?.message);
      }
    } catch (err) {
      displayErrMsg("Something went wrong!");
    } finally {
      setIsLoadingPositionList(false);
    }
  };

  const getAllProfessions = async () => {
    try {
      const professionResponse = await apiCall(
        `${userInfo.id}/getAdditionalProfessions`,
        "GET",
        null,
        TOKEN
      );
  
      if (professionResponse?.status === 200 && professionResponse?.data?.length > 0) {
        setAdditionalProfessions(professionResponse.data);
        additionalProfessionFunc(professionResponse.data);
      }
    } catch (error) {
      displayErrMsg("Something went wrong while fetching professions!");
      console.error("Error fetching professions:", error);
    }
  };
  

  const additionalProfessionFunc = (professions) => {
    const newSelectedTypes = professions.map((prof) => prof.profession);
    setSelectedTypes(newSelectedTypes);
    setSelectedProfessionList(newSelectedTypes);
    
    const selectedPositions = newSelectedTypes
      .map((type) => positionDropdownList.find((pos) => pos.typeName === type))
      .filter(Boolean);
  
    const newFields = {};
    selectedPositions.forEach((pos) => {
      newFields[pos.typeName] = JSON.parse(pos.relatedFields).fields;
    });
  
    setSelectedFields(newFields);
  
    const newFormValues = {};
    professions.forEach((prof) => {
      newFormValues[prof.profession] = {};
      Object.keys(prof).forEach((key) => {
        if (key !== "profession") {
          newFormValues[prof.profession][key] = prof[key];
        }
      });
    });
  
    setFormValues(newFormValues);
  };
  


  const handleRegisterSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      let finalProfessionData = selectedTypes.map((profession) => {
        const formData = formValues[profession] || {};
        return {
          profession,
          ...formData,
        };
      });
  
      try {
        const registerResponse = await apiCall(
          `${userInfo.id}/addMultipleProfessions`,
          "POST",
          finalProfessionData,
          TOKEN
        );
  
        if (registerResponse?.status === 200) {
          displaySuccessMsg();
          await getAllProfessions(); // Fetch the updated professions list
        } else if (registerResponse?.response?.status === 400) {
          displayErrMsg(registerResponse?.response?.data?.errorMessage);
          setSessionExpired(true);
        } else {
          displayErrMsg(registerResponse?.response?.data?.message);
        }
      } catch (error) {
        displayErrMsg("Something went wrong!");
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      displayErrMsg("Check the form validation!");
    }
  };
  

  const setCurrentProfession = (positionDropdownList) => {
    const initialSelectedTypes = userInfo?.profession ? [userInfo?.profession] : [];
    setSelectedTypes(initialSelectedTypes);

    const selectedPositions = initialSelectedTypes
      .map((type) => positionDropdownList.find((pos) => pos.typeName === type))
      .filter(Boolean); // Ensure only valid positions are processed

    const newFields = {};
    selectedPositions.forEach((pos) => {
      newFields[pos.typeName] = JSON.parse(pos.relatedFields).fields;
    });
    setSelectedFields(newFields);
    const newFormValues = {};
    Object.entries(newFields).forEach(([typeName, fields]) => {
      newFormValues[typeName] = {};
      fields.forEach((field) => {
        newFormValues[typeName][field.mapField] = userInfo[field.mapField] || "";
      });
    });
    setFormValues(newFormValues);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const getCheckboxAttributesDisabled = (position) => {
    const isChecked = selectedTypes.includes(position.typeName);
    const isOnlySelected = selectedTypes.length === 1 && isChecked;

    return isOnlySelected ? { disabled: true } : {};
  };

  const onCloseModal = () => {
    additionalProfessionFunc(additionalProfessions);
  };
  const selectedProfessionNames = selectedTypes
    .map((type) => positionDropdownList.find((pos) => pos.typeName === type)?.typeName)
    .filter(Boolean) // Ensure only valid names are included
    .join(", ");

  return (
    <>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div className="wt-dashboardbox">
          <div className="panel-heading wt-panel-heading p-a20 mb-4">
            <h4 className="panel-tittle m-a0">Professions</h4>
          </div>
          <div className="wt-dashboardboxcontent">
            <form className="wt-formtheme wt-formregister">
              <div className="form-group">
                <label htmlFor="profession">Professions</label>
                <div className="custom-dropdown">
                  <button
                    type="button"
                    className="btn dropdown-toggle form-control"
                    onClick={toggleDropdown}
                    style={{
                      color: "black",
                      textAlign: "left",
                      height: "auto",
                      textWrap: "wrap",
                    }}
                  >
                    {console.log("selectedFields", selectedFields)}
                    {selectedProfessionNames || "Select professions"}
                  </button>
                  <div
                    ref={dropdownRef}
                    className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}
                  >
                    {positionDropdownList?.map((position) => (
                      <div key={position.typeName} className="dropdown-item">
                        <input
                          type="checkbox"
                          style={{ marginRight: 10 }}
                          value={position.typeName}
                          checked={selectedTypes.includes(position.typeName)}
                          onChange={(e) =>
                            handlePositionChange(e, position.typeName)
                          }
                          {...getCheckboxAttributesDisabled(position)}
                        />
                        <label>{position.typeName}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {Object.entries(selectedFields).map(
                ([typeName, fields]) =>
                  fields.length > 0 && (
                    <div key={typeName}>
                      <h4>{typeName}</h4>
                      {fields.map((field) => (
                        <div key={field.mapField} className="form-group">
                          <label>{field.title}</label>
                          <input
                            type="text"
                            name={field.mapField}
                            className="form-control"
                            value={formValues[typeName]?.[field.mapField] || ""}
                            onChange={(e) => handleInputChange(e, typeName)}
                          />
                          {formErrors[typeName]?.[field.mapField] && (
                            <span className="error-message">
                              {formErrors[typeName][field.mapField]}
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  )
              )}

              <div className="wt-btnarea">
                <button
                  type="submit"
                  className="site-button"
                  role="button"
                  onClick={handleRegisterSubmit}
                >
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      <i className="fa fa-save"></i> Save
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {errSuccessMsg.message && <Toast message={errSuccessMsg} />}
      {sessionExpired && <SessionExpired />}
    </>
  );
};

export default CanProfession;



