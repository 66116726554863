import React, { useState } from "react";
import Toast from "../../../../common/toast";
import Spinner from "../../../../common/spinner";
import { useDispatch, useSelector } from "react-redux";
import apiCall from "../../../../../apicall";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { loadScript } from "../../../../../globals/constants";
import { jobDetailsData, roleType } from "../../../../../store/reducers/menu";

const ConfirmationPostJobPopUp = ({ registerResponseData,  handleHideJobType, setShowPaymentScreen }) => {
  const { TOKEN, role_type } = useSelector((state) => state.menu);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [isJobDeteleted, setIsJobDeteleted] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const hideModal = () =>{
    const modal = document.getElementById("post_job_confirmation_popup");
    const modalInstance = window.bootstrap.Modal.getInstance(modal);
    const postJobmodal = document.getElementById("sign_up_popup3");
    const postJobmodalInstance = window.bootstrap.Modal.getInstance(postJobmodal);
   
    console.log("navigateToJobDetail=====", modalInstance,postJobmodalInstance)

    if(modalInstance){
      modalInstance.hide();
    }
    if(postJobmodalInstance){
      postJobmodalInstance.hide();
    }
  }

  const navigateToJobDetail = () => {
    const itemId = registerResponseData;
    console.log("navigateToJobDetail=====", itemId)
    hideModal();
    // dispatch(jobDetailsData({id: registerResponseData}));
    navigate(`/job-detail/${itemId}`, { state: {id: registerResponseData} });
  };

  const navigateToSearchJob = () => {
    const itemId = registerResponseData;
    console.log("navigateToJobDetail=====", itemId)
    hideModal();
    if (role_type.includes("ROLE_INSTITUTION_ADMIN")){
      navigate("/search-job");
    } else if (role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN") || role_type.includes("ROLE_OPENSTAFF_ADMIN")){
      navigate("/job-list");
    }  
  };
  const handleAddMoreJobs = () => {
    // console.log("handleAddMoreJobs=====", handleAddMoreJobs)
    // navigate("/post-job");
    const ConfirmationBackDrop = document.getElementById("modalBackdrop");
    const ConfirmationPopupModal = document.getElementById("post_job_confirmation_popup");
        if (ConfirmationPopupModal) {
          ConfirmationPopupModal.classList.remove("show");
          ConfirmationBackDrop.classList.remove("show");
          ConfirmationPopupModal.style.display = "none";
        }

        handleHideJobType(true)
        setShowPaymentScreen(false)
  };
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <div
      className="modal fade twm-sign-up"
      id="post_job_confirmation_popup"
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{zIndex: "1000"}}>
          <div className="modal-header">
            <h2 className="modal-title" id="sign_up_popupLabel2">
              Job Posted Successfully
            </h2>
            <p>You have submitted the job successfully.
              {/* <NavLink to="#" onClick={ navigateToJobDetail}>
                Click Here
              </NavLink> */}
               <span
                          className="twm-jobs-browse site-text-primary job-details"
                          onClick={navigateToJobDetail}
                        >
                          &nbsp;Click Here&nbsp;
                        </span>
              to view the posted job details.</p>
            {/* <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleAddMoreJobs}
            /> */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
              onClick={navigateToSearchJob}
            >
              View My Jobs
            </button>
            <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
              onClick={handleAddMoreJobs}
            >
              Add New Job
            </button>


          </div>
        </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
      <div id="modalBackdrop" class="modal-backdrop fade show" style={{zIndex: "1"}}></div>
    </div>
    
  );
};

export default ConfirmationPostJobPopUp;
