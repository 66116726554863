function SectionApplicantCoverLetter({ applicantDetailInfo, coverLetterText }) {
  return (
    <>
      <h4 className="twm-s-title">Resume / Cover Letter</h4>
      <div className="twm-timing-list-wrap">
        <div className="twm-s-info-inner" dangerouslySetInnerHTML={{ __html: coverLetterText }} />
      </div>
    </>
  );
}

export default SectionApplicantCoverLetter;