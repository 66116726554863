import React from "react";
import TableLoader from "../../../../common/tableLoader";
import SectionRecordsFilter from "../../../public-user/sections/common/section-records-filter";
import SectionPagination from "../../../public-user/sections/common/section-pagination";
import GetProfileImage from "../../../../common/getProfileImage";
import { publicUser } from "../../../../../globals/route-names";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import apiCall from "../../../../../apicall";
import CanPaymentDetailsPage from "../../components/can-payment-details-jobs";

const SectionCandidatePaymentInfo = () => {
  // const [tableFilter, setTableFilter] = useState({
  //   sortType: "createdDate",
  //   sortDirection: "desc",
  //   status: "",
  //   searchKey: ""
  // });

  // const _filterConfig = {
  //   prefix: "Showing",
  //   type: "Records",
  //   total: paginationPaymentData?.totalElements,
  //   showRange: false,
  //   showingUpto: "",
  // };

  // const StarRating = ({ rating }) => {
  //   const stars = [];
  //   for (let i = 0; i < 5; i++) {
  //     if (i < rating) {
  //       stars.push(
  //         <i
  //           key={i}
  //           className="feather feather-star"
  //           style={{ color: "#128c5e" }}
  //         />
  //       );
  //     } else {
  //       stars.push(
  //         <i
  //           key={i}
  //           className="feather feather-star"
  //           style={{ color: "lightgrey" }}
  //         />
  //       );
  //     }
  //   }
  //   return stars;
  // };

  // const handleRecordsPaymentPerPageChange = (event) => {
  //   setPaymentCurrentPage(1);
  //   setPaymentRowPerPage(event.target.value);
  // };

  return (
    <>
      {/* <div class="panel-heading wt-panel-heading p-a20 mb-4">
        <h3 class="wt-title">Payment Details</h3>
      </div> */}
      {/* <SectionRecordsFilter
        _config={_filterConfig}
        rowPerPage={rowPaymentPerPage}
        handleRecordsPerPageChange={handleRecordsPaymentPerPageChange}
        setTableFilter={setTableFilter}
        tableFilter={tableFilter}
      /> */}

      <div className="section-full p-t120  p-b90 site-bg-white">
        <div className="twm-right-section-panel candidate-save-job container">
          <div class="panel-heading wt-panel-heading p-a20">
            <h3 class="wt-title">Payment Details</h3>
          </div>
          <CanPaymentDetailsPage />
        </div>
      </div>
    </>
  );
};

export default SectionCandidatePaymentInfo;
