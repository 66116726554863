import { NavLink, useNavigate } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import SectionPagination from "../common/section-pagination";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import GetProfileImage from "../../../../common/getProfileImage";
import TableLoader from "../../../../common/tableLoader";
import { employerDetails } from "../../../../../store/reducers/menu";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../../globals/fomat-comapny-name";

function SectionEmpsList({
  isLoading,
  empListData,
  currentPage,
  setCurrentPage,
  totalPages,
}) {
  const { role_type } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const navigate = useNavigate();
//   const StarRating = ({ rating }) => {
//     const stars = [];
//     for (let i = 0; i < 5; i++) {
//       if (i < rating) {
//         stars.push(
//           <i
//             key={i}
//             className="feather feather-star"
//             style={{ color: "#128c5e" }}
//           />
//         );
//       } else {
//         stars.push(
//           <i
//             key={i}
//             className="feather feather-star"
//             style={{ color: "lightgrey" }}
//           />
//         );
//       }
//     }
//     return stars;
//   };

const StarRating = ({ rating }) => {
    const stars = [];
    console.log("rating----------", rating);
    // Iterate over 5 stars
    for (let i = 0; i < 5; i++) {
      if (rating >= i + 1) {
        // Full star if rating is greater than or equal to current index + 1
        stars.push(
          <i
            key={i}
            className="fas fa-star"
            style={{ color: '#128c5e' }}
          />
        );
      } else if (rating >= i + 0.5) {
        // Half star if rating is greater than or equal to current index + 0.5
        stars.push(
          <i
            key={i + 0.5}
            className="fas fa-star-half-alt"
            style={{ color: '#128c5e' }}
          />
        );
      } else {
        // Empty star otherwise
        stars.push(
          <i
            key={i}
            className="far fa-star"
            style={{ color: 'lightgrey' }}
          />
        );
      }
    }
  
    return <div>{stars}</div>;
  };
  const navigateToEmpDetails = (item) => {
    // console.log("emp item::::::", item)
    dispatch(employerDetails(item))
    navigate("/emp-detail", { state: item });
  };
  const formatAddress = (address, city, state, zipCode) => {
    const parts = [address, city, state, zipCode].filter(Boolean);
    return parts.join(", ");
  };
  return (
    <>
    <div>
        <Helmet>
          <title>{`Employer List | Companies Hiring | Employer Listings | Company Profiles | ${formatCompanyName()}`}</title>
          <meta name="description" content="Welcome to the Employer List! This section provides a comprehensive directory of companies actively hiring and seeking talent. Browse through a diverse range of employers to find potential job opportunities that align with your career goals." />
          <meta name="keywords" content="employers, hiring companies, company directory, Career Opportunities, Employer Listings, Job Openings" />
        </Helmet>
      </div>
      <div>
        <div className="twm-employer-list-wrap">
          <ul>
            {!isLoading ? (
              empListData?.length > 0 ? (
                empListData?.map((item) => (
                  <li key={item.id}>
                    <div className="twm-employer-list-style1 mb-5">
                      <div className="twm-media">
                        <GetProfileImage id={item?.id} type={"jobList"} />
                      </div>
                      <div className="twm-mid-content">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => navigateToEmpDetails(item)}
                          className="twm-job-title"
                        >
                          <h4>
                            {item?.name}
                            <span className="twm-job-post-duration">
                              {/* / {getTimeDifference(item?.createdDate)} */}
                            </span>
                          </h4>
                        </span>
                        <div className="twm-job-address">
                        {formatAddress(
                            item?.address,
                            item?.city,
                            item?.state,
                            item?.zipCode
                          )}
                        </div>
                        <div className="twm-job-address">
                        {moment
                            .utc(item?.createdDate, "YYYY-MM-DD HH:mm:ss")
                            .local()
                            .format("MM/DD/YYYY")}
                        </div>
                        <div>
                          <StarRating rating={item?.averageNumStars} />{" "}
                          <span style={{ fontSize: 12 }}>
                            {(item?.numberOfReview || 0) + " Reviews"}
                          </span>
                        </div>
                        <span className="twm-job-address">
                          {/* {displayJobTime(
                                                    item?.startTime,
                                                    item?.endTime,
                                                    item?.multipleDayJob
                                                    )} */}
                        </span>
                      </div>
                      <div className="twm-right-content">
                          <div className="twm-jobs-category green mb-5">
                            <span className="twm-bg-green">
                              {item?.distance?.toFixed(2)} Miles
                            </span>
                          </div>
                        <span
                          className="twm-jobs-browse site-text-primary job-details"
                          onClick={() => navigateToEmpDetails(item)}
                        >
                          More Info
                        </span>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                "No Record Found"
              )
            ) : (
              <TableLoader />
            )}
            {/* <li>
                            <div className="twm-employer-list-style1 mb-5">
                                <div className="twm-media">
                                    <JobZImage src="images/jobs-company/pic1.jpg" alt="#" />
                                </div>
                                <div className="twm-mid-content">
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                        <h4>Herbal Ltd</h4>
                                    </NavLink>
                                    <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">Accountancy</NavLink>
                                </div>
                                <div className="twm-right-content">
                                    <div className="twm-jobs-vacancies"><span>25</span>Vacancies</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="twm-employer-list-style1 mb-5">
                                <div className="twm-media">
                                    <JobZImage src="images/jobs-company/pic2.jpg" alt="#" />
                                </div>
                                <div className="twm-mid-content">
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                        <h4>Artistre Studio PVT Ltd</h4>
                                    </NavLink>
                                    <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">IT Contractor</NavLink>
                                </div>
                                <div className="twm-right-content">
                                    <div className="twm-jobs-vacancies"><span>30</span>Vacancies</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="twm-employer-list-style1 mb-5">
                                <div className="twm-media">
                                    <JobZImage src="images/jobs-company/pic3.jpg" alt="#" />
                                </div>
                                <div className="twm-mid-content">
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                        <h4 className="twm-job-title">Wins Developers</h4>
                                    </NavLink>
                                    <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">Banking</NavLink>
                                </div>
                                <div className="twm-right-content">
                                    <div className="twm-jobs-vacancies"><span>32</span>Vacancies</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="twm-employer-list-style1 mb-5">
                                <div className="twm-media">
                                    <JobZImage src="images/jobs-company/pic4.jpg" alt="#" />
                                </div>
                                <div className="twm-mid-content">
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                        <h4 className="twm-job-title">Thewebmax PVT Ltd</h4>
                                    </NavLink>
                                    <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">Digital &amp; Creative</NavLink>
                                </div>
                                <div className="twm-right-content">
                                    <div className="twm-jobs-vacancies"><span>38</span>Vacancies</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="twm-employer-list-style1 mb-5">
                                <div className="twm-media">
                                    <JobZImage src="images/jobs-company/pic5.jpg" alt="#" />
                                </div>
                                <div className="twm-mid-content">
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                        <h4 className="twm-job-title">Robo Tech</h4>
                                    </NavLink>
                                    <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">Sales &amp; Marketing</NavLink>
                                </div>
                                <div className="twm-right-content">
                                    <div className="twm-jobs-vacancies"><span>40</span>Vacancies</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="twm-employer-list-style1 mb-5">
                                <div className="twm-media">
                                    <JobZImage src="images/jobs-company/pic1.jpg" alt="#" />
                                </div>
                                <div className="twm-mid-content">
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                        <h4>Galaxy IT Solution</h4>
                                    </NavLink>
                                    <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">Leisure &amp; Tourismm</NavLink>
                                </div>
                                <div className="twm-right-content">
                                    <div className="twm-jobs-vacancies"><span>38</span>Vacancies</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="twm-employer-list-style1 mb-5">
                                <div className="twm-media">
                                    <JobZImage src="images/jobs-company/pic2.jpg" alt="#" />
                                </div>
                                <div className="twm-mid-content">
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                        <h4>Coderbotics solutions</h4>
                                    </NavLink>
                                    <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">Consultancy</NavLink>
                                </div>
                                <div className="twm-right-content">
                                    <div className="twm-jobs-vacancies"><span>35</span>Vacancies</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="twm-employer-list-style1 mb-5">
                                <div className="twm-media">
                                    <JobZImage src="images/jobs-company/pic3.jpg" alt="#" />
                                </div>
                                <div className="twm-mid-content">
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                        <h4 className="twm-job-title">Microsoft solution</h4>
                                    </NavLink>
                                    <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">Technologies</NavLink>
                                </div>
                                <div className="twm-right-content">
                                    <div className="twm-jobs-vacancies"><span>65</span>Vacancies</div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="twm-employer-list-style1 mb-5">
                                <div className="twm-media">
                                    <JobZImage src="images/jobs-company/pic4.jpg" alt="#" />
                                </div>
                                <div className="twm-mid-content">
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-title">
                                        <h4 className="twm-job-title">Dot Circle PVT Ltd</h4>
                                    </NavLink>
                                    <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                    <NavLink to={publicUser.employer.DETAIL1} className="twm-job-websites site-text-primary">Sales &amp; Marketing</NavLink>
                                </div>
                                <div className="twm-right-content">
                                    <div className="twm-jobs-vacancies"><span>50</span>Vacancies</div>
                                </div>
                            </div>
                        </li> */}
          </ul>
        </div>
        <SectionPagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
    </>
  );
}

export default SectionEmpsList;
