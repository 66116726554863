import SectionContact from "./section-contact";
import SectionLocation from "./section-location";
import SectionProfile from "./section-profile";
import SectionApplicantVerification from "./section-applicant-verification";
import SectionApplicantCoverLetter from "./section-applicant-cover-letter";
import { useSelector } from "react-redux";

function SectionEmployersCandidateSidebar({ type, applicantDetailInfo, coverLetterText }) {
  const { role_type } = useSelector((state)=>state.menu)
  return (
    <>
      <div className="side-bar-2 rightSidebar">
        {type === "1" ? (
          <>
            <div className="twm-s-map mb-5">
              <SectionLocation applicantDetailInfo={applicantDetailInfo} />
            </div>
            <div className="side-bar">
              <div className="twm-s-info2-wrap">
                <div className="twm-s-info2 twm-tabs-style-2">
                  <ul className="nav nav-tabs" id="myTab2" role="tablist">
                    {/*Login Candidate*/}
                    <li className="nav-item" style={{ width: "100%" }}>
                      <button
                        className="nav-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#applicant-document"
                        type="button"
                        style={{ width: "100%" }}
                      >
                        <i className="fas fa-user-tie" />
                        Documents
                      </button>
                    </li>
                    {/*Login Employer*/}
                    <li className="nav-item" style={{ width: "100%" }}>
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#applicant-certificates"
                        type="button"
                        style={{ width: "100%" }}
                      >
                        <i className="fas fa-award" />
                        Certificates
                      </button>
                    </li>
                    {
                      !role_type.includes('ROLE_SEEKER_USER_ADMIN') && 
                      <li className="nav-item" style={{ width: "100%" }}>
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#applicant-notes"
                        type="button"
                        style={{ width: "100%" }}
                      >
                        <i className="fas fa-book-open" />
                        Notes
                      </button>
                    </li>
                    }
                    
                  </ul>
                </div>
              </div>
            </div>
            <div className="twm-s-info-wrap mb-5">
              <SectionProfile applicantDetailInfo={applicantDetailInfo} />
            </div>
            <div className="twm-s-info-wrap mb-5">
              <SectionApplicantVerification applicantDetailInfo={applicantDetailInfo} />
            </div>
          </>
        ) : (
          <>
            <div className="twm-s-map mb-5">
              <SectionProfile />
            </div>
            <div className="twm-s-info-wrap mb-5">
              <SectionLocation />
            </div>
          </>
        )}
        {/* <div className="twm-s-contact-wrap mb-5">
          <SectionContact />
        </div> */}
      </div>
    </>
  );
}

export default SectionEmployersCandidateSidebar;
