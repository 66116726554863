import GoogleMapCotainer from "../../../../common/googleMap";

function SectionLocation({applicantDetailInfo}) {
    return (
        <>
            <h4 className="section-head-small mb-4">Location</h4>
            <div className="twm-m-map mb-2" style={{ height: "310px" }}>
                <GoogleMapCotainer mapDetails={applicantDetailInfo} />
                {/* <iframe height={270} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3304.8534521658976!2d-118.2533646842856!3d34.073270780600225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c6fd9829c6f3%3A0x6ecd11bcf4b0c23a!2s1363%20Sunset%20Blvd%2C%20Los%20Angeles%2C%20CA%2090026%2C%20USA!5e0!3m2!1sen!2sin!4v1620815366832!5m2!1sen!2sin" /> */}
            </div>
        </>
    )
}

export default SectionLocation;