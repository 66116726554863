import { NavLink, useNavigate } from "react-router-dom";
import JobZImage from "../../../../common/jobz-img";
import {
  canRoute,
  candidate,
  empRoute,
  employer,
  publicUser,
} from "../../../../../globals/route-names";
import { useState } from "react";
import processLogin from "../../../../form-processing/login";
import { formType } from "../../../../../globals/constants";
import JobSeekerRegister from "../../../../common/register/jobseekerregister";
import SignUpPopup from "../../../../common/popups/popup-signup";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../../globals/fomat-comapny-name";
function SignUppage() {
  const navigate = useNavigate();
  const [canusername, setCanUsername] = useState("guest");
  const [empusername, setEmpUsername] = useState("admin");
  const [password, setPassword] = useState("12345");

  const handleCandidateLogin = (event) => {
    event.preventDefault();
    loginCandidate();
  };

  const handleEmployerLogin = (event) => {
    event.preventDefault();
    loginEmployer();
  };

  const loginCandidate = () => {
    processLogin(
      {
        type: formType.LOGIN_CANDIDATE,
        username: canusername,
        password: password,
      },
      (valid) => {
        if (valid) {
          moveToCandidate();
        } else {
          // show error
          console.log("error");
        }
      }
    );
  };

  const loginEmployer = () => {
    processLogin(
      {
        type: formType.LOGIN_EMPLOYER,
        username: empusername,
        password: password,
      },
      (valid) => {
        if (valid) {
          moveToEmployer();
        } else {
          // show error
          console.log("error");
        }
      }
    );
  };

  const moveToCandidate = () => {
    navigate(canRoute(candidate.DASHBOARD));
  };

  const moveToEmployer = () => {
    navigate(empRoute(employer.DASHBOARD));
  };

  return (
    <>
      <div>
        <Helmet>
          <title>{`Sign Up | ${formatCompanyName()}`}</title>
          <meta name="description" content="" />
          <meta name="keywords" content="sign up" />
        </Helmet>
      </div>
      <div className="section-full p-t120  p-b90 site-bg-white">
        <div className=" sign-up-background-image">
          <div className="container">
            <div className="twm-right-section-panel">
              {/* <JobSeekerRegister /> */}
              <SignUpPopup />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUppage;
