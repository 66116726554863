import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Toast from "../../../../common/toast";
import Spinner from "../../../../common/spinner";
import apiCall from "../../../../../apicall";
import { userDetails } from "../../../../../store/reducers/menu";
import { NavLink } from "react-router-dom";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";

const CanPermission = () => {
  const { TOKEN, userInfo } = useSelector((state) => state.menu);
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailNotificationEnabled, setIsEmailNotificationEnabled] =
    useState(userInfo?.emailNotificationEnable);
  const [errSuccessMsg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  console.log("emailNotificationEnable",userInfo)

  const handleCheckboxChange = (e) => {
    setIsEmailNotificationEnabled(e.target.checked);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const api = `settings/emailNotificationEnable`;

      const emailNotificationResponse = await apiCall(
        api, "PUT", isEmailNotificationEnabled, TOKEN, false, false, false
      );

      console.log("API call successful:", emailNotificationResponse);

      if (emailNotificationResponse?.status === 200) {
        setIsLoading(false);
        setErrSuccessMsg({
          message: "Email notification settings updated successfully!",
          status: true,
        });
        dispatch(userDetails({
          ...userInfo,
          emailNotificationEnable: isEmailNotificationEnabled
        }));
      } else if (emailNotificationResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
      } else {
        displayErrMsg("Network error!");
      }
    } catch (error) {
      displayErrMsg("Network error!");
      console.error("API call failed:", error);
    }
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
  };

  // const updateEmailNotificationStatus =async () =>{
  //   try {
  //     const accountResponse = await apiCall(
  //       "account",
  //       "GET",
  //       null,
  //       TOKEN
  //     );
  //     console.log("accountResponse-----",accountResponse)
  //     if (accountResponse?.data?.id && accountResponse?.status === 200) {
  //       dispatch(userDetails(accountResponse?.data));
  //     } else {
  //       displayErrMsg(accountResponse?.response?.data?.message);
  //     }
  //   } catch (err) {
  //     displayErrMsg("Something went wrong!");
  //   }
  // }

  // useEffect(()=>{
  //   updateEmailNotificationStatus()
  // },[])

  useEffect(() =>{
    logEvent_Analytics('email_permission_screen_web');
  }, [])

  return (
    <div className="twm-right-section-panel candidate-save-job site-bg-gray">
      <NavLink className="twm-job-title">
        <h4>Email Permission</h4>
      </NavLink>
      <p>Change your email permission settings</p>
      <div className="pricing-block-outer">
        <div className="row justify-content-center">
          <div className="col-md-2 p-table-highlight"></div>
          <div className="col-md-8 p-table-highlight m-b30">
            <div className="pricing-table-1 circle-yellow">
              <div className="p-table-title">
                <h4 className="wt-title">
                  Check the box below to receive all email notifications:
                </h4>
              </div>
              <div className="p-table-inner text-center checkbox-container">
                <div className="p-table-price"></div>
                <span>
                  <input
                    type="checkbox"
                    id="emailNotificationCheckbox"
                    checked={isEmailNotificationEnabled}
                    onChange={handleCheckboxChange}
                    className="styled-checkbox"
                  />
                  <label htmlFor="emailNotificationCheckbox">
                    Enable email notifications
                  </label>
                  <br />
                  <button className="site-button mt-5" onClick={handleSave}>
                    {isLoading ? <Spinner /> : "Save"}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {errSuccessMsg?.message !== "" && <Toast message={errSuccessMsg} />}
    </div>
  );
};

export default CanPermission;
