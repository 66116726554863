import moment from "moment";

function SectionProfile({ applicantDetailInfo }) {
  return (
    <>
      <h4 className="section-head-small mb-4">Profile Info</h4>
      <div className="twm-s-info">
        <ul>
          {/* <li>
                        <div className="twm-s-info-inner">
                            <i className="fas fa-money-bill-wave" />
                            <span className="twm-title">Offered Salary</span>
                            <div className="twm-s-info-discription">$20 / Day</div>
                        </div>
                    </li> */}
          <li>
            <div className="twm-s-info-inner">
              <i className="fas fa-user-tie" />
              <span className="twm-title">Name</span>
              <div className="twm-s-info-discription">
                {applicantDetailInfo?.firstName + " " + applicantDetailInfo.lastName ? applicantDetailInfo?.firstName + " " + applicantDetailInfo.lastName : "-"}
              </div>
            </div>
          </li>
          <li>
            <div className="twm-s-info-inner">
              <i className="fas fa-calendar-alt" />
              <span className="twm-title">DOB</span>
              <div className="twm-s-info-discription">{moment(applicantDetailInfo?.dob, 'YYYY-MM-DD').format('MM/DD/YYYY')}</div>
            </div>
          </li>
          {/* <li>
                        <div className="twm-s-info-inner">
                            <i className="fas fa-venus-mars" />
                            <span className="twm-title">Gender</span>
                            <div className="twm-s-info-discription">Male</div>
                        </div>
                    </li> */}
          <li>
            <div className="twm-s-info-inner">
              <i className="fas fa-mobile-alt" />
              <span className="twm-title">Phone</span>
              <div className="twm-s-info-discription">
                {applicantDetailInfo?.phoneNumber}
              </div>
            </div>
          </li>
          <li>
            <div className="twm-s-info-inner">
              <i className="fas fa-at" />
              <span className="twm-title">Email</span>
              <div className="twm-s-info-discription">
                {applicantDetailInfo?.email}
              </div>
            </div>
          </li>
          {/* <li>
                        <div className="twm-s-info-inner">
                            <i className="fas fa-book-reader" />
                            <span className="twm-title">Qualification</span>
                            <div className="twm-s-info-discription">Developer</div>
                        </div>
                    </li> */}
          <li>
            <div className="twm-s-info-inner">
              <i className="fas fa-map-marker-alt" />
              <span className="twm-title">Address</span>
              <div className="twm-s-info-discription">{`${applicantDetailInfo?.address}, ${applicantDetailInfo?.city}, ${applicantDetailInfo?.state}, ${applicantDetailInfo?.zipCode}`}</div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}

export default SectionProfile;
