import SectionCandidateShortIntro1 from "../../sections/candidates/detail1/section-can-short-intro1";
import SectionCandidateAbout1 from "../../sections/candidates/detail1/section-can-about1";
import SectionCandidateSkills from "../../sections/candidates/section-can-skills";
import SectionCandidateExperience from "../../sections/candidates/section-can-experience";
import SectionCandidateEducation from "../../sections/candidates/section-can-education";
import SectionTabContent from "../../sections/candidates/detail1/section-can-tab";
import { useEffect, useState } from "react";
import { loadScript } from "../../../../../globals/constants";
import SectionEmployersCandidateSidebar from "../../sections/common/section-emp-can-sidebar";
import apiCall from "../../../../../apicall";
import { useLocation, useParams } from "react-router-dom";
import Toast from "../../../../common/toast";
import SessionExpired from "../../../../common/sessionExpired";
import { useSelector } from "react-redux";
import SectionApplicantCoverLetter from "../../sections/common/section-applicant-cover-letter";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../../globals/fomat-comapny-name";
// import { useAppSelector } from '../../../Redux/Hooks';

function CandidateDetail1Page() {
  const location = useLocation();
  const { id } = useParams();
  console.log("user:::::", id)


  const { role_type, TOKEN, candidateDetails } = useSelector((state) => state.menu);
  console.log("candidateDetails", candidateDetails)
  const [applicantDetailInfo, setApplicantDetailInfo] = useState({});
  const [applicantDetailsId, setApplicantDetailsId] = useState(
    location?.state?.userEntityId ? location?.state?.userEntityId : id 
  );
  const [applicantDetailsUserEntity, setApplicantDetailsUserEntity] = useState(
    location?.state?.userEntityId ? location?.state?.userEntityId : id 
  );
  const [coverLetterText, setCoverLetterText] = useState('');
  const [sessionExpired, setSessionExpired] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setApplicantDetailInfo({});
  };

  // const getApplicantDetailsData = async () => {
  //   try {
  //     const api = `${
  //       role_type === "ROLE_OPENSTAFF_ADMIN"
  //         ? `openstaff/applicants/${applicantDetailsId}/applicantDetails`
  //         : ``
  //     }`;
  //     const applicantDetailResponse = await apiCall(api, "GET", null, TOKEN);
  //     console.log("applicantDetailResponse---", applicantDetailResponse);

  //     if (applicantDetailResponse?.data?.userEntityId) {
  //       setSessionExpired(false);
  //       setApplicantDetailInfo(applicantDetailResponse.data);
  //     } else if (applicantDetailResponse?.response?.status == 401) {
  //       setSessionExpired(true);
  //       displayErrMsg("Your session has been expired!!");
  //     } else {
  //       setSessionExpired(false);
  //       // displayErrMsg("Something went wrong!");
  //       displayErrMsg(applicantDetailResponse?.response?.data?.message)
  //     }
  //   } catch (err) {
  //     setSessionExpired(false);
  //     displayErrMsg("Something went wrong!");
  //   }
  // };
  const cleanHtmlContent = (html) => {
    return html
      .replace(/^"|"$/g, '')           // Remove leading and trailing quotes
      .replace(/\\&quot;/g, '"')      // Replace escaped quotes with normal quotes
      .replace(/\\"/g, '"')            // Replace escaped double quotes with normal quotes
      .replace(/\\'/g, "'");           // Replace escaped single quotes with normal quotes
  };
  
  // const getApplicantDetailsData = async () => {
  //   try {
  //     const api = (role_type === "ROLE_OPENSTAFF_ADMIN" || role_type === "ROLE_USER_SEEKER_ADMIN")
  //       ? `openstaff/applicants/${applicantDetailsId}/applicantDetails`
  //       : "";
  
  //     const applicantDetailResponse = await apiCall(api, "GET", null, TOKEN);
  //     console.log("applicantDetailResponse---", applicantDetailResponse);  
  //     if (applicantDetailResponse?.data?.userEntityId) {
  //       setSessionExpired(false);
  //       setApplicantDetailInfo(applicantDetailResponse.data);
  
  //       // Clean up HTML content
  //       let coverLetter = applicantDetailResponse.data.coverLetter;
  //       coverLetter = cleanHtmlContent(coverLetter);
        
  //       // Set cover letter text
  //       setCoverLetterText(coverLetter);
  //       console.log("coverLetterText", coverLetterText);
  //     } else if (applicantDetailResponse?.response?.status == 401) {
  //       setSessionExpired(true);
  //       displayErrMsg("Your session has been expired!!");
  //     } else {
  //       setSessionExpired(false);
  //       displayErrMsg(applicantDetailResponse?.response?.data?.message);
  //     }
  //   } catch (err) {
  //     setSessionExpired(false);
  //     displayErrMsg("Something went wrong!");
  //   }
  // };

  const getApplicantDetailsData = async () => {
    try {
      const api = (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_SEEKER_USER_ADMIN"))
        ? `openstaff/applicants/${applicantDetailsId}/applicantDetails`
        : "";

      const applicantDetailResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("applicantDetailResponse---", applicantDetailResponse);  
      if (applicantDetailResponse?.data?.userEntityId) {
        setSessionExpired(false);
        setApplicantDetailInfo(applicantDetailResponse.data);

        // Clean up HTML content
        let coverLetter = applicantDetailResponse.data.coverLetter;
        coverLetter = cleanHtmlContent(coverLetter);
        
        // Set cover letter text
        setCoverLetterText(coverLetter);
        console.log("coverLetterText", coverLetterText);
      } else if (applicantDetailResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!!");
      } else {
        setSessionExpired(false);
        displayErrMsg(applicantDetailResponse?.response?.data?.message);
      }
    } catch (err) {
      setSessionExpired(false);
      displayErrMsg("Something went wrong!");
    }
  };
  
  useEffect(() => {
    loadScript("js/custom.js");
  });

  // useEffect(() => {
  //   getApplicantDetailsData();
  // }, []);
  useEffect(() => {
    if (applicantDetailsId) {
      getApplicantDetailsData();
    }
  }, [applicantDetailsId]);

  return (
    <>
      <div>
        <Helmet>
          <title>{`Detailed Candidate Information | Candidate Profile | Candidate Overview | ${formatCompanyName()}`}</title>
          <meta name="description" content="Welcome to the Candidate Details page! Here, you can find comprehensive information about a specific candidate seeking job opportunities. This page offers a thorough overview of their qualifications, skills, and experience, helping you assess their fit for your open positions." />
          <meta name="keywords" content="candidate details, candidate profile, candidate information, job seeker details, contact information, candidate summary, candidate insights, candidate overview"/>
        </Helmet>
      </div>
      <div className="section-full  p-t120 p-b90 bg-white">
        <div className="container">
          {/* BLOG SECTION START */}
          <div className="section-content">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8 col-md-12">
                {/* Candidate detail START */}
                <div className="cabdidate-de-info">
                  <SectionCandidateShortIntro1 
                  applicantDetailInfo={applicantDetailInfo}
                  />

                  {/* <SectionCandidateAbout1 /> */}

                  <SectionTabContent
                  applicantDetailsId={applicantDetailsUserEntity}
                  />

                  {/* <SectionCandidateSkills /> */}

                  {/* <SectionCandidateExperience /> */}

                  {/* <SectionCandidateEducation /> */}
                  <SectionApplicantCoverLetter applicantDetailInfo={applicantDetailInfo} coverLetterText={coverLetterText} />
                </div>
              </div>
              <div className="col-lg-4 col-md-12 rightSidebar">
                <SectionEmployersCandidateSidebar
                  type="1"
                  applicantDetailInfo={applicantDetailInfo}
                  coverLetterText={coverLetterText}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {sessionExpired && <SessionExpired />}
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default CandidateDetail1Page;
