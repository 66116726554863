import React from "react";
import Spinner from "../../../../common/spinner";
import InvitationTable from "./invitationTable";

const InvitationPopup = ({
  invitationList,
  invitationLoader,
  invitationListRowPerPage,
  handleInvitationListRecordsPerPageChange,
  invitationListPaginationData,
  invitationCurrentPage,
  setInvitationCurrentPage,
  tableFilter,
  setTableFilter,
  jobDetailId
}) => {
  return (
    <div
      className="modal fade twm-sign-up"
      id="invitation_popup"
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header" style={{margin:0}}>
            {/* <h2 className="modal-title" id="sign_up_popupLabel2">
              All Applicants List
            </h2> */}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <InvitationTable
              invitationList={invitationList}
              invitationLoader={invitationLoader}
              invitationListRowPerPage={invitationListRowPerPage}
              handleInvitationListRecordsPerPageChange={
                handleInvitationListRecordsPerPageChange
              }
              invitationListPaginationData={invitationListPaginationData}
              invitationCurrentPage={invitationCurrentPage}
              setInvitationCurrentPage={setInvitationCurrentPage}
              tableFilter={tableFilter}
              setTableFilter={setTableFilter}
              jobDetailId={jobDetailId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvitationPopup;
