import React, { useState } from "react";
import TableLoader from "../../../common/tableLoader";
import SectionRecordsFilter from "../../public-user/sections/common/section-records-filter";
import SectionPagination from "../../public-user/sections/common/section-pagination";
import { NavLink } from "react-router-dom";
import { loadScript } from "../../../../globals/constants";
import { useEffect } from "react";
import GetProfileImage from "../../../common/getProfileImage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {doc,getDoc, updateDoc, setDoc, onSnapshot  } from 'firebase/firestore';
import { db } from "../../../common/firebase/firebase";
import { jobDetailsData } from "../../../../store/reducers/menu";

const CanJobList = ({
  jobLists,
  isLoading,
  getAllChats,
  setIsJobList,
  isJobList,
  setJobId
}) => {
  const {isLoggedIn, role_type, userInfo } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _filterConfig = {
    prefix: "Showing",
    type: "Records",
    // total: paginationLocationData?.totalElements,
    total: 0,
    showRange: false,
    showingUpto: "",
  };
// const role_type=userInfo?.roleEntities[0]?.roleType
  useEffect(() => {
    loadScript("js/custom.js");
  });
  const getJobValue = (status, item) => {
    // console.log("statusssss", status, role_type);
    if (status === "eApplied" && role_type.includes("ROLE_USER")) {
      return <h4 style={{ color: "orange" }}>PENDING</h4>; // Return an empty string or handle it as needed
    } else if (status === "eAccepted" && role_type.includes("ROLE_USER")) {
      return <h4 style={{ color: "green" }}>HIRED</h4>;
    }else if (status === "eInprogress" && role_type.includes("ROLE_USER")) {
        return <h4 style={{ color: "green" }}>HIRED</h4>;
      } else if (
      ((status === "eCancelled" || status === "eInProgress") &&
        role_type.includes("ROLE_USER")) ||
      (status === "eInProgress" && role_type.includes("ROLE_INSTITUTION_ADMIN"))
    ) {
      return (
        <h4
          style={{
            color:
              item?.clockInStatus == "TIME PASSED"
                ? "black"
                : item?.clockInStatus === "Upcoming"
                ? "orange"
                : item?.clockInStatus === "Clocked Out"
                ? "red"
                : item?.clockInStatus === "Clocked In"
                ? "green"
                : "black",
          }}
        >
          {item?.clockInStatus?.toUpperCase()}
        </h4>
      );
    } else {
      return <h4>{status.startsWith("e") ? status.substring(1) : status}</h4>;
    }
  };
  const navigateToJobDetails = (item) => {
    // dispatch(jobDetailsData(item))
    navigate(`/job-detail/${item.id}`, { state: item });
  };
  const handleStartChat=async(jobItem)=>{
        try {
          const jobId=jobItem?.id
            const currentDateInSeconds = Math.floor(new Date().getTime() / 1000);
    
            const docRef = doc(db, 'chats', jobId);
              let chatObj={}
              if(userInfo?.roleEntities[0]?.roleType=="ROLE_USER"){
                chatObj={
                receiverId: jobItem?.userId|| 'unknown', // Replace with actual receiver ID
                receiverEmail: jobItem?.email|| 'unknown', // Replace with actual receiver email
                receiverFirstName: jobItem?.firstName|| 'unknown', // Replace with actual receiver first name
                receiverLastName: jobItem?.lastName|| 'unknown', 
                }
            }
                else if(userInfo?.roleEntities[0]?.roleType=="ROLE_INSTITUTION_ADMIN"){
                chatObj={ 
                receiverId: jobItem?.hireApplicantId|| 'unknown', // Replace with actual receiver ID
                receiverEmail: jobItem?.hireEmail|| 'unknown', // Replace with actual receiver email
                receiverFirstName: jobItem?.hireFirstName|| 'unknown', // Replace with actual receiver first name
                receiverLastName: jobItem?.hireLastName|| 'unknown', 
                    }
                
              }
              await setDoc(docRef, {
                message: [],
                jobId: jobId|| 'unknown',
                jobTitle: jobItem?.jobTitle|| 'unknown', // Replace with actual job title from props or state
                senderId: userInfo?.id|| 'unknown',
                senderEmail: userInfo?.email|| 'unknown',
                senderFirstname: userInfo?.firstName|| 'unknown',
                senderLastname: userInfo?.lastName|| 'unknown',
                startedOn:new Date(),
                _id: userInfo?.id+currentDateInSeconds,
                modifiedOn:new Date(),
                ...chatObj
              });
              getAllChats()
            
        } catch (error) {
        }
  }
  return (
    <>
      <SectionRecordsFilter
        _config={_filterConfig}
        // rowPerPage={rowLocationPerPage}
        // handleRecordsPerPageChange={handleRecordsLocationPerPageChange}
        // setTableFilter={setTableFilter}
        // tableFilter={tableFilter}
      />
      <div className="twm-candidates-list-wrap">
        <ul style={{ height: "700px", overflow: "auto" }}>
          {!isLoading ? (
            jobLists?.length > 0 ? (
              jobLists?.map((item) => (
                <li>
                  <div className="twm-candidates-list-style1 mb-5">
                    <div className="twm-mid-content">
                      <div style={{ width: "100%", display: "inline-block" }}>
                        {/* <NavLink to="" className="twm-job-title"> */}
                          <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="d-flex align-items-center mb-3">
                              <div style={{ height: "50px", width:'70px' }}>
                                {item?.institutionEntityId && (
                                  <GetProfileImage
                                    id={item?.institutionEntityId}
                                    type={"jobList"}
                                  />
                                )}
                              </div>
                              <div className="ms-3">
                                <h4 className="mb-0" style={{ cursor: 'pointer' }} onClick={() => navigateToJobDetails(item)}>{item?.jobTitle}</h4>
                                {getJobValue(
                                  item?.jobStatus,
                                  item,
                                  role_type
                                )}
                              </div>
                            </div>

                            <div className="d-flex align-items-center">
                              <button className="site-button button-sm my-3 float-right" onClick={()=>{
                                setIsJobList(!isJobList)
                                handleStartChat(item)
                                setJobId(item?.id)
                              }
                                
                              }
                                >
                                <i className="fas fa-comments ms-3"></i> Start
                                Chat
                              </button>
                            </div>
                          </div>
                        {/* </NavLink> */}
                        <div>
                          {item?.jobPosition && (
                            <p className="twm-candidate-address">
                              {" "}
                              Position : {item?.jobPosition}
                            </p>
                          )}
                          {!role_type.includes('ROLE_INSTITUTION_ADMIN') &&
                          <p className="twm-candidate-address">
                            {" "}
                            Poster : {item?.firstName + " " + item?.lastName}
                          </p>
}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              "No Record Found"
            )
          ) : (
            <TableLoader />
          )}
        </ul>
        {/* <SectionPagination
          currentPage={locationCurrentPage}
          setCurrentPage={setLocationCurrentPage}
          totalPages={paginationLocationData?.totalPages}
        /> */}
        {/* <PostJobPopup
          selectedItemId={selectedItemId}
          empDetailId={empDetailId}
          modalShow={modalShow}
        /> */}
      </div>
    </>
  );
};

export default CanJobList;
