function Spinner() {
  return (
    <center class='spinner'>
      <div className="wrapper">
        <div className="cssload-loader" />
      </div>
    </center>
  );
}

export default Spinner;
