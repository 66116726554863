import React, { useState } from "react";
import TableLoader from "../../../common/tableLoader";
import SectionRecordsFilter from "../../public-user/sections/common/section-records-filter";
import SectionPagination from "../../public-user/sections/common/section-pagination";
import GetProfileImage from "../../../common/getProfileImage";
import { publicUser } from "../../../../globals/route-names";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { loadScript } from "../../../../globals/constants";
import { useEffect } from "react";

const CanLocationList = ({
  locationList,
  locationLoader,
  rowLocationPerPage,
  handleRecordsLocationPerPageChange,
  paginationLocationData,
  locationCurrentPage,
  setLocationCurrentPage,
  tableFilter,
  setTableFilter,
}) => {

  const _filterConfig = {
    prefix: "Showing",
    type: "Records",
    total: paginationLocationData?.totalElements,
    showRange: false,
    showingUpto: "",
  };

  const StarRating = ({ rating }) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(
          <i
            key={i}
            className="feather feather-star"
            style={{ color: "#128c5e" }}
          />
        );
      } else {
        stars.push(
          <i
            key={i}
            className="feather feather-star"
            style={{ color: "lightgrey" }}
          />
        );
      }
    }
    return stars;
  };

  useEffect(() => {
    loadScript("js/custom.js");
  });
  return (
    <>
      <SectionRecordsFilter
        _config={_filterConfig}
        rowPerPage={rowLocationPerPage}
        handleRecordsPerPageChange={handleRecordsLocationPerPageChange}
        setTableFilter={setTableFilter}
        tableFilter={tableFilter}
      />
      <div className="twm-candidates-list-wrap">
        {console.log("section record filter",locationList)}
        <ul>
          {!locationLoader ? (
            locationList?.length > 0 ? (
              locationList?.map((item) => (
                <li>
                  <div className="twm-candidates-list-style1 mb-5">
                    {/* <div className="twm-media">
                      <div className="twm-media-pic">
                        <GetProfileImage
                          id={item?.id}
                          type={"applicant"}
                        />
                      </div>
                    </div> */}
                    <div className="twm-mid-content">
                    <div style={{width: "50%", display:"inline-block"}}>
                      <span
                        className="twm-job-title"
                      >
                        <h4><b>{`${item?.locationName}`}</b></h4>
                      </span>
                      <p className="twm-candidate-address">
                         {item?.address}
                        </p>
                      {
                        item?.suitNumber && 
                        <div>
                        <p className="twm-candidate-address">
                          {item?.suitNumber}
                        </p>
                      </div>
                      }
                      <div>
                        <p className="twm-candidate-address">
                          {item?.city}, {item?.state} {item?.country} {item?.zipCode}
                        </p>
                      </div>
                      </div>
                      {/* <div style={{width: "50%", display:"inline-block", textAlign:"right"}}>
                      <a
                        className="site-button"
                        data-bs-toggle="modal"
                        href={"#sign_up_popup3"}
                        role="button"
                        onClick={() => handlePostJobClick(item.id)}
                      >
                        Post job
                      </a>
                      </div> */}
                    </div>
                  </div>
                </li>
              ))
            ) : (
              "No Record Found"
            )
          ) : (
            <TableLoader />
          )}
        </ul>
        <SectionPagination
          currentPage={locationCurrentPage}
          setCurrentPage={setLocationCurrentPage}
          totalPages={paginationLocationData?.totalPages}
        />
        {/* <PostJobPopup
          selectedItemId={selectedItemId}
          empDetailId={empDetailId}
          modalShow={modalShow}
        /> */}
      </div>
    </>
  );
};

export default CanLocationList;
